export function filterObject<T extends string, U>(
    obj: Record<T, U>,
    filter: (key: T, value: U) => boolean,
): Record<T, U> {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => filter(key as T, value as U))) as Record<
        T,
        U
    >;
}

export function mapObjectValues<T, U>(obj: Record<string, T>, mapFn: (value: T) => U): Record<string, U> {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapFn(value)]));
}
