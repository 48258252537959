import React from "react";
import { getMixpanelInstance } from "../mixpanel";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { useQuery } from "@tanstack/react-query";
import { CURRENT_USER_KEY } from "../react-query/queryKeys";
import { H } from "highlight.run";

export function useCurrentUserQuery() {
    const service = React.useMemo(() => getBackendService(), []);
    const loggedIn = isLoggedIn();
    const identifyUserQuery = useQuery({
        queryFn: async () => {
            const user = await service.getCurrentUser();
            if (user == null) {
                throw new Error("User is not logged in");
            }
            const mixpanel = getMixpanelInstance();
            mixpanel?.identify(user.username);
            mixpanel?.people.set({
                $email: user.email,
                $plan_type: user.plan_type,
            });

            H.identify(user.username, {
                email: user.email ?? "",
                plan_type: user.plan_type,
            });
            return user;
        },
        staleTime: 1000 * 60 * 60,
        queryKey: CURRENT_USER_KEY,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: loggedIn,
        refetchInterval: () => (isLoggedIn() ? 1000 * 60 * 5 : 10000),
        meta: {
            toastOnFailure: false,
        },
    });

    return identifyUserQuery;
}
