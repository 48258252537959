import * as React from "react";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    CircularProgress,
    TextField,
    Tooltip,
    useTheme,
} from "@mui/material";
import { ValueChip } from "./valueChip";
import { getBackendService } from "../../services/cb-backend";
import { debounce } from "lodash-es";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { identity } from "../../utils/functions";
import { getReactQueryApolloTitleSearchQuery } from "../../react-query/queryKeys";
import IconButton from "@mui/material/IconButton";
import { Copy, TickCircle } from "iconsax-react";

export interface SearchableApolloPersonRoleSelectProps {
    fullWidth?: boolean;
    style?: "small" | "medium";
    values: string[];
    setValues: (vs: Array<string>) => void;
}

export const SearchableApolloPersonRoleSelect: React.FC<SearchableApolloPersonRoleSelectProps> = ({
    values,
    style = "medium",
    setValues,
    fullWidth,
}) => {
    const service = React.useMemo(() => getBackendService(), []);

    const [debouncedInputValue, setDebouncedInputValue] = React.useState<string>("");

    const handleDebouncedInputChange = React.useMemo(() => debounce(setDebouncedInputValue, 300), []);

    const { data: autocompleteValues, isFetching } = useQuery({
        queryKey: getReactQueryApolloTitleSearchQuery(debouncedInputValue),
        queryFn: () => service.apolloTitleAutocomplete({ query: debouncedInputValue }),
        enabled:
            debouncedInputValue.trim() !== "" && debouncedInputValue.length < 50 && !debouncedInputValue.includes(","),
        initialData: { titles: [] },
        placeholderData: keepPreviousData,
        select: data => data.titles,
        meta: {
            toastOnFailure: false,
        },
    });

    const handleChange = React.useCallback(
        (e: unknown, v: string[]) => {
            setValues(v);
        },
        [setValues],
    );

    const handleSetInputValue = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            handleDebouncedInputChange(e.target.value);
        },
        [handleDebouncedInputChange],
    );

    const handlePaste = React.useCallback(
        (event: React.ClipboardEvent<HTMLDivElement>) => {
            event.preventDefault();
            const pastedText = event.clipboardData.getData("text");
            const pastedValues = pastedText
                .split(",")
                .map(v => v.trim())
                .filter(v => v);
            setValues([...new Set([...values, ...pastedValues])]);
        },
        [values, setValues],
    );

    const renderInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                placeholder="Enter titles"
                size="small"
                onChange={handleSetInputValue}
                InputProps={{
                    ...params.InputProps,
                    sx: {
                        typography: style === "small" ? "body2" : "auto",
                        // Add padding override for the MuiOutlinedInput-input class
                        "& .MuiOutlinedInput-input": {
                            padding: style === "small" ? "2px 4px !important" : "auto",
                        },
                        // on  focus within show the copy button or hover
                        "&:focus-within, &:hover": {
                            "& #copy-button": {
                                visibility: "visible",
                            },
                        },
                    },
                    endAdornment: (
                        <React.Fragment>
                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                            {values.length > 0 && <CopyButton values={values} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        ),
        [handleSetInputValue, style, isFetching, values],
    );

    const handleClose = React.useCallback(() => {
        setDebouncedInputValue("");
    }, []);

    const getOptionLabel = React.useCallback((option: string) => option, []);

    const optionsWithInput = React.useMemo(() => {
        if (isFetching) {
            return autocompleteValues;
        }
        const inputValue = debouncedInputValue.trim().toLowerCase();
        if (inputValue && !autocompleteValues.includes(inputValue)) {
            return [inputValue, ...autocompleteValues];
        }
        return autocompleteValues;
    }, [autocompleteValues, debouncedInputValue, isFetching]);

    return (
        <Autocomplete
            freeSolo
            fullWidth={fullWidth}
            multiple
            filterSelectedOptions
            onClose={handleClose}
            id="searchable-entity-select"
            options={optionsWithInput}
            getOptionLabel={getOptionLabel}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <ValueChip {...tagProps} label={getOptionLabel(option)} key={key} />;
                })
            }
            noOptionsText={
                debouncedInputValue.trim() === ""
                    ? "Start typing for suggestions"
                    : isFetching
                      ? "Searching…"
                      : "No results found"
            }
            renderInput={renderInput}
            onChange={handleChange}
            value={values}
            loading={isFetching}
            filterOptions={identity}
            onPaste={handlePaste}
        />
    );
};

const CopyButton: React.FC<{ values: string[] }> = ({ values }) => {
    const [copied, setCopied] = React.useState(false);
    const theme = useTheme();

    const handleCopy = () => {
        void navigator.clipboard.writeText(values.join(", "));
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <Tooltip title={copied ? "Successfully copied" : `Copy ${values.length} titles to clipboard`}>
            <IconButton
                id="copy-button"
                onClick={handleCopy}
                size="small"
                sx={{
                    visibility: "hidden",
                }}
            >
                {copied ? <TickCircle size="20" color={theme.palette.success.main} /> : <Copy size="20" />}
            </IconButton>
        </Tooltip>
    );
};
