import * as React from "react";
import { Button, ButtonGroup, useTheme, Typography, Box, SxProps, Theme } from "@mui/material";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

interface PaginationFooterProps {
    selectedPage: number;
    onSelectPage: (page: number) => void;
    numPages: number;
    mutedSubtitle?: string;
    mutedRightSubtitle?: string;
    sx?: SxProps<Theme>;
}

const MAX_NUM_PAGES_TO_SHOW = 5;

export const PaginationFooter: React.FC<PaginationFooterProps> = ({
    selectedPage,
    onSelectPage,
    numPages,
    mutedSubtitle,
    mutedRightSubtitle,
    sx: parentSx,
}) => {
    const pages = React.useMemo(() => {
        if (numPages <= MAX_NUM_PAGES_TO_SHOW) {
            return Array.from({ length: numPages }, (_, i) => i + 1);
        }

        const pages = [];
        const lastPage = numPages;

        if (selectedPage <= 2) {
            pages.push(1, 2, "...", lastPage - 1, lastPage);
        } else if (selectedPage >= lastPage - 1) {
            pages.push(1, 2, "...", lastPage - 1, lastPage);
        } else {
            pages.push(1, "...", selectedPage, "...", lastPage);
        }

        return pages;
    }, [numPages, selectedPage]);

    const handlePageClick = React.useCallback(
        (page: number) => {
            onSelectPage(page);
        },
        [onSelectPage],
    );

    const handleBack = React.useCallback(() => {
        onSelectPage(selectedPage - 1);
    }, [onSelectPage, selectedPage]);

    const handleNext = React.useCallback(() => {
        onSelectPage(selectedPage + 1);
    }, [onSelectPage, selectedPage]);

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    flexGrow: 0,
                    bgcolor: "#F4F2EF",
                    pr: 1,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(parentSx) ? parentSx : [parentSx]),
            ]}
        >
            <Typography
                variant="body2"
                noWrap
                color="#667085"
                sx={{
                    alignSelf: "center",
                    visibility: mutedSubtitle != null ? "visible" : "hidden",
                }}
            >
                {mutedSubtitle}
            </Typography>
            <ButtonGroup
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Button
                    onClick={handleBack}
                    disabled={selectedPage === 1}
                    color="secondary"
                    variant="text"
                    sx={{
                        textTransform: "none",
                        color: "primary.contrastText",
                    }}
                    startIcon={<ArrowLeft2 size={16} />}
                    size="small"
                >
                    Back
                </Button>
                {pages.map((page, index) =>
                    typeof page === "number" ? (
                        <PageButton
                            key={index}
                            page={page}
                            selected={page === selectedPage}
                            onClick={handlePageClick}
                        />
                    ) : (
                        <Typography
                            key={index}
                            sx={{
                                px: 1,
                                py: 0.5,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {page}
                        </Typography>
                    ),
                )}
                <Button
                    onClick={handleNext}
                    disabled={selectedPage === numPages}
                    color="secondary"
                    variant="text"
                    sx={{
                        textTransform: "none",
                        color: "primary.contrastText",
                    }}
                    size="small"
                    endIcon={<ArrowRight2 size={16} />}
                >
                    Next
                </Button>
            </ButtonGroup>
            <Typography
                variant="body2"
                color="#667085"
                noWrap
                sx={{
                    alignSelf: "center",
                    visibility: mutedRightSubtitle != null ? "visible" : "hidden",
                }}
            >
                {mutedRightSubtitle ?? mutedSubtitle}
            </Typography>
        </Box>
    );
};

interface PageButtonProps {
    page: number;
    selected: boolean;
    onClick: (page: number) => void;
}

const PageButton: React.FC<PageButtonProps> = ({ page, selected, onClick }) => {
    const theme = useTheme();
    const handleClick = React.useCallback(() => {
        onClick(page);
    }, [onClick, page]);
    return (
        <Button
            onClick={handleClick}
            color={selected ? "secondary" : "primary"}
            variant="text"
            sx={{
                backgroundColor: selected ? "#E3DAC8" : undefined,
                color: selected ? theme.palette.secondary.main : "primary.contrastText",
                px: 0,
            }}
            size="small"
        >
            {page}
        </Button>
    );
};
