import { EditableCrunchbaseFilter, EditableIncompleteCrunchbaseFilter, getIncompleteCrunchbaseFilterParts } from "../home/filters/types";

const NUM_FILTER_FIELDS = 3;

export function getCreateDisabledReason(
    query: string,
    filters: EditableCrunchbaseFilter[],
    newFilter: EditableIncompleteCrunchbaseFilter,
    hasChanges: boolean,
    mode: "creating" | "editing",
): string | undefined {
    const incompleteFilterPartByFilter = filters
        .map(f => getIncompleteCrunchbaseFilterParts(f.filter))
        .filter(parts => parts.length > 0);

    const newFilterIncompleteParts = getIncompleteCrunchbaseFilterParts(newFilter.filter);
    const isNewFilterPartial =
        newFilterIncompleteParts.length !== 0 && newFilterIncompleteParts.length < NUM_FILTER_FIELDS;
    if (incompleteFilterPartByFilter.some(parts => parts.length > 0) || isNewFilterPartial) {
        const plural = incompleteFilterPartByFilter.length + (isNewFilterPartial ? 1 : 0) > 1;

        const incompleteNewFilterStr = isNewFilterPartial
            ? `New filter is missing ${newFilterIncompleteParts.join(", ")}`
            : "";
        const incompleteFilterPartsStr =
            incompleteFilterPartByFilter
                .map((part, idx) => `Filter ${idx + 1} is missing ${part.join(", ")}`)
                .join(", ") +
            (incompleteFilterPartByFilter.length > 0 ? ". " : "") +
            incompleteNewFilterStr;

        return mode === "creating"
            ? `Finish editing filter${plural ? "s" : ""} before creating a grid. ${incompleteFilterPartsStr}`
            : `Finish editing filter${plural ? "s" : ""} before saving changes. ${incompleteFilterPartsStr}`;
    }
    if (query.trim() === "" && filters.length === 0 && newFilterIncompleteParts.length > 0) {
        return mode === "creating" ? "Configure filters or enter a query" : "Configure filters";
    }
    if (mode === "editing" && !hasChanges) {
        return "No changes to save";
    }
    return undefined;
}
