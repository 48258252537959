import * as React from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Box, CircularProgress } from "@mui/material";
import { AnswersGridHeader } from "./AnswersGridHeader";
import {
    Column,
    Row,
    isRealRow,
    INITIAL_COLUMNS,
    INITIAL_ROWS,
    RowGeneratingFilters,
    Grid,
    SelectedCell,
    SelectedCellInfo,
    COMPANIES_COLLECTION_DESC,
    ANYTHING_COLLECTION_DESC,
    VisibilityFiltersByColumn,
    getStringKeyFromRow,
    randomUniqueId,
    ExportMode,
    ExportProgress,
    LoadingStatus,
    isKeyColumn,
    getUnloadedCellsColQueriesWithRows,
    PAGE_SIZE,
    getRowsInPage,
    CellValueV2,
    RowValueWithCitations,
    CellValueV2String,
} from "./grid";
import { AbstractBackendService, getBackendService } from "../services/cb-backend";
import { asCompleteColumnForQuery, useRetryFailedColumnValues } from "./useUpdateQueries";
import {
    convertBackendGridDataToFrontend,
    convertBackendGridToFrontend,
    convertFrontendGridDataToGridData,
} from "./gridSerialization";
import { SelectedCellSidebar } from "../selected-cell-components/selectedCellSidebar";
import { AnswersGridTable, GeneratedByFiltersInfo } from "./AnswersGridTable";
import { cellValueV2ToString, hasMoreRows, isFinalTaskStatus } from "./gridUtils";
import { getReactQueryGridKey, getReactQueryTaskKey } from "../react-query/queryKeys";
import { PaginationFooter } from "./paginationFooter";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { getVisibleRows } from "../home/visibility/visibility";
import { useSaveCurrentGrid, getSaveCurrentGridSync } from "./useSaveGrid";
import { useColumnEdits, useGenerateNewRows, useRowEdits } from "./useEdits";
import debounce from "lodash-es/debounce";
import { useOnMount } from "../utils/hooks";
import { MarkColumnAsWebsiteRequest, PivotGridRequest } from "../services/cb-backend-types";
import { useNavigate } from "react-router-dom";

const EMPTY_ROW_GENERATING_FILTERS: RowGeneratingFilters = { filters: [], pagingInfo: undefined };

export interface AnswersGridProps {
    gridId: string;
    grid: Grid;
}

export const AnswersGrid: React.FC<AnswersGridProps> = ({ gridId, grid }) => {
    const isEmptyGrid = grid.columns.length <= 1 && grid.rows.length === 0;
    const [rows, setRows] = React.useState<Row[]>(isEmptyGrid ? INITIAL_ROWS : grid.rows);
    const [columns, setColumns] = React.useState<Column[]>(isEmptyGrid ? INITIAL_COLUMNS : grid.columns);
    const [rowGeneratingFilters, setRowGeneratingFilters] = React.useState<RowGeneratingFilters>(
        grid.rowGeneratingFilters ?? EMPTY_ROW_GENERATING_FILTERS,
    );
    const [visibilityFiltersByColumn, setVisibilityFiltersByColumn] = React.useState<VisibilityFiltersByColumn>(
        grid.visibilityFiltersByColumn ?? {},
    );

    const setStateAfterExport = React.useCallback(
        (gridToSet: Grid) => {
            setColumns(gridToSet.columns);
            setRows(gridToSet.rows);
            setRowGeneratingFilters(gridToSet.rowGeneratingFilters);
        },
        [setColumns, setRows, setRowGeneratingFilters],
    );

    const service = React.useMemo(() => getBackendService(), []);

    const { handleGenerateNewRows, onLoadAllUnloadedCells, exportMode, setExportMode } = useGenerateNewRows(
        gridId,
        service,
        rows,
        columns,
        grid.version,
        rowGeneratingFilters,
    );

    const queryClient = useQueryClient();
    const exportStatusQuery = useQuery({
        queryKey: getReactQueryTaskKey(grid.lastExportTaskId),
        queryFn: async () => {
            // TODO: can never happen
            if (grid.lastExportTaskId == null) {
                return null;
            }
            // TODO: What happens if the task ID no longer exists?
            const status = await service.checkTaskStatus(grid.lastExportTaskId);

            // TODO: Hackhackhack
            // When export is done, set the state with the new grid
            if (status.status === "SUCCESS") {
                const grid = await fetchGrid(service, gridId);
                if (grid != null) {
                    queryClient.setQueryData(getReactQueryGridKey(gridId), grid);
                    // TODO: Only overwrite existing columns, not isEditing ones
                    setStateAfterExport(grid);
                }
            }
            // TODO: Hack. If the user refreshes the page this won't work.
            if (exportMode == ExportMode.QuickAdd) {
                await service.updateGrid(gridId, {
                    last_export_task_id: undefined,
                });
            }
            return status;
        },
        enabled: grid.lastExportTaskId != null,
        // TODO: Pick fetch intervals based on how long it will take (e.g. columns and row count)
        refetchInterval: q =>
            (q.state.data != null && isFinalTaskStatus(q.state.data.status)) || q.state.error ? false : 3000,
        refetchOnWindowFocus: q => q.state.data != null && !isFinalTaskStatus(q.state.data.status),
        refetchOnMount: q => q.state.data != null && !isFinalTaskStatus(q.state.data.status),
        refetchOnReconnect: q => q.state.data != null && !isFinalTaskStatus(q.state.data.status),
    });

    const maybeExportProgress = React.useMemo(
        (): ExportProgress | undefined =>
            exportStatusQuery.data != null
                ? {
                      total: exportStatusQuery.data.desired_num_rows,
                      numExported: exportStatusQuery.data.exported_rows,
                      status: exportStatusQuery.data.status,
                      taskId: exportStatusQuery.data.task_id,
                      exportMode,
                  }
                : undefined,
        [exportStatusQuery.data, exportMode],
    );

    if (
        maybeExportProgress != null &&
        maybeExportProgress.exportMode !== ExportMode.QuickAdd &&
        exportStatusQuery.status === "pending" &&
        exportStatusQuery.fetchStatus === "fetching"
    ) {
        return (
            <SidebarLayout selectedGridId={gridId}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "primary.main",
                        borderRadius: 3,
                    }}
                >
                    <CircularProgress
                        size={16}
                        sx={{
                            color: "primary.contrastText",
                        }}
                    />
                </Box>
            </SidebarLayout>
        );
    }

    return (
        <AnswersGridContent
            gridId={gridId}
            grid={grid}
            maybeExportData={maybeExportProgress}
            rows={rows}
            columns={columns}
            rowGeneratingFilters={rowGeneratingFilters}
            visibilityFiltersByColumn={visibilityFiltersByColumn}
            onGenerateNewRows={handleGenerateNewRows}
            onLoadAllUnloadedCells={onLoadAllUnloadedCells}
            setExportMode={setExportMode}
            setColumns={setColumns}
            setRows={setRows}
            setRowGeneratingFilters={setRowGeneratingFilters}
            setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
        />
    );
};

type AnswersGridContentProps = AnswersGridProps & {
    maybeExportData: ExportProgress | undefined;
    rows: Row[];
    columns: Column[];
    rowGeneratingFilters: RowGeneratingFilters;
    visibilityFiltersByColumn: VisibilityFiltersByColumn;
    onGenerateNewRows: (rowCount: number, mode: ExportMode) => Promise<void>;
    onLoadAllUnloadedCells: () => Promise<void>;
    setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>;
    setRows: React.Dispatch<React.SetStateAction<Row[]>>;
    setRowGeneratingFilters: React.Dispatch<React.SetStateAction<RowGeneratingFilters>>;
    setVisibilityFiltersByColumn: React.Dispatch<React.SetStateAction<VisibilityFiltersByColumn>>;
};

const AnswersGridContent: React.FC<AnswersGridContentProps> = ({
    gridId,
    grid,
    maybeExportData,
    rows,
    columns,
    rowGeneratingFilters,
    visibilityFiltersByColumn,
    setColumns,
    setExportMode,
    onGenerateNewRows,
    onLoadAllUnloadedCells,
    setRows,
    setRowGeneratingFilters,
    setVisibilityFiltersByColumn,
}) => {
    const service = React.useMemo(() => getBackendService(), []);

    const [cellGenerationStatusesByColByRow, setCellGenerationStatusesByColByRow] = React.useState<
        Record<string, Record<string, LoadingStatus | undefined>>
    >({});

    const [rowGenerationStatuses, setRowGenerationStatuses] = React.useState<Record<string, LoadingStatus | undefined>>(
        {},
    );

    const [isRightSidebarOpen, setIsRightSidebarOpen] = React.useState(false);
    const [selectedCell, setSelectedCell] = React.useState<SelectedCell | undefined>(undefined);

    const handleOpenRightSidebar = React.useCallback(() => setIsRightSidebarOpen(true), []);
    const handleCloseRightSidebar = React.useCallback(() => setIsRightSidebarOpen(false), []);

    const collectionTypeName = React.useMemo(
        () => (rowGeneratingFilters.filters.length > 0 ? COMPANIES_COLLECTION_DESC : ANYTHING_COLLECTION_DESC),
        [rowGeneratingFilters.filters.length],
    );

    const {
        handleEditKeyColumnCell,
        handleDeleteRow,
        handleOnAddRowWithRowKey,
        handleStartAddingNewRows,
        handleChangePlaceholderRowValue,
        handleBlurLastRow,
        isLastPlaceholderRowFocused,
    } = useRowEdits(
        columns,
        rows,
        setColumns,
        setRows,
        setRowGenerationStatuses,
        rowGenerationStatuses,
        collectionTypeName,
    );

    const isExportOngoing = React.useMemo(() => {
        const exportOngoing = maybeExportData != null && !isFinalTaskStatus(maybeExportData.status);
        return exportOngoing;
    }, [maybeExportData]);

    const isSomeRowLoading = React.useMemo(
        () => Object.values(rowGenerationStatuses).some(status => status === "loading"),
        [rowGenerationStatuses],
    );

    const isSomeColumnLoading = React.useMemo(
        () =>
            columns.some(
                col =>
                    (!isKeyColumn(col) && col.generatedBy == null) ||
                    (col.generatedBy?.type === "web_search" && col.generatedBy.toolConfig?.toolName == null),
            ),
        [columns],
    );

    const isSomeCellLoading = React.useMemo(() => {
        return Object.values(cellGenerationStatusesByColByRow).some(statuses =>
            Object.values(statuses).some(status => status === "loading"),
        );
    }, [cellGenerationStatusesByColByRow]);

    const visibleRows = React.useMemo(
        () => getVisibleRows(rows, columns, visibilityFiltersByColumn),
        [rows, columns, visibilityFiltersByColumn],
    );

    const { page, numPages, onPageChange } = usePagingStateAndHooks(visibleRows.length, gridId);

    const rowsInPage = React.useMemo(() => getRowsInPage(page, visibleRows), [page, visibleRows]);

    const selectedCellInfo = React.useMemo((): SelectedCellInfo | undefined => {
        if (selectedCell == null) {
            return undefined;
        }
        const row = rowsInPage.find(row => getStringKeyFromRow(row) === selectedCell.rowStringKey);
        if (row == null || !isRealRow(row)) {
            return undefined;
        }
        if (!(selectedCell.colId in row.data)) {
            return undefined;
        }
        return {
            rowKey: row.data.name.value.value,
            valueWithCitations: row.data[selectedCell.colId],
            columnGeneratedBy: columns.find(column => column.id === selectedCell.colId)?.generatedBy,
        };
    }, [columns, rowsInPage, selectedCell]);

    const generatedByFiltersInfo = React.useMemo((): GeneratedByFiltersInfo | undefined => {
        if ((rowGeneratingFilters?.filters?.length ?? 0) === 0) {
            return undefined;
        }
        return {
            hasMoreRows: hasMoreRows(rowGeneratingFilters, rows.length),
        };
    }, [rowGeneratingFilters, rows.length]);

    const handleGenerateIncrementalRows = React.useCallback(
        (incrementalRowCount: number, exportMode: ExportMode) =>
            onGenerateNewRows(rows.length + incrementalRowCount, exportMode),
        [onGenerateNewRows, rows.length],
    );

    const name = grid.name;
    useAutoSave(
        gridId,
        service,
        columns,
        rows,
        rowGeneratingFilters,
        visibilityFiltersByColumn,
        name,
        grid ?? undefined,
        isExportOngoing,
        isSomeCellLoading,
    );

    const { onAddColumnValuesToThisPage, onAddColumnValues, handleDeleteColumn, handleAddColumn, onChangeCellValue } =
        useColumnEdits(
            columns,
            rowsInPage,
            setColumns,
            setRows,
            setCellGenerationStatusesByColByRow,
            collectionTypeName,
        );

    const currentPageRef = React.useRef(page);

    const loadUnloadedCells = React.useCallback(
        (newPage: number) => {
            if (isExportOngoing) {
                return;
            }

            const visibleRowsInPage = getRowsInPage(newPage, visibleRows);
            const colQueriesWithRows = getUnloadedCellsColQueriesWithRows(columns, visibleRowsInPage);

            if (colQueriesWithRows.length > 0) {
                void onAddColumnValues(colQueriesWithRows, columns);
            }
        },
        [columns, isExportOngoing, onAddColumnValues, visibleRows],
    );

    const debouncedLoadUnloadedCells = React.useMemo(() => {
        return debounce(
            (
                loadUnloadedCells: (page: number) => void,
                page: number,
                currentPageRef: React.MutableRefObject<number>,
            ) => {
                if (currentPageRef.current === page) {
                    loadUnloadedCells(page);
                }
            },
            1500,
        );
    }, []);

    const debouncedLoadUnloadedCellsOnMount = React.useMemo(() => {
        return debounce(() => {
            loadUnloadedCells(page);
        }, 1500);
    }, [loadUnloadedCells, page]);

    useOnMount(() => {
        debouncedLoadUnloadedCellsOnMount();
    });

    const columnsWithUnloadedCells = React.useMemo(() => {
        return new Set(
            rows
                .filter(isRealRow)
                .flatMap(row => Object.keys(row.data).filter(colId => row.data[colId].value.type === "unloaded")),
        );
    }, [rows]);

    const handlePageChange = React.useCallback(
        (newPage: number) => {
            onPageChange(newPage);
            currentPageRef.current = newPage;
            debouncedLoadUnloadedCells(loadUnloadedCells, newPage, currentPageRef);
        },
        [onPageChange, debouncedLoadUnloadedCells, loadUnloadedCells],
    );

    const retryFailedColumnValues = useRetryFailedColumnValues(service);
    const handleRetryColumnValues = React.useCallback(
        async (column: Column, errorValueType: CellValueV2["type"] = "error") => {
            const completeCol = asCompleteColumnForQuery(column);
            if (completeCol == null) {
                return;
            }
            await retryFailedColumnValues(
                completeCol,
                columns,
                rows,
                setColumns,
                setRows,
                setCellGenerationStatusesByColByRow,
                collectionTypeName,
                errorValueType,
            );
        },
        [
            collectionTypeName,
            columns,
            retryFailedColumnValues,
            rows,
            setColumns,
            setRows,
            setCellGenerationStatusesByColByRow,
        ],
    );

    const markAsWebsiteMutation = useMutation({
        mutationFn: async (columnId: string) => {
            const request: MarkColumnAsWebsiteRequest = {
                grid_data: convertFrontendGridDataToGridData(columns, rows, grid.version),
                column_id: columnId,
            };
            return service.markColumnAsWebsite(request);
        },
        onSuccess: data => {
            const updatedGrid = convertBackendGridDataToFrontend(data.updated_grid_data);
            setColumns(updatedGrid.columns);
            setRows(updatedGrid.rows);
        },
    });

    const handleMarkAsWebsite = React.useCallback(
        async (columnId: string) => {
            await markAsWebsiteMutation.mutateAsync(columnId);
        },
        [markAsWebsiteMutation],
    );

    useKeyBindings(handleAddColumn, isSomeColumnLoading, isSomeRowLoading, setRows);

    const { mutateAsync: pivotGrid } = useMutation({
        mutationFn: async (columnId: string) => {
            const request: PivotGridRequest = {
                grid_data: convertFrontendGridDataToGridData(columns, rows, grid.version),
                new_key_col_id: columnId,
                grid_name: name,
            };
            return service.pivotGrid(request);
        },
    });

    const navigate = useNavigate();
    const handlePivotGrid = React.useCallback(
        async (columnId: string) => {
            const resp = await pivotGrid(columnId);
            navigate(`/grid/${resp.grid_unique_id}`);
        },
        [navigate, pivotGrid],
    );

    const handleMarkAsNewKey = React.useCallback(
        (columnId: string) => {
            setColumns(prevColumns => {
                return prevColumns.map(col =>
                    col.id === "name"
                        ? {
                              ...col,
                              label: "Name",
                          }
                        : col,
                );
            });

            setRows(prevRows => {
                return prevRows.map(row => {
                    if (!isRealRow(row)) return row;
                    const newName = row.data[columnId];
                    return {
                        ...row,
                        data: {
                            ...row.data,
                            name:
                                newName.value.type === "string"
                                    ? (newName as RowValueWithCitations<CellValueV2String>)
                                    : {
                                          ...newName,
                                          value: {
                                              type: "string",
                                              value: cellValueV2ToString(newName.value),
                                          },
                                      },
                        },
                    };
                });
            });
        },
        [setColumns, setRows],
    );

    const chromeTabsAvoidedString = React.useMemo(() => {
        if (rowGeneratingFilters.filters.length > 0) {
            return undefined;
        }
        const count = rows
            .filter(isRealRow)
            .reduce(
                (acc, row) => acc + Object.values(row.data).reduce((acc, cell) => acc + cell.citations.length, 0),
                0,
            );
        return count > 0 ? `${count} Chrome ${count === 1 ? "tab" : "tabs"} avoided` : undefined;
    }, [rowGeneratingFilters.filters.length, rows]);

    return (
        <SidebarLayout
            selectedGridId={gridId}
            footer={
                <PaginationFooter
                    selectedPage={page}
                    onSelectPage={handlePageChange}
                    numPages={numPages}
                    mutedSubtitle={
                        rowGeneratingFilters.filters.length === 0 ? `${rows.length} rows available` : undefined
                    }
                    mutedRightSubtitle={rowGeneratingFilters.filters.length === 0 ? chromeTabsAvoidedString : undefined}
                    sx={{
                        pt: 2,
                    }}
                />
            }
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "stretch",
                    justifyContent: "stretch",
                    flex: "1 1 auto",
                    overflowY: "hidden",
                    bgcolor: "#F4F2EF",
                }}
            >
                <Box
                    sx={{
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "hidden",
                        alignItems: "stretch",
                        justifyContent: "stretch",
                        bgcolor: "primary.main",
                        borderRadius: 3,
                        border: 1,
                        borderColor: "#DEDEDE",
                    }}
                >
                    <AnswersGridHeader
                        version={grid.version}
                        columns={columns}
                        name={name}
                        rows={rows}
                        visibleRows={visibleRows}
                        rowGeneratingFilters={rowGeneratingFilters}
                        gridId={gridId}
                        exportStatus={maybeExportData}
                        // TODO: We should store the persona per grid and wire it here
                        persona="companies"
                        onGenerateRows={onGenerateNewRows}
                        setExportMode={setExportMode}
                        setRowGeneratingFilters={setRowGeneratingFilters}
                        setRows={setRows}
                        setCellGenerationStatusesByColByRow={setCellGenerationStatusesByColByRow}
                        collectionTypeName={collectionTypeName}
                        visibilityFiltersByColumn={visibilityFiltersByColumn}
                        setColumns={setColumns}
                    />
                    <AnswersGridTable
                        columns={columns}
                        isExportOngoing={isExportOngoing}
                        allRows={rows}
                        rowsInPage={rowsInPage}
                        generatedByFiltersInfo={generatedByFiltersInfo}
                        rowGenerationStatuses={rowGenerationStatuses}
                        cellGenerationStatusesByColByRow={cellGenerationStatusesByColByRow}
                        gridId={gridId}
                        isRightSidebarOpen={isRightSidebarOpen}
                        isSomeColumnLoading={isSomeColumnLoading}
                        isSomeRowLoading={isSomeRowLoading}
                        visibilityFiltersByColumn={visibilityFiltersByColumn}
                        selectedCell={selectedCell}
                        columnsWithUnloadedCells={columnsWithUnloadedCells}
                        showBottomBorder={true}
                        exportStatus={maybeExportData}
                        isMarkingSomeColumnAsWebsite={markAsWebsiteMutation.isPending}
                        setSelectedCell={setSelectedCell}
                        onChangeCellValue={onChangeCellValue}
                        onPivotGrid={handlePivotGrid}
                        handleRetryColumnValues={handleRetryColumnValues}
                        onEditKeyColumnCell={handleEditKeyColumnCell}
                        onDeleteRow={handleDeleteRow}
                        onOnAddRowWithRowKey={handleOnAddRowWithRowKey}
                        onStartAddingNewRows={handleStartAddingNewRows}
                        onChangePlaceholderRowValue={handleChangePlaceholderRowValue}
                        handleBlurLastRow={handleBlurLastRow}
                        onLoadAllUnloadedCells={onLoadAllUnloadedCells}
                        onGenerateNewRows={handleGenerateIncrementalRows}
                        isLastPlaceholderRowFocused={isLastPlaceholderRowFocused}
                        onAddColumnValuesToThisPage={onAddColumnValuesToThisPage}
                        onDeleteColumn={handleDeleteColumn}
                        onAddColumn={handleAddColumn}
                        handleOpenRightSidebar={handleOpenRightSidebar}
                        setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
                        onMarkAsWebsite={handleMarkAsWebsite}
                        onMarkAsNewKey={handleMarkAsNewKey}
                    />
                </Box>
                {isRightSidebarOpen && (
                    <SelectedCellSidebar cellInfo={selectedCellInfo} onClose={handleCloseRightSidebar} />
                )}
            </Box>
        </SidebarLayout>
    );
};

const getPageStorageKey = (gridId: string) => `grid-${gridId}-page`;
function usePagingStateAndHooks(rowCount: number, gridId: string) {
    const [page, setPage] = React.useState(parseInt(localStorage.getItem(getPageStorageKey(gridId)) ?? "1", 10));
    const numPages = Math.max(1, Math.ceil(rowCount / PAGE_SIZE));

    React.useEffect(() => {
        if (page > numPages) {
            setPage(1);
            localStorage.setItem(getPageStorageKey(gridId), "1");
        }
    }, [numPages, page, gridId]);

    const onPageChange = React.useCallback(
        (newPage: number) => {
            setPage(newPage);
            localStorage.setItem(getPageStorageKey(gridId), newPage.toString());
        },
        [setPage, gridId],
    );

    return { page, numPages, onPageChange };
}

const fetchGrid = async (service: AbstractBackendService, gridId: string) => {
    const request = { ids: [gridId] };
    const response = await service.batchGetGrids(request);
    const grid = response[gridId];
    if (grid == null) {
        throw new Error(`Grid with id ${gridId} not found`);
    }
    return convertBackendGridToFrontend(grid);
};

const useAutoSave = (
    gridId: string,
    service: AbstractBackendService,
    columns: Column[],
    rows: Row[],
    rowGeneratingFilters: RowGeneratingFilters,
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    name: string,
    savedGridFromServer: Grid,
    isExportOngoing: boolean,
    isSomeCellLoading: boolean,
) => {
    // Ref to keep track of current columns and rows
    const columnsRef = React.useRef(columns);
    const rowsRef = React.useRef(rows);
    const nameRef = React.useRef(name);
    const rowGeneratingFiltersRef = React.useRef(rowGeneratingFilters);
    const visibilityFiltersByColumnRef = React.useRef(visibilityFiltersByColumn);
    const gridRef = React.useRef(savedGridFromServer);

    // Effect to update refs when columns or rows change
    React.useEffect(() => {
        columnsRef.current = columns;
        rowsRef.current = rows;
        nameRef.current = name;
        rowGeneratingFiltersRef.current = rowGeneratingFilters;
        visibilityFiltersByColumnRef.current = visibilityFiltersByColumn;
        gridRef.current = savedGridFromServer;
    }, [columns, savedGridFromServer, name, rowGeneratingFilters, rows, visibilityFiltersByColumn]);

    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

    const saveCurrentGridPeriodically = useSaveCurrentGrid(service, "Periodic autosave");

    React.useEffect(() => {
        if (isExportOngoing || isSomeCellLoading) {
            return;
        }

        const startInterval = () => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            intervalRef.current = setInterval(async () => {
                await saveCurrentGridPeriodically({
                    name: nameRef.current,
                    columns: columnsRef.current,
                    rows: rowsRef.current,
                    rowGeneratingFilters: rowGeneratingFiltersRef.current,
                    visibilityFiltersByColumn: visibilityFiltersByColumnRef.current,
                    currentGrid: gridRef.current,
                    gridId,
                });
            }, 5000);
        };

        const stopInterval = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                stopInterval();
            } else {
                startInterval();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        startInterval();

        // Clean up the interval and event listener when the component unmounts
        return () => {
            stopInterval();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [isExportOngoing, isSomeCellLoading, gridId, saveCurrentGridPeriodically]);

    const saveCurrentGridOnGridChange = useSaveCurrentGrid(service, "On grid change or exit");

    // save last time when gridId changes
    React.useEffect(() => {
        const cleanup = async () => {
            console.debug("Cleaning up autosave on grid change");
            if (isExportOngoing) {
                return;
            }
            try {
                await saveCurrentGridOnGridChange({
                    name: nameRef.current,
                    columns: columnsRef.current,
                    rows: rowsRef.current,
                    rowGeneratingFilters: rowGeneratingFiltersRef.current,
                    visibilityFiltersByColumn: visibilityFiltersByColumnRef.current,
                    currentGrid: gridRef.current,
                    gridId,
                });
            } catch (e) {
                console.log("Failed to autosave on exit", e);
            }
        };

        return () => {
            void cleanup();
        };
    }, [isExportOngoing, service, gridId, saveCurrentGridOnGridChange]);

    React.useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            const saveCurrentGridBeforeUnload = getSaveCurrentGridSync(service, "Before unload");
            if (isExportOngoing) {
                console.log("Didn't autosave because grid is being generated with more rows");
            } else {
                const isRequestTooLargeForKeepAlive = saveCurrentGridBeforeUnload({
                    name: nameRef.current,
                    columns: columnsRef.current,
                    rows: rowsRef.current,
                    rowGeneratingFilters: rowGeneratingFiltersRef.current,
                    visibilityFiltersByColumn: visibilityFiltersByColumnRef.current,
                    currentGrid: gridRef.current,
                    gridId,
                });
                if (isRequestTooLargeForKeepAlive) {
                    const message = "You have unsaved changes. Are you sure you want to leave?";
                    e.returnValue = message;
                    return message;
                }
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.addEventListener("beforeunload", handleBeforeUnload);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [gridId, isExportOngoing, service]);
};

const useKeyBindings = (
    handleAddColumn: () => void,
    isSomeColumnLoading: boolean,
    isSomeRowLoading: boolean,
    setRows: (callback: (prevRows: Row[]) => Row[]) => void,
) => {
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === "o") {
                if (!isSomeColumnLoading) {
                    event.preventDefault();
                    setRows(prevRows => [...prevRows, { type: "placeholder", name: "", uniqueId: randomUniqueId() }]);
                }
            } else if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === "p") {
                if (!isSomeRowLoading) {
                    event.preventDefault();
                    handleAddColumn();
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [handleAddColumn, isSomeColumnLoading, isSomeRowLoading, setRows]);
};
