import {
    RowValueWithCitations,
    RealRow,
    Row,
    Column,
    isRealRow,
    Grid,
    AvailableGrid,
    RowGeneratingFilters,
    ColumnGeneratedBy,
    RowGeneratingFiltersPagingInfo,
    RowKey,
    CellAnswerDetails,
    CellValueV2,
    AgentToolConfig,
    FormatOptions,
    VisibilityFiltersByColumn,
    ColumnVisibilityFilter,
    ApolloPeopleEnrichmentSettings,
    APPerson,
    APPersonField,
    Citation,
    APPersonPhoneNumbers,
    APPersonFieldMissing,
    APPersonFieldNotRequested,
    NumberVisibilityFilter,
    APPersonEmploymentHistory,
    APPersonEmploymentHistoryEntry,
} from "./grid";
import {
    BeGridData,
    BeGridColumn,
    BeGridRow,
    BeGridRowValueWithCitations,
    BeGrid,
    GridUpdateRequest,
    BeAvailableGrid,
    BeRowGeneratingFilters,
    BeColumnGeneratedBy,
    BeRowGeneratingFiltersPagingInfo,
    BeRowKey,
    BeCellAnswerDetails,
    ApolloPersonFiltersWithFallback,
    BeCellValueV2,
    BeAgentToolConfig,
    BeFormatOptions,
    BeVisibilityFilters,
    BeColumnVisibilityFilter,
    BeApolloPeopleEnrichmentSettings,
    LegacyColumnQueryAnswerSourceConfigApollo,
    BeAPPersonField,
    BeAPPerson,
    BeAPPersonPhoneNumbers,
    BeAPPersonFieldMissing,
    BeAPPersonFieldNotRequested,
    BeNumberVisibilityFilter,
    BeAPPersonFieldEmploymentHistory,
    BeAPPersonEmploymentHistoryEntry,
} from "../services/cb-backend-types";
import { mapObjectValues } from "../utils/objects";

export function convertBeRowKeyToFrontendRowKey(beRowKey: BeRowKey): RowKey {
    return {
        title: beRowKey.title,
        value: beRowKey.value,
        externalIdentifiers: beRowKey.external_identifiers,
    };
}

export function convertFrontendRowKeyToBeRowKey(rowKey: RowKey): BeRowKey {
    return {
        title: rowKey.title,
        value: rowKey.value,
        external_identifiers: rowKey.externalIdentifiers,
    };
}

export function convertGridRowToRealRow(backendRow: BeGridRow): RealRow {
    const nonNameCols = Object.keys(backendRow.columns).reduce(
        (acc, key) => {
            acc[key] = convertBeRowValueWithCitationsToRowValueWithCitations(backendRow.columns[key]);
            return acc;
        },
        {} as Record<string, RowValueWithCitations<CellValueV2>>,
    );

    return {
        type: "real",
        imageUrl: backendRow.image_url,
        externalIdentifiers: backendRow.external_identifiers,
        data: {
            ...nonNameCols,
            name: {
                value: { type: "string", value: backendRow.key },
                citations: [], // No citations for the key field
                sourceDetails: undefined,
            },
        },
    };
}

// Convert backend row to frontend row
function convertBeRowValueWithCitationsToRowValueWithCitations(
    beValueWithCitations: BeGridRowValueWithCitations,
): RowValueWithCitations<CellValueV2> {
    const value: CellValueV2 =
        beValueWithCitations.value_v2 != null
            ? convertBeCellValueV2ToFrontendCellValueV2(beValueWithCitations.value_v2)
            : { type: "string", value: beValueWithCitations.value };

    return {
        value,
        citations:
            beValueWithCitations.citations_v2 ??
            beValueWithCitations.citationUrls.map<Citation>(link => ({ link, title: undefined })),
        sourceDetails: convertBeCellAnswerDetailsToFrontend(beValueWithCitations.source_details),
    };
}

export function convertBeCellValueV2ToFrontendCellValueV2(beCellValueV2: BeCellValueV2): CellValueV2 {
    switch (beCellValueV2.type) {
        case "string":
            return { type: "string", value: beCellValueV2.value };
        case "apollo_people":
            return {
                type: "apollo_people",
                people: beCellValueV2.people.map(person => convertBeAPPersonToFrontend(person)),
                totalCount: beCellValueV2.total_count,
            };
        case "missing":
            return { type: "missing" };
        case "error":
            return { type: "error", error: beCellValueV2.error };
        case "boolean":
            return { type: "boolean", value: beCellValueV2.value, reasoning: beCellValueV2.reasoning };
        case "technologies":
            return { type: "technologies", technologies: beCellValueV2.technologies };
        case "job_postings":
            return { type: "job_postings", jobPostings: beCellValueV2.job_postings };
        case "unloaded":
            return { type: "unloaded" };
        case "number":
            return { type: "number", value: beCellValueV2.value };
        case "links":
            return { type: "links", links: beCellValueV2.links };
    }
}

export function convertFrontendCellValueV2ToBeCellValueV2(cellValueV2: CellValueV2): BeCellValueV2 {
    switch (cellValueV2.type) {
        case "string":
            return { type: "string", value: cellValueV2.value };
        case "apollo_people":
            return {
                type: "apollo_people",
                people: cellValueV2.people.map(person => ({
                    name: person.name.value,
                    name_v2: person.name,
                    email: person.email.type === "string" ? person.email.value : undefined,
                    email_v2: convertFrontendAPPersonFieldToBe(person.email),
                    linkedin: person.linkedin.type === "string" ? person.linkedin.value : undefined,
                    linkedin_v2: convertFrontendAPPersonFieldToBe(person.linkedin),
                    title: person.title.type === "string" ? person.title.value : undefined,
                    title_v2: convertFrontendAPPersonFieldToBe(person.title),
                    phone_v2:
                        person.phoneNumbers.type === "phone_numbers"
                            ? convertFrontendAPPersonPhoneNumbersToBe(person.phoneNumbers)
                            : person.phoneNumbers.type === "missing"
                              ? { type: "missing" }
                              : { type: "not_requested" },
                    headline_v2: convertFrontendAPPersonFieldToBe(person.headline),
                    image_url: person.imageUrl,
                    employment_history:
                        person.employmentHistory.type === "employment_history"
                            ? convertFrontendAPPersonEmploymentHistoryToBe(person.employmentHistory)
                            : { type: "missing" },
                })),
                total_count: cellValueV2.totalCount,
            };
        case "missing":
            return { type: "missing" };
        case "error":
            return { type: "error", error: cellValueV2.error };
        case "boolean":
            return { type: "boolean", value: cellValueV2.value, reasoning: cellValueV2.reasoning };
        case "technologies":
            return { type: "technologies", technologies: cellValueV2.technologies };
        case "job_postings":
            return { type: "job_postings", job_postings: cellValueV2.jobPostings };
        case "unloaded":
            return { type: "unloaded" };
        case "number":
            return { type: "number", value: cellValueV2.value };
        case "links":
            return { type: "links", links: cellValueV2.links };
    }
}

export function convertFrontendAPPersonEmploymentHistoryToBe(
    employmentHistory: APPersonEmploymentHistory,
): BeAPPersonFieldEmploymentHistory {
    return {
        type: "employment_history",
        values: employmentHistory.values.map(employment => ({
            description: employment.description,
            end_date: employment.endDate,
            organization_id: employment.organizationId,
            organization_name: employment.organizationName,
            start_date: employment.startDate,
            title: employment.title,
        })),
    };
}

export function convertFrontendAPPersonPhoneNumbersToBe(phoneNumbers: APPersonPhoneNumbers): BeAPPersonPhoneNumbers {
    return {
        type: "phone_numbers",
        values: phoneNumbers.phoneNumbers.map(phoneNumber => ({ value: phoneNumber.value })),
    };
}

export function convertBeApolloPeopleEnrichmentSettingsToFrontend(
    enrichmentSettings: BeApolloPeopleEnrichmentSettings,
): ApolloPeopleEnrichmentSettings {
    return {
        enrichSinglePerson: enrichmentSettings.enrich_single_person ?? true,
    };
}

export function convertFrontendApolloPeopleEnrichmentSettingsToBackend(
    enrichmentSettings: ApolloPeopleEnrichmentSettings,
): BeApolloPeopleEnrichmentSettings {
    return {
        enrich_single_person: enrichmentSettings.enrichSinglePerson,
    };
}

export function convertGridColumnGeneratedByToColumnGeneratedBy(
    backendColumnGeneratedBy: BeColumnGeneratedBy | undefined,
    deprecatedContextColumnIds: string[] | undefined,
): ColumnGeneratedBy | undefined {
    if (backendColumnGeneratedBy == null) {
        return undefined;
    }

    return convertGridColumnGeneratedByToColumnGeneratedByDefined(backendColumnGeneratedBy, deprecatedContextColumnIds);
}
export function convertLegacyApolloConfigToNewFormat(
    legacyConfig: LegacyColumnQueryAnswerSourceConfigApollo,
): ApolloPersonFiltersWithFallback {
    return {
        primary: {
            person_titles: legacyConfig.person_titles || [],
        },
        fallback: [],
    };
}

export function convertGridColumnGeneratedByToColumnGeneratedByDefined(
    columnGeneratedBy: BeColumnGeneratedBy,
    deprecatedContextColumnIds: string[] | undefined,
): ColumnGeneratedBy {
    switch (columnGeneratedBy.type) {
        case "web_search":
            return {
                query: columnGeneratedBy.query,
                type: "web_search",
                contextColumnIds:
                    // Handle old field nicely
                    (deprecatedContextColumnIds?.length ?? 0) > 0 && (columnGeneratedBy.context_cols?.length ?? 0) === 0
                        ? deprecatedContextColumnIds ?? []
                        : columnGeneratedBy.context_cols ?? [],
                toolConfig: convertBeAgentToolConfigToFrontendAgentToolConfig(columnGeneratedBy.tool_config),
                formatOptions: convertBackendFormatOptionsToFrontendFormatOptions(columnGeneratedBy.format_options),
            };

        case "crunchbase":
            return {
                fieldId: columnGeneratedBy.field_id,
                type: "crunchbase",
            };
        case "apollo":
            return {
                type: "apollo",
                personFilters:
                    columnGeneratedBy.person_filters ??
                    convertLegacyApolloConfigToNewFormat(columnGeneratedBy.source_config),
                enrichmentSettings:
                    columnGeneratedBy.enrichment_settings != null
                        ? convertBeApolloPeopleEnrichmentSettingsToFrontend(columnGeneratedBy.enrichment_settings)
                        : { enrichSinglePerson: true },
            };
        case "apollo_organization":
            return {
                field: columnGeneratedBy.field,
                type: "apollo_organization",
            };
        case "user_input":
            return {
                type: "user_input",
            };
    }
}

// Convert backend column to frontend column
export function convertGridColumnToColumn(backendColumn: BeGridColumn): Column {
    return {
        id: backendColumn.id,
        label: backendColumn.label,
        align: (backendColumn.align ?? "center") as Column["align"],
        isEditable: false,
        generatedBy: convertGridColumnGeneratedByToColumnGeneratedBy(
            backendColumn.generated_by,
            backendColumn?.context_cols,
        ),
    };
}

// Convert backend grid data to frontend grid data
export function convertBackendGridDataToFrontend(gridData: BeGridData): {
    columns: Column[];
    rows: Row[];
    version: number;
} {
    const keyColumn: Column = {
        id: "name",
        label: gridData.key_column.display_name,
        align: "left",
        isEditable: false,
        generatedBy: undefined,
    };

    const columns = [keyColumn, ...gridData.columns.map(convertGridColumnToColumn)];
    const rows = gridData.rows.map(convertGridRowToRealRow);

    return { columns, rows, version: gridData.version ?? 0 };
}

// Convert frontend row to backend row
export function convertRealRowToGridRow(realRow: RealRow): BeGridRow {
    return {
        key: realRow.data.name.value.value,
        image_url: realRow.imageUrl,
        external_identifiers: realRow.externalIdentifiers,
        columns: Object.keys(realRow.data).reduce(
            (acc, key) => {
                if (key !== "name") {
                    acc[key] = convertRowValueWithCitationsToBeGridRowValueWithCitations(realRow.data[key]);
                }
                return acc;
            },
            {} as Record<string, BeGridRowValueWithCitations>,
        ),
    };
}

export function convertRowValueWithCitationsToBeGridRowValueWithCitations(
    rowValueWithCitations: RowValueWithCitations<CellValueV2>,
): BeGridRowValueWithCitations {
    return {
        value: rowValueWithCitations.value.type === "string" ? rowValueWithCitations.value.value : "-",
        value_v2: convertFrontendCellValueV2ToBeCellValueV2(rowValueWithCitations.value),
        citationUrls: rowValueWithCitations.citations.map(citation => citation.link),
        citations_v2: rowValueWithCitations.citations,
        source_details: convertCellAnswerDetailsToBackend(rowValueWithCitations.sourceDetails),
    };
}

export function convertFrontendAgentToolConfigToBeAgentToolConfig(
    toolConfig: AgentToolConfig | undefined,
): BeAgentToolConfig | undefined {
    if (toolConfig == null) {
        return undefined;
    }
    return {
        tool_name: toolConfig.toolName,
        use_vision_if_page_scrape: toolConfig.useVisionIfPageScrape,
    };
}

export function convertBeAgentToolConfigToFrontendAgentToolConfig(
    toolConfig: BeAgentToolConfig | undefined,
): AgentToolConfig | undefined {
    if (toolConfig == null) {
        return undefined;
    }
    return {
        toolName: toolConfig.tool_name,
        useVisionIfPageScrape: toolConfig.use_vision_if_page_scrape ?? true,
    };
}

export function convertBackendFormatOptionsToFrontendFormatOptions(
    formatOptions: BeFormatOptions | undefined,
): FormatOptions | undefined {
    if (formatOptions == null) {
        return undefined;
    }
    switch (formatOptions.type) {
        case "bool":
            return { type: "bool" };
        case "concise":
            return { type: "concise" };
        case "inferred":
            return { type: "inferred" };
        case "number":
            return { type: "number" };
    }
}

export function convertFrontendFormatOptionsToBackendFormatOptions(
    formatOptions: FormatOptions | undefined,
): BeFormatOptions | undefined {
    if (formatOptions == null) {
        return undefined;
    }
    switch (formatOptions.type) {
        case "bool":
            return { type: "bool" };
        case "concise":
            return { type: "concise" };
        case "inferred":
            return { type: "inferred" };
        case "number":
            return { type: "number" };
    }
}

export function convertColumnGeneratedByToGridColumnGeneratedBy(
    columnGeneratedBy: ColumnGeneratedBy | undefined,
): BeColumnGeneratedBy | undefined {
    if (columnGeneratedBy === undefined) {
        return undefined;
    }

    return convertColumnGeneratedByToGridColumnGeneratedByDefined(columnGeneratedBy);
}

function convertColumnGeneratedByToGridColumnGeneratedByDefined(
    columnGeneratedBy: ColumnGeneratedBy,
): BeColumnGeneratedBy {
    switch (columnGeneratedBy.type) {
        case "web_search":
            return {
                query: columnGeneratedBy.query,
                type: "web_search",
                context_cols: columnGeneratedBy.contextColumnIds,
                tool_config: convertFrontendAgentToolConfigToBeAgentToolConfig(columnGeneratedBy.toolConfig),
                format_options: convertFrontendFormatOptionsToBackendFormatOptions(columnGeneratedBy.formatOptions),
            };
        case "crunchbase":
            return {
                field_id: columnGeneratedBy.fieldId,
                type: "crunchbase",
            };
        case "apollo":
            return {
                source_config: { person_titles: columnGeneratedBy.personFilters.primary.person_titles },
                type: "apollo",
                person_filters: columnGeneratedBy.personFilters,
                enrichment_settings: convertFrontendApolloPeopleEnrichmentSettingsToBackend(
                    columnGeneratedBy.enrichmentSettings,
                ),
            };
        case "apollo_organization":
            return {
                field: columnGeneratedBy.field,
                type: "apollo_organization",
            };
        case "user_input":
            return {
                type: "user_input",
            };
    }
}

// Convert frontend column to backend column
export function convertColumnToGridColumn(column: Column): BeGridColumn {
    return {
        id: column.id,
        label: column.label,
        align: column.align ?? "center",
        generated_by: convertColumnGeneratedByToGridColumnGeneratedBy(column.generatedBy),
        // TODO: stop passing
        context_cols: column.generatedBy?.type === "web_search" ? column.generatedBy.contextColumnIds : [],
    };
}

// Convert frontend grid data to backend grid data
export function convertFrontendGridDataToGridData(columns: Column[], rows: Row[], version: number): BeGridData {
    const backendColumns = columns.filter(column => column.id !== "name").map(convertColumnToGridColumn);

    const backendRows = rows.filter(isRealRow).map(convertRealRowToGridRow);

    return {
        key_column: {
            display_name: columns.find(column => column.id === "name")?.label ?? "Name",
        },
        columns: backendColumns,
        rows: backendRows,
        version,
    };
}

export function convertBackendRowGeneratingFiltersPagingInfo(
    pagingInfo: BeRowGeneratingFiltersPagingInfo,
): RowGeneratingFiltersPagingInfo {
    return {
        lastFetchedUuid: pagingInfo.last_fetched_uuid,
        totalCount: pagingInfo.total_count,
        fetchedCount: pagingInfo.fetched_count,
    };
}

export function convertBackendRowGeneratingFilters(rowGeneratingFilters: BeRowGeneratingFilters): RowGeneratingFilters {
    return {
        filters: rowGeneratingFilters.filters,
        pagingInfo:
            rowGeneratingFilters.paging_info != null
                ? convertBackendRowGeneratingFiltersPagingInfo(rowGeneratingFilters.paging_info)
                : undefined,
    };
}

export function convertFrontendRowGeneratingFiltersPagingInfo(
    pagingInfo: RowGeneratingFiltersPagingInfo,
): BeRowGeneratingFiltersPagingInfo {
    return {
        last_fetched_uuid: pagingInfo.lastFetchedUuid,
        total_count: pagingInfo.totalCount,
        fetched_count: pagingInfo.fetchedCount,
    };
}

export function convertFrontendRowGeneratingFilters(
    rowGeneratingFilters: RowGeneratingFilters,
): BeRowGeneratingFilters {
    return {
        filters: rowGeneratingFilters.filters,
        paging_info:
            rowGeneratingFilters.pagingInfo != null
                ? convertFrontendRowGeneratingFiltersPagingInfo(rowGeneratingFilters.pagingInfo)
                : undefined,
    };
}
export function convertBeColumnVisibilityFilter(filter: BeColumnVisibilityFilter): ColumnVisibilityFilter {
    return {
        nullFilter: filter.null_filter ?? undefined,
        booleanFilter: filter.boolean_filter ?? undefined,
        stringFilter: filter.string_filter ?? undefined,
        numberFilter: filter.number_filter != null ? convertBeNumberVisibilityFilter(filter.number_filter) : undefined,
    };
}

export function convertBackendVisibilityFiltersByColumn(
    visibilityFiltersByColumn: BeVisibilityFilters,
): VisibilityFiltersByColumn {
    return mapObjectValues(visibilityFiltersByColumn?.filters_by_column ?? {}, convertBeColumnVisibilityFilter);
}

export function convertColumnVisibilityFilter(filter: ColumnVisibilityFilter): BeColumnVisibilityFilter {
    return {
        null_filter: filter.nullFilter ?? null,
        boolean_filter: filter.booleanFilter ?? null,
        string_filter: filter.stringFilter ?? null,
        number_filter:
            filter.numberFilter != null
                ? convertNumberVisibilityFilterToBeNumberVisibilityFilter(filter.numberFilter)
                : null,
    };
}

export function convertNumberVisibilityFilterToBeNumberVisibilityFilter(
    filter: NumberVisibilityFilter,
): BeNumberVisibilityFilter {
    return {
        type: "number",
        eq: filter.eq ?? null,
        gte: filter.gte ?? null,
        lte: filter.lte ?? null,
    };
}
export function convertBeNumberVisibilityFilter(filter: BeNumberVisibilityFilter): NumberVisibilityFilter {
    return {
        type: "number",
        eq: filter.eq ?? undefined,
        gte: filter.gte ?? undefined,
        lte: filter.lte ?? undefined,
    };
}

export function convertFrontendVisibilityFiltersByColumn(
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
): BeVisibilityFilters {
    return {
        filters_by_column: mapObjectValues(visibilityFiltersByColumn, convertColumnVisibilityFilter),
    };
}

export function convertBackendGridToFrontend(grid: BeGrid): Grid {
    const { columns, rows } = convertBackendGridDataToFrontend(grid.data);
    return {
        unique_id: grid.unique_id,
        columns,
        rows,
        name: grid.name,
        version: grid.data.version ?? 0,
        lastEdited: new Date(grid.last_edited),
        created_at: new Date(grid.created_at),
        rowGeneratingFilters: convertBackendRowGeneratingFilters(grid.row_generating_filters),
        visibilityFiltersByColumn: convertBackendVisibilityFiltersByColumn(
            grid.visibility_filters ?? { filters_by_column: {} },
        ),
        lastExportTaskId: grid.last_export_task_id,
        createdBy: grid.created_by,
    };
}

export function convertFrontendGridToBackend(grid: Grid): BeGrid {
    return {
        unique_id: grid.unique_id,
        name: grid.name,
        data: convertFrontendGridDataToGridData(grid.columns, grid.rows, grid.version),
        row_generating_filters: convertFrontendRowGeneratingFilters(grid.rowGeneratingFilters),
        visibility_filters: convertFrontendVisibilityFiltersByColumn(grid.visibilityFiltersByColumn),
        created_at: grid.created_at.toString(),
        last_edited: grid.lastEdited.toString(),
        created_by: grid.createdBy,
        last_export_task_id: grid.lastExportTaskId,
    };
}

export function convertFrontendGridToBackendUpdatePayload(grid: Grid): GridUpdateRequest {
    return {
        name: grid.name,
        data: convertFrontendGridDataToGridData(grid.columns, grid.rows, grid.version),
        row_generating_filters: convertFrontendRowGeneratingFilters(grid.rowGeneratingFilters),
        visibility_filters: convertFrontendVisibilityFiltersByColumn(grid.visibilityFiltersByColumn),
    };
}

export function convertFrontendAvailableGridToBackend(grid: AvailableGrid): BeAvailableGrid {
    return {
        unique_id: grid.unique_id,
        name: grid.name,
        created_at: grid.created_at.toString(),
        last_edited: grid.lastEdited.toString(),
    };
}

export function convertBackendAvailableGridToFrontend(grid: BeAvailableGrid): AvailableGrid {
    return {
        unique_id: grid.unique_id,
        name: grid.name,
        created_at: new Date(grid.created_at),
        lastEdited: new Date(grid.last_edited),
    };
}

export function convertBeCellAnswerDetailsToFrontend(
    cellAnswerDetails: BeCellAnswerDetails | undefined,
): CellAnswerDetails | undefined {
    if (cellAnswerDetails == null) {
        return undefined;
    }
    return convertBeCellAnswerDetailsToFrontendDefined(cellAnswerDetails);
}

function convertBeCellAnswerDetailsToFrontendDefined(cellAnswerDetails: BeCellAnswerDetails): CellAnswerDetails {
    switch (cellAnswerDetails.source_type) {
        case "AGENT":
            return {
                sourceType: "AGENT",
                rawQuery: cellAnswerDetails.raw_query,
            };
        case "FOCUS_AP":
            return {
                sourceType: "FOCUS_AP",
                personFilters:
                    cellAnswerDetails.person_filters ||
                    convertLegacyApolloConfigToNewFormat(cellAnswerDetails.source_config),
                enrichmentSettings: convertBeApolloPeopleEnrichmentSettingsToFrontend(
                    cellAnswerDetails.enrichment_settings ?? { enrich_single_person: true },
                ),
            };
        case "FOCUS_CB":
            return {
                sourceType: "FOCUS_CB",
                fieldId: cellAnswerDetails.field_id,
            };
        case "FOCUS_AP_ORG":
            return {
                sourceType: "FOCUS_AP_ORG",
                field: cellAnswerDetails.field,
            };
    }
}

export function convertCellAnswerDetailsToBackend(
    cellAnswerDetails: CellAnswerDetails | undefined,
): BeCellAnswerDetails | undefined {
    if (cellAnswerDetails == null) {
        return undefined;
    }
    return convertCellAnswerDetailsToBackendDefined(cellAnswerDetails);
}

function convertCellAnswerDetailsToBackendDefined(cellAnswerDetails: CellAnswerDetails): BeCellAnswerDetails {
    switch (cellAnswerDetails.sourceType) {
        case "AGENT":
            return {
                source_type: "AGENT",
                raw_query: cellAnswerDetails.rawQuery,
            };
        case "FOCUS_AP":
            return {
                source_type: "FOCUS_AP",
                source_config: { person_titles: cellAnswerDetails.personFilters.primary.person_titles },
                person_filters: cellAnswerDetails.personFilters,
                enrichment_settings: convertFrontendApolloPeopleEnrichmentSettingsToBackend(
                    cellAnswerDetails.enrichmentSettings,
                ),
            };
        case "FOCUS_CB":
            return {
                source_type: "FOCUS_CB",
                field_id: cellAnswerDetails.fieldId,
            };
        case "FOCUS_AP_ORG":
            return {
                source_type: "FOCUS_AP_ORG",
                field: cellAnswerDetails.field,
            };
    }
}

function convertBeAPPersonFieldToFrontend(field: BeAPPersonField): APPersonField {
    switch (field.type) {
        case "string":
            return { type: "string", value: field.value };
        case "error":
            return { type: "error", error: field.error };
        case "missing":
            return { type: "missing" };
        case "not_requested":
            return { type: "not_requested" };
    }
}

function convertBeAPPersonToFrontend(person: BeAPPerson): APPerson {
    if (person.name_v2 != null && person.email_v2 != null && person.linkedin_v2 != null && person.title_v2 != null) {
        return {
            name: person.name_v2,
            email: convertBeAPPersonFieldToFrontend(person.email_v2),
            linkedin: convertBeAPPersonFieldToFrontend(person.linkedin_v2),
            title: convertBeAPPersonFieldToFrontend(person.title_v2),
            phoneNumbers: convertBeAPPersonPhoneNumbersToFrontend(person.phone_v2),
            headline:
                person.headline_v2 != null ? convertBeAPPersonFieldToFrontend(person.headline_v2) : { type: "missing" },
            imageUrl: person.image_url,
            employmentHistory: convertBeAPPersonEmploymentHistoryToFrontend(person.employment_history),
        };
    }
    return {
        name: { type: "string", value: person.name },
        email: person.email != null ? { type: "string", value: person.email } : { type: "missing" },
        linkedin: person.linkedin != null ? { type: "string", value: person.linkedin } : { type: "missing" },
        title: person.title != null ? { type: "string", value: person.title } : { type: "missing" },
        phoneNumbers: convertBeAPPersonPhoneNumbersToFrontend(person.phone_v2),
        headline: { type: "not_requested" },
        imageUrl: undefined,
        employmentHistory: { type: "not_requested" },
    };
}

export function convertBeAPPersonEmploymentHistoryToFrontend(
    employmentHistory: BeAPPersonFieldEmploymentHistory | BeAPPersonFieldMissing | undefined,
): APPersonEmploymentHistory | APPersonFieldMissing | APPersonFieldNotRequested {
    if (employmentHistory == null) {
        return { type: "not_requested" };
    }
    if (employmentHistory.type === "employment_history") {
        return {
            type: "employment_history",
            values: employmentHistory.values.map(convertBeAPPersonEmploymentHistoryEntryToFrontend),
        };
    }
    return { type: "missing" };
}

export function convertBeAPPersonEmploymentHistoryEntryToFrontend(
    employmentHistoryEntry: BeAPPersonEmploymentHistoryEntry,
): APPersonEmploymentHistoryEntry {
    return {
        description: employmentHistoryEntry.description,
        endDate: employmentHistoryEntry.end_date,
        organizationId: employmentHistoryEntry.organization_id,
        organizationName: employmentHistoryEntry.organization_name,
        startDate: employmentHistoryEntry.start_date,
        title: employmentHistoryEntry.title,
    };
}

function convertBeAPPersonPhoneNumbersToFrontend(
    phoneNumbers: BeAPPersonPhoneNumbers | BeAPPersonFieldNotRequested | BeAPPersonFieldMissing | undefined,
): APPersonPhoneNumbers | APPersonFieldNotRequested | APPersonFieldMissing {
    if (phoneNumbers == null) {
        return { type: "not_requested" };
    }
    if (phoneNumbers.type === "not_requested") {
        return { type: "not_requested" };
    }
    if (phoneNumbers.type === "missing") {
        return { type: "missing" };
    }
    return {
        type: "phone_numbers",
        phoneNumbers: phoneNumbers.values.map(phoneNumber => ({ value: phoneNumber.value })),
    };
}

function convertFrontendAPPersonFieldToBe(field: APPersonField): BeAPPersonField {
    switch (field.type) {
        case "string":
            return { type: "string", value: field.value };
        case "error":
            return { type: "error", error: field.error };
        case "missing":
            return { type: "missing" };
        case "not_requested":
            return { type: "not_requested" };
    }
}
