import * as React from "react";
import { Box, Button, IconButton, Paper, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { CellValueV2, RowValueWithCitations } from "../grid/grid";
import { CitationCard } from "../selected-cell-components/citationCard";
import { CitationsFooter } from "../selected-cell-components/citationsFooter";
import { Maximize4 } from "iconsax-react";
import { SelectedCellValue } from "./selectedCellValue";
import { Close } from "@mui/icons-material";

export interface SelectedCellMenuContentProps {
    valueWithCitations: RowValueWithCitations<CellValueV2>;
    sx?: SxProps<Theme>;
    onOpenInRightSidebar: () => void;
    onDeselectCell: () => void;
}

// eslint-disable-next-line react/display-name
export const SelectedCellMenuContent = React.forwardRef<HTMLDivElement, SelectedCellMenuContentProps>(
    ({ valueWithCitations, sx: parentSx, onOpenInRightSidebar: onOpenInSidebar, onDeselectCell }, ref) => {
        const theme = useTheme();
        return (
            <Paper
                ref={ref}
                elevation={8}
                sx={[
                    {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        width: "240px",
                        maxWidth: "240px",
                        borderRadius: 2,
                        maxHeight: "500px",
                        overflowY: "auto",
                        px: 1.5,
                        pt: 1,
                        pb: 1.5,
                        rowGap: 1,
                    },

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(parentSx) ? parentSx : [parentSx]),
                ]}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="body1" fontWeight="bold">
                        Answer
                    </Typography>
                    <IconButton size="small" onClick={onDeselectCell}>
                        <Close
                            sx={{
                                width: 16,
                                height: 16,
                            }}
                        />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        rowGap: 2,
                    }}
                >
                    <SelectedCellValue
                        valueWithCitations={valueWithCitations}
                        containerSx={{
                            overflowY: "auto",
                            maxHeight: "60px",
                            backgroundColor: "grey.100",
                            p: 1,
                            borderRadius: 1,
                            minWidth: 0,
                            maxWidth: "100%",
                            overflowX: "hidden",
                        }}
                    />
                    {valueWithCitations.citations.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                minWidth: 0,
                                maxWidth: "100%",
                                rowGap: 1,
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "#667085" }}>
                                Sources
                            </Typography>
                            {valueWithCitations.citations.slice(0, 2).map((citation, index) => (
                                <CitationCard citation={citation} key={index} />
                            ))}
                            <CitationsFooter citations={valueWithCitations.citations.slice(2)} />
                        </Box>
                    )}
                    <Button
                        endIcon={<Maximize4 size={12} color={theme.palette.secondary.main} />}
                        size="small"
                        fullWidth={false}
                        variant="text"
                        onClick={onOpenInSidebar}
                        color="secondary"
                        sx={{ textTransform: "none", borderRadius: 8 }}
                    >
                        Expand in sidebar
                    </Button>
                </Box>
            </Paper>
        );
    },
);
