import * as React from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PlanCardGrid } from "./planCardGrid";
import { useCurrentUserQuery } from "../context/userContext";
import { getPlanFromUserPlanType, UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY } from "../grid/grid";
import { getBackendService } from "../services/cb-backend";
import { GetCurrentUserResponse } from "../services/cb-backend-types";
import { useNavigate } from "react-router-dom";
import { getMixpanelInstance } from "../mixpanel";

interface UpgradePlanDialogProps {
    open: boolean;
    onClose: () => void;
}

const STRIPE_CUSTOMER_PORTAL_URL =
    process.env.NODE_ENV === "production"
        ? "https://billing.stripe.com/p/login/fZe28adjL2ONbAc000"
        : "https://billing.stripe.com/p/login/test_9AQ5kA3FCcUlgnuaEE";

function useClickPlanHandler(
    planType: "free-trial" | "paying-user" | "higher-tier-paying-user",
    user: GetCurrentUserResponse | undefined,
) {
    const backendService = React.useMemo(() => getBackendService(), []);

    const navigate = useNavigate();
    return React.useCallback(async () => {
        const mixpanel = getMixpanelInstance();
        mixpanel?.track("Upgrade Plan Clicked", {
            Plan: planType,
            CurrentPlan: user?.plan_type,
        });
        if (user?.plan_type == null) {
            return;
        }
        if (user.plan_type === "trial") {
            localStorage.setItem(UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY, getQueryParamFromPlanType(planType));
            navigate(`/signup?plan=${getQueryParamFromPlanType(planType)}`);
            return;
        }
        if (user.plan_type === "free-account" || user.plan_type === "free-trial") {
            const { url } = await backendService.createSelfServiceCheckoutSession({
                plan_type: planType,
            });
            if (typeof window !== "undefined" && window.open) {
                window.open(url, "_blank");
            } else {
                window.location.href = url;
            }
        } else {
            const customerPortalUrlWithPrefilledEmail =
                user.email != null
                    ? `${STRIPE_CUSTOMER_PORTAL_URL}?prefilled_email=${encodeURIComponent(user.email)}`
                    : STRIPE_CUSTOMER_PORTAL_URL;
            if (typeof window !== "undefined" && window.open) {
                window.open(customerPortalUrlWithPrefilledEmail, "_blank");
            } else {
                window.location.href = STRIPE_CUSTOMER_PORTAL_URL;
            }
        }
    }, [backendService, navigate, planType, user?.plan_type, user?.email]);
}

function getQueryParamFromPlanType(planType: "free-trial" | "paying-user" | "higher-tier-paying-user"): string {
    switch (planType) {
        case "free-trial":
            return "free-trial";
        case "paying-user":
            return "basic";
        case "higher-tier-paying-user":
            return "pro";
    }
}

export const UpgradePlanDialog: React.FC<UpgradePlanDialogProps> = ({ open, onClose }) => {
    const user = useCurrentUserQuery();

    const currentPlan = user.data?.plan_type != null ? getPlanFromUserPlanType(user.data.plan_type) : undefined;

    const handleClickProPlan = useClickPlanHandler("higher-tier-paying-user", user.data);
    const handleClickBasicPlan = useClickPlanHandler("paying-user", user.data);
    const handleClickFreePlan = useClickPlanHandler("free-trial", user.data);

    const handleContactUsClick = React.useCallback(() => {
        window.location.href = "https://calendly.com/bolu-answergrid/30-minute-meeting-clone";
        const mixpanel = getMixpanelInstance();
        mixpanel?.track("Contact Us Clicked");
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    pb: 3,
                },
            }}
        >
            <DialogTitle sx={{ m: 0, px: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {/* TODO: Validatedomnesting h6 cannot appear as a child of h2 */}
                    <Typography variant="h6">Upgrade plan</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Typography variant="body2" sx={{ pb: 2 }}>
                    Unlock more answers for you and your team
                </Typography>
            </DialogTitle>
            <PlanCardGrid
                cardsPerRow={4}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onGetStartedWithFreeClick={handleClickFreePlan}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onGetStartedWithBasicClick={handleClickBasicPlan}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onGetStartedWithProClick={handleClickProPlan}
                onContactUsClick={handleContactUsClick}
                currentPlan={currentPlan}
            />
        </Dialog>
    );
};
