import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface ButtonWithDisabledTooltipProps extends Omit<ButtonProps, "sx" | "disabled"> {
    disabledTooltip: string | undefined;
    sx?: SxProps<Theme>;
}

const ButtonWithDisabledTooltip: React.FC<ButtonWithDisabledTooltipProps> = ({
    disabledTooltip: tooltip,
    children,
    sx,
    ...props
}) => {
    const disabled = tooltip != null;
    const button = (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Button {...props} disabled={disabled} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            {children}
        </Button>
    );
    if (!disabled) {
        return button;
    }
    return (
        <Tooltip
            title={disabled ? tooltip : ""}
            arrow
            sx={[
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
                {
                    display: "flex",
                    flex: "1 1 auto",
                    minWidth: 0,
                    maxWidth: "fit-content",
                },
            ]}
        >
            <Box
                sx={{
                    display: "flex",
                    flex: "1 1 auto",
                    minWidth: 0,
                    // TODO: Is this hacky?
                    maxWidth: "fit-content",
                }}
            >
                {button}
            </Box>
        </Tooltip>
    );
};

export default ButtonWithDisabledTooltip;
