import * as React from "react";
import { Box, Menu, MenuItem, TableRow, Typography } from "@mui/material";
import { Trash } from "iconsax-react";

export interface DeletableGridRowProps {
    rowStringKey: string;
    disableDelete: boolean;
    children: React.ReactNode;
    onDeleteRow: (rowStringKey: string) => void;
}

export const DeletableGridRow: React.FC<DeletableGridRowProps> = React.memo(function DeletableGridRow({
    rowStringKey,
    disableDelete,
    onDeleteRow,
    children,
}: DeletableGridRowProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorPosition, setAnchorPosition] = React.useState<null | { top: number; left: number }>(null);

    const handleContextMenu = React.useCallback((event: React.MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setAnchorPosition({ top: event.clientY, left: event.clientX });
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
        setAnchorPosition(null);
    }, []);

    const handleDeleteRow = React.useCallback(() => {
        onDeleteRow(rowStringKey);
        handleClose();
    }, [handleClose, onDeleteRow, rowStringKey]);

    return (
        <TableRow onContextMenu={handleContextMenu} sx={{ position: "relative" }}>
            {children}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition ?? undefined}
                disableAutoFocusItem
            >
                <MenuItem onClick={handleDeleteRow} disabled={disableDelete}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Trash size="16" />
                        <Typography variant="caption">Delete row</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </TableRow>
    );
});
