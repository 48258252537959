import { Box, Typography } from "@mui/material";
import * as React from "react";

interface SuggestedQueryCardProps {
    title: string;
    onClick: (query: string) => void;
}

export const SuggestedQueryCard: React.FC<SuggestedQueryCardProps> = ({ title, onClick }) => {
    const handleClick = React.useCallback(() => {
        onClick(title);
    }, [title, onClick]);

    return (
        <Box
            onClick={handleClick}
            sx={{
                bgcolor: "white",
                color: "primary.contrastText",
                px: 2,
                py: 1,
                borderRadius: 2,
                cursor: "pointer",
                border: 1,
                borderColor: "divider",
                flexShrink: 0,
                minWidth: 200,
            }}
        >
            <Typography variant="body2" noWrap>
                {title}
            </Typography>
        </Box>
    );
};
