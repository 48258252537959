import Box from "@mui/material/Box";
import * as React from "react";
import { Menu, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Edit2, Trash, Grid2, Copy, More } from "iconsax-react";
import "../App.css";
import { AvailableGrid } from "../grid/grid";
import { useQuery } from "@tanstack/react-query";
import { gridQueryOptions } from "../react-query/queryKeys";
import { getBackendService } from "../services/cb-backend";

const SCROLL_POSITION_KEY = "gridListScrollPosition";

export interface GridListProps {
    grids: AvailableGrid[] | undefined;
    isCollapsed: boolean;
    selectedGridId: string | undefined;
    onGridRename: (gridId: string, newName: string) => Promise<void>;
    onGridDelete: (gridId: string) => Promise<void>;
    onGridDuplicate: (gridId: string) => Promise<void>;
}

export const GridList: React.FC<GridListProps> = ({
    grids,
    isCollapsed,
    selectedGridId,
    onGridDelete,
    onGridRename,
    onGridDuplicate,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const handleGridClick = React.useCallback(
        (gridId: string) => {
            // Save scroll position before navigating
            if (scrollRef.current) {
                localStorage.setItem(SCROLL_POSITION_KEY, scrollRef.current.scrollTop.toString());
            }
            navigate(`/grid/${gridId}`);
        },
        [navigate],
    );

    React.useEffect(() => {
        // Restore scroll position on mount
        const scrollContainer = scrollRef.current;
        const savedScrollPosition = localStorage.getItem(SCROLL_POSITION_KEY);
        if (scrollContainer != null && savedScrollPosition != null) {
            scrollContainer.scrollTop = parseInt(savedScrollPosition, 10);
        }
    }, []);

    const backendService = React.useMemo(() => getBackendService(), []);
    const currentGrid = useQuery(gridQueryOptions(backendService, selectedGridId));

    const sortedGrids = React.useMemo(
        () => grids?.sort((a, b) => b.lastEdited.toISOString().localeCompare(a.lastEdited.toISOString())),
        [grids],
    );

    const patchedGrids = React.useMemo((): AvailableGrid[] | undefined => {
        if (currentGrid.data == null) {
            return sortedGrids;
        }
        if (sortedGrids != null && sortedGrids.some(g => g.unique_id === selectedGridId)) {
            return sortedGrids;
        }
        return [currentGrid.data, ...(sortedGrids ?? [])];
    }, [currentGrid.data, selectedGridId, sortedGrids]);

    // Loading
    if (patchedGrids == null) {
        return null;
    }

    if (patchedGrids.length > 0 && isCollapsed) {
        return <Grid2 color={theme.palette.primary.light} size="20" />;
    }

    return (
        <>
            <Box
                ref={scrollRef}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        width: "14px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#98A2B3",
                        borderRadius: "8px",
                        border: "3px solid #F4F2EF",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#F4F2EF",
                    },
                }}
            >
                {patchedGrids.map(grid => (
                    <GridItem
                        key={grid.unique_id}
                        grid={grid}
                        isSelected={selectedGridId === grid.unique_id}
                        onClick={handleGridClick}
                        onDelete={onGridDelete}
                        onRename={onGridRename}
                        onDuplicate={onGridDuplicate}
                    />
                ))}
            </Box>
        </>
    );
};

interface GridItemProps {
    grid: AvailableGrid;
    isSelected: boolean;
    onClick: (gridId: string) => void;
    onRename: (gridId: string, newName: string) => Promise<void>;
    onDelete: (gridId: string) => Promise<void>;
    onDuplicate: (gridId: string) => Promise<void>;
}

const GridItem: React.FC<GridItemProps> = ({ grid, isSelected, onClick, onRename, onDelete, onDuplicate }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [newName, setNewName] = React.useState(grid.name);
    const theme = useTheme();

    const handleItemClick = React.useCallback(() => {
        if (isSelected || isEditing) {
            return;
        }
        onClick(grid.unique_id);
    }, [isEditing, isSelected, onClick, grid.unique_id]);

    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, []);

    const handleRename = React.useCallback(
        async (name: string) => {
            setIsEditing(false);
            await onRename(grid.unique_id, name);
        },
        [onRename, grid.unique_id],
    );

    const handleDelete = React.useCallback(() => void onDelete(grid.unique_id), [onDelete, grid.unique_id]);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleRenameClick = React.useCallback(() => {
        setIsEditing(true);
        inputRef.current?.focus();
    }, []);

    const handleNameChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    }, []);

    const handleBlur = React.useCallback(() => {
        // HACKHACKHACK - this is a workaround to prevent the blur event from firing before it is focused
        if (inputRef.current !== document.activeElement) {
            return;
        }
        setIsEditing(false);
        setNewName(grid.name);
    }, [grid.name]);

    const handleNameSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await handleRename(newName);
        },
        [handleRename, newName],
    );

    const handleDuplicate = React.useCallback(() => void onDuplicate(grid.unique_id), [onDuplicate, grid.unique_id]);

    const selectedSx = isSelected ? { bgcolor: theme.palette.action.selected } : {};

    return (
        <Box
            key={grid.unique_id}
            sx={[
                {
                    display: "flex",
                    alignItems: "center",
                    padding: 1,
                    cursor: "pointer",
                    justifyContent: "space-between",
                    overflowX: "hidden",
                    flexShrink: 0,
                    "&:hover": {
                        bgcolor: theme.palette.action.hover,
                    },
                },
                selectedSx,
            ]}
            onClick={handleItemClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <Grid2
                    color={theme.palette.primary.light}
                    size="16"
                    style={{
                        flexShrink: 0,
                    }}
                />
                {isEditing ? (
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    <form onSubmit={handleNameSubmit}>
                        <TextField
                            value={newName}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                            inputRef={inputRef}
                            autoFocus
                            size="small"
                            variant="outlined"
                            color="secondary"
                            InputProps={{
                                sx: {
                                    typography: "caption",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                sx: {
                                    typography: "caption",
                                },
                            }}
                        />
                    </form>
                ) : (
                    <Typography
                        title={grid.name}
                        noWrap
                        sx={{ ml: 1, color: "primary.contrastText", minWidth: 0 }}
                        variant="caption"
                    >
                        {grid.name}
                    </Typography>
                )}
            </Box>
            {(isHovered || isSelected || isEditing) && (
                <GridMoreActions onRename={handleRenameClick} onDelete={handleDelete} onDuplicate={handleDuplicate} />
            )}
        </Box>
    );
};

interface GridMoreActionsProps {
    onRename: () => void;
    onDelete: () => void;
    onDuplicate: () => void;
}

const GridMoreActions: React.FC<GridMoreActionsProps> = ({ onRename, onDelete, onDuplicate }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleRename = React.useCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onRename();
            handleClose();
        },
        [onRename, handleClose],
    );
    const handleDelete = React.useCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onDelete();
            handleClose();
        },
        [onDelete, handleClose],
    );

    const handleDuplicate = React.useCallback(
        (event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onDuplicate();
            handleClose();
        },
        [handleClose, onDuplicate],
    );

    return (
        <div>
            <Box
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                }}
            >
                <More size={16} color={theme.palette.secondary.main} />
            </Box>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    p: 1,
                }}
            >
                <MenuItem
                    onClick={handleRename}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        px: 1.5,
                    }}
                >
                    <Edit2 size="16" />
                    <Typography variant="caption">Rename</Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleDuplicate}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        px: 1.5,
                    }}
                >
                    <Copy size="16" />
                    <Typography variant="caption">Duplicate</Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleDelete}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        px: 1.5,
                        color: theme.palette.error.main,
                    }}
                >
                    <Trash size="16" />
                    <Typography variant="caption">Delete</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};
