import * as React from "react";
import { Box, CircularProgress, lighten, Typography, useTheme } from "@mui/material";
import { ValueChip } from "../home/filters/valueChip";
import { APPersonFieldString, CellValueV2 } from "./grid";
import { Circle } from "@mui/icons-material";

export interface GridCellValueProps {
    value: CellValueV2;
    imageUrl?: string | undefined;
    valueType?: "person";
    loadingStatus: "loading" | undefined;
}

export const GridCellValue: React.FC<GridCellValueProps> = ({ imageUrl, value, valueType, loadingStatus }) => {
    const theme = useTheme();
    const pillColors = React.useMemo(
        () => ({
            bgcolor: "#EAE4D7",
            color: theme.palette.text.primary,
        }),
        [theme],
    );
    const falsePillColors = React.useMemo(
        () => ({
            bgcolor: lighten("#EAE4D7", 0.3),
            color: theme.palette.text.primary,
        }),
        [theme],
    );

    if (loadingStatus === "loading") {
        return <CircularProgress color="secondary" size={14} />;
    } else if (value?.type === "error") {
        return (
            <Circle
                color="warning"
                sx={{
                    width: 8,
                    height: 8,
                }}
            />
        );
    }

    const stringPersonValue =
        valueType === "person" && value != null && value.type === "string" && isPersonValue(value.value)
            ? value.value
            : undefined;
    if (stringPersonValue != null) {
        const name = stringPersonValue.split(";")[0];
        return <ValueChip label={formatPersonName({ value: name, type: "string" })} />;
    }

    if (value?.type === "apollo_people") {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: 0.5,
                }}
            >
                {value.people.slice(0, 1).map((person, index) => (
                    <ValueChip key={index} label={formatPersonName(person.name)} colors={pillColors} />
                ))}
                {value.people.length > 1 && (
                    <ValueChip label={`+${(value.totalCount ?? value.people.length) - 1}`} colors={pillColors} />
                )}
            </Box>
        );
    }
    if (value?.type === "technologies") {
        return (
            <Box
                sx={{
                    display: "flex",
                    gap: 0.5,
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                {value.technologies.slice(0, 1).map((technology, index) => (
                    <ValueChip key={index} label={technology.name} colors={pillColors} />
                ))}
                {value.technologies.length > 1 && (
                    <ValueChip label={`+${value.technologies.length - 1}`} colors={pillColors} />
                )}
            </Box>
        );
    }

    if (value?.type === "job_postings") {
        if (value.jobPostings.length === 0) {
            return <Circle sx={{ width: 8, height: 8, color: "primary.dark" }} />;
        }
        return (
            <Box
                sx={{
                    display: "flex",
                    gap: 0.5,
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                {value.jobPostings.slice(0, 1).map(jobPosting => (
                    <ValueChip key={jobPosting.id} label={jobPosting.title} colors={pillColors} />
                ))}
                {value.jobPostings.length > 1 && (
                    <ValueChip label={`+${value.jobPostings.length - 1}`} colors={pillColors} />
                )}
            </Box>
        );
    }

    if (value?.type === "boolean") {
        return <ValueChip label={value.value ? "Yes" : "No"} colors={value.value ? pillColors : falsePillColors} />;
    }

    if (value.type === "missing") {
        return <Circle sx={{ width: 8, height: 8, color: "primary.dark" }} />;
    }

    if (value.type === "unloaded") {
        return <CircularProgress color="secondary" size={14} />;
    }

    if (value?.type === "number") {
        return <Typography variant="body2">{value.value.toLocaleString()}</Typography>;
    }

    if (value?.type === "links") {
        return <GridCellValueString value={value.links.map(link => link.url.replace("www.", "")).join("\n")} />;
    }

    if (imageUrl != null) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <img src={imageUrl} alt="Image" style={{ width: 20, height: 20, borderRadius: "50%" }} />
                <GridCellValueString value={value.value} valueType={valueType} />
            </Box>
        );
    }

    return <GridCellValueString value={value.value} valueType={valueType} />;
};

interface GridCellValueStringProps {
    value: string;
    valueType?: "person" | "user-input";
}

const GridCellValueString: React.FC<GridCellValueStringProps> = ({ value }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                maxHeight: 100,
                WebkitLineClamp: 3,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordBreak: !value.includes(" ") ? "break-all" : "normal",
                whiteSpace: "pre-line",
            }}
        >
            {formatValueAsString(value)}
        </Typography>
    );
};

function formatValueAsString(value: string): string {
    return value.replace(/\\n/g, "\n");
}

function formatPersonName(name: APPersonFieldString): string {
    const nameValue = name.value;
    const splitName = nameValue.split(" ");
    if (splitName.length === 1) return nameValue;
    const firstName = splitName[0];
    const lastNameInitial = `${splitName[splitName.length - 1][0]}.` ?? "";
    return `${firstName} ${lastNameInitial}`;
}

function isPersonValue(value: string): boolean {
    return value.split(";").length === 3;
}
