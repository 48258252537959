import * as React from "react";
import { ReactComponent as More } from "../assets/more.svg";
import { CircularProgress, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { CloseCircle, Copy, Export, Minus, UserAdd, TickCircle, MinusSquare } from "iconsax-react";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCurrentUserQuery } from "../context/userContext";
import { isFinalTaskStatus } from "./gridUtils";
import { useBoolean } from "../utils/hooks";
import { getBackendService } from "../services/cb-backend";
import { getReactQueryGridKey } from "../react-query/queryKeys";
import { AnswerGridHeaderActionsProps } from "./answerGridHeaderActions";
import { useExportToCSV } from "../csv/useExportToCsv";
import { hasTrialFeatures, isRealRow, Row } from "./grid";
import { isNonNullable } from "../utils/isNonNullable";
import { useShareGrid } from "./useShareGrid";
import { ExportCsvDialog } from "./exportCsvDialog";
import { TruncationLimitDialog } from "./truncateDialog";

const DEFAULT_TRUNCATE_LIMIT = 10;

export const AnswerGridHeaderActionsMenu: React.FC<AnswerGridHeaderActionsProps> = ({
    columns,
    rows,
    visibleRows,
    rowGeneratingFilters,
    version,
    gridId,
    name,
    exportStatus,
    setExportMode,
    setRowGeneratingFilters,
    setRows,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openCsvDialog, setOpenDialog] = React.useState(false);

    const handleCloseDialog = React.useCallback(() => setOpenDialog(false), [setOpenDialog]);

    const exportToCSV = useExportToCSV();

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const user = useCurrentUserQuery();

    const handleClickExport = React.useCallback(() => {
        setOpenDialog(true);
        setAnchorEl(null);
    }, []);

    const backendService = React.useMemo(() => getBackendService(), []);
    const queryClient = useQueryClient();

    const handleCancel = React.useCallback(async () => {
        if (exportStatus?.taskId == null) {
            return;
        }
        await backendService.cancelTask(exportStatus.taskId);
        await queryClient.fetchQuery({ queryKey: getReactQueryGridKey(gridId) });
        handleClose();
    }, [backendService, exportStatus?.taskId, gridId, handleClose, queryClient]);

    const theme = useTheme();

    const handleTruncateGrid = React.useCallback(
        (limit: number) => {
            setRows(rows => {
                const newRows = rows.slice(0, limit);
                const lastCbUuidInNewRows = getNewLastUuidAfterTruncation(rows, limit);
                setRowGeneratingFilters(filters =>
                    filters.pagingInfo != null
                        ? {
                              ...filters,
                              pagingInfo: {
                                  ...filters.pagingInfo,
                                  fetchedCount: limit,
                                  lastFetchedUuid: lastCbUuidInNewRows,
                              },
                          }
                        : filters,
                );

                return newRows;
            });
        },
        [setRowGeneratingFilters, setRows],
    );

    const {
        value: isTruncationDialogOpen,
        setFalse: closeTruncationDialog,
        setTrue: openTruncationDialog,
    } = useBoolean(false);

    const [truncationLimit, setTruncationLimit] = React.useState<number | null>(
        Math.min(DEFAULT_TRUNCATE_LIMIT, rows.length),
    );

    const { shareGrid, isSharingGridLoading } = useShareGrid();

    const { enqueueSnackbar } = useSnackbar();

    const canShareTrialLinkToGrid = React.useMemo(
        () => user.data != null && (hasTrialFeatures(user.data.plan_type) || user.data.is_admin),
        [user.data],
    );

    const [isShared, setIsShared] = React.useState(false);
    const shareTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const handleShareGrid = React.useCallback(async () => {
        if (user.data == null) {
            return;
        }

        const shareResult = await shareGrid(gridId, canShareTrialLinkToGrid);
        if (shareResult.success) {
            setIsShared(true);
            if (shareTimeoutRef.current) {
                clearTimeout(shareTimeoutRef.current);
            }
            shareTimeoutRef.current = setTimeout(() => {
                setIsShared(false);
            }, 1500);
        } else {
            enqueueSnackbar("Failed to share grid", {
                variant: "error",
            });
        }
    }, [enqueueSnackbar, gridId, shareGrid, user.data, canShareTrialLinkToGrid]);

    React.useEffect(() => {
        return () => {
            if (shareTimeoutRef.current) {
                clearTimeout(shareTimeoutRef.current);
            }
        };
    }, []);

    const handleDropHiddenRows = React.useCallback(() => {
        // browser warning with confirmation and set rows to visible rows
        if (
            confirm(
                `Are you sure you want to remove all filtered-out rows? This will reduce the number of rows from ${rows.length} to ${visibleRows.length}. Do you want to proceed?`,
            )
        ) {
            setRows(visibleRows);
        }
    }, [setRows, visibleRows, rows.length]);

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    boxShadow: "inset 0px 0px 1px 1px #EAECF0",
                }}
            >
                <More
                    style={{
                        color: theme.palette.secondary.main,
                        width: 20,
                        height: 20,
                        // TODO: HACK: This is to remove padding in  the icon in the button
                        margin: "-2px",
                    }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    p: 1,
                }}
            >
                {exportStatus != null && !isFinalTaskStatus(exportStatus.status) ? (
                    <MenuItem
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleCancel}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            px: 1.5,
                        }}
                    >
                        <CloseCircle size="16" />
                        <Typography variant="caption">Cancel Export</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem
                        data-value="export-grid"
                        // TODO: Should we check is fetched or something
                        onClick={user.data != null ? handleClickExport : undefined}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            px: 1.5,
                        }}
                    >
                        {user.isPending ? (
                            <CircularProgress
                                sx={{
                                    color: "primary.contrastText",
                                }}
                                size={16}
                            />
                        ) : (
                            <Export size="16" />
                        )}
                        <Typography variant="caption">Export as CSV</Typography>
                    </MenuItem>
                )}
                {rows.length > 0 && (user.data?.is_admin ?? false) && (
                    <MenuItem
                        data-value="truncate-grid"
                        onClick={openTruncationDialog}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            px: 1.5,
                        }}
                    >
                        <MinusSquare size="16" />
                        <Typography variant="caption">Truncate rows</Typography>
                    </MenuItem>
                )}
                {visibleRows.length < rows.length && (
                    <MenuItem
                        data-value="drop-hidden-rows"
                        onClick={handleDropHiddenRows}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            px: 1.5,
                        }}
                    >
                        <Minus size="16" />
                        <Typography variant="caption">Drop hidden rows</Typography>
                    </MenuItem>
                )}
                {/* N.B. Trial users share via a big button, not here */}
                {user.data != null && !hasTrialFeatures(user.data.plan_type) && (
                    <MenuItem
                        data-value="share-grid"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleShareGrid}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            px: 1.5,
                            color: isShared ? theme.palette.success.main : "inherit",
                            transition: "color 0.3s ease",
                        }}
                    >
                        {isSharingGridLoading ? (
                            <CircularProgress
                                sx={{
                                    color: "primary.contrastText",
                                }}
                                size={16}
                            />
                        ) : isShared ? (
                            <TickCircle size="16" color={theme.palette.success.main} />
                        ) : canShareTrialLinkToGrid ? (
                            <UserAdd size="16" />
                        ) : (
                            <Copy size="16" />
                        )}
                        <Typography
                            variant="caption"
                            sx={{
                                visibility: isShared ? "hidden" : "visible",
                                opacity: isShared ? 0 : 1,
                                transition: "all 0.3s ease",
                            }}
                        >
                            {canShareTrialLinkToGrid ? "Share with a friend" : "Copy link to grid"}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                position: "absolute",
                                left: "40px", // Adjust this value as needed
                                visibility: isShared ? "visible" : "hidden",
                                opacity: isShared ? 1 : 0,
                                transition: "all 0.3s ease",
                            }}
                        >
                            Link copied
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
            {openCsvDialog && (
                <ExportCsvDialog
                    totalRowCount={rowGeneratingFilters.pagingInfo?.totalCount ?? rows.length}
                    isOpen={openCsvDialog}
                    columns={columns}
                    rows={rows}
                    visibleRows={visibleRows}
                    version={version}
                    rowGeneratingFilters={rowGeneratingFilters}
                    gridId={gridId}
                    name={name}
                    setExportMode={setExportMode}
                    onClose={handleCloseDialog}
                    exportToCsv={exportToCSV}
                />
            )}
            {isTruncationDialogOpen && (
                <TruncationLimitDialog
                    isOpen={isTruncationDialogOpen}
                    onClose={closeTruncationDialog}
                    rowCount={rows.length}
                    truncationLimit={truncationLimit}
                    setTruncationLimit={setTruncationLimit}
                    onTruncate={handleTruncateGrid}
                />
            )}
        </>
    );
};

function getNewLastUuidAfterTruncation(rows: Row[], limit: number) {
    const lastCbUuidInNewRows = rows
        .slice(0, limit)
        .filter(isRealRow)
        .map(row => row.externalIdentifiers?.crunchbase?.uuid)
        // find last non-null
        .reduce((acc, curr) => curr ?? acc, undefined);
    if (lastCbUuidInNewRows != null) {
        return lastCbUuidInNewRows;
    } else {
        return rows
            .slice(limit)
            .filter(isRealRow)
            .map(row => row.externalIdentifiers?.crunchbase?.uuid)
            .find(isNonNullable);
    }
}
