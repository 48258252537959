import * as React from "react";

type UseBooleanOutput = {
    value: boolean;
    setTrue: () => void;
    setFalse: () => void;
    updateValue: React.Dispatch<React.SetStateAction<boolean>>;
    toggleValue: () => void;
};

export const useBoolean = (initialValue = false): UseBooleanOutput => {
    const [value, setValue] = React.useState(initialValue);

    const setTrue = React.useCallback(() => setValue(true), []);
    const setFalse = React.useCallback(() => setValue(false), []);
    const toggleValue = React.useCallback(() => setValue(prevValue => !prevValue), []);

    return {
        value,
        setTrue,
        setFalse,
        updateValue: setValue,
        toggleValue,
    };
};

export const useOnMount = (callback: () => void): void => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, []);
};

export const usePrevious = <T,>(value: T): T | undefined => {
    const ref = React.useRef<T>();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
