export type Primitive = string | number | boolean | Date;

export type FieldExtractor<T> = (obj: T) => Primitive[];

export function comparatorOnFields<T>(fieldExtractor: FieldExtractor<T>) {
    return (a: T, b: T): number => {
        const aValues = fieldExtractor(a);
        const bValues = fieldExtractor(b);

        for (let i = 0; i < aValues.length; i++) {
            const aValue = aValues[i];
            const bValue = bValues[i];

            if (aValue === bValue) {
                continue;
            }

            if (typeof aValue === "string" && typeof bValue === "string") {
                return aValue.localeCompare(bValue);
            }

            if (typeof aValue === "number" && typeof bValue === "number") {
                return aValue - bValue;
            }

            if (typeof aValue === "boolean" && typeof bValue === "boolean") {
                return aValue === bValue ? 0 : aValue ? 1 : -1;
            }

            if (aValue instanceof Date && bValue instanceof Date) {
                return aValue.getTime() - bValue.getTime();
            }

            // Add other primitive comparisons as needed
        }

        return 0;
    };
}
