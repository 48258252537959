import * as React from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Button, Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { ArrowOutward } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useCurrentUserQuery } from "../context/userContext";
import { InfoCircle } from "iconsax-react";
import { SignUpForm } from "./SignUpForm";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { Helmet } from "react-helmet-async";

const IMAGE_URL = "https://AnswerGrid.b-cdn.net/the-harvest.png";

export const SignUpViaInvitation: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Invitation - AnswerGrid</title>
            </Helmet>
            <SignUpViaInvitationContent />
        </>
    );
};

export const SignUpViaInvitationContent: React.FC = () => {
    const backendService = React.useMemo(() => getBackendService(), []);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const invitationCode = searchParams.get("code");
    const { enqueueSnackbar } = useSnackbar();

    const user = useCurrentUserQuery();

    React.useEffect(() => {
        if (isLoggedIn() && user.data != null) {
            if (user.data.plan_type === "trial") {
                localStorage.setItem("answerGrid.trialUser", user.data.username);
                void backendService.logout();
            } else {
                navigate("/home");
                enqueueSnackbar("You are already logged in. Redirecting to home.", { variant: "info" });
            }
        }
    }, [backendService, enqueueSnackbar, navigate, user.data]);

    const {
        data: invitationStatus,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ["invitationStatus", invitationCode],
        queryFn: () =>
            invitationCode != null ? backendService.getInvitationStatus(invitationCode) : Promise.resolve(null),
        enabled: invitationCode != null,
    });

    const createCheckoutSession = useMutation({
        mutationFn: ({ code }: { code: string }) => backendService.createInvitationCheckoutSession({ code }),
        onSuccess: data => {
            window.location.href = data.url;
        },
        onError: error => {
            console.error("Error creating checkout session:", error);
            enqueueSnackbar("Error creating checkout session", { variant: "error" });
        },
    });

    const handleRedirectToPayment = React.useCallback(async () => {
        if (invitationCode == null) {
            return;
        }
        return createCheckoutSession.mutateAsync({ code: invitationCode });
    }, [createCheckoutSession, invitationCode]);

    const theme = useTheme();

    if (invitationCode == null) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <InfoCircle color={theme.palette.error.main} size={32} />
                    <Typography variant="h6" align="center" color="error">
                        No invite code provided.
                    </Typography>
                </Box>
            </UserAdministrationLayout>
        );
    }

    if (isLoading) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <CircularProgress
                    sx={{
                        color: "primary.contrastText",
                        alignSelf: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                />
            </UserAdministrationLayout>
        );
    }

    if (isError || invitationStatus == null) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <InfoCircle color={theme.palette.error.main} size={24} />
                    <Typography variant="body1" align="center" color="error">
                        Unable to retrieve invitation details.
                    </Typography>
                </Box>
            </UserAdministrationLayout>
        );
    }

    const isPaid = invitationStatus?.paid ?? false;
    const isPaidInvitation =
        invitationStatus.plan_type === "paying-user" || invitationStatus.plan_type === "higher-tier-paying-user";

    if (isPaidInvitation && !isPaid) {
        return (
            <UserAdministrationLayout imageUrl={IMAGE_URL}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <Logo
                        style={{
                            width: 40,
                            height: 40,
                        }}
                    />
                    <Typography variant="body1" align="center" sx={{ mt: 2, mb: 2, maxWidth: 450 }}>
                        Welcome to AnswerGrid. To get you started, we&apos;ll first redirect you to Stripe to pay for
                        your subscription.
                    </Typography>
                    {invitationStatus != null && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                endIcon={<ArrowOutward />}
                                variant="contained"
                                color="secondary"
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={handleRedirectToPayment}
                                disabled={createCheckoutSession.isPending}
                                sx={{
                                    textTransform: "none",
                                    borderRadius: 8,
                                }}
                            >
                                {createCheckoutSession.isPending ? "Redirecting…" : "Check out with Stripe"}
                            </Button>
                        </Box>
                    )}
                </Box>
            </UserAdministrationLayout>
        );
    }

    return (
        <UserAdministrationLayout imageUrl={IMAGE_URL}>
            <SignUpContent invitationCode={invitationCode} isPaidInvitation={isPaidInvitation} />
        </UserAdministrationLayout>
    );
};

const SignUpContent: React.FC<{ invitationCode: string | null; isPaidInvitation: boolean }> = ({
    invitationCode,
    isPaidInvitation,
}) => {
    const backendService = React.useMemo(() => getBackendService(), []);
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [agreeToPolicy, setAgreeToPolicy] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const handleAgreeToPolicy = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeToPolicy(event.target.checked);
    }, []);

    const signupMutation = useMutation({
        mutationFn: (data: { email: string; password: string; code: string; first_name: string; last_name: string }) =>
            backendService.signup(data),
        onSuccess: () => {
            navigate("/login");
        },
        onError: error => {
            console.error("Sign up failed:", error);
            setError("Sign up failed.");
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (password !== confirmPassword) {
                setError("Passwords do not match.");
                return;
            }

            if (!invitationCode) {
                setError("Invalid invite code.");
                return;
            }
            if (!agreeToPolicy) {
                setError("You must agree to the Privacy Policy to sign up.");
                return;
            }

            await signupMutation.mutateAsync({
                email,
                password,
                code: invitationCode,
                first_name: firstName.trim(),
                last_name: lastName.trim(),
            });
        },
        [signupMutation, invitationCode, email, password, confirmPassword, agreeToPolicy, firstName, lastName],
    );

    const handleClick = React.useCallback(async () => {
        if (invitationCode == null) {
            return;
        }
        await signupMutation.mutateAsync({
            email,
            password,
            code: invitationCode,
            first_name: firstName.trim(),
            last_name: lastName.trim(),
        });
    }, [signupMutation, email, password, invitationCode, firstName, lastName]);

    return (
        <UserAdministrationForm
            title="Sign Up"
            actionButtonText="Sign up"
            loading={signupMutation.isPending}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleClick}
        >
            <SignUpForm
                email={email}
                password={password}
                confirmPassword={confirmPassword}
                agreeToPolicy={agreeToPolicy}
                firstName={firstName}
                lastName={lastName}
                onEmailChange={e => setEmail(e.target.value)}
                onPasswordChange={e => setPassword(e.target.value)}
                onConfirmPasswordChange={e => setConfirmPassword(e.target.value)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                onAgreeChange={handleAgreeToPolicy}
                onFirstNameChange={setFirstName}
                onLastNameChange={setLastName}
                error={error ?? undefined}
            />
            {isPaidInvitation && (
                <Box sx={{ borderRadius: 1, alignSelf: "stretch", bgcolor: "success.light", px: 1, py: 0.5 }}>
                    <Typography variant="body2" align="center" color="white">
                        Payment successful. Time to sign up!
                    </Typography>
                </Box>
            )}
        </UserAdministrationForm>
    );
};
