import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUserQuery } from "../context/userContext";
import { freeTrialQueryOptions } from "../react-query/queryKeys";
import { getBackendService } from "../services/cb-backend";

export function useFreeTrialUsage() {
    const currentUser = useCurrentUserQuery();

    const backendService = React.useMemo(() => getBackendService(), []);
    const freeTrialUsageQuery = useQuery(freeTrialQueryOptions(currentUser.data, backendService));

    return freeTrialUsageQuery;
}
