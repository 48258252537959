import { Grid } from "@mui/material";
import * as React from "react";
import { SuggestedQueryCard } from "./suggestedQueryCard";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SuggestedQueriesProps {
    queries: [string, string, string, string] | readonly [string, string, string, string];
    onClick: (query: string) => void;
}

export const SuggestedQueries: React.FC<SuggestedQueriesProps> = ({ queries, onClick }) => {
    // TODO: Onclick
    const handleClick = React.useCallback(
        (query: string) => {
            onClick(query);
        },
        [onClick],
    );

    return (
        <Grid
            container
            spacing={1}
            sx={{
                mt: 1,
            }}
        >
            {queries.map((item, index) => (
                <Grid key={index} item xs={6}>
                    <SuggestedQueryCard title={item} onClick={handleClick} />
                </Grid>
            ))}
        </Grid>
    );
};
