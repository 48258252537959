import * as React from "react";
import { Link, Typography, Box, useTheme } from "@mui/material";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { TickCircle } from "iconsax-react";
import { useCurrentUserQuery } from "../context/userContext";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { CURRENT_USER_KEY } from "../react-query/queryKeys";
import { SignUpForm } from "./SignUpForm";
import { UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY } from "../grid/grid";
import { Helmet } from "react-helmet-async";

const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const SignUpViaSelfService: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Sign Up - AnswerGrid</title>
            </Helmet>
            <UserAdministrationLayout imageUrl="https://AnswerGrid.b-cdn.net/the-harvest.png">
                <SignUpContent />
            </UserAdministrationLayout>
        </>
    );
};

const SignUpContent: React.FC = () => {
    const backendService = React.useMemo(() => getBackendService(), []);
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [isSignupComplete, setIsSignupComplete] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [agreeToPolicy, setAgreeToPolicy] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const handleAgreeToPolicy = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeToPolicy(event.target.checked);
    }, []);

    const user = useCurrentUserQuery();

    React.useEffect(() => {
        if (isLoggedIn() && user.data != null && user.data.plan_type !== "trial") {
            enqueueSnackbar("You are already logged in. Redirecting to home.", { variant: "info" });
            navigate("/home");
        }
    }, [enqueueSnackbar, navigate, user.data]);

    const queryClient = useQueryClient();
    const [queryParams] = useSearchParams();
    const plan = React.useMemo(() => queryParams.get("plan"), [queryParams]);
    const signupMutation = useMutation({
        mutationFn: (data: { email: string; password: string; first_name: string; last_name: string }) =>
            backendService.selfServiceSignUp(data),
        onSuccess: () => {
            setIsSignupComplete(true);
        },
        onMutate: () => {
            if (plan != null && localStorage.getItem(UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY) == null) {
                localStorage.setItem(UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY, plan);
            }
        },
        onError: (error: Error) => {
            console.error("Sign up failed:", error);
            setError(`Sign up failed. \n ${error.message}`);
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (password !== confirmPassword) {
                setError("Passwords do not match.");
                return;
            }
            await signupMutation.mutateAsync({
                email,
                password,
                first_name: firstName.trim(),
                last_name: lastName.trim(),
            });
            await backendService.logout();
            await queryClient.invalidateQueries({ queryKey: CURRENT_USER_KEY });
        },
        [signupMutation, queryClient, backendService, email, password, confirmPassword, firstName, lastName],
    );

    const handleSignUp = React.useCallback(async () => {
        if (password.length === 0) {
            setError("Please enter a password.");
            return;
        }
        if (confirmPassword.length === 0) {
            setError("Please confirm your password.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        if (!EMAIL_REGEX.test(email)) {
            setError("Invalid email address.");
            return;
        }
        if (!agreeToPolicy) {
            setError("You must agree to the Privacy Policy to sign up.");
            return;
        }
        await signupMutation.mutateAsync({
            email,
            password,
            first_name: firstName.trim(),
            last_name: lastName.trim(),
        });
    }, [signupMutation, email, password, confirmPassword, agreeToPolicy, firstName, lastName]);

    const handleChangeEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleChangePassword = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, []);

    const handleChangeConfirmPassword = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    }, []);

    const theme = useTheme();

    if (isSignupComplete) {
        return (
            <Box
                sx={{
                    textAlign: "center",
                    mt: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 1,
                }}
            >
                <TickCircle color={theme.palette.success.main} size={48} variant="Bold" />
                <Typography variant="h6" sx={{ mt: 2 }}>
                    Sign Up Successful
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, maxWidth: 400 }}>
                    Please check your email to verify your account. You&apos;ll need to verify your email before logging
                    in.
                </Typography>
            </Box>
        );
    }

    return (
        <UserAdministrationForm
            title="Sign Up"
            actionButtonText="Sign up"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleSignUp}
            loading={signupMutation.isPending}
            underButtonContent={
                <Typography variant="body2">
                    Already have an account?{" "}
                    <Link href="/login" color="secondary" sx={{ textDecoration: "none", fontWeight: "medium" }}>
                        Sign in →
                    </Link>
                </Typography>
            }
        >
            <SignUpForm
                email={email}
                password={password}
                confirmPassword={confirmPassword}
                agreeToPolicy={agreeToPolicy}
                onEmailChange={handleChangeEmail}
                onPasswordChange={handleChangePassword}
                onConfirmPasswordChange={handleChangeConfirmPassword}
                onAgreeChange={handleAgreeToPolicy}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                error={error ?? undefined}
                firstName={firstName}
                lastName={lastName}
                onFirstNameChange={setFirstName}
                onLastNameChange={setLastName}
            />
        </UserAdministrationForm>
    );
};
