import {
    CellValueV2,
    Column,
    isRealRow,
    RealRow,
    Row,
    RowValueWithCitations,
    ColumnVisibilityFilter,
    VisibilityFiltersByColumn,
    NumberVisibilityFilter,
    NullVisibilityFilter,
} from "../../grid/grid";

export function hasFilters(visibilityFilters: ColumnVisibilityFilter): boolean {
    return (
        visibilityFilters.nullFilter != null ||
        visibilityFilters.booleanFilter != null ||
        visibilityFilters.stringFilter != null ||
        (visibilityFilters.numberFilter != null &&
            (visibilityFilters.numberFilter.gte != null ||
                visibilityFilters.numberFilter.lte != null ||
                (visibilityFilters.numberFilter.eq?.length ?? 0) > 0))
    );
}
// TODO: CLean this
const NULL_ISH_VALUES_ALWAYS = [null, undefined, "", "-", "N/A"];

export function getVisibleRows(
    rows: Row[],
    columns: Column[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
): Row[] {
    return rows.filter(
        row =>
            !isRealRow(row) ||
            Object.entries(visibilityFiltersByColumn).every(([columnId, columnFilter]) => {
                const column = columns.find(column => column.id === columnId);
                if (column == null) {
                    return true;
                }
                return isRowVisibleForColumn(row, column, columnFilter);
            }),
    );
}

const APOLLO_NULL_VALUE = "Unknown";
export function isRowVisibleForColumn(row: RealRow, column: Column, columnFilter: ColumnVisibilityFilter): boolean {
    const visibleForNullFilter =
        columnFilter.nullFilter == null || isVisibleForNullFilter(row, column, columnFilter.nullFilter);
    const visibleForBooleanFilter =
        columnFilter.booleanFilter == null || isBooleanVisible(row, column, columnFilter.booleanFilter.value);
    const visibleForStringFilter =
        columnFilter.stringFilter == null || isStringValueVisible(row, column, columnFilter.stringFilter.value);
    const visibleForNumberFilter =
        columnFilter.numberFilter == null || isNumberValueVisible(row, column, columnFilter.numberFilter);
    return visibleForNullFilter && visibleForBooleanFilter && visibleForStringFilter && visibleForNumberFilter;
}

function isVisibleForNullFilter(row: RealRow, column: Column, filter: NullVisibilityFilter): boolean {
    const isNullValue = isNull(row, column);
    return filter.type === "is-null" ? isNullValue : !isNullValue;
}

function isNull(row: RealRow, column: Column): boolean {
    const rowValueWithCitations = row.data[column.id];
    if (rowValueWithCitations.value.type === "apollo_people" || rowValueWithCitations.value.type === "boolean") {
        return false;
    }
    if (rowValueWithCitations.value.type === "error" || rowValueWithCitations.value.type === "missing") {
        return true;
    }
    if (rowValueWithCitations.value.type === "technologies") {
        return rowValueWithCitations.value.technologies.length === 0;
    }
    if (rowValueWithCitations.value.type === "job_postings") {
        return rowValueWithCitations.value.jobPostings.length === 0;
    }
    if (rowValueWithCitations.value.type === "unloaded") {
        return true;
    }
    if (rowValueWithCitations.value.type === "number") {
        return false;
    }
    if (rowValueWithCitations.value.type === "links") {
        return rowValueWithCitations.value.links.length === 0;
    }
    const isNullish = NULL_ISH_VALUES_ALWAYS.includes(rowValueWithCitations.value.value);
    if (column.generatedBy?.type === "apollo") {
        return (
            isNullish ||
            // Backcompat: Apollo used to return "Unknown" for null values
            (rowValueWithCitations.value.type === "string" && rowValueWithCitations.value.value === APOLLO_NULL_VALUE)
        );
    }
    return isNullish;
}

function isBooleanVisible(row: RealRow, column: Column, value: boolean): boolean {
    const rowValueWithCitations = row.data[column.id];
    if (rowValueWithCitations.value.type !== "boolean") {
        return false;
    }
    return rowValueWithCitations.value.value === value;
}

function isStringValueVisible(row: RealRow, column: Column, filterValues: string[]): boolean {
    if (filterValues.length === 0) {
        return true;
    }
    const rowValueWithCitations = row.data[column.id];
    const valuesInCell = getStringValuesFromValue(rowValueWithCitations);
    if (valuesInCell.length === 0) {
        return false;
    }
    const lowerCaseFilterValues = filterValues.map(value => value.toLowerCase());
    return valuesInCell.map(value => value.toLowerCase()).some(value => lowerCaseFilterValues.includes(value));
}

function isNumberValueVisible(row: RealRow, column: Column, filter: NumberVisibilityFilter): boolean {
    const value = row.data[column.id].value;
    if (value.type !== "number") {
        return true;
    }
    const isVisibleForGte = filter.gte == null || value.value >= filter.gte;
    const isVisibleForLte = filter.lte == null || value.value <= filter.lte;
    const isVisibleForEq = filter.eq == null || filter.eq.length === 0 || filter.eq.includes(value.value);
    return isVisibleForGte && isVisibleForLte && isVisibleForEq;
}

export function getStringValuesFromValue(value: RowValueWithCitations<CellValueV2>): string[] {
    if (value.value.type === "technologies") {
        return value.value.technologies.map(t => t.name);
    }
    if (value.value.type === "job_postings") {
        return value.value.jobPostings.map(j => j.title);
    }
    if (value.value.type === "string") {
        return [value.value.value];
    }
    if (value.value.type === "unloaded") {
        return [];
    }
    return [];
}
