import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

interface UserAdministrationFormProps {
    title: string;
    children: React.ReactNode;
    actionButtonText: string;
    onActionButtonClick: () => void;
    underButtonContent?: React.ReactNode;
    loading?: boolean;
}

export const UserAdministrationForm: React.FC<UserAdministrationFormProps> = ({
    title,
    children,
    actionButtonText,
    onActionButtonClick,
    underButtonContent,
    loading,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "400px",
                width: "100%",
                margin: "auto",
                padding: 3,
            }}
        >
            <Typography variant="h5" component="h1" sx={{ mb: 3, fontWeight: "medium", color: "secondary.main" }}>
                {title}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {children}

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onActionButtonClick}
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} /> : undefined}
                    sx={{
                        mt: 2,
                        py: 1.5,
                        borderRadius: 8,
                        height: 40,
                    }}
                >
                    {actionButtonText}
                </Button>

                {underButtonContent && <Box sx={{ mt: 2, textAlign: "left" }}>{underButtonContent}</Box>}
            </Box>
        </Box>
    );
};
