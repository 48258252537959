import * as React from "react";
import { AgentToolConfig, FormatOptions } from "./grid";
import { Box, ListItemIcon, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { Calculator, Check, Magicpen, Smallcaps, TextBlock } from "iconsax-react";
import { filterObject } from "../utils/objects";

type PickableFormatOption = "Default" | "Boolean" | "Concise" | "Inferred" | "Number";

const FORMAT_INFO_BY_OPTION: Record<
    PickableFormatOption,
    {
        displayName: string;
    }
> = {
    Inferred: {
        displayName: "Auto-pick",
    },
    Default: {
        displayName: "Default",
    },
    Boolean: {
        displayName: "Boolean",
    },
    Concise: {
        displayName: "Concise",
    },
    Number: {
        displayName: "Number",
    },
};

const FORMAT_OPTIONS_TYPE_BY_PICKABLE_OPTION: Record<PickableFormatOption, FormatOptions["type"] | undefined> = {
    Default: undefined,
    Boolean: "bool",
    Concise: "concise",
    Inferred: "inferred",
    Number: "number",
};

const ICON_SIZE = 16;

export interface FormatOptionsPickerProps {
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
    onChange: (formatOptions: FormatOptions | undefined) => void;
}

// TODO: share more code ith tool picker
export const FormatOptionsPicker: React.FC<FormatOptionsPickerProps> = ({ toolConfig, formatOptions, onChange }) => {
    const availableFormatInfos = React.useMemo(() => {
        return filterObject(FORMAT_INFO_BY_OPTION, formatOption => {
            if (FORMAT_OPTIONS_TYPE_BY_PICKABLE_OPTION[formatOption] === formatOptions?.type) {
                return true;
            }
            if (toolConfig?.toolName === "FindWebPages") {
                return formatOption === "Inferred";
            }
            return true;
        });
    }, [formatOptions?.type, toolConfig?.toolName]);
    const handleChange = React.useCallback(
        (e: SelectChangeEvent<PickableFormatOption>) => {
            const value: PickableFormatOption = e.target.value as PickableFormatOption;
            const type = FORMAT_OPTIONS_TYPE_BY_PICKABLE_OPTION[value];
            onChange(type == null ? undefined : { type });
        },
        [onChange],
    );

    const selectedValue: PickableFormatOption = getPickableFormatOption(formatOptions);

    return (
        <Select
            value={selectedValue}
            onChange={handleChange}
            size="small"
            inputProps={{
                sx: { pt: 0.65, pb: 0.25, pl: 1 },
            }}
        >
            {Object.entries(availableFormatInfos).map(([option, info]) => (
                <MenuItem key={option} value={option}>
                    <FormatOptionsMenuItem
                        icon={
                            <FormatOptionIcon
                                isSelected={selectedValue === option}
                                optionName={option as PickableFormatOption}
                            />
                        }
                        displayName={info.displayName}
                    />
                </MenuItem>
            ))}
        </Select>
    );
};

function getPickableFormatOption(formatOptions: FormatOptions | undefined): PickableFormatOption {
    switch (formatOptions?.type) {
        case "bool":
            return "Boolean";
        case "concise":
            return "Concise";
        case "number":
            return "Number";
        case undefined:
            return "Default";
        default:
            return "Inferred";
    }
}

interface FormatOptionsMenuItemProps {
    icon: React.ReactNode;
    displayName: string;
}

const FormatOptionsMenuItem: React.FC<FormatOptionsMenuItemProps> = ({ icon, displayName }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 0.75,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "0 !important",
                }}
            >
                {icon}
            </ListItemIcon>
            <Typography variant="body2">{displayName}</Typography>
        </Box>
    );
};

interface FormatOptionIconProps {
    optionName: PickableFormatOption;
    isSelected: boolean;
}

const FormatOptionIcon: React.FC<FormatOptionIconProps> = ({ optionName, isSelected }) => {
    const theme = useTheme();
    switch (optionName) {
        case "Boolean":
            return (
                <Check
                    variant={isSelected ? "Bold" : undefined}
                    size={ICON_SIZE}
                    color={theme.palette.secondary.main}
                />
            );
        case "Default":
            return (
                <TextBlock
                    variant={isSelected ? "Bold" : undefined}
                    size={ICON_SIZE}
                    color={theme.palette.secondary.main}
                />
            );
        case "Concise":
            return (
                <Smallcaps
                    variant={isSelected ? "Bold" : undefined}
                    size={ICON_SIZE}
                    color={theme.palette.secondary.main}
                />
            );
        case "Inferred":
            return (
                <Magicpen
                    variant={isSelected ? "Bold" : undefined}
                    size={ICON_SIZE}
                    color={theme.palette.secondary.main}
                />
            );
        case "Number":
            return (
                <Calculator
                    variant={isSelected ? "Bold" : undefined}
                    size={ICON_SIZE}
                    color={theme.palette.secondary.main}
                />
            );
    }
};
