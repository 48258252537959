import { Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";
import { ExportProgress } from "./grid";
import { Information, TickCircle } from "iconsax-react";

interface ExportStatusIconProps {
    status: ExportProgress["status"];
}

export const ExportStatusIcon: React.FC<ExportStatusIconProps> = ({ status }) => {
    const theme = useTheme();
    switch (status) {
        case "SUCCESS":
            return <TickCircle size="16" />;
        case "FAILURE":
            return <Information color={theme.palette.error.main} />;
        case "PENDING":
        case "RETRY":
        case "STARTED":
        case "PROGRESS":
            return (
                <Box
                    sx={{
                        width: 16,
                        height: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress
                        size={16}
                        sx={{
                            color: "primary.contrastText",
                        }}
                    />
                </Box>
            );
        case "REVOKED":
            return <Information color={theme.palette.warning.main} />;
    }
};
