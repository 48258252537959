import * as React from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { CellValueV2Missing, CellValueV2String } from "./grid";

interface EditableGridCellValueProps {
    value: CellValueV2String | CellValueV2Missing;
    isFocused: boolean;
    onChange: (value: CellValueV2String) => void;
    onBlur: () => void;
}

export const EditableGridCellValue: React.FC<EditableGridCellValueProps> = ({ isFocused, value, onChange, onBlur }) => {
    // const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ type: "string", value: event.target.value });
        },
        [onChange],
    );

    const inputProps = React.useMemo<TextFieldProps["inputProps"]>(
        () => ({
            maxRows: 2,
            "data-1p-ignore": true,
        }),
        [],
    );

    const strValue = value.type === "string" ? value.value : "";

    // const handleFocus = React.useCallback(() => setIsFocused(true), []);
    const handleBlur = React.useCallback(() => {
        // trim whitespace
        onChange({ type: "string", value: strValue.trim() });
        onBlur();
    }, [onChange, strValue, onBlur]);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)) {
                event.stopPropagation();
                return;
            }

            if (event.key === "Escape") {
                handleBlur();
            } else if (event.key === "Enter") {
                if (event.shiftKey) {
                    // Allow Shift+Enter for newline
                    return;
                }
                event.preventDefault();
                handleBlur();
            }
        },
        [handleBlur],
    );

    if (!isFocused) {
        return (
            <Typography
                variant="body2"
                // onClick={handleFocus}
                sx={{
                    cursor: "pointer",
                    // py: 2,
                    // p: 1,
                    maxHeight: 100,
                    WebkitLineClamp: 3,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "break-word",
                    wordBreak: !strValue.includes(" ") ? "break-all" : "normal",
                }}
            >
                {strValue}
            </Typography>
        );
    }

    return (
        <TextField
            multiline
            fullWidth
            variant="standard"
            value={strValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            InputProps={{
                disableUnderline: true,
                sx: {
                    typography: "body2",
                    p: 0,
                },
            }}
            inputProps={inputProps}
        />
    );
};
