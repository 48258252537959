import * as React from "react";
import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from "@mui/material";
import {
    CRUNCHBASE_FILTERABLE_FIELD_IDS,
    operatorDisplayNames,
    CrunchbasePickableFilterPredicates,
    INITIAL_SUPPORTED_OPERATORS_BY_FIELD_ID,
} from "../../services/filterUtils";
import { OperatorsAllSuperType, BeSupportedFieldId, Money } from "../../services/cb-backend-types";
import { EditableIncompleteCrunchbaseFilter } from "./types";
import { CRUNCHBASE_FIELD_DISPLAY_NAMES } from "../../services/crunchbase";
import { comparatorOnFields } from "../../utils/comparators";
import { EditableFilterComponentLayoutNonEditingContent } from "./editableFilterComponentLayoutNonEditingContent";
import { SelectWithAutocomplete } from "../../reusable/selectWithAutocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DEFAULT_MONEY_VALUE: Money = {
    value: 1000000,
    value_usd: 1000000,
    currency: "USD",
};

// EditableFilterComponentLayout.tsx
export interface EditableFilterComponentLayoutProps<T extends EditableIncompleteCrunchbaseFilter> {
    actionButtonWhileEditing?: React.ReactNode;
    isEditing: boolean;
    dlt: React.ReactNode;
    edit: React.ReactNode;
    valuePicker: React.ReactNode;
    nonEditingContent: React.ReactNode | undefined;
    filter: T;
    index: number;
    onFilterChange: (idx: number, newFilter: T) => void;
}

export const EditableFilterComponentLayout = <T extends EditableIncompleteCrunchbaseFilter>({
    actionButtonWhileEditing,
    isEditing,
    dlt,
    edit,
    valuePicker,
    nonEditingContent,
    filter,
    index,
    onFilterChange,
}: EditableFilterComponentLayoutProps<T>) => {
    return (
        <Box sx={{ display: "flex", alignItems: "stretch", flex: 1, overflowX: "hidden" }}>
            <EditableFilterComponentLayoutContent
                actionButtonWhileEditing={actionButtonWhileEditing}
                isEditing={isEditing}
                dlt={dlt}
                edit={edit}
                valuePicker={valuePicker}
                nonEditingContent={nonEditingContent}
                filter={filter}
                index={index}
                onFilterChange={onFilterChange}
            />
        </Box>
    );
};

const EditableFilterComponentLayoutContent = <T extends EditableIncompleteCrunchbaseFilter>({
    actionButtonWhileEditing,
    isEditing,
    dlt,
    edit,
    valuePicker,
    nonEditingContent,
    filter,
    index,
    onFilterChange,
}: EditableFilterComponentLayoutProps<T>) => {
    const handleFieldIdChange = React.useCallback(
        (e: SelectChangeEvent<CrunchbasePickableFilterPredicates["field_id"] | undefined>) => {
            if (e.target.value == null) {
                onFilterChange(index, {
                    ...filter,
                    filter: { ...filter.filter, field_id: undefined, operator_id: undefined, values: [] },
                });
            }
            const newSupportedOperators = INITIAL_SUPPORTED_OPERATORS_BY_FIELD_ID[e.target.value as BeSupportedFieldId];

            const newOperatorIdIfPreviouslySet =
                filter.filter.operator_id != null &&
                newSupportedOperators.includes(filter.filter.operator_id as OperatorsAllSuperType)
                    ? filter.filter.operator_id
                    : undefined;

            onFilterChange(index, {
                ...filter,
                filter: {
                    ...filter.filter,
                    field_id: e.target.value,
                    operator_id:
                        filter.filter.operator_id != null ? newOperatorIdIfPreviouslySet : newSupportedOperators[0],
                    // TODO: This will also depend on the operator if we support multiple values
                    values:
                        e.target.value === "funding_total" || e.target.value === "last_funding_total"
                            ? [DEFAULT_MONEY_VALUE]
                            : [],
                },
            });
        },
        [filter, index, onFilterChange],
    );

    const handleOperatorChange = React.useCallback(
        (e: SelectChangeEvent<CrunchbasePickableFilterPredicates["operator_id"] | undefined>) => {
            const filterSupportedOperators =
                INITIAL_SUPPORTED_OPERATORS_BY_FIELD_ID[filter.filter.field_id as BeSupportedFieldId];
            if (!filterSupportedOperators.includes(e.target.value as OperatorsAllSuperType)) {
                console.error(
                    `Operator ${filter.filter.operator_id ?? "undef"} is not supported for field ${
                        filter.filter.field_id ?? "undef"
                    }`,
                );
                return;
            }
            onFilterChange(index, {
                ...filter,
                filter: { ...filter.filter, operator_id: e.target.value },
            });
        },
        [filter, index, onFilterChange],
    );

    const filterableFieldsSorted = React.useMemo(
        () =>
            Array.from(CRUNCHBASE_FILTERABLE_FIELD_IDS).sort(
                comparatorOnFields(field => [CRUNCHBASE_FIELD_DISPLAY_NAMES[field].displayName]),
            ),
        [],
    );

    const renderTypeValue = React.useCallback(
        (value: CrunchbasePickableFilterPredicates["field_id"] | "") =>
            value == null || value === "" ? "Select type" : CRUNCHBASE_FIELD_DISPLAY_NAMES[value].displayName,
        [],
    );

    const renderOperatorValue = React.useCallback(
        (value: CrunchbasePickableFilterPredicates["operator_id"] | "") =>
            value == null || value === "" ? "Select operation" : operatorDisplayNames[value].displayName,
        [],
    );

    if (!isEditing) {
        return (
            <EditableFilterComponentLayoutNonEditingContent
                nonEditingContent={nonEditingContent}
                dlt={dlt}
                edit={edit}
            />
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                flex: 1,
                overflowX: "hidden",
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, overflowX: "hidden" }}>
                <Box sx={{ display: "flex", columnGap: 1, overflowX: "hidden" }}>
                    <FormControl>
                        <SelectWithAutocomplete
                            options={filterableFieldsSorted}
                            getOptionLabel={type =>
                                type in CRUNCHBASE_FIELD_DISPLAY_NAMES
                                    ? CRUNCHBASE_FIELD_DISPLAY_NAMES[type].displayName
                                    : ""
                            }
                            value={filter.filter.field_id ?? ""}
                            displayEmpty
                            // hiddenLabel={true}
                            size="small"
                            renderValue={renderTypeValue}
                            placeholder="Select type"
                            onChange={handleFieldIdChange}
                            MenuProps={MenuProps}
                            sx={{
                                typography: "body2",
                            }}
                        >
                            {filterableFieldsSorted.map(type => (
                                <MenuItem key={type} value={type}>
                                    {CRUNCHBASE_FIELD_DISPLAY_NAMES[type].displayName}
                                </MenuItem>
                            ))}
                        </SelectWithAutocomplete>
                    </FormControl>
                    <FormControl>
                        <Select
                            value={filter.filter.operator_id ?? ""}
                            disabled={filter.filter.field_id == null}
                            displayEmpty
                            renderValue={renderOperatorValue}
                            hiddenLabel
                            size="small"
                            onChange={handleOperatorChange}
                            sx={{
                                typography: "body2",
                            }}
                        >
                            {filter.filter.field_id != null &&
                                INITIAL_SUPPORTED_OPERATORS_BY_FIELD_ID[filter.filter.field_id].map(op => (
                                    <MenuItem key={op} value={op} sx={{ color: operatorDisplayNames[op].color }}>
                                        {operatorDisplayNames[op].displayName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                {actionButtonWhileEditing}
            </Box>
            {valuePicker != null && (
                <Box sx={{ mt: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}>{valuePicker}</Box>
            )}
        </Box>
    );
};
