import * as React from "react";
import { SuggestedQueries } from "./suggestedQueries";

const GENERAL_EXAMPLE_QUERIES: readonly [string, string, string, string] = [
    "🎥 Digital cameras for vlogging",
    "💻 Best gaming laptops",
    "🥗 Healthy breakfast recipes",
    "📚 Gripping sci-fi books",
] as const;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GeneralPersonaExamplesProps {}

export const GeneralPersonaExamples: React.FC<GeneralPersonaExamplesProps> = () => {
    // TODO: Onclick
    const handleClick = React.useCallback((query: string) => {
        console.log("Search query:", query);
    }, []);
    return <SuggestedQueries queries={GENERAL_EXAMPLE_QUERIES} onClick={handleClick} />;
};
