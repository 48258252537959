import { CheckTaskStatusResponse, GetCurrentUserResponse } from "../services/cb-backend-types";
import { partition } from "../utils/arrays";
import { filterObject } from "../utils/objects";
import { CellValueV2, Column, hasTrialFeatures, Row, RowGeneratingFilters } from "./grid";

/**
 * Remove columns from the grid with the given predicate.
 * @param columns The columns to remove from.
 * @param rows The rows to remove the columns from.
 * @param predicate The predicate to determine which columns to remove.
 * @returns A tuple with the new columns and rows.
 */
export function removeColumns(columns: Column[], rows: Row[], predicate: (column: Column) => boolean) {
    const [columnsToRemove, columnsToKeep] = partition(columns, predicate);
    const columnIdsToRemove = new Set(columnsToRemove.map(col => col.id));
    const newRows = rows.map<Row>(row =>
        row.type === "real"
            ? {
                  ...row,
                  data: { name: row.data.name, ...filterObject(row.data, key => !columnIdsToRemove.has(key)) },
              }
            : row,
    );
    return [columnsToKeep, newRows] as const;
}

export function isFinalTaskStatus(status: CheckTaskStatusResponse["status"]) {
    return status === "FAILURE" || status === "SUCCESS" || status === "REVOKED";
}

export function cellValueV2ToString(value: CellValueV2): string {
    switch (value.type) {
        case "string":
            return value.value;
        case "missing":
            return "-";
        case "error":
            return value.error ?? "Error";
        // TODO: Should never hit this, so fix types
        case "apollo_people":
            return value.people.map(person => [person.name, person.email, person.linkedin].join("; ")).join(", ");
        case "boolean":
            return value.value ? "Yes" : "No";
        case "technologies":
            return value.technologies.map(tech => tech.name).join(", ");
        case "job_postings":
            return value.jobPostings.map(jobPosting => jobPosting.title).join(", ");
        case "unloaded":
            return "-";
        case "number":
            return value.value.toLocaleString();
        case "links":
            return value.links.map(link => link.url).join(", ");
    }
}

export function hasMoreRows(rowGeneratingFilters: RowGeneratingFilters, rowCount: number): boolean {
    if (rowGeneratingFilters.pagingInfo == null) {
        return false;
    }
    return (
        rowGeneratingFilters.pagingInfo.totalCount > rowCount &&
        rowGeneratingFilters.pagingInfo.lastFetchedUuid != null &&
        (rowGeneratingFilters.pagingInfo.fetchedCount == null ||
            rowGeneratingFilters.pagingInfo.fetchedCount < rowGeneratingFilters.pagingInfo.totalCount)
    );
}

const MAX_ALLOWED_EXPORT_ROWS_FOR_PAYING = 15000;
const MAX_ALLOWED_EXPORT_ROWS_FOR_NON_ADMINS = 50;

export function getMaxAllowedExportRows(user: GetCurrentUserResponse) {
    if (hasTrialFeatures(user.plan_type)) {
        return 0;
    }
    if (user.plan_type === "default-plan" && !user.is_admin) {
        return MAX_ALLOWED_EXPORT_ROWS_FOR_NON_ADMINS;
    }
    return MAX_ALLOWED_EXPORT_ROWS_FOR_PAYING;
}

export function isErrorValueTypeValue(value: CellValueV2 | undefined, errorValueType: CellValueV2["type"]): boolean {
    if (value?.type === errorValueType) {
        return true;
    }
    // Special-case where the original call worked but the enrichment failed
    if (errorValueType === "error" && value?.type === "apollo_people") {
        return value.people.some(person => person.email.type === "error");
    }
    return false;
}
