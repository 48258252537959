import * as React from "react";
import { Box, CircularProgress, Grow, Menu, MenuItem, Popper, Typography } from "@mui/material";
import { DocumentCopy, Information, Trash } from "iconsax-react";
import { CellValueV2, CellValueV2String, ColumnGeneratedBy, RowValueWithCitations, SelectedCell } from "./grid";
import { AnswerGridCell } from "./AnswerGridCell";
import { SelectedCellMenuContent } from "../selected-cell-components/selectedCellMenuContent";
import { GridCellValue } from "./gridCellValue";
import { EditableGridCellValue } from "./editableGridCellValue";
import { getBackendService } from "../services/cb-backend";
import { enqueueSnackbar } from "notistack";

export interface GridCellWithContextMenuProps {
    isRightSidebarOpen: boolean;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    rowStringKey: string;
    colId: string;
    isExportOngoing: boolean;
    imageUrl?: string | undefined;
    selectedState: "selected" | "editing" | undefined;
    isThisColumnLoading: boolean;
    align?: "left" | "right" | "center" | "justify" | "inherit";
    generatedBy: ColumnGeneratedBy | undefined;
    valueWithCitations: RowValueWithCitations<CellValueV2>;
    loadingStatus: "loading" | undefined;
    isLargeStyle: boolean;
    onDeleteRow: (rowStringKey: string) => void;
    onDeleteColumn: (colId: string) => void;
    onSelectCell: (cell: SelectedCell | undefined) => void;
    onOpenRightSidebar: () => void;
    onChangeCellValue: (colId: string, value: CellValueV2String) => void;
}

export const GridCellWithContextMenu: React.FC<GridCellWithContextMenuProps> = React.memo(
    function _GridCellWithContextMenu({
        rowStringKey,
        colId,
        isRightSidebarOpen,
        isLastColumn,
        isFirstColumn,
        isExportOngoing,
        isThisColumnLoading,
        generatedBy,
        imageUrl,
        selectedState,
        align,
        valueWithCitations,
        loadingStatus,
        isLargeStyle,
        onDeleteRow,
        onDeleteColumn,
        onSelectCell,
        onOpenRightSidebar,
        onChangeCellValue,
    }: GridCellWithContextMenuProps) {
        const cellRef = React.useRef<HTMLTableElement>(null);
        const [rightAnchorPosition, setRightAnchorPosition] = React.useState<null | { top: number; left: number }>(
            null,
        );
        const [rightClickAnchorEl, setRightClickAnchorEl] = React.useState<null | HTMLElement>(null);

        const handleRightClick = React.useCallback((event: React.MouseEvent<HTMLTableCellElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setRightClickAnchorEl(event.currentTarget);
            setRightAnchorPosition({ top: event.clientY, left: event.clientX });
        }, []);

        const handleLeftClick = React.useCallback(
            (event: React.MouseEvent<HTMLTableCellElement>) => {
                if (loadingStatus != null || valueWithCitations.value == null) {
                    return;
                }
                onSelectCell({
                    rowStringKey,
                    colId,
                });
                event.preventDefault();
                event.stopPropagation();
            },
            [colId, loadingStatus, onSelectCell, rowStringKey, valueWithCitations.value],
        );

        const isSelected = selectedState === "selected" || selectedState === "editing";
        const isEditing = selectedState === "editing";

        const handleDoubleClick = React.useCallback(
            (event: React.MouseEvent<HTMLTableCellElement>) => {
                if (loadingStatus != null && valueWithCitations.value == null) {
                    return;
                }
                onSelectCell({
                    rowStringKey,
                    colId,
                    isEditing: true,
                });
                event.preventDefault();
                event.stopPropagation();
            },
            [colId, onSelectCell, rowStringKey, loadingStatus, valueWithCitations.value],
        );

        const handleCloseRightClick = React.useCallback(() => {
            setRightClickAnchorEl(null);
            setRightAnchorPosition(null);
        }, []);

        const handleCloseLeftClick = React.useCallback(() => {
            onSelectCell(undefined);
        }, [onSelectCell]);

        const handleDeleteRow = React.useCallback(() => {
            onDeleteRow(rowStringKey);
            handleCloseRightClick();
        }, [onDeleteRow, rowStringKey, handleCloseRightClick]);

        const handleDeleteColumn = React.useCallback(() => {
            onDeleteColumn(colId);
            handleCloseRightClick();
        }, [onDeleteColumn, colId, handleCloseRightClick]);

        const [isSubmittingFeedback, setIsSubmittingFeedback] = React.useState(false);

        const backendService = React.useMemo(() => getBackendService(), []);
        const handleSubmitAnswerFeedback = React.useCallback(async () => {
            setIsSubmittingFeedback(true);
            try {
                await backendService.createAnswerIssue({
                    citations: valueWithCitations.citations.map(citation => citation.link),
                    query: `${rowStringKey} - ${colId}`,
                    response: JSON.stringify(valueWithCitations.value),
                });
                enqueueSnackbar("Reported answer successfully", {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                });
            } finally {
                setIsSubmittingFeedback(false);
            }
            handleCloseRightClick();
        }, [
            backendService,
            valueWithCitations.citations,
            valueWithCitations.value,
            rowStringKey,
            colId,
            handleCloseRightClick,
        ]);

        const handleClickOpenInRightSidebar = React.useCallback(() => {
            onOpenRightSidebar();
            onSelectCell({
                rowStringKey,
                colId,
            });
            handleCloseRightClick();
        }, [onOpenRightSidebar, onSelectCell, rowStringKey, colId, handleCloseRightClick]);

        const leftClickAnchorEl = React.useMemo(() => {
            if (isSelected && cellRef.current != null) {
                return cellRef.current;
            }
            return null;
        }, [isSelected]);

        const handleChangeCellValue = React.useCallback(
            (value: CellValueV2String) => {
                onChangeCellValue(colId, value);
            },
            [colId, onChangeCellValue],
        );

        const showEditableCellValue =
            generatedBy?.type === "user_input" &&
            (valueWithCitations.value.type === "string" || valueWithCitations.value.type === "missing");

        return (
            <>
                <AnswerGridCell
                    align={align}
                    data-is-selected={isSelected}
                    sx={{
                        position: "relative",
                        ...(isSelected && {
                            boxShadow: "inset 0 0 0 2px #7AB143",
                        }),
                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.03)", // Light grey background on hover
                            cursor: "default",
                        },
                    }}
                    isFirstColumn={isFirstColumn}
                    isLastColumn={isLastColumn}
                    isLargeStyle={isLargeStyle}
                    ref={cellRef}
                    onClick={handleLeftClick}
                    onDoubleClick={showEditableCellValue ? handleDoubleClick : undefined}
                    onContextMenu={handleRightClick}
                    onBlur={handleCloseLeftClick}
                >
                    {showEditableCellValue &&
                    (valueWithCitations.value.type === "string" || valueWithCitations.value.type === "missing") ? (
                        <EditableGridCellValue
                            isFocused={isEditing}
                            value={valueWithCitations.value}
                            onChange={handleChangeCellValue}
                            onBlur={handleCloseLeftClick}
                        />
                    ) : (
                        <GridCellValue
                            imageUrl={imageUrl}
                            value={valueWithCitations.value}
                            loadingStatus={loadingStatus}
                            valueType={getValueType(generatedBy)}
                        />
                    )}
                </AnswerGridCell>
                <Popper
                    open={!isRightSidebarOpen && isSelected && Boolean(leftClickAnchorEl) && !showEditableCellValue}
                    anchorEl={isSelected ? leftClickAnchorEl : undefined}
                    placement="bottom"
                    transition
                    sx={{
                        // TODO: Hack to go above add row thing
                        zIndex: 4,
                    }}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} timeout={"auto"}>
                            <SelectedCellMenuContent
                                valueWithCitations={valueWithCitations}
                                onOpenInRightSidebar={handleClickOpenInRightSidebar}
                                onDeselectCell={handleCloseLeftClick}
                                sx={{
                                    minWidth: leftClickAnchorEl?.clientWidth,
                                }}
                            />
                        </Grow>
                    )}
                </Popper>
                <Menu
                    anchorEl={rightClickAnchorEl}
                    open={Boolean(rightClickAnchorEl)}
                    onClose={handleCloseRightClick}
                    anchorReference="anchorPosition"
                    anchorPosition={rightAnchorPosition ?? undefined}
                    disableAutoFocusItem
                    sx={{ p: 1 }}
                >
                    <MenuItem onClick={handleDeleteRow} sx={{ px: 1.5 }} disabled={isExportOngoing}>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <Trash size="16" />
                            <Typography variant="caption">Delete row</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem
                        onClick={handleDeleteColumn}
                        sx={{ px: 1.5 }}
                        disabled={isExportOngoing || isThisColumnLoading}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <Trash size="16" />
                            <Typography variant="caption">Delete column</Typography>
                        </Box>
                    </MenuItem>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <MenuItem onClick={handleSubmitAnswerFeedback} sx={{ px: 1.5 }}>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            {!isSubmittingFeedback ? (
                                <Information size="16" />
                            ) : (
                                <CircularProgress size="16px" sx={{ color: "primary.contrastText" }} />
                            )}
                            <Typography variant="caption">Report answer</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={handleClickOpenInRightSidebar} sx={{ px: 1.5 }}>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }} data-value="Open in sidebar">
                            <DocumentCopy size="16" />
                            <Typography variant="caption">Open in sidebar</Typography>
                        </Box>
                    </MenuItem>
                </Menu>
            </>
        );
    },
);

function getValueType(generatedBy: ColumnGeneratedBy | undefined) {
    if (generatedBy?.type === "apollo") {
        return "person";
    }
    return undefined;
}
