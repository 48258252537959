import { queryOptions } from "@tanstack/react-query";
import { CrunchbaseAutoCompleteCollectionId, GetCurrentUserResponse } from "../services/cb-backend-types";
import { AbstractBackendService, isLoggedIn } from "../services/cb-backend";
import { convertBackendAvailableGridToFrontend, convertBackendGridToFrontend } from "../grid/gridSerialization";

export const CURRENT_USER_KEY = ["currentUser"] as const;

export const getReactQueryGridKey = (gridId: string | undefined) => ["grid", gridId] as const;

export const gridQueryOptions = (backendService: AbstractBackendService, gridId: string | undefined) =>
    queryOptions({
        queryKey: getReactQueryGridKey(gridId),
        queryFn: () => fetchGrid(backendService, gridId),
        refetchOnWindowFocus: false,
        retry: false, // Disable retries
        meta: {
            toastOnFailure: false,
        },
    });

const fetchGrid = async (service: AbstractBackendService, gridId: string | undefined) => {
    if (gridId == null) {
        return null;
        // return undefined;
    }
    const request = { ids: [gridId] };
    const response = await service.batchGetGrids(request);
    const grid = response[gridId];
    if (grid == null) {
        throw new Error(`Grid with id ${gridId} not found`);
    }
    return convertBackendGridToFrontend(grid);
};

export const getReactQueryTaskKey = (taskId: string | undefined) => ["task", taskId] as const;

export const getReactQueryCbAutocompleteQuery = (
    entityCategoryIds: CrunchbaseAutoCompleteCollectionId[],
    query: string,
) => ["cb-autocomplete", entityCategoryIds, query] as const;

export const getReactQueryApolloTitleSearchQuery = (query: string) => ["apollo-title-search", query] as const;

export const FREE_TRIAL_QUERY_KEY_FIRST_PART = ["free-trial-usage"] as const;
export const freeTrialQueryOptions = (
    currentUser: GetCurrentUserResponse | undefined,
    backendService: AbstractBackendService,
) => {
    return queryOptions({
        queryKey: [...FREE_TRIAL_QUERY_KEY_FIRST_PART, currentUser?.plan_type ?? "unknown"],
        queryFn: async () => backendService.getFreeTrialUsage(),
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
        enabled: isLoggedIn() && currentUser?.plan_type === "free-trial",
        refetchInterval: () => (isLoggedIn() ? 1000 * 60 * 5 : false), // Refetch every 5 minutes if logged in, otherwise don't refetch
        meta: {
            toastOnFailure: false,
        },
    });
};

export const AVAILABLE_GRIDS_KEY = ["availableGrids"] as const;

export const availableGridsQueryOptions = (backendService: AbstractBackendService) =>
    queryOptions({
        queryKey: AVAILABLE_GRIDS_KEY,
        queryFn: async () => backendService.getAvailableGrids(),
        select: data => data.map(convertBackendAvailableGridToFrontend),
        staleTime: 1000 * 60 * 2, // 2 minutes
        enabled: isLoggedIn(),
        meta: {
            toastOnFailure: false,
        },
    });
