import { useMutation, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash-es/isEqual";
import {
    Column,
    Row,
    RowGeneratingFilters,
    Grid,
    VisibilityFiltersByColumn,
    getStringKeyFromRow,
    isRealRow,
    isPlaceholderRow,
    isKeyColumn,
} from "./grid";
import { AbstractBackendService, VersionConflictError } from "../services/cb-backend";
import {
    convertBackendGridToFrontend,
    convertColumnToGridColumn,
    convertFrontendGridDataToGridData,
    convertFrontendRowGeneratingFilters,
    convertFrontendVisibilityFiltersByColumn,
    convertRowValueWithCitationsToBeGridRowValueWithCitations,
} from "./gridSerialization";
import {
    BeGrid,
    BeGridRowValueWithCitations,
    GridPatch,
    GridPatchRequest,
    GridUpdateRequest,
} from "../services/cb-backend-types";
import { removeColumns } from "./gridUtils";
import { getReactQueryGridKey } from "../react-query/queryKeys";
import { useCallback } from "react";
import { isNonNullable } from "../utils/isNonNullable";
import { updateAvailableGridsOnUpdate } from "../context/gridContextAndProvider";

// Asynchronous hook
export const useSaveCurrentGrid = (service: AbstractBackendService, logContext: string) => {
    const queryClient = useQueryClient();

    const saveCurrentGrid = useCallback(
        async ({
            name,
            columns,
            rows,
            rowGeneratingFilters,
            visibilityFiltersByColumn,
            currentGrid,
            gridId,
        }: {
            name: string;
            columns: Column[];
            rows: Row[];
            rowGeneratingFilters: RowGeneratingFilters;
            visibilityFiltersByColumn: VisibilityFiltersByColumn;
            currentGrid: Grid;
            gridId: string;
        }): Promise<BeGrid | undefined> => {
            const shouldRemoveColumn = (col: Column) => !isCompleteColumn(col);
            const [completeColumns, rowsWithoutIncompleteColumns] = removeColumns(columns, rows, shouldRemoveColumn);

            // Early return if no changes
            if (
                !hasChanges(
                    currentGrid,
                    completeColumns,
                    rowsWithoutIncompleteColumns,
                    name,
                    rowGeneratingFilters,
                    visibilityFiltersByColumn,
                )
            ) {
                console.debug(`${logContext}: No changes to autosave`);
                return undefined;
            }

            const hasComplexChanges = detectComplexChanges(
                currentGrid,
                completeColumns,
                rowsWithoutIncompleteColumns,
                rowGeneratingFilters,
            );

            if (hasComplexChanges) {
                return handleFullUpdateAsync(
                    service,
                    gridId,
                    name,
                    completeColumns,
                    rowsWithoutIncompleteColumns,
                    visibilityFiltersByColumn,
                    rowGeneratingFilters,
                    currentGrid,
                    logContext,
                );
            } else {
                return handlePatchUpdateAsync(
                    service,
                    gridId,
                    name,
                    completeColumns,
                    rowsWithoutIncompleteColumns,
                    visibilityFiltersByColumn,
                    rowGeneratingFilters,
                    currentGrid,
                    logContext,
                );
            }
        },
        [service, logContext],
    );

    const mutation = useMutation({
        mutationFn: saveCurrentGrid,
        onSuccess: (data, { gridId }) => {
            if (data != null) {
                const feGrid = convertBackendGridToFrontend(data);
                queryClient.setQueryData<Grid>(getReactQueryGridKey(gridId), feGrid);
                updateAvailableGridsOnUpdate(queryClient, data);
            }
        },
        onError: (error, vars) => {
            console.error(error);
            if (error instanceof VersionConflictError) {
                console.error(
                    "Failed to update because a simultaneous change was made to the grid. Refresh the page and try again. ",
                    vars,
                );
            }
        },
        throwOnError: false,
    });

    return mutation.mutateAsync;
};

// Synchronous hook
export const getSaveCurrentGridSync = (service: AbstractBackendService, logContext: string) => {
    return ({
        name,
        columns,
        rows,
        rowGeneratingFilters,
        visibilityFiltersByColumn,
        currentGrid,
        gridId,
    }: {
        name: string;
        columns: Column[];
        rows: Row[];
        rowGeneratingFilters: RowGeneratingFilters;
        visibilityFiltersByColumn: VisibilityFiltersByColumn;
        currentGrid: Grid;
        gridId: string;
    }): boolean => {
        const shouldRemoveColumn = (col: Column) => !isCompleteColumn(col);
        const [completeColumns, rowsWithoutIncompleteColumns] = removeColumns(columns, rows, shouldRemoveColumn);

        // Early return if no changes
        if (
            !hasChanges(
                currentGrid,
                completeColumns,
                rowsWithoutIncompleteColumns,
                name,
                rowGeneratingFilters,
                visibilityFiltersByColumn,
            )
        ) {
            console.log(`${logContext}: No changes to autosave`);
            return false;
        }

        const hasComplexChanges = detectComplexChanges(
            currentGrid,
            completeColumns,
            rowsWithoutIncompleteColumns,
            rowGeneratingFilters,
        );

        if (hasComplexChanges) {
            return handleFullUpdateSync(
                service,
                gridId,
                name,
                completeColumns,
                rowsWithoutIncompleteColumns,
                visibilityFiltersByColumn,
                rowGeneratingFilters,
                currentGrid,
                logContext,
            );
        } else {
            return handlePatchUpdateSync(
                service,
                gridId,
                name,
                completeColumns,
                rowsWithoutIncompleteColumns,
                visibilityFiltersByColumn,
                rowGeneratingFilters,
                currentGrid,
                logContext,
            );
        }
    };
};

function hasChanges(
    currentGrid: Grid,
    newColumns: Column[],
    newRows: Row[],
    newName: string,
    newRowGeneratingFilters: RowGeneratingFilters,
    newVisibilityFiltersByColumn: VisibilityFiltersByColumn,
): boolean {
    return (
        !isEqual(currentGrid.columns, newColumns) ||
        !isEqual(currentGrid.rows, newRows) ||
        currentGrid.name !== newName ||
        !isEqual(currentGrid.rowGeneratingFilters, newRowGeneratingFilters) ||
        !isEqual(currentGrid.visibilityFiltersByColumn, newVisibilityFiltersByColumn)
    );
}

// Async update functions
async function handleFullUpdateAsync(
    service: AbstractBackendService,
    gridId: string,
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
    logContext: string,
): Promise<BeGrid | undefined> {
    const updateRequest = getUpdateRequestWithChanges(
        name,
        columns,
        rows,
        visibilityFiltersByColumn,
        rowGeneratingFilters,
        currentGrid,
    );

    if (updateRequest == null) {
        return undefined;
    }

    console.debug(`${logContext}: Performing full update asynchronously`);

    try {
        return service.updateGrid(gridId, updateRequest);
    } catch (e) {
        console.error(`${logContext}: Failed to perform full update`, e);
        return undefined;
    }
}

async function handlePatchUpdateAsync(
    service: AbstractBackendService,
    gridId: string,
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
    logContext: string,
): Promise<BeGrid | undefined> {
    const patchRequest = getPatchRequestWithChanges(
        name,
        columns,
        rows,
        visibilityFiltersByColumn,
        rowGeneratingFilters,
        currentGrid,
    );

    if (patchRequest == null) {
        return undefined;
    }

    console.debug(`${logContext}: Performing patch update asynchronously`);

    try {
        return service.patchGrid(gridId, patchRequest);
    } catch (e) {
        console.debug(`${logContext}: Failed to perform patch update`, e);
        return undefined;
    }
}

// Sync update functions
function handleFullUpdateSync(
    service: AbstractBackendService,
    gridId: string,
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
    logContext: string,
): boolean {
    const updateRequest = getUpdateRequestWithChanges(
        name,
        columns,
        rows,
        visibilityFiltersByColumn,
        rowGeneratingFilters,
        currentGrid,
    );

    if (updateRequest == null) {
        return false;
    }

    const updateRequestSize = new TextEncoder().encode(JSON.stringify(updateRequest)).length;
    console.debug(`${logContext}: Updating synchronously. Update request size: ${updateRequestSize} bytes`);

    if (updateRequestSize >= 64 * 1024) {
        void service.updateGrid(gridId, updateRequest);
        return true;
    }

    try {
        service.updateGridOnUnload(gridId, updateRequest);
    } catch (e) {
        console.error(`${logContext}: Failed to perform full update`, e);
    }
    return false;
}

function handlePatchUpdateSync(
    service: AbstractBackendService,
    gridId: string,
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
    logContext: string,
): boolean {
    const patchRequest = getPatchRequestWithChanges(
        name,
        columns,
        rows,
        visibilityFiltersByColumn,
        rowGeneratingFilters,
        currentGrid,
    );

    if (patchRequest == null) {
        return false;
    }

    // Calculate and log the size of the patch request in bytes
    const patchRequestSize = new TextEncoder().encode(JSON.stringify(patchRequest)).length;
    console.log(`${logContext}: Patching synchronously. Patch request size: ${patchRequestSize} bytes`);

    if (patchRequestSize >= 64 * 1024) {
        void service.patchGrid(gridId, patchRequest);
        return true;
    }

    try {
        service.patchGridOnUnload(gridId, patchRequest);
    } catch (e) {
        console.log(`${logContext}: Failed to perform patch update`, e);
    }
    return false;
}

function isCompleteColumn(column: Column): boolean {
    return (
        column.id === "name" ||
        (column.generatedBy != null && (column.generatedBy.type !== "web_search" || column.generatedBy.query !== ""))
    );
}

function detectComplexChanges(
    currentGrid: Grid,
    newColumns: Column[],
    newRows: Row[],
    newRowGeneratingFilters: RowGeneratingFilters,
): boolean {
    // Create a set of existing row keys
    const existingRowsByRowKeys = new Map(currentGrid.rows.map(row => [getStringKeyFromRow(row), row]));

    // Check if there are any new row keys that weren't in the original grid
    // This will indicate a row added, or that a row has been edited via the key
    const hasNewRows = newRows.some(newRow => !existingRowsByRowKeys.has(getStringKeyFromRow(newRow)));

    const hasAnyPlaceholderRows = newRows.some(row => isPlaceholderRow(row));

    const haveFiltersChanged = !isEqual(currentGrid.rowGeneratingFilters, newRowGeneratingFilters);

    const haveExternalIdentifiersChanged = newRows.filter(isRealRow).some(row => {
        const existingRow = existingRowsByRowKeys.get(getStringKeyFromRow(row));
        return (
            existingRow != null &&
            isRealRow(existingRow) &&
            !isEqual(existingRow.externalIdentifiers, row.externalIdentifiers)
        );
    });

    return hasNewRows || hasAnyPlaceholderRows || haveFiltersChanged || haveExternalIdentifiersChanged;
}

function getPatchRequestWithChanges(
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
): GridPatchRequest | undefined {
    const patches: GridPatch[] = [];

    // Handle row deletions
    const { patches: rowPatches, remainingRows: nonDeletedOldRows } = handleRowDeletions(currentGrid.rows, rows);
    patches.push(...rowPatches);

    // Handle column changes
    patches.push(
        ...handleColumnChanges(
            currentGrid.columns.filter(col => !isKeyColumn(col)),
            columns.filter(col => !isKeyColumn(col)),
            nonDeletedOldRows,
            rows,
        ),
    );

    const changes: GridPatchRequest = {
        version: currentGrid.version,
    };

    if (patches.length > 0) {
        changes.patches = patches;
    }

    if (name !== currentGrid.name) {
        changes.name = name;
    }

    if (!isEqual(currentGrid.rowGeneratingFilters, rowGeneratingFilters)) {
        changes.row_generating_filters = convertFrontendRowGeneratingFilters(rowGeneratingFilters);
    }

    if (!isEqual(currentGrid.visibilityFiltersByColumn, visibilityFiltersByColumn)) {
        changes.visibility_filters = convertFrontendVisibilityFiltersByColumn(visibilityFiltersByColumn);
    }

    return Object.values(changes).filter(isNonNullable).length > 1 ? changes : undefined;
}

const EMPTY_BE_VALUE: BeGridRowValueWithCitations = {
    value: "-",
    value_v2: {
        type: "missing",
    },
    citationUrls: [],
    citations_v2: [],
    source_details: undefined,
};

function handleColumnChanges(oldColumns: Column[], newColumns: Column[], oldRows: Row[], newRows: Row[]): GridPatch[] {
    const oldColumnMap = new Map(oldColumns.filter(col => !isKeyColumn(col)).map(col => [col.id, col]));
    const newColumnMap = new Map(newColumns.filter(col => !isKeyColumn(col)).map(col => [col.id, col]));
    const patches: GridPatch[] = [];

    const deletedColIds = new Set(
        Array.from(oldColumnMap.values())
            .filter(col => !newColumnMap.has(col.id))
            .map(col => col.id),
    );
    patches.push(
        ...Array.from(deletedColIds).map<GridPatch>(id => ({
            type: "delete_column",
            id,
        })),
    );

    // Handle added and edited columns
    Array.from(newColumnMap.values()).forEach(newCol => {
        if (deletedColIds.has(newCol.id)) {
            return;
        }
        const oldCol = oldColumnMap.get(newCol.id);
        if (!oldCol) {
            patches.push({
                type: "add_column",
                column: convertColumnToGridColumn(newCol),
                row_values: newRows.map(row => getCellValue(row, newCol.id)),
            });
        } else {
            const oldRowValues = oldRows.map(row => getCellValue(row, newCol.id));
            const newRowValues = newRows.map(row => getCellValue(row, newCol.id));
            if (!isEqual(oldRowValues, newRowValues) || !isEqual(oldCol, newCol)) {
                patches.push({
                    type: "edit_column",
                    id: newCol.id,
                    column: convertColumnToGridColumn(newCol),
                    row_values: newRowValues,
                });
            }
        }
    });

    return patches;
}

function getCellValue(row: Row, colId: string): BeGridRowValueWithCitations {
    return isRealRow(row) && row.data[colId] != null
        ? convertRowValueWithCitationsToBeGridRowValueWithCitations(row.data[colId])
        : EMPTY_BE_VALUE;
}

function handleRowDeletions(oldRows: Row[], newRows: Row[]): { patches: GridPatch[]; remainingRows: Row[] } {
    const newRowKeys = new Set(newRows.map(row => getStringKeyFromRow(row)));
    const patches: GridPatch[] = [];
    const remainingRows: Row[] = [];

    oldRows.forEach((oldRow, index) => {
        if (!newRowKeys.has(getStringKeyFromRow(oldRow))) {
            patches.push({
                type: "delete_row",
                index,
            });
        } else {
            remainingRows.push(oldRow);
        }
    });

    return { patches, remainingRows };
}

function getUpdateRequestWithChanges(
    name: string,
    columns: Column[],
    rows: Row[],
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    rowGeneratingFilters: RowGeneratingFilters,
    currentGrid: Grid,
): GridUpdateRequest | undefined {
    const shouldRemoveColumn = (col: Column) => !isCompleteColumn(col);
    const [completeColumns, rowsWithoutIncompleteColumns] = removeColumns(columns, rows, shouldRemoveColumn);

    const newData = convertFrontendGridDataToGridData(
        completeColumns,
        rowsWithoutIncompleteColumns,
        currentGrid.version + 1,
    );
    const currentData = convertFrontendGridDataToGridData(currentGrid.columns, currentGrid.rows, currentGrid.version);

    const changes: GridUpdateRequest = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customIsEqual = (a: any, b: any) => {
        if (a == null && b == null) {
            return true;
        }
        return isEqual(a, b);
    };

    if (!customIsEqual(newData, currentData)) {
        changes.data = newData;
    }

    if (name !== currentGrid.name) {
        changes.name = name;
    }

    if (!customIsEqual(currentGrid.rowGeneratingFilters, rowGeneratingFilters)) {
        changes.row_generating_filters = convertFrontendRowGeneratingFilters(rowGeneratingFilters);
    }

    if (!customIsEqual(currentGrid.visibilityFiltersByColumn, visibilityFiltersByColumn)) {
        changes.visibility_filters = convertFrontendVisibilityFiltersByColumn(visibilityFiltersByColumn);
    }

    return Object.keys(changes).length > 0 ? changes : undefined;
}
