import * as React from "react";
import { Input } from "@mui/material";
import { AnswerGridCell } from "./AnswerGridCell";
import { GridCellValue } from "./gridCellValue";
import { CellValueV2String, LoadingStatus } from "./grid";

export interface EditabledKeyColumnCellProps {
    align: "left" | "right" | "center" | "justify" | "inherit" | undefined;
    bannedValues: Set<string>;
    colId: "name";
    loadingStatus: LoadingStatus | undefined;
    imageUrl?: string;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    value: string;
    onBlur?: () => void;
    onFinishEditingCellValue: (colId: "name", prevValue: string, value: string) => Promise<void>;
    initialEditMode?: boolean;
    mode?: "existing-row" | "new-row";
}

export const EditableKeyColumnCell: React.FC<EditabledKeyColumnCellProps> = ({
    align,
    bannedValues,
    colId,
    loadingStatus,
    imageUrl,
    isFirstColumn,
    isLastColumn,
    value,
    onBlur,
    onFinishEditingCellValue,
    mode = "existing-row",
}) => {
    const [editMode, setEditMode] = React.useState(mode === "new-row");
    const [newValue, setNewValue] = React.useState<string>(value ?? "");

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value), []);
    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (bannedValues.has(newValue) && mode === "existing-row") {
                setNewValue(value ?? "");
                setEditMode(false);
                return;
            }
            void onFinishEditingCellValue(colId, value ?? "", newValue);
            setEditMode(false);
        }
    };

    const handleClick = React.useCallback(() => setEditMode(true), []);
    const handleBlur = React.useCallback(() => {
        setEditMode(false);
        onBlur?.();
    }, [onBlur]);

    const valueAsCellValueV2 = React.useMemo((): CellValueV2String => ({ type: "string", value }), [value]);

    return (
        <AnswerGridCell
            align={align}
            sx={{ cursor: editMode ? "text" : "pointer", bgcolor: editMode ? "white" : undefined }}
            onClick={loadingStatus == null ? handleClick : undefined}
            isFirstColumn={isFirstColumn}
            isLastColumn={isLastColumn}
            isLargeStyle={false}
        >
            {editMode && loadingStatus == null ? (
                <Input
                    autoFocus
                    disableUnderline
                    type="text"
                    sx={{ width: 1, maxWidth: 200, typography: "body2" }}
                    placeholder="Enter value"
                    value={newValue}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                />
            ) : (
                <GridCellValue value={valueAsCellValueV2} imageUrl={imageUrl} loadingStatus={loadingStatus} />
            )}
        </AnswerGridCell>
    );
};
