import * as React from "react";
import {
    BatchQueryCollectionRequest,
    CellLevelBatchQueryRequest,
    CellLevelRowQuestions,
    ColumnQuery,
    ColumnQueryAnswerSourceWithTool,
} from "../services/cb-backend-types";
import { AbstractBackendService } from "../services/cb-backend";
import {
    Column,
    RealRow,
    Row,
    isRealRow,
    RowKey,
    ColumnGeneratedBy,
    ColumnGeneratedByUserInput,
    getStringKeyFromValueAndExternalIdentifiers,
    LoadingStatus,
    CellValueV2,
} from "./grid";
import {
    convertBackendFormatOptionsToFrontendFormatOptions,
    convertBeAgentToolConfigToFrontendAgentToolConfig,
    convertBeApolloPeopleEnrichmentSettingsToFrontend,
    convertBeCellAnswerDetailsToFrontend,
    convertBeCellValueV2ToFrontendCellValueV2,
    convertFrontendAgentToolConfigToBeAgentToolConfig,
    convertFrontendApolloPeopleEnrichmentSettingsToBackend,
    convertFrontendFormatOptionsToBackendFormatOptions,
    convertFrontendGridDataToGridData,
    convertFrontendRowKeyToBeRowKey,
    convertLegacyApolloConfigToNewFormat,
} from "./gridSerialization";
import { isNonNullable } from "../utils/isNonNullable";
import { useSnackbar } from "notistack";
import { isErrorValueTypeValue } from "./gridUtils";

function getColQueriesFromColumns(columns: CompleteColumnForQuery[]): ColumnQuery[] {
    return columns.map(getColQueryFromColumn).filter(isNonNullable);
}

function getColQueryFromColumn(column: CompleteColumnForQuery): ColumnQuery {
    switch (column.generatedBy.type) {
        case "web_search":
            return {
                answer_source: {
                    source_type: "AGENT",
                    context_cols: column.generatedBy.contextColumnIds,
                    raw_query: column.generatedBy.query,
                    tool_config: convertFrontendAgentToolConfigToBeAgentToolConfig(column.generatedBy.toolConfig),
                    format_options: convertFrontendFormatOptionsToBackendFormatOptions(
                        column.generatedBy.formatOptions,
                    ),
                },
                col_id: column.id,
            };
        case "crunchbase":
            return {
                answer_source: {
                    source_type: "FOCUS_CB",
                    field_id: column.generatedBy.fieldId,
                },
                col_id: column.id,
            };
        case "apollo":
            return {
                answer_source: {
                    source_type: "FOCUS_AP",
                    source_config: { person_titles: column.generatedBy.personFilters.primary.person_titles },
                    person_filters: column.generatedBy.personFilters,
                    enrichment_settings: convertFrontendApolloPeopleEnrichmentSettingsToBackend(
                        column.generatedBy.enrichmentSettings,
                    ),
                },
                col_id: column.id,
            };
        case "apollo_organization":
            return {
                answer_source: {
                    source_type: "FOCUS_AP_ORG",
                    source_config: {
                        field: column.generatedBy.field,
                    },
                },
                col_id: column.id,
            };
    }
}

export interface CompleteColumnForQuery extends Column {
    generatedBy: NonNullable<Exclude<Column["generatedBy"], ColumnGeneratedByUserInput>>;
}

export function asCompleteColumnForQuery(column: Column): CompleteColumnForQuery | undefined {
    if (column.generatedBy === undefined || column.generatedBy.type === "user_input") {
        return undefined;
    }
    return {
        ...column,
        generatedBy: column.generatedBy,
    };
}

function getTransitiveDependencyCols(column: Column, allColumns: Column[]): Set<string> {
    const dependencies = new Set<string>();
    if (column.generatedBy?.type === "web_search") {
        for (const id of column.generatedBy.contextColumnIds) {
            dependencies.add(id);
            const dependencyColumn = allColumns.find(col => col.id === id);
            if (dependencyColumn) {
                for (const depId of getTransitiveDependencyCols(dependencyColumn, allColumns)) {
                    dependencies.add(depId);
                }
            }
        }
    }
    return dependencies;
}

export function getBatchQueryRequest(
    columnsWithRows: Array<{ rows: Row[]; column: CompleteColumnForQuery }>,
    allColumns: Column[],
    collectionTypeName: string,
): CellLevelBatchQueryRequest {
    const columnMap = new Map<string, CompleteColumnForQuery>();
    const rowMap = new Map<string, Set<string>>();

    for (const { rows, column } of columnsWithRows) {
        columnMap.set(column.id, column);

        for (const row of rows.filter(isRealRow)) {
            const rowKey = getStringKeyFromValueAndExternalIdentifiers(
                row.data.name.value.value,
                row.externalIdentifiers,
            );

            if (!rowMap.has(rowKey)) {
                rowMap.set(rowKey, new Set());
            }

            const colsForRow = rowMap.get(rowKey) ?? new Set();
            colsForRow.add(column.id);
            const dependencies = getTransitiveDependencyCols(column, allColumns);
            for (const depColId of dependencies) {
                if (row.data[depColId]?.value.type === "unloaded") {
                    colsForRow.add(depColId);
                    const depColumn = allColumns.find(col => col.id === depColId);
                    if (depColumn) {
                        const completeDepColumn = asCompleteColumnForQuery(depColumn);
                        if (completeDepColumn) {
                            columnMap.set(depColumn.id, completeDepColumn);
                        }
                    }
                }
            }
        }
    }

    const feRowKeys = columnsWithRows.flatMap(({ rows }) =>
        rows.filter(isRealRow).map<RowKey>(row => ({
            title: "Name",
            value: row.data.name.value.value,
            externalIdentifiers: row.externalIdentifiers,
        })),
    );

    const rowKeyByStringKey = new Map(
        feRowKeys.map(rowKey => [
            getStringKeyFromValueAndExternalIdentifiers(rowKey.value, rowKey.externalIdentifiers),
            convertFrontendRowKeyToBeRowKey(rowKey),
        ]),
    );

    const rowQuestions: CellLevelRowQuestions[] = Array.from(rowMap.entries())
        .map<CellLevelRowQuestions | undefined>(([rowStringKey, colIds]) => {
            const rowKey = rowKeyByStringKey.get(rowStringKey);
            if (rowKey == null) {
                console.error(`SHOULD NEVER HAPPEN. Row key not found for row string key: ${rowStringKey}`);
                return undefined;
            }
            return {
                row_key: rowKey,
                col_ids: Array.from(colIds),
            };
        })
        .filter(isNonNullable);

    const allQueryColumns = Array.from(columnMap.values());
    const colQueries = getColQueriesFromColumns(allQueryColumns);

    return {
        mode: "better-homemade",
        cell_level_questions: {
            title: collectionTypeName,
            col_queries: colQueries,
            row_questions: rowQuestions,
        },
        old_answers_grid: convertFrontendGridDataToGridData(allColumns, columnsWithRows[0].rows, -1),
    };
}

export function useUpdateColumnsWithQuery(service: AbstractBackendService) {
    return React.useCallback(
        async (
            queryColumnsAndRows: Array<{ column: CompleteColumnForQuery; rows: Row[] }>,
            allColumns: Column[],
            setColumns: React.Dispatch<React.SetStateAction<Column[]>>,
            setRows: React.Dispatch<React.SetStateAction<Row[]>>,
            setCellGenerationStatusesByColByRow: React.Dispatch<
                React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
            >,
            collectionTypeName: string,
        ) => {
            if (queryColumnsAndRows.flatMap(({ rows }) => rows).filter(isRealRow).length === 0) {
                return;
            }

            const request = getBatchQueryRequest(queryColumnsAndRows, allColumns, collectionTypeName);

            try {
                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, "loading");

                const response = await service.cellLevelBatchQuery(request);

                setColumns(prevColumns =>
                    prevColumns.map(c =>
                        response.used_columns_configs[c.id] != null && c.generatedBy != null
                            ? withNewConfig(c, response.used_columns_configs[c.id])
                            : c,
                    ),
                );

                const cellResponsesByRowKey = new Map(
                    response.rows.map(row => [
                        getStringKeyFromValueAndExternalIdentifiers(
                            row.row_key.value,
                            row.row_key.external_identifiers,
                        ),
                        row.cell_response_by_col_id,
                    ]),
                );

                setRows(prevRows =>
                    prevRows.map((row: Row) => {
                        if (!isRealRow(row)) {
                            return row;
                        }

                        const rowKey = getStringKeyFromValueAndExternalIdentifiers(
                            row.data.name.value.value,
                            row.externalIdentifiers,
                        );

                        const cellResponses = cellResponsesByRowKey.get(rowKey);
                        if (cellResponses == null) {
                            return row;
                        }

                        const updatedRow = { ...row };
                        for (const [columnId, queryResponse] of Object.entries(cellResponses)) {
                            const queryResponseInfo = queryResponse.cell_response;

                            updatedRow.data[columnId] = {
                                value: convertBeCellValueV2ToFrontendCellValueV2(queryResponseInfo.query_response),
                                citations: queryResponseInfo.citations,
                                sourceDetails:
                                    queryResponse.details != null
                                        ? convertBeCellAnswerDetailsToFrontend(queryResponse.details)
                                        : undefined,
                            };
                        }
                        return updatedRow;
                    }),
                );

                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, undefined);
            } catch (error) {
                const queriedColIdsByRowStringKey = new Map(
                    request.cell_level_questions.row_questions.map(rowQuestion => [
                        getStringKeyFromValueAndExternalIdentifiers(
                            rowQuestion.row_key.value,
                            rowQuestion.row_key.external_identifiers,
                        ),
                        rowQuestion.col_ids,
                    ]),
                );

                setRows(prevRows =>
                    prevRows.map((row: Row) => {
                        if (!isRealRow(row)) {
                            return row;
                        }
                        const rowKey = getStringKeyFromValueAndExternalIdentifiers(
                            row.data.name.value.value,
                            row.externalIdentifiers,
                        );
                        const queriedColIds = queriedColIdsByRowStringKey.get(rowKey);
                        if (queriedColIds == null) {
                            return row;
                        }
                        const updatedRow = { ...row };
                        for (const colId of queriedColIds) {
                            updatedRow.data[colId] = {
                                value: { type: "error", error: error instanceof Error ? error.message : String(error) },
                                citations: [],
                                sourceDetails: undefined,
                            };
                        }
                        return updatedRow;
                    }),
                );

                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, undefined);
                throw error;
            }
        },
        [service],
    );
}

function withNewConfig(column: Column, answerSource: ColumnQueryAnswerSourceWithTool): Column {
    return {
        ...column,
        generatedBy: toGeneratedBy(answerSource),
    };
}

function toGeneratedBy(answerSource: ColumnQueryAnswerSourceWithTool): ColumnGeneratedBy {
    switch (answerSource.source_type) {
        case "AGENT":
            return {
                type: "web_search",
                contextColumnIds: answerSource.context_cols,
                query: answerSource.raw_query,
                toolConfig: convertBeAgentToolConfigToFrontendAgentToolConfig(answerSource.tool_config),
                formatOptions: convertBackendFormatOptionsToFrontendFormatOptions(answerSource.format_options),
            };
        case "FOCUS_CB":
            return {
                type: "crunchbase",
                fieldId: answerSource.field_id,
            };
        case "FOCUS_AP":
            return {
                type: "apollo",
                personFilters:
                    answerSource.person_filters ?? convertLegacyApolloConfigToNewFormat(answerSource.source_config),
                enrichmentSettings:
                    answerSource.enrichment_settings != null
                        ? convertBeApolloPeopleEnrichmentSettingsToFrontend(answerSource.enrichment_settings)
                        : { enrichSinglePerson: true },
            };
        case "FOCUS_AP_ORG":
            return {
                type: "apollo_organization",
                field: answerSource.source_config.field,
            };
    }
}

export function useUpdateRowsWithQuery(service: AbstractBackendService) {
    return React.useCallback(
        async (
            rowKeys: RowKey[],
            rowKeyColumnId: "name",
            columns: CompleteColumnForQuery[],
            rows: Row[],
            // TODO: DO we want to update the columns here with tool choices and stuff? It's not really necessary
            setRows: React.Dispatch<React.SetStateAction<Row[]>>,
            setRowLoadingStatuses: React.Dispatch<React.SetStateAction<Record<string, LoadingStatus | undefined>>>,
            collectionTypeName: string,
        ) => {
            const nonEmptyColQueryColumns = columns.filter(
                column => column.generatedBy.type !== "web_search" || column.generatedBy.query !== "",
            );
            if (nonEmptyColQueryColumns.length === 0) {
                return;
            }
            try {
                const colQueries = getColQueriesFromColumns(nonEmptyColQueryColumns);

                const request: BatchQueryCollectionRequest = {
                    mode: "better-homemade",
                    collection: {
                        collection_desc: collectionTypeName,
                        row_keys: rowKeys.map(convertFrontendRowKeyToBeRowKey),
                        col_queries: colQueries,
                    },
                    // TODO: This assumes that `columns` is complete
                    // Also, passing it here is unnecessary since we are generating a new row
                    // and so it doesn't need any other data (since you can't depend on other row answers)
                    // for now. Does version matter?
                    old_answers_grid: convertFrontendGridDataToGridData(columns, rows, -1),
                };

                setRowLoadingStatuses(prevStatuses =>
                    rowKeys.reduce(
                        (updatedStatuses, rowKey) => {
                            updatedStatuses[rowKey.value] = "loading";
                            return updatedStatuses;
                        },
                        { ...prevStatuses },
                    ),
                );
                const response = await service.batchQuery(request);

                const isEditedRow = (row: RealRow) =>
                    rowKeys.find(rowKey => row.data[rowKeyColumnId].value.value === rowKey.value);
                const getRowResponseIndex = (row: RealRow) =>
                    rowKeys.findIndex(rowKey => row.data[rowKeyColumnId].value.value === rowKey.value);

                setRows(prevRows =>
                    prevRows.map((row: Row) =>
                        isRealRow(row) && isEditedRow(row)
                            ? nonEmptyColQueryColumns.reduce(
                                  (updatedRow, column) => {
                                      const queryResponse =
                                          response.rows[getRowResponseIndex(row)].cell_response_by_col_id[column.id];
                                      const queryResponseInfo = queryResponse.cell_response;
                                      return {
                                          ...updatedRow,
                                          data: {
                                              ...updatedRow.data,
                                              [column.id]: {
                                                  value: convertBeCellValueV2ToFrontendCellValueV2(
                                                      queryResponseInfo.query_response,
                                                  ),
                                                  citations: queryResponseInfo.citations,
                                                  sourceDetails:
                                                      queryResponse.details != null
                                                          ? convertBeCellAnswerDetailsToFrontend(queryResponse.details)
                                                          : undefined,
                                              },
                                          },
                                      };
                                  },
                                  { ...row },
                              )
                            : row,
                    ),
                );

                setRowLoadingStatuses(prevStatuses =>
                    rowKeys.reduce(
                        (updatedStatuses, rowKey) => {
                            updatedStatuses[rowKey.value] = undefined;
                            return updatedStatuses;
                        },
                        { ...prevStatuses },
                    ),
                );
            } catch (error) {
                setRowLoadingStatuses(prevStatuses =>
                    rowKeys.reduce(
                        (updatedStatuses, rowKey) => {
                            updatedStatuses[rowKey.value] = undefined;
                            return updatedStatuses;
                        },
                        { ...prevStatuses },
                    ),
                );
                throw error;
            }
        },
        [service],
    );
}

// TODO: Use keys instead of row indexes
export function useRetryFailedColumnValues(service: AbstractBackendService) {
    const { enqueueSnackbar } = useSnackbar();
    return React.useCallback(
        async (
            queryColumn: CompleteColumnForQuery,
            allColumns: Column[],
            rows: Row[],
            // TODO: NEeeded for tools?
            setColumns: React.Dispatch<React.SetStateAction<Column[]>>,
            setRows: React.Dispatch<React.SetStateAction<Row[]>>,
            setCellGenerationStatusesByColByRow: React.Dispatch<
                React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
            >,
            collectionTypeName: string,
            errorValueType: CellValueV2["type"] = "error",
        ) => {
            const erroredRows = rows
                .filter(isRealRow)
                .filter(row => isErrorValueTypeValue(row.data[queryColumn.id]?.value, errorValueType));

            if (erroredRows.length === 0) {
                return;
            }

            const request = getBatchQueryRequest(
                [{ rows: erroredRows, column: queryColumn }],
                allColumns,
                collectionTypeName,
            );

            try {
                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, "loading");

                const response = await service.cellLevelBatchQuery(request);

                setRows(prevRows =>
                    prevRows.map((row: Row) => {
                        if (
                            !isRealRow(row) ||
                            !isErrorValueTypeValue(row.data[queryColumn.id]?.value, errorValueType)
                        ) {
                            return row;
                        }
                        const rowKey = getStringKeyFromValueAndExternalIdentifiers(
                            row.data.name.value.value,
                            row.externalIdentifiers,
                        );
                        const queryResponse = response.rows.find(
                            r =>
                                getStringKeyFromValueAndExternalIdentifiers(
                                    r.row_key.value,
                                    r.row_key.external_identifiers,
                                ) === rowKey,
                        )?.cell_response_by_col_id[queryColumn.id];

                        if (queryResponse == null) {
                            return row;
                        }

                        const queryResponseInfo = queryResponse.cell_response;

                        return {
                            ...row,
                            data: {
                                ...row.data,
                                [queryColumn.id]: {
                                    value: convertBeCellValueV2ToFrontendCellValueV2(queryResponseInfo.query_response),
                                    citations: queryResponseInfo.citations,
                                    sourceDetails:
                                        queryResponse.details != null
                                            ? convertBeCellAnswerDetailsToFrontend(queryResponse.details)
                                            : undefined,
                                },
                            },
                        };
                    }),
                );

                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, undefined);
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to retry failed column values", { variant: "error" });

                setGridLoadingStatusesFromRequest(request, setCellGenerationStatusesByColByRow, undefined);
            }
        },
        [enqueueSnackbar, service],
    );
}

function setGridLoadingStatusesFromRequest(
    request: CellLevelBatchQueryRequest,
    setCellGenerationStatusesByColByRow: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
    >,
    loadingStatus: LoadingStatus | undefined,
) {
    setCellGenerationStatusesByColByRow(prevStatuses => {
        const newStatuses = { ...prevStatuses };

        for (const rowQuestion of request.cell_level_questions.row_questions) {
            const rowStringKey = getStringKeyFromValueAndExternalIdentifiers(
                rowQuestion.row_key.value,
                rowQuestion.row_key.external_identifiers,
            );

            for (const colId of rowQuestion.col_ids) {
                if (!newStatuses[colId]) {
                    newStatuses[colId] = {};
                }

                if (loadingStatus === undefined) {
                    delete newStatuses[colId][rowStringKey];
                } else {
                    newStatuses[colId][rowStringKey] = loadingStatus;
                }

                if (Object.keys(newStatuses[colId]).length === 0) {
                    delete newStatuses[colId];
                }
            }
        }

        return newStatuses;
    });
}
