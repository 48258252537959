import * as React from "react";
import { AgentToolConfig } from "./grid";
import { ColumnToolName } from "../services/cb-backend-types";
import {
    Box,
    ListItemIcon,
    MenuItem,
    Select,
    SelectChangeEvent,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { GlobalSearch, Image, LinkSquare, Magicpen, Message2, TableDocument } from "iconsax-react";
import { useCurrentUserQuery } from "../context/userContext";

type PickableTool = ColumnToolName | "AnswerFromContextWebPageWithImage" | "Infer";

const TOOL_INFO_BY_TOOL: Record<
    PickableTool,
    {
        displayName: string;
        description: string;
    }
> = {
    Infer: {
        displayName: "Auto-pick",
        description: "Auto-pick one of the tools below",
    },
    AnswerFromSearchEngine: {
        displayName: "Web search",
        description: "Answer by searching the web",
    },
    GeneralInstructionAssistant: {
        displayName: "Instruction",
        description: "Answer using an LLM only",
    },
    AnswerFromContextWebPageWithImage: {
        displayName: "Webpage",
        description: "Answer using a webpage screenshot",
    },
    AnswerFromContextWebPage: {
        displayName: "Webpage text",
        description: "Answer using a website's text",
    },
    FindWebPages: {
        displayName: "Find links",
        description: "Search for relevant webpages",
    },
};

const ICON_SIZE = 16;

export interface AgentToolPickerProps {
    toolConfig: AgentToolConfig | undefined;
    onChange: (toolConfig: AgentToolConfig | undefined) => void;
    isAnyContextColumnSelected: boolean;
    sx?: SxProps<Theme>;
}

export const AgentToolPicker: React.FC<AgentToolPickerProps> = ({
    toolConfig,
    onChange,
    isAnyContextColumnSelected,
    sx,
}) => {
    const handleChange = React.useCallback(
        (e: SelectChangeEvent<PickableTool>) => {
            const value = e.target.value;
            if (value === "Infer") {
                onChange(undefined);
                return;
            }
            if (value == "AnswerFromContextWebPageWithImage") {
                onChange({
                    toolName: "AnswerFromContextWebPage",
                    useVisionIfPageScrape: true,
                });
                return;
            }
            onChange({
                toolName: e.target.value as ColumnToolName,
                useVisionIfPageScrape: false,
            });
        },
        [onChange],
    );

    const value: PickableTool =
        toolConfig?.toolName === "AnswerFromContextWebPage" && toolConfig.useVisionIfPageScrape
            ? "AnswerFromContextWebPageWithImage"
            : toolConfig?.toolName ?? "Infer";

    const renderValue = (selected: PickableTool) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 0.75,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: "0 !important",
                    }}
                >
                    <AgentToolIcon toolName={selected} />
                </ListItemIcon>
                <Typography variant="body2">{TOOL_INFO_BY_TOOL[selected].displayName}</Typography>
            </Box>
        );
    };

    const user = useCurrentUserQuery();

    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            renderValue={renderValue}
            inputProps={{
                sx: { pt: 0.65, pb: 0.25, pl: 1 },
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            }}
            sx={{
                ...sx,
            }}
        >
            {Object.entries(TOOL_INFO_BY_TOOL).map(([tool, info]) => {
                const typedTool = tool as keyof typeof TOOL_INFO_BY_TOOL;
                // Only show page text tool to admins
                if (
                    typedTool === "AnswerFromContextWebPage" &&
                    // TODO: Hack. Stop allowing free trial users to use webpage text
                    (user.data == null || !user.data.is_admin || user.data.plan_type !== "free-trial")
                ) {
                    return null;
                }
                // Only show auto-pick tool if there are context columns selected
                if (!isAnyContextColumnSelected && typedTool === "Infer") {
                    return null;
                }
                return (
                    <MenuItem key={tool} value={tool}>
                        <AgentToolMenuItem
                            icon={<AgentToolIcon toolName={tool as PickableTool} />}
                            displayName={info.displayName}
                            description={info.description}
                        />
                    </MenuItem>
                );
            })}
        </Select>
    );
};

interface AgentToolMenuItemProps {
    icon: React.ReactNode;
    displayName: string;
    description: string;
}

const AgentToolMenuItem: React.FC<AgentToolMenuItemProps> = ({ icon, displayName, description }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 0.75,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "0 !important",
                    mt: 0.25,
                    alignSelf: "flex-start",
                }}
            >
                {icon}
            </ListItemIcon>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Typography variant="body2">{displayName}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {description}
                </Typography>
            </Box>
        </Box>
    );
};

interface AgentToolIconProps {
    toolName: PickableTool;
}

const AgentToolIcon: React.FC<AgentToolIconProps> = ({ toolName }) => {
    const theme = useTheme();
    switch (toolName) {
        case "AnswerFromSearchEngine":
            return <GlobalSearch variant="Bold" size={ICON_SIZE} color={theme.palette.secondary.main} />;
        case "AnswerFromContextWebPage":
            return <TableDocument size={ICON_SIZE} color={theme.palette.secondary.main} />;
        case "AnswerFromContextWebPageWithImage":
            return <Image size={ICON_SIZE} color={theme.palette.secondary.main} />;
        // TODO:
        case "GeneralInstructionAssistant":
            return <Message2 size={ICON_SIZE} color={theme.palette.secondary.main} />;
        case "Infer":
            return <Magicpen size={ICON_SIZE} color={theme.palette.secondary.main} />;
        case "FindWebPages":
            return <LinkSquare size={ICON_SIZE} color={theme.palette.secondary.main} />;
    }
};
