import * as React from "react";
import { Box, IconButton, lighten, Typography, useTheme } from "@mui/material";
import { SearchPersona } from "../personas";
import { ApolloPersonFilters, ApolloPersonFiltersWithFallback } from "../../services/cb-backend-types";
import { ApolloEditableFilterComponentLayout } from "./apolloEditableFilterComponentLayout";
import { ApolloEditableFilterValuePicker } from "./apolloEditableFilterValuePicker";
import { Trash } from "iconsax-react";

interface ApolloEditablePersonFiltersProps {
    config: ApolloPersonFiltersWithFallback;
    setConfig: (config: ApolloPersonFiltersWithFallback) => void;
    persona: SearchPersona;
}

export const ApolloEditablePersonFilters: React.FC<ApolloEditablePersonFiltersProps> = ({ config, setConfig }) => {
    const handleSetPersonTitles = React.useCallback(
        ({ person_titles }: { person_titles: string[] }) => {
            setConfig({ ...config, primary: { ...config.primary, person_titles } });
        },
        [config, setConfig],
    );
    const handleSetFallbackPersonTitles = React.useCallback(
        (individualConfig: ApolloPersonFilters, idx: number) => {
            const newFallback = [...config.fallback];
            newFallback[idx] = individualConfig;
            setConfig({ ...config, fallback: newFallback });
        },
        [config, setConfig],
    );

    const handleDeleteFallback = React.useCallback(
        (idx: number) => {
            const newFallback = [...config.fallback];
            newFallback.splice(idx, 1);
            setConfig({ ...config, fallback: newFallback });
        },
        [config, setConfig],
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
                alignItems: "stretch",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    alignItems: "stretch",
                }}
            >
                <ApolloEditableFilterComponentLayout
                    isEditing={true}
                    dlt={null}
                    edit={null}
                    valuePicker={
                        <ApolloEditableFilterValuePicker
                            config={config.primary}
                            setConfig={handleSetPersonTitles}
                            fieldKey={"person_titles"}
                        />
                    }
                    nonEditingContent={null}
                    field={"person_titles"}
                />
            </Box>
            {config.fallback.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                        alignItems: "stretch",
                    }}
                >
                    {config.fallback.map((individualConfig, idx) => (
                        <ApolloFallbackPersonFilters
                            key={idx}
                            config={individualConfig}
                            idx={idx}
                            onDeleteFallback={handleDeleteFallback}
                            setConfig={handleSetFallbackPersonTitles}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

interface ApolloFallbackPersonFiltersProps {
    config: ApolloPersonFilters;
    idx: number;
    onDeleteFallback: (idx: number) => void;
    setConfig: (config: ApolloPersonFilters, idx: number) => void;
}

const ApolloFallbackPersonFilters: React.FC<ApolloFallbackPersonFiltersProps> = ({
    config,
    idx,
    onDeleteFallback,
    setConfig,
}) => {
    const handleSetPersonTitles = React.useCallback(
        ({ person_titles }: { person_titles: string[] }) => {
            setConfig({ ...config, person_titles }, idx);
        },
        [config, idx, setConfig],
    );
    const handleDeleteFallback = React.useCallback(() => {
        onDeleteFallback(idx);
    }, [idx, onDeleteFallback]);

    const theme = useTheme();

    return (
        <Box
            sx={theme => ({
                pt: 0.5,
                pr: 1,
                pl: 1.5,
                pb: 1,
                display: "flex",
                flexDirection: "column",
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                bgcolor: lighten(theme.palette.primary.main, 0.9),
            })}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" fontWeight="medium">
                    Fallback {idx + 1}
                </Typography>
                <IconButton onClick={handleDeleteFallback} size="small">
                    <Trash size="16" color={theme.palette.error.main} />
                </IconButton>
            </Box>
            <ApolloEditableFilterComponentLayout
                isEditing={true}
                dlt={null}
                edit={null}
                valuePicker={
                    <ApolloEditableFilterValuePicker
                        config={config}
                        setConfig={handleSetPersonTitles}
                        fieldKey={"person_titles"}
                        style="small"
                    />
                }
                nonEditingContent={null}
                field="person_titles"
                style="small"
            />
        </Box>
    );
};
