import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { getBackendService } from "../services/cb-backend";

function getTryItLinkForShareGrid(publicGridUniqueId: string) {
    return `https://app.answergrid.ai/try-it?starting-grid=${publicGridUniqueId}`;
}

function getGridLink(gridId: string) {
    return `https://app.answergrid.ai/grid/${gridId}`;
}

export function useShareGrid() {
    const [isSharingGridLoading, setIsSharingGridLoading] = React.useState(false);
    const backendService = getBackendService();

    const { mutateAsync: shareGridMutation } = useMutation({
        onMutate: () => {
            setIsSharingGridLoading(true);
        },
        mutationFn: (gridId: string) => backendService.shareGrid(gridId),
        onSettled: () => {
            setIsSharingGridLoading(false);
        },
    });

    const shareGrid = React.useCallback(
        async (gridId: string, isTrialShare: boolean) => {
            try {
                if (isTrialShare) {
                    const shareGridResp = await shareGridMutation(gridId);
                    const link = getTryItLinkForShareGrid(shareGridResp.public_grid_unique_id);
                    void navigator.clipboard.writeText(link);
                    return {
                        success: true,
                        link,
                        message: "Trial share link copied to clipboard",
                    };
                } else {
                    const link = getGridLink(gridId);
                    void navigator.clipboard.writeText(link);
                    return {
                        success: true,
                        link,
                        message: "Successfully copied link to grid",
                    };
                }
            } catch (error) {
                return { success: false };
            }
        },
        [shareGridMutation],
    );

    return { shareGrid, isSharingGridLoading };
}
