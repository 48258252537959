import Box from "@mui/material/Box";
import * as React from "react";
import { getBackendService } from "../services/cb-backend";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SidebarRight, Logout, Add, MessageQuestion, SidebarLeft, ArrowUp, Setting2, Login } from "iconsax-react";
import "../App.css";
import { GridList } from "./gridList";
import { useDeleteGrid, useRenameGrid } from "./useRenameAndDeleteGrid";
import { useCurrentUserQuery } from "../context/userContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { convertBackendGridToFrontend } from "../grid/gridSerialization";
import { availableGridsQueryOptions, getReactQueryGridKey } from "../react-query/queryKeys";
import { CircularProgress } from "@mui/material";
import { allowSelfServicePlanManagement, FREE_TRIAL_DAYS, hasTrialFeatures, isPaidUser } from "../grid/grid";
import { UpgradePlanDialog } from "../settings/upgradeDialog";
import { getMixpanelInstance } from "../mixpanel";
import { useFreeTrialUsage } from "../grid/userUsageQuery";
import { FreeTrialUsageResponse, GetCurrentUserResponse } from "../services/cb-backend-types";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { updateAvailableGridsOnCreate } from "../context/gridContextAndProvider";

export interface SidebarProps {
    selectedGridId: string | undefined;
}

const LOCAL_STORAGE_KEY = "answergrid.sidebarCollapsed";

const MAX_ROWS_FOR_CSV_IMPORT_TRIAL_USERS = 8;

export const Sidebar: React.FC<SidebarProps> = ({ selectedGridId }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(localStorage.getItem(LOCAL_STORAGE_KEY) === "true");
    const [isDragging, setIsDragging] = React.useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const backendService = React.useMemo(() => getBackendService(), []);

    const toggleSidebar = React.useCallback(() => {
        const newCollapsedValue = !isCollapsed;
        setIsCollapsed(newCollapsedValue);
        localStorage.setItem(LOCAL_STORAGE_KEY, newCollapsedValue.toString());
    }, [isCollapsed]);

    const handleLogout = React.useCallback(async () => {
        try {
            await backendService.logout();
        } finally {
            navigate("/login");
        }
    }, [backendService, navigate]);

    const handleClickNewTab = React.useCallback(() => {
        navigate("/home");
    }, [navigate]);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if CMD+G on Mac or Ctrl+G on Windows/Linux
            if ((event.ctrlKey || event.metaKey) && event.key === "g") {
                event.preventDefault();
                handleClickNewTab();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleClickNewTab]);

    const grids = useQuery(availableGridsQueryOptions(backendService));

    const theme = useTheme();

    const onGridDelete = useDeleteGrid();
    const onGridRename = useRenameGrid();

    // reload after rename or delete
    const handleDeleteGrid = React.useCallback(
        async (gridId: string) => {
            if (gridId === selectedGridId) {
                navigate("/home");
            }
            await onGridDelete(gridId);
        },
        [navigate, onGridDelete, selectedGridId],
    );

    const handleRenameGrid = React.useCallback(
        async (gridId: string, name: string) => {
            await onGridRename(gridId, name);
        },
        [onGridRename],
    );

    const { mutateAsync: duplicateGrid } = useMutation({
        mutationFn: (gridId: string) => (gridId != null ? backendService.duplicateGrid(gridId) : Promise.resolve(null)),
        onSuccess: newGrid => {
            if (newGrid != null) {
                updateAvailableGridsOnCreate(queryClient, newGrid);
                queryClient.setQueryData(
                    getReactQueryGridKey(newGrid.unique_id),
                    convertBackendGridToFrontend(newGrid),
                );
                navigate(`/grid/${newGrid.unique_id}`);
            }
        },
    });

    const handleDuplicateGrid = React.useCallback(
        async (gridId: string) => {
            await duplicateGrid(gridId);
        },
        [duplicateGrid],
    );

    const userQuery = useCurrentUserQuery();

    const createGridFromCsvMutation = useMutation({
        mutationFn: (csvData: string) =>
            backendService.createGridFromCsv({
                csv_data: csvData,
                name: "Imported CSV Grid",
            }),
        onSuccess: data => {
            navigate(`/grid/${data.unique_id}`);
            // TODO: Const
            void queryClient.invalidateQueries({ queryKey: ["availableGrids"] });
        },
    });

    const handleDragOver = React.useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = React.useCallback(() => {
        setIsDragging(false);
    }, []);

    const user = useCurrentUserQuery();

    const handleDrop = React.useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setIsDragging(false);

            if (user.data == null) {
                return;
            }

            const file = event.dataTransfer.files[0];
            if (file && file.type === "text/csv") {
                const reader = new FileReader();
                reader.onload = async e => {
                    const rawCsvData = e.target?.result as string;
                    const csvData = hasTrialFeatures(user.data.plan_type)
                        ? rawCsvData
                              .split("\n")
                              .slice(0, MAX_ROWS_FOR_CSV_IMPORT_TRIAL_USERS + 1)
                              .join("\n")
                        : rawCsvData;
                    await createGridFromCsvMutation.mutateAsync(csvData);
                };
                reader.readAsText(file);
            }
        },
        [createGridFromCsvMutation, user.data],
    );

    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = React.useState(false);

    const onClose = React.useCallback(() => {
        setIsUpgradeDialogOpen(false);
    }, []);

    const handleClickLogin = React.useCallback(() => {
        navigate("/login");
    }, [navigate]);

    const handleUpgradeClick = React.useCallback(() => {
        if (user.data == null) {
            return;
        }
        const mixpanel = getMixpanelInstance();
        mixpanel?.track("Upgrade Dialog Opened", {
            CurrentPlan: user.data.plan_type,
        });
        setIsUpgradeDialogOpen(true);
    }, [user.data]);

    if (createGridFromCsvMutation.isPending) {
        return (
            <Box
                sx={{
                    pt: 3,
                    px: 1,
                    flex: "0 0 auto",
                    flexBasis: isCollapsed ? 64 : 200,
                    minHeight: "0",
                    overflow: "hidden",
                    backgroundColor: isDragging ? "rgba(244, 242, 239, 0.8)" : "#F4F2EF",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "flex-basis 0.3s ease, background-color 0.3s ease",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    pt: 3,
                    px: 1,
                    flex: "0 0 auto",
                    flexBasis: isCollapsed ? 64 : 200,
                    minHeight: "0",
                    overflow: "hidden",
                    backgroundColor: isDragging ? "rgba(244, 242, 239, 0.8)" : "#F4F2EF",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "flex-basis 0.3s ease, background-color 0.3s ease",
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        px: 1,
                        overflowY: "hidden",
                    }}
                >
                    <LogoComponent isCollapsed={isCollapsed} />
                    <Box
                        sx={{
                            display: "flex",
                            mt: 2,
                            flexDirection: "column",
                            alignItems: isCollapsed ? "center" : "stretch",
                            maxWidth: "100%",
                            rowGap: 0.5,
                            overflow: "hidden",
                        }}
                    >
                        <GridActionButton
                            isCollapsed={isCollapsed}
                            icon={<Add color={theme.palette.secondary.main} size="24" />}
                            text="New Grid"
                            onClick={handleClickNewTab}
                            useSecondaryColor={true}
                            hotkey="CMD+G"
                        />
                        <GridList
                            grids={grids.data}
                            isCollapsed={isCollapsed}
                            selectedGridId={selectedGridId}
                            onGridDelete={handleDeleteGrid}
                            onGridRename={handleRenameGrid}
                            onGridDuplicate={handleDuplicateGrid}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        borderTop: isCollapsed ? 0 : 1,
                        pt: 0.5,
                        borderColor: "#E4E7EC",
                    }}
                >
                    {!isCollapsed && <UpgradeCard onClick={handleUpgradeClick} />}
                    <ActionButton
                        isCollapsed={isCollapsed}
                        icon={
                            isCollapsed ? (
                                <SidebarRight color={"#475467"} size="20" />
                            ) : (
                                <SidebarLeft color={"#475467"} size="20" />
                            )
                        }
                        text={isCollapsed ? "Expand" : "Collapse"}
                        onClick={toggleSidebar}
                    />
                    {userQuery.data != null && !isPaidUser(userQuery.data.plan_type) && (
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<MessageQuestion color={"#475467"} size="20" />}
                            text="Help"
                            onClick={() => (window.location.href = "mailto:founders@answergrid.ai")}
                        />
                    )}
                    {userQuery.data != null &&
                        isPaidUser(userQuery.data.plan_type) &&
                        allowSelfServicePlanManagement(userQuery.data) && (
                            <ActionButton
                                isCollapsed={isCollapsed}
                                icon={<Setting2 color={"#475467"} size="20" />}
                                text="Plan & billing"
                                onClick={handleUpgradeClick}
                            />
                        )}
                    {userQuery.data == null || userQuery.data.plan_type !== "trial" ? (
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<Logout color={"#475467"} size="20" />}
                            text="Sign out"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleLogout}
                        />
                    ) : (
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<Login color={"#475467"} size="20" />}
                            text="Sign in"
                            onClick={handleClickLogin}
                        />
                    )}
                </Box>
            </Box>
            <UpgradePlanDialog open={isUpgradeDialogOpen} onClose={onClose} />
        </>
    );
};

interface UpgradeCardProps {
    onClick: () => void;
}

const UpgradeCard: React.FC<UpgradeCardProps> = ({ onClick }) => {
    const theme = useTheme();
    const user = useCurrentUserQuery();

    const freeTrialUsage = useFreeTrialUsage();

    if (
        user.data == null ||
        isPaidUser(user.data.plan_type) ||
        user.data.is_admin ||
        // TODO: Remove this once we want to allow self-service for users invited via invitation
        (isPaidUser(user.data.plan_type) && !allowSelfServicePlanManagement(user.data))
    ) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    bgcolor: "#EEEBE5",
                    borderRadius: 2,
                    p: 1,
                    mx: 0.5,
                    my: 1,
                    columnGap: 1,
                    cursor: "pointer",
                }}
                onClick={onClick}
            >
                <ArrowUp color={theme.palette.success.main} size="20" style={{ flexShrink: 0 }} />
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <Typography variant="caption" fontWeight="550" noWrap>
                        {user.data.plan_type === "free-trial" ? "Upgrade to Pro" : "Try Pro"}
                    </Typography>
                    <Typography variant="caption" sx={{ minWidth: 130 }}>
                        {getSubtitle(freeTrialUsage.data, user.data)}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

function getSubtitle(freeTrialUsage: FreeTrialUsageResponse | undefined, user: GetCurrentUserResponse) {
    const freeTrialEndDate = new Date(
        new Date(user.date_joined).setDate(new Date(user.date_joined).getDate() + FREE_TRIAL_DAYS),
    );
    if (new Date() > freeTrialEndDate) {
        return "Your free trial has ended. Upgrade to continue with Pro features.";
    }
    if (freeTrialUsage != null && freeTrialUsage.has_reached_free_trial_limit) {
        return "You've reached the free trial total rows limit. Upgrade to continue.";
    }
    return "Unlock more limits for you and your team";
}

interface LogoComponentProps {
    isCollapsed: boolean;
}

const LogoComponent: React.FC<LogoComponentProps> = ({ isCollapsed }) => {
    const navigate = useNavigate();
    const handleGoHome = React.useCallback(() => {
        navigate("/home");
    }, [navigate]);

    return (
        <Box
            aria-label="AnswerGrid"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            }}
            onClick={handleGoHome}
        >
            {isCollapsed ? (
                <Logo
                    style={{
                        width: 28,
                        height: 28,
                    }}
                />
            ) : (
                <>
                    <Logo
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                    <Typography
                        variant="h6"
                        className="AG-font"
                        sx={{
                            ml: 1,
                            // TODO: Fix Hackhack to align text
                            mb: -0.5,
                            color: "secondary.main",
                            fontFamily: "Garamond-Light-Cond",
                            fontWeight: 500,
                            fontSize: "25px",
                            display: isCollapsed ? "none" : "auto",
                        }}
                    >
                        AnswerGrid
                    </Typography>
                </>
            )}
            {/* {isCollapsed ? (
                <Logo
                    style={{
                        width: 28,
                        height: 28,
                    }}
                />
            ) : (
                <LogoText style={{ width: 120 }} />
            )} */}
        </Box>
    );
};

interface GridActionButtonProps {
    isCollapsed: boolean;
    icon: React.ReactNode;
    text: string;
    onClick: () => void;
    useSecondaryColor?: boolean;
    hotkey?: string;
}

const GridActionButton: React.FC<GridActionButtonProps> = ({
    isCollapsed,
    icon,
    text,
    onClick,
    useSecondaryColor,
    hotkey,
}) => {
    const renderPlatformKey = React.useCallback((key: string) => {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const isWindows = navigator.platform.toUpperCase().indexOf("WIN") >= 0;

        if (isMac) {
            return key.replace("CMD", "⌘"); // Replace "CMD" with Command symbol on macOS
        } else if (isWindows) {
            return key.replace("CMD", "CTRL"); // Replace "CMD" with Windows key symbol on Windows
        } else {
            return key;
        }
    }, []);

    if (isCollapsed) {
        return (
            <IconButton
                aria-label={text}
                color={useSecondaryColor ? "secondary" : "primary"}
                onClick={onClick}
                size="small"
                sx={theme => ({
                    bgcolor: useSecondaryColor ? theme.palette.surface[100] : "unset",
                    borderRadius: 2,
                    width: 32,
                    height: 32,
                })}
            >
                {icon}
            </IconButton>
        );
    }

    return (
        <Button
            onClick={onClick}
            startIcon={icon}
            fullWidth
            sx={theme => ({
                alignItems: "center",
                justifyContent: "start",
                display: "flex",
                flexDirection: "row",
                textTransform: "none",
                typography: "caption",
                color: useSecondaryColor ? "secondary.main" : "primary.contrastText",
                whiteSpace: "nowrap",
                bgcolor: useSecondaryColor ? theme.palette.surface[100] : "unset",
            })}
            color={useSecondaryColor ? "secondary" : "primary"}
        >
            <>
                {text}
                {hotkey != null && (
                    <Typography
                        variant="caption"
                        noWrap
                        sx={{
                            color: "#827179",
                            marginLeft: "auto",
                        }}
                    >
                        {hotkey.split("+").map((keyPart, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && "+"}
                                {renderPlatformKey(keyPart)}
                            </React.Fragment>
                        ))}
                    </Typography>
                )}
            </>
        </Button>
    );
};

interface ActionButtonProps {
    isCollapsed: boolean;
    icon: React.ReactNode;
    text: string;
    href?: string;
    onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ isCollapsed, icon, text, href, onClick }) => {
    if (isCollapsed) {
        return (
            <IconButton aria-label={text} onClick={onClick} size="small" sx={{ mx: 1 }}>
                {icon}
            </IconButton>
        );
    } else {
        return (
            <Button
                href={href}
                onClick={onClick}
                startIcon={icon}
                color="primary"
                sx={{
                    justifyContent: "center",
                    mx: 1,
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                    color: "primary.contrastText",
                    textTransform: "none",
                    typography: "caption",
                }}
            >
                {text}
            </Button>
        );
    }
};
