import * as React from "react";
import { TableCell, TableCellProps, SxProps, Theme } from "@mui/material";

export interface AnswerGridCellProps extends TableCellProps {
    isFirstColumn: boolean;
    isLastColumn: boolean;
    isLargeStyle: boolean;
    sx?: SxProps<Theme>;
}

export const ANSWER_GRID_CELL_CLASS = "answer-grid-cell";
export const KEEP_SELECTED_CELL_CLASS = "keep-selected-cell";

export const AnswerGridCell = React.memo(
    React.forwardRef<HTMLTableElement, AnswerGridCellProps>(function AnswerGridCell(
        // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
        { isLastColumn: _, isFirstColumn, isLargeStyle, children, sx: parentSx, ...rest }: AnswerGridCellProps,
        ref,
    ) {
        return (
            <TableCell
                width={1}
                ref={ref}
                className={ANSWER_GRID_CELL_CLASS}
                sx={[
                    {
                        // TODO: Allow bigger?
                        maxWidth: isLargeStyle ? 310 : 200,
                        width: isLargeStyle ? 310 : 200,
                        minWidth: isLargeStyle ? 310 : 140,
                        pl: isFirstColumn ? 3 : undefined,
                        borderRight: 1,
                        borderColor: "divider",
                        bgcolor: "primary.main",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(parentSx) ? parentSx : [parentSx]),
                ]}
                {...rest}
            >
                {children}
            </TableCell>
        );
    }),
);
