import mixpanel, { Config } from "mixpanel-browser";
import { useLocation } from "react-router-dom";
import React from "react";

// Define the configuration for Mixpanel initialization
const mixpanelConfig: Partial<Config> = {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
};

// Singleton instance for Mixpanel
let mixpanelInstance: typeof mixpanel | undefined = undefined;

export const getMixpanelInstance = (): typeof mixpanel | undefined => {
    const isDeveloping = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    if (isDeveloping) {
        console.log("Mixpanel is disabled in development mode");
        return undefined;
    }
    if (mixpanelInstance == null) {
        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

        if (mixpanelToken) {
            mixpanel.init(mixpanelToken, mixpanelConfig);
            mixpanelInstance = mixpanel;
            return mixpanelInstance;
        } else {
            console.error("No Mixpanel token found in env");
        }
    }

    return mixpanelInstance;
};

export const useTrackPageView = (): void => {
    const location = useLocation();
    React.useEffect(() => {
        const mixpanelInstance = getMixpanelInstance();

        if (mixpanelInstance) {
            mixpanelInstance.track_pageview({
                // TODO: NOt scalable
                // If the path is grid/:gridId, we only want to track the grid part
                "clean-path": location.pathname.includes("/grid/") ? "/grid/:gridId" : location.pathname,
            });
        }
    }, [location]);
};
