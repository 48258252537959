import * as React from "react";
import {
    Box,
    Button,
    darken,
    Divider,
    Fade,
    IconButton,
    MenuItem,
    Popover,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { AgentToolConfig, Column, ColumnGeneratedBy, ColumnGeneratedByApollo, FormatOptions } from "./grid";
import { CRUNCHBASE_FIELD_DISPLAY_NAMES, FeSupportedCrunchbaseFieldId } from "../services/crunchbase";
import { comparatorOnFields } from "../utils/comparators";
import { EMPLOYEE_DISPLAY_NAME } from "../services/apollo";
import { WebQueryColumnContextFields } from "./WebQueryColumnContextFields";
import { useBoolean } from "../utils/hooks";
import { CompanyPeopleDialog } from "../home/filters/companyPeopleDialog";
import { AgentToolPicker } from "./agentToolPicker";
import { ReactComponent as Maximize4 } from "../assets/maximize-4.svg";
import { ArrowUp, People, TextalignJustifycenter } from "iconsax-react";
import { FormatOptionsPicker } from "./formatOptionsPicker";

type CrunchbaseProperty = {
    type: "crunchbase";
    field: FeSupportedCrunchbaseFieldId;
};

type ApolloOrgProperty = {
    type: "apollo-org";
    field:
        | "technologies"
        | "job_postings"
        | "short_description"
        | "estimated_num_employees"
        | "departmental_head_counts";
};

type PropertyAddableAsColumn = CrunchbaseProperty | ApolloOrgProperty;

const APOLLO_PROPERTIES_DISPLAY_NAMES: Record<ApolloOrgProperty["field"], string> = {
    technologies: "Technologies",
    job_postings: "Job Postings",
    short_description: "LinkedIn Description",
    estimated_num_employees: "Precise number of Employees",
    departmental_head_counts: "Departmental Head Counts",
};

const PROPERTIES_ADDABLE_AS_COLUMNS: ReadonlyArray<PropertyAddableAsColumn> = [
    { type: "crunchbase", field: "categories" },
    { type: "crunchbase", field: "description" },
    { type: "crunchbase", field: "founded_on" },
    { type: "crunchbase", field: "funding_total" },
    { type: "crunchbase", field: "investor_identifiers" },
    { type: "crunchbase", field: "last_equity_funding_type" },
    { type: "crunchbase", field: "last_funding_at" },
    { type: "crunchbase", field: "last_funding_total" },
    { type: "crunchbase", field: "linkedin" },
    { type: "crunchbase", field: "location_identifiers" },
    { type: "crunchbase", field: "num_employees_enum" },
    { type: "apollo-org", field: "technologies" },
    { type: "apollo-org", field: "job_postings" },
    { type: "apollo-org", field: "short_description" },
    { type: "apollo-org", field: "estimated_num_employees" },
    { type: "apollo-org", field: "departmental_head_counts" },
    { type: "crunchbase", field: "website_url" },
];

export interface HeaderGridCellPopoverProps {
    anchorEl: HTMLDivElement | null;
    open: boolean;
    apolloSourceConfig: Omit<ColumnGeneratedByApollo, "type">;
    enableApolloColumns: boolean;
    enableCrunchbaseColumns: boolean;
    newColQuery: string;
    newContextColumnIds: string[];
    isExportOngoing: boolean;
    isSomeRowLoading: boolean;
    otherColumns: Column[];
    colLabel: string;
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
    onChangeColQuery: (newColQuery: string) => void;
    onClose: () => void;
    onOpenAdvancedDialog: () => void;
    onChangeColumnContextField: (contextFieldIds: string[]) => void;
    onFinishEditingHeaderColumn: (columnQuery: ColumnGeneratedBy, colLabel?: string) => Promise<void>;
    onChangeApolloSourceConfig: React.Dispatch<React.SetStateAction<Omit<ColumnGeneratedByApollo, "type">>>;
    onChangeColLabel: (newColLabel: string) => void;
    onChangeToolConfig: (toolConfig: AgentToolConfig | undefined) => void;
    onChangeFormatOptions: (formatOptions: FormatOptions | undefined) => void;
}

const POPOVER_WIDTH = 316;

export const HeaderGridCellPopover: React.FC<HeaderGridCellPopoverProps> = ({
    open,
    anchorEl,
    apolloSourceConfig,
    enableApolloColumns,
    enableCrunchbaseColumns,
    newColQuery,
    newContextColumnIds,
    isExportOngoing,
    isSomeRowLoading,
    otherColumns,
    colLabel,
    toolConfig,
    formatOptions,
    onChangeFormatOptions,
    onChangeColQuery,
    onOpenAdvancedDialog,
    onClose,
    onChangeColumnContextField,
    onFinishEditingHeaderColumn,
    onChangeApolloSourceConfig,
    onChangeColLabel,
    onChangeToolConfig,
}) => {
    const id = open ? "header-grid-cell-popover" : undefined;

    const inputRef = React.useRef<HTMLInputElement>(null);

    const availableCrunchbaseProperties = React.useMemo(
        () =>
            [...PROPERTIES_ADDABLE_AS_COLUMNS]
                .sort(
                    comparatorOnFields(p =>
                        p.type === "apollo-org"
                            ? [APOLLO_PROPERTIES_DISPLAY_NAMES[p.field]]
                            : [CRUNCHBASE_FIELD_DISPLAY_NAMES[p.field].displayName],
                    ),
                )
                .filter(property =>
                    // Filter out properties that are already columns
                    otherColumns.every(col =>
                        property.type === "apollo-org"
                            ? col.generatedBy?.type !== "apollo_organization" ||
                              col.generatedBy?.field !== property.field
                            : col.generatedBy?.type !== "crunchbase" || col.generatedBy.fieldId !== property.field,
                    ),
                ),
        [otherColumns],
    );

    const matchingCrunchbaseProperties = React.useMemo(
        () =>
            newColQuery.length > 0
                ? availableCrunchbaseProperties.filter(field => {
                      const displayName =
                          field.type === "crunchbase"
                              ? CRUNCHBASE_FIELD_DISPLAY_NAMES[field.field].displayName
                              : APOLLO_PROPERTIES_DISPLAY_NAMES[field.field];
                      return matchesQuery(newColQuery, displayName);
                  })
                : availableCrunchbaseProperties,
        [availableCrunchbaseProperties, newColQuery],
    );

    const [selectedButton, setSelectedButton] = React.useState<{
        selectedButton: "properties" | undefined;
        isAutoSet: boolean;
    }>({ selectedButton: undefined, isAutoSet: true });

    const handleToggleSelectPropertiesButton = React.useCallback(
        () =>
            setSelectedButton(old => ({
                selectedButton: old.selectedButton === "properties" ? undefined : "properties",
                isAutoSet: false,
            })),
        [],
    );

    const handleAddProperty = React.useCallback(
        (columnQuery: ColumnGeneratedBy) => {
            onClose();
            void onFinishEditingHeaderColumn(columnQuery);
        },
        [onClose, onFinishEditingHeaderColumn],
    );

    const handleAddCompanyEmployee = React.useCallback(
        (sourceConfig: Omit<ColumnGeneratedByApollo, "type">) => {
            onClose();
            void onFinishEditingHeaderColumn(
                {
                    type: "apollo",
                    ...sourceConfig,
                },
                colLabel.trim() !== "" ? colLabel : undefined,
            );
        },
        [colLabel, onClose, onFinishEditingHeaderColumn],
    );

    const handleSubmitWebSearch = React.useCallback(() => {
        if (isExportOngoing || isSomeRowLoading) {
            return;
        }
        void onFinishEditingHeaderColumn({
            query: newColQuery,
            type: "web_search",
            contextColumnIds: newContextColumnIds,
            toolConfig: toolConfig,
            formatOptions,
        });
        onClose();
    }, [
        formatOptions,
        isExportOngoing,
        isSomeRowLoading,
        newColQuery,
        newContextColumnIds,
        onClose,
        onFinishEditingHeaderColumn,
        toolConfig,
    ]);

    const disabledReason = React.useMemo(() => {
        if (toolConfig?.toolName === "AnswerFromContextWebPage" && newContextColumnIds.length === 0) {
            return "You need to specify a URL column as context to use this tool";
        }
        if (toolConfig?.toolName === "GeneralInstructionAssistant" && newContextColumnIds.length === 0) {
            return "You need to specify one or more columns as context to use this tool";
        }
        if (newColQuery.trim().length === 0) {
            return "Enter a query to search";
        }
        if (isExportOngoing || isSomeRowLoading) {
            return "Export or loading in progress";
        }
        return undefined;
    }, [newColQuery, isExportOngoing, isSomeRowLoading, toolConfig?.toolName, newContextColumnIds.length]);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (disabledReason != null) {
                return;
            }
            if (event.key === "Enter") {
                event.preventDefault();
                void handleSubmitWebSearch();
            }
        },
        [disabledReason, handleSubmitWebSearch],
    );

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeColQuery(e.target.value);
            const newMatchingProperties = getMatchingProperties(e.target.value, availableCrunchbaseProperties);
            if (e.target.value.length >= 1) {
                if (newMatchingProperties.length > 0) {
                    setSelectedButton(old => (old.isAutoSet ? { selectedButton: "properties", isAutoSet: true } : old));
                } else {
                    setSelectedButton(old => (old.isAutoSet ? { selectedButton: undefined, isAutoSet: true } : old));
                }
            }
            if (e.target.value.length === 0) {
                setSelectedButton(old => (old.isAutoSet ? { selectedButton: undefined, isAutoSet: true } : old));
            }
        },
        [onChangeColQuery, availableCrunchbaseProperties],
    );

    const handleChangeContextColumnIdsInPopover = React.useCallback(
        (contextColumnIds: string[]) => {
            onChangeColumnContextField(contextColumnIds);
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        },
        [onChangeColumnContextField],
    );

    const handleChangeToolConfig = React.useCallback(
        (toolConfig: AgentToolConfig | undefined) => {
            onChangeToolConfig(toolConfig);
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        },
        [onChangeToolConfig],
    );

    const inputProps = React.useMemo(
        () =>
            ({
                "data-1p-ignore": true,
            }) as const,
        [],
    );

    const theme = useTheme();

    const handleAddUserInputColumn = React.useCallback(() => {
        onClose();
        void onFinishEditingHeaderColumn({ type: "user_input" }, colLabel.trim() !== "" ? colLabel : undefined);
    }, [onClose, onFinishEditingHeaderColumn, colLabel]);

    const patchedOpen = open && anchorEl != null;

    const isWebSearchWithNoContext =
        toolConfig?.toolName === "AnswerFromContextWebPage" && newContextColumnIds.length === 0;

    return (
        <Popover
            id={id}
            open={patchedOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                sx: {
                    boxShadow: "none",
                    border: "2px solid #0071E3",
                },
            }}
            transitionDuration={0}
            TransitionComponent={Fade}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 1,
                    maxWidth: POPOVER_WIDTH,
                    minHeight: 43,
                }}
            >
                <TextField
                    autoFocus
                    multiline
                    maxRows={7}
                    variant="standard"
                    inputRef={inputRef}
                    InputProps={{
                        disableUnderline: true,
                        sx: {
                            typography: "body2",
                        },
                        ...inputProps,
                    }}
                    sx={{ flexGrow: 1, pl: 1.8, pr: 1.5, pt: 1 }}
                    placeholder="Enter query here…"
                    value={newColQuery}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />

                <Tooltip title={disabledReason}>
                    <span
                        style={{
                            alignSelf: "flex-end",
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{
                                alignSelf: "flex-end",
                            }}
                            disabled={disabledReason != null}
                            onClick={handleSubmitWebSearch}
                        >
                            <Box
                                sx={{
                                    // TODO: Disabled color
                                    bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 20,
                                    height: 20,
                                }}
                            >
                                <ArrowUp size={20} color={theme.palette.common.white} />
                            </Box>
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box
                sx={{
                    minWidth: POPOVER_WIDTH,
                    pb: 1.5,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: POPOVER_WIDTH,
                    typography: "body2",
                }}
            >
                <HeaderGridCellPopoverSection
                    title="Custom search"
                    headerIcon={
                        <IconButton
                            onClick={onOpenAdvancedDialog}
                            size="small"
                            sx={{
                                p: 0,
                                m: 0,
                            }}
                        >
                            <Maximize4 />
                        </IconButton>
                    }
                    sx={{
                        px: 1.5,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 1,
                            }}
                        >
                            <HeaderGridCellPopoverSection title="Select tool" type="subsection" sx={{ flex: 1 }}>
                                <AgentToolPicker
                                    isAnyContextColumnSelected={newContextColumnIds.length > 0}
                                    toolConfig={toolConfig}
                                    onChange={handleChangeToolConfig}
                                />
                            </HeaderGridCellPopoverSection>
                            <HeaderGridCellPopoverSection title="Format" type="subsection" sx={{ flex: 1 }}>
                                <FormatOptionsPicker
                                    toolConfig={toolConfig}
                                    formatOptions={formatOptions}
                                    onChange={onChangeFormatOptions}
                                />
                            </HeaderGridCellPopoverSection>
                        </Box>
                        {otherColumns.length > 0 && (
                            <HeaderGridCellPopoverSection title="Add context" type="subsection">
                                <WebQueryColumnContextFields
                                    contextColumnIds={newContextColumnIds}
                                    otherColumns={otherColumns}
                                    label={isWebSearchWithNoContext ? "Add URL column" : `Select columns`}
                                    onChangeColumnContextField={handleChangeContextColumnIdsInPopover}
                                    size="small"
                                />
                            </HeaderGridCellPopoverSection>
                        )}
                    </Box>
                </HeaderGridCellPopoverSection>
                {(enableApolloColumns || enableCrunchbaseColumns) && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            "&::before, &::after": {
                                content: '""',
                                flex: 1,
                                borderBottom: 1,
                                borderColor: "divider",
                            },
                            px: 1.5,
                            mt: 1.5,
                            mb: 1,
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                px: 1,
                                color: "primary.light",
                            }}
                        >
                            or add employees/properties
                        </Typography>
                    </Box>
                )}
                {enableApolloColumns && (
                    <HeaderGridCellLinkedEntitiesAndPropertiesButtonsSection
                        numMatchingProperties={matchingCrunchbaseProperties.length}
                        onChangeApolloSourceConfig={onChangeApolloSourceConfig}
                        onAddCompanyEmployee={handleAddCompanyEmployee}
                        colLabel={colLabel}
                        config={apolloSourceConfig}
                        onChangeColLabel={onChangeColLabel}
                        onToggleSelectPropertiesButton={handleToggleSelectPropertiesButton}
                        selectedButton={selectedButton.selectedButton}
                    />
                )}
                {enableApolloColumns && enableCrunchbaseColumns && (
                    <Divider
                        sx={{
                            mx: 1.5,
                            my: 1.5,
                        }}
                    />
                )}
                {enableCrunchbaseColumns && selectedButton.selectedButton === "properties" && (
                    <HeaderGridCellPopoverSection title="Properties" titleSx={{ px: 1.5 }}>
                        <HeaderGridCellPropertiesSelector
                            isVisible={selectedButton.selectedButton === "properties"}
                            matchingFields={matchingCrunchbaseProperties}
                            onFinishEditingHeaderColumn={handleAddProperty}
                        />
                    </HeaderGridCellPopoverSection>
                )}
                {enableCrunchbaseColumns && selectedButton.selectedButton === "properties" && (
                    <Divider sx={{ mx: 1.5, my: 1.5 }} />
                )}
                <HeaderGridCellPopoverSection title="Manual Input" titleSx={{ px: 1.5 }}>
                    <MenuItem
                        onClick={handleAddUserInputColumn}
                        sx={{
                            typography: "body2",
                            px: 1.5,
                        }}
                    >
                        <Typography variant="body2" noWrap>
                            Text column
                        </Typography>
                    </MenuItem>
                </HeaderGridCellPopoverSection>
            </Box>
        </Popover>
    );
};

function getMatchingProperties(query: string, properties: PropertyAddableAsColumn[]) {
    return query.length > 0
        ? properties.filter(property => {
              const displayName =
                  property.type === "crunchbase"
                      ? CRUNCHBASE_FIELD_DISPLAY_NAMES[property.field].displayName
                      : APOLLO_PROPERTIES_DISPLAY_NAMES[property.field];
              return matchesQuery(query, displayName);
          })
        : properties;
}

interface HeaderGridCellPopoverSectionProps {
    title: string;
    headerIcon?: React.ReactNode;
    children: React.ReactNode;
    type?: "section" | "subsection";
    sx?: SxProps<Theme>;
    titleSx?: SxProps<Theme>;
}

const HeaderGridCellPopoverSection: React.FC<HeaderGridCellPopoverSectionProps> = ({
    title,
    children,
    headerIcon,
    type = "section",
    sx,
    titleSx,
}) => {
    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    rowGap: type === "subsection" ? 0 : 0.5,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={[
                    { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(titleSx) ? titleSx : [titleSx]),
                ]}
            >
                <Typography
                    variant={type === "section" ? "body2" : "caption"}
                    sx={{ fontWeight: type === "section" ? "600" : "medium" }}
                >
                    {title}
                </Typography>
                {headerIcon}
            </Box>
            {children}
        </Box>
    );
};

interface HeaderGridCellPropertiesSelectorProps {
    isVisible: boolean;
    matchingFields: PropertyAddableAsColumn[];
    onFinishEditingHeaderColumn: (columnQuery: ColumnGeneratedBy, contextColumnIds: string[]) => void;
}

const HeaderGridCellPropertiesSelector: React.FC<HeaderGridCellPropertiesSelectorProps> = ({
    isVisible,
    matchingFields,
    onFinishEditingHeaderColumn,
}) => {
    const handleAddFieldAsColumn = React.useCallback(
        (field: PropertyAddableAsColumn) => {
            if (field.type === "crunchbase") {
                onFinishEditingHeaderColumn({ fieldId: field.field, type: "crunchbase" }, []);
            } else if (field.type === "apollo-org") {
                onFinishEditingHeaderColumn(
                    { type: "apollo_organization", field: field.field === "technologies" ? "technology" : field.field },
                    [],
                );
            }
        },
        [onFinishEditingHeaderColumn],
    );

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
                maxHeight: 200,
                overflowY: "auto",
            }}
        >
            {matchingFields.length > 0 ? (
                matchingFields.map(field => (
                    <HeaderGridCellPropertiesSelectorItem
                        key={`${field.type}-${field.field}`}
                        field={field}
                        onAddFieldAsColumn={handleAddFieldAsColumn}
                    />
                ))
            ) : (
                <Typography
                    variant="body2"
                    color="primary.light"
                    sx={{
                        px: 1.5,
                    }}
                >
                    No matching fields
                </Typography>
            )}
        </Box>
    );
};

interface HeaderGridCellPropertiesSelectorItemProps {
    field: PropertyAddableAsColumn;
    onAddFieldAsColumn: (field: PropertyAddableAsColumn) => void;
}

const HeaderGridCellPropertiesSelectorItem: React.FC<HeaderGridCellPropertiesSelectorItemProps> = ({
    field,
    onAddFieldAsColumn,
}) => {
    const handleAddField = React.useCallback(() => {
        onAddFieldAsColumn(field);
    }, [field, onAddFieldAsColumn]);

    const displayName =
        field.type === "crunchbase"
            ? CRUNCHBASE_FIELD_DISPLAY_NAMES[field.field].displayName
            : APOLLO_PROPERTIES_DISPLAY_NAMES[field.field];

    return (
        <MenuItem
            onClick={handleAddField}
            sx={{
                typography: "body2",
                px: 1.5,
            }}
        >
            <Typography variant="body2" noWrap>
                {displayName}
            </Typography>
        </MenuItem>
    );
};

interface HeaderGridCellLinkedEntitiesAndPropertiesButtonsSectionProps {
    config: Omit<ColumnGeneratedByApollo, "type">;
    colLabel: string;
    selectedButton: "properties" | undefined;
    numMatchingProperties: number;
    onChangeApolloSourceConfig: React.Dispatch<React.SetStateAction<Omit<ColumnGeneratedByApollo, "type">>>;
    onAddCompanyEmployee: (sourceConfig: Omit<ColumnGeneratedByApollo, "type">) => void;
    onChangeColLabel: (newColLabel: string) => void;
    onToggleSelectPropertiesButton: () => void;
}

const HeaderGridCellLinkedEntitiesAndPropertiesButtonsSection: React.FC<
    HeaderGridCellLinkedEntitiesAndPropertiesButtonsSectionProps
> = ({
    config,
    colLabel,
    selectedButton,
    numMatchingProperties,
    onChangeApolloSourceConfig,
    onAddCompanyEmployee,
    onChangeColLabel,
    onToggleSelectPropertiesButton,
}) => {
    const { value: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolean(false);
    const handleOpenCompanyPeopleDialog = React.useCallback(() => {
        openDialog();
    }, [openDialog]);

    const handleSubmit = React.useCallback(
        (config: Omit<ColumnGeneratedByApollo, "type">) => {
            onAddCompanyEmployee(config);
        },
        [onAddCompanyEmployee],
    );

    const theme = useTheme();

    return (
        <>
            <CompanyPeopleDialog
                isOpen={isDialogOpen}
                onClose={closeDialog}
                config={config}
                setConfig={onChangeApolloSourceConfig}
                persona="general"
                onSubmit={handleSubmit}
                colLabel={colLabel}
                onChangeColLabel={onChangeColLabel}
            />
            <Box
                sx={{
                    display: "flex",
                    columnGap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Button
                    onClick={handleOpenCompanyPeopleDialog}
                    sx={{
                        typography: "body2",
                        display: "flex",
                        alignItems: "center",
                        color: "#475467",
                        borderColor: "divider",
                        borderRadius: 8,
                    }}
                    variant="outlined"
                    startIcon={<People size={20} color="#475467" />}
                >
                    <Typography variant="body2" fontWeight="medium" noWrap>
                        {EMPLOYEE_DISPLAY_NAME}
                    </Typography>
                </Button>
                <Button
                    onClick={onToggleSelectPropertiesButton}
                    sx={{
                        typography: "body2",
                        display: "flex",
                        alignItems: "center",
                        color: selectedButton === "properties" ? theme.palette.secondary.main : "#475467",
                        borderColor: selectedButton === "properties" ? theme.palette.success.main : "divider",
                        borderWidth: selectedButton === "properties" ? 2 : undefined,
                        // WHen border width changes, we want it not to move around so hack here
                        margin: selectedButton === "properties" ? 0 : "1px",
                        borderRadius: 8,
                        "&:hover": {
                            borderColor:
                                selectedButton === "properties" ? darken(theme.palette.success.main, 0.15) : "divider",
                            borderWidth: selectedButton === "properties" ? 2 : undefined,
                        },
                    }}
                    variant="outlined"
                    startIcon={
                        <TextalignJustifycenter
                            size={20}
                            variant={selectedButton === "properties" ? "Bold" : undefined}
                            color={selectedButton === "properties" ? theme.palette.secondary.main : "#475467"}
                        />
                    }
                >
                    <Typography variant="body2" fontWeight="medium" noWrap>
                        Properties
                    </Typography>
                    <Box
                        sx={{
                            alignSelf: "flex-end",
                            bgcolor: "#F0EDE6",
                            color: "#475467",
                            width: 20,
                            borderRadius: "50%",
                            // maxHeight: 15,
                            typography: "caption",
                            fontWeight: "medium",
                            ml: 0.5,
                            // TODO: Hack
                            mr: -1,
                        }}
                    >
                        {numMatchingProperties}
                    </Box>
                </Button>
            </Box>
        </>
    );
};

// TODO: Better match algo
function matchesQuery(query: string, displayName: string) {
    return displayName.toLowerCase().includes(query.toLowerCase());
}
