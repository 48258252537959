import { useSnackbar } from "notistack";
import { getBackendService } from "../services/cb-backend";
import { Row, Column, isRealRow, isPaidUser } from "../grid/grid";
import * as React from "react";
import {
    convertToCSV,
    createAndDownloadCsv,
    filterAllowedRows,
    isCompanyRealRow,
    prepareTrackRequest,
} from "./exportToCsv";
import { useCurrentUserQuery } from "../context/userContext";

// Hook to export data to CSV
export const useExportToCSV = () => {
    const [csvData, setCsvData] = React.useState<{ csv: string; filename: string } | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const backendService = getBackendService();
    const user = useCurrentUserQuery();

    const trackAndFilterRows = React.useCallback(
        async (rows: Row[]): Promise<Row[]> => {
            const companyRows = rows.filter(isRealRow).filter(isCompanyRealRow);
            const trackRequest = prepareTrackRequest(companyRows);
            const trackResponse = await backendService.trackCompanyExport(trackRequest);
            // Pass rows here so non-company rows get included for free
            const filteredRows = filterAllowedRows(rows, trackResponse.allowed_export_rows);

            if (filteredRows.length < rows.length) {
                enqueueSnackbar(
                    `You've hit your monthly export limit of ${trackResponse.max_exportable_rows}. Contact the AnswerGrid team to upgrade.`,
                    { variant: "info", anchorOrigin: { vertical: "top", horizontal: "center" } },
                );
            }

            return filteredRows;
        },
        [backendService, enqueueSnackbar],
    );

    const exportToCSV = React.useCallback(
        async (
            rows: Row[],
            columns: Column[],
            fileName = "data.csv",
            rowCount?: number,
            maxRowsPerCompany?: number,
        ) => {
            const rowsToExport = rowCount != null ? rows.slice(0, rowCount) : rows;

            try {
                const filteredRows = await trackAndFilterRows(rowsToExport);
                const dropContactInfo =
                    user.data?.plan_type != null && !isPaidUser(user.data.plan_type) && !user.data.is_admin;
                const csvData = convertToCSV(filteredRows, columns, maxRowsPerCompany, dropContactInfo);
                setCsvData({ csv: csvData, filename: fileName });
            } catch (error) {
                console.error("Failed to track or process export:", error);
                enqueueSnackbar("Failed to process export.", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                });
            }
        },
        [trackAndFilterRows, user.data?.plan_type, user.data?.is_admin, enqueueSnackbar],
    );

    React.useEffect(() => {
        if (csvData) {
            createAndDownloadCsv(csvData.csv, csvData.filename);
            setCsvData(null); // Reset CSV data after download
        }
    }, [csvData]);

    return exportToCSV;
};
