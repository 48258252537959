import { EditableIncompleteCrunchbaseFilter } from "../home/filters/types";
import {
    DescriptionValue,
    LastEquityFundingTypeValue,
    LastFundingAtValue,
    LastFundingTotalValue,
    WebsiteURLValue,
    InvestorIdentifiersValue,
    CategoriesValue,
    CategoryGroupsValue,
    LocationGroupIdentifiersValue,
    LocationIdentifiersValue,
    OperatorsAllSuperType,
    NumEmployeesEnumValue,
    DescriptionPredicate,
    LastEquityFundingTypePredicate,
    InvestorIdentifiersPredicate,
    LocationGroupIdentifiersPredicate,
    LocationIdentifiersPredicate,
    NumEmployeesEnumPredicate,
    CategoriesPredicate,
    CategoryGroupsPredicate,
    Filter,
    LastFundingTotalPredicate,
    FundingTotalPredicate,
    FundingTotalValue,
    CreatedAtValue,
    FoundedOnPredicate,
    FoundedOnValue,
    LastFundingAtPredicate,
} from "./cb-backend-types";
import { FeSupportedCrunchbaseFieldId } from "./crunchbase";

// Operator display names and colors
export const operatorDisplayNames: Record<OperatorsAllSuperType, { displayName: string; color?: string }> = {
    blank: { displayName: "is Blank", color: "#f44336" },
    eq: { displayName: "equals", color: "#4caf50" },
    not_eq: { displayName: "not equals", color: "#ff9800" },
    contains: { displayName: "contains", color: "#3f51b5" },
    not_contains: { displayName: "not contains", color: "#e91e63" },
    starts: { displayName: "starts with" },
    includes: { displayName: "includes" },
    not_includes: { displayName: "not includes" },
    between: { displayName: "between" },
    gte: { displayName: "greater than or equal" },
    lte: { displayName: "less than or equal" },
    gt: { displayName: "greater than" },
    lt: { displayName: "less than" },
    domain_blank: { displayName: "domain is blank" },
    domain_eq: { displayName: "domain equals" },
    domain_includes: { displayName: "domain includes" },
    not_domain_eq: { displayName: "not domain equals" },
    not_domain_includes: { displayName: "not domain includes" },
    includes_all: { displayName: "includes all" },
    not_includes_all: { displayName: "not includes all" },
};

// Functions to map value to readable string
export function descriptionValueToString(value: DescriptionValue): string {
    return value;
}

const FUNDING_TYPES: Record<LastEquityFundingTypeValue, string> = {
    angel: "Angel",
    convertible_note: "Convertible Note",
    corporate_round: "Corporate Round",
    debt_financing: "Debt Financing",
    equity_crowdfunding: "Equity Crowdfunding",
    grant: "Grant",
    initial_coin_offering: "Initial Coin Offering",
    non_equity_assistance: "Non-equity Assistance",
    post_ipo_debt: "Post-IPO Debt",
    post_ipo_equity: "Post-IPO Equity",
    post_ipo_secondary: "Post-IPO Secondary",
    pre_seed: "Pre-Seed",
    private_equity: "Private Equity",
    product_crowdfunding: "Product Crowdfunding",
    secondary_market: "Secondary Market",
    seed: "Seed",
    series_a: "Series A",
    series_b: "Series B",
    series_c: "Series C",
    series_d: "Series D",
    series_e: "Series E",
    series_f: "Series F",
    series_g: "Series G",
    series_h: "Series H",
    series_i: "Series I",
    series_j: "Series J",
    series_unknown: "Venture - Series Unknown",
    undisclosed: "Undisclosed",
};

const NUM_EMPLOYEES_ENUMS: Record<NumEmployeesEnumValue, string> = {
    c_00001_00010: "1-10",
    c_00011_00050: "11-50",
    c_00051_00100: "51-100",
    c_00101_00250: "101-250",
    c_00251_00500: "251-500",
    c_00501_01000: "501-1000",
    c_01001_05000: "1001-5000",
    c_05001_10000: "5001-10000",
    c_10001_max: "10001+",
};

export function lastEquityFundingTypeValueToString(value: LastEquityFundingTypeValue): string {
    return FUNDING_TYPES[value];
}

export function numEmployeesEnumValueToString(value: NumEmployeesEnumValue): string {
    return NUM_EMPLOYEES_ENUMS[value];
}

export function lastFundingAtValueToString(value: LastFundingAtValue): string {
    return new Date(value).toLocaleDateString();
}

export function lastFundingTotalValueToString(value: LastFundingTotalValue): string {
    return `${value.currency} ${value.value.toLocaleString()}`;
}

export function fundingTotalValueToString(value: FundingTotalValue): string {
    return `${value.currency} ${value.value.toLocaleString()}`;
}

export function websiteURLValueToString(value: WebsiteURLValue): string {
    return value;
}

export function investorIdentifiersValueToString(value: InvestorIdentifiersValue): string {
    return value.value || value.permalink || value.uuid;
}

export function categoriesValueToString(value: CategoriesValue): string {
    return value.value || value.permalink || value.uuid;
}

export function categoryGroupsValueToString(value: CategoryGroupsValue): string {
    return value.value || value.permalink || value.uuid;
}

export function locationGroupIdentifiersValueToString(value: LocationGroupIdentifiersValue): string {
    return value.value || value.permalink || value.uuid;
}

export function locationIdentifiersValueToString(value: LocationIdentifiersValue): string {
    return value.value || value.permalink || value.uuid;
}

export function createdAtValueToString(value: CreatedAtValue): string {
    return value;
}

export function foundedOnValueToString(value: FoundedOnValue): string {
    return value;
}

// TODO: This is a bit nasty, we need to keep these three in sync
export const CRUNCHBASE_FILTERABLE_FIELD_IDS = [
    "description",
    "last_equity_funding_type",
    "last_funding_total",
    "funding_total",
    "investor_identifiers",
    "location_identifiers",
    "num_employees_enum",
    "categories",
    "founded_on",
    "last_funding_at",
] as const;

export type CrunchbaseFilterableFieldId = (typeof CRUNCHBASE_FILTERABLE_FIELD_IDS)[number];

export type CrunchbasePickableFilterPredicates =
    | DescriptionPredicate
    | LastEquityFundingTypePredicate
    | LastFundingTotalPredicate
    | LastFundingAtPredicate
    | FundingTotalPredicate
    | InvestorIdentifiersPredicate
    | LocationGroupIdentifiersPredicate
    | LocationIdentifiersPredicate
    | CategoriesPredicate
    | CategoryGroupsPredicate
    | NumEmployeesEnumPredicate
    | FoundedOnPredicate;

export function isCrunchbasePickableFilterPredicate(
    predicate: Filter,
): predicate is CrunchbasePickableFilterPredicates {
    return (
        predicate.field_id === "description" ||
        predicate.field_id === "last_equity_funding_type" ||
        predicate.field_id === "funding_total" ||
        predicate.field_id === "investor_identifiers" ||
        predicate.field_id === "location_group_identifiers" ||
        predicate.field_id === "location_identifiers" ||
        predicate.field_id === "categories" ||
        predicate.field_id === "category_groups" ||
        predicate.field_id === "num_employees_enum" ||
        predicate.field_id === "founded_on" ||
        predicate.field_id === "last_funding_at" ||
        predicate.field_id === "last_funding_total"
    );
}

export const INITIAL_SUPPORTED_OPERATORS_BY_FIELD_ID: Record<FeSupportedCrunchbaseFieldId, OperatorsAllSuperType[]> = {
    num_employees_enum: ["includes", "not_includes"],
    description: ["contains", "not_contains"],
    last_equity_funding_type: ["includes", "not_includes"],
    last_funding_at: ["gte", "lte"],
    last_funding_total: ["gte", "lte"],
    funding_total: ["gte", "lte"],
    website_url: ["domain_eq", "domain_includes"],
    investor_identifiers: ["includes", "not_includes"],
    categories: ["includes", "not_includes"],
    category_groups: ["includes", "not_includes"],
    location_group_identifiers: ["includes", "not_includes"],
    location_identifiers: ["includes", "not_includes"],
    created_at: ["gte", "lte"],
    founded_on: ["gte", "lte"],
    // TODO: THIS IS NOT RIGHT
    rank: ["gte", "lte"],
    linkedin: ["domain_eq", "domain_includes"],
};
export const EMPTY_FILTER: Omit<EditableIncompleteCrunchbaseFilter, "persona"> = {
    isEditing: true,
    addAsColumn: true,
    filter: { type: "predicate" },
};
