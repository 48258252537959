export interface PromptExample {
    title: string;
    prompt: string;
}

const SELF_SERVE_WEBSITE_DESCRIPTION: PromptExample = {
    title: "Description from Website (Onboarding)",
    prompt: `Given the screenshot of the company's landing page, describe what options a customer would have for getting started with the product and onboarding.
Pay attention to:
- Mention of pricing tiers
- Call to actions for getting started such as "Sign Up", "Get Started", "Try for Free", "Download Now" etc`,
};

const SELF_SERVE_EVAL: PromptExample = {
    title: "Self-Serve Onboarding?",
    prompt: `Based on the given context, which describes the onboarding options a company offers to its customers on its website, determine if the company offers ANY self-serve tier (i.e., can be accessed directly by users without needing to speak to anyone) or if a demo or sales consultation is strongly required before accessing the product at all. Focus on identifying clear indicators of immediate access options versus required intermediary steps.
To do so, you should use the following rules of thumb when looking at the company’s website:
Look for call to actions asking a user to sign up, Get Started, Try for Free, or download some application, etc that could lead directly to account creation.
The above list of examples is not exhaustive, so consider other similar calls to action.
Check for the presence of a free trial option that doesn’t require speaking to sales.
Search for self-service pricing plans that can be purchased directly from the website.
Note that some products may offer both self-serve and an Enterprise option which requires a demo or talking to sales- focus on whether there is at least ONE self-serve option.
Structure your output as a likelihood value, either very likely, likely, uncertain, not likely or very unlikely, followed by a newline and then the reasoning, i.e., one or more specific reasons from the provided context that led to your assessment.
Example output 1:
Likely.
Acme corp has a clear “Get Started” call to action which suggests there is at least a product tier that allows users to self-serve.
Don’t include any markdown syntax.`,
};

const AI_WEBSITE_DESCRIPTION: PromptExample = {
    title: "Description from Website",
    prompt: `Describe what the company does based on their landing page.
Pay particular attention to anything mentioning AI, agents, large language models (LLMs) or automation`,
};

const LLM_PROMPT_EXAMPLE: PromptExample = {
    title: "Does the company's product use LLMs?",
    prompt: `Based on the given context which describes what the company does with a short description and a summary of their landing page, determine if the company uses large language models in their primary product or service. However, you must make sure to not flag companies that offer infrastructure, devtools, libraries or otherwise support the development of agents/LLMs, rather than using them in their own product. Only use content in the context to make your decision, and do not use your prior knowledge.
    To do so, you should use the following rules of thumb:
    - Do not mark very likely or likely for companies that offer infrastructure for AI, such as devops for LLMs, AI infrastructure, libraries for AI, places to deploy AI etc.. Instead, focus on companies who use LLMs in their main product offering. This is super important. Even if the company matches any of the rules below, if they are infrastructure/devops/libraries etc., you must not mark them as likely or very likely.
    - Generally, AI agents or agents refer to using LLMs to automate some task, so this is a strong indicator.
    - Based on your understanding of AI and LLMs, you may also use other facts about the company in the context to determine if they appear to be using LLMs
    - Generally, LLMs are not used for computer vision/robotics and similar tasks. However, there may be exceptions.
    - Hardware companies generally don't use LLMs.
    - LLMs are often synonymous with generative AI (gen-AI). GPT, transformers, OpenAI, and semantic search and RAG are also terms strongly indicative of LLMs. If any of these terms are used in relation to their product offering, the result should be at least "likely"
    Structure your output as a likelihood value, either very likely, likely, uncertain, not likely or very unlikely, followed by a newline and then the reasoning i.e. one one or more specific reasons from the context that indicate that they use LLMs.
    Example output 1:
    Likely.
    Acme corp mentions the use of advanced automating AI agents to process your invoice claims.
    Don't include any markdown syntax.`,
};

const EMAIL_PROMPT_EXAMPLE: PromptExample = {
    title: "Sales email",
    prompt: `You are a sales expert.
Write a very short outreach sales email to a provided employee at a company.
Say Hi to the employee’s name.
Mention your interest in what they do.
Then, briefly describe what your company does, and ask if they would be interested in chatting about the solution you provide.
Here’s what your company does:
Company name: [COMPANY NAME]
Description: [COMPANY DESCRIPTION]

DO NOT INCLUDE ANYTHING ELSE BESIDES THE MESSAGE BODY. DO NOT INCLUDE THE SUBJECT.
IF ANY INFORMATION FROM THE TEMPLATE IS UNAVAILABLE, DO NOT INCLUDE IT.
DO NOT INCLUDE PLACEHOLDERS LIKE “Your Company”, “Our Company”, “Your Name”, “Employee Name”, or "[Employee’s Name]" because your response will be sent directly to the recipient and you do not want them to think it was generated by an LLM.
DO not include a concluding salute with a name.
THE OUTPUT SHOULD BE NO LONGER THAN 4 SENTENCES`,
};

const EXTRACT_YES_NO_PROMPT_EXAMPLE: PromptExample = {
    title: "Extract Yes/No",
    prompt: `Extract a “Yes” or a “No” from the likelihood. Only output “Yes” or “No” with no quotes. Mapping:
Very likely - Yes
Likely - Yes
Uncertain - No
Not likely - No
Very unlikely - No
N/A - No`,
};

export const PROMPT_EXAMPLES: PromptExample[] = [
    SELF_SERVE_WEBSITE_DESCRIPTION,
    SELF_SERVE_EVAL,
    EMAIL_PROMPT_EXAMPLE,
    AI_WEBSITE_DESCRIPTION,
    LLM_PROMPT_EXAMPLE,
    EXTRACT_YES_NO_PROMPT_EXAMPLE,
];
