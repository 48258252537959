import * as React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { FAVICON_SIZE, getFavIconUrl } from "./utils";
import { Citation } from "../grid/grid";

export interface CitationsFooterProps {
    citations: Citation[];
}

export const CitationsFooter: React.FC<CitationsFooterProps> = ({ citations }) => {
    if (citations.length === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "grey.100",
                alignItems: "center",
                columnGap: 1,
                py: 0.5,
                px: 1,
                borderRadius: 1,
            }}
        >
            {citations.slice(0, 2).map((citation, idx) => (
                <Avatar
                    key={idx}
                    src={getFavIconUrl(citation.link)}
                    sx={{ width: FAVICON_SIZE, height: FAVICON_SIZE }}
                    alt="Site Icon"
                />
            ))}
            <Typography variant="caption">{citations.length > 2 ? `+${citations.length - 2} more` : ""}</Typography>
        </Box>
    );
};
