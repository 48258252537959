import * as React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import { getBackendService, isLoggedIn } from "./services/cb-backend";
import { useSnackbar } from "notistack";
import { TickCircle, CloseCircle } from "iconsax-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCurrentUserQuery } from "./context/userContext";
import { CURRENT_USER_KEY } from "./react-query/queryKeys";
import { Helmet } from "react-helmet-async";

export const VerifyEmail: React.FC = () => {
    const backendService = React.useMemo(() => getBackendService(), []);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const user = useCurrentUserQuery();

    const queryClient = useQueryClient();
    const [isRedirecting, setIsRedirecting] = React.useState(false);
    const {
        mutate: verifyEmail,
        isIdle,
        isPending,
        isError,
    } = useMutation({
        mutationFn: (token: string) => backendService.verifyEmail(token),
        onSuccess: () => {
            try {
                if (isLoggedIn()) {
                    void backendService.logout();
                }
                void queryClient.invalidateQueries({ queryKey: CURRENT_USER_KEY });
            } finally {
                setIsRedirecting(true);
                setTimeout(() => {
                    navigate("/login?welcome", { replace: true });
                }, 1000);
            }
        },
        onError: error => {
            console.error("Email verification failed:", error);
        },
    });

    const token = searchParams.get("token");

    const hasVerifiedEmailRef = React.useRef(false);

    React.useEffect(() => {
        if (
            isIdle &&
            !user.isLoading &&
            (user.data == null || user.data.plan_type === "trial" || !user.data.is_email_verified) &&
            token != null &&
            !hasVerifiedEmailRef.current
        ) {
            hasVerifiedEmailRef.current = true;
            verifyEmail(token);
        } else if (token == null) {
            enqueueSnackbar("Invalid verification link", { variant: "error" });
        }
    }, [enqueueSnackbar, isIdle, token, user.data, user.isLoading, verifyEmail]);

    const hasAlreadyVerifiedEmail = user.data?.is_email_verified && user.data.plan_type !== "trial";

    return (
        <>
            <Helmet>
                <title>Verify Email - AnswerGrid</title>
            </Helmet>
            <Container
                component="main"
                maxWidth="xs"
                sx={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {hasAlreadyVerifiedEmail && (
                        <>
                            <TickCircle color={theme.palette.success.main} size={48} variant="Bold" />
                            <Typography variant="h6" align="center">
                                You have already verified your email.
                            </Typography>
                        </>
                    )}
                    {isPending && !isRedirecting && (
                        <>
                            <CircularProgress color="secondary" />
                            <Typography variant="h6" align="center">
                                Verifying your email…
                            </Typography>
                        </>
                    )}
                    {/* TODO: ISsuccess doesn't work for some reason */}
                    {isRedirecting && (
                        <>
                            <TickCircle color={theme.palette.success.main} size={48} variant="Bold" />
                            <Typography variant="h6" align="center">
                                Email verified successfully!
                            </Typography>
                            <Typography variant="body1" align="center">
                                Redirecting to login page…
                            </Typography>
                        </>
                    )}
                    {user.data != null && !hasAlreadyVerifiedEmail && isError && (
                        <>
                            <CloseCircle color={theme.palette.error.main} size={48} variant="Bold" />
                            <Typography variant="h6" align="center">
                                Email verification failed
                            </Typography>
                            <Typography variant="body1" align="center">
                                Please try again or contact support at founders@answergrid.ai.
                            </Typography>
                        </>
                    )}
                </Box>
            </Container>
        </>
    );
};
