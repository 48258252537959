import Box from "@mui/material/Box";
import * as React from "react";
import "../App.css";
import { Sidebar } from "./sidebar";

export interface SidebarLayoutProps {
    children: React.ReactNode;
    selectedGridId: string | undefined;
    footer?: React.ReactNode;
}

export const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children, selectedGridId, footer }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "stretch",
                height: "100vh",
                overflowY: "hidden",
                maxWidth: "100vw",
                backgroundColor: "#F4F2EF",
            }}
        >
            <Sidebar selectedGridId={selectedGridId} />
            <Box
                sx={{
                    mt: 2,
                    mr: 2,
                    mb: 2,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    alignItems: "stretch",
                }}
            >
                {children}
                {footer}
            </Box>
        </Box>
    );
};
