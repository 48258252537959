import * as React from "react";
import { Link, Typography, Box } from "@mui/material";
import { getBackendService } from "../services/cb-backend";
import { useNavigate } from "react-router-dom";
import { useTrackPageView } from "../mixpanel";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { Sms } from "iconsax-react";
import { FormTextField } from "./formTextField";
import { Helmet } from "react-helmet-async";

export const ForgotPassword: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Forgot Password - AnswerGrid</title>
            </Helmet>
            <UserAdministrationLayout imageUrl="https://AnswerGrid.b-cdn.net/field-of.png">
                <ForgotPasswordContent />
            </UserAdministrationLayout>
        </>
    );
};

const ForgotPasswordContent: React.FC = () => {
    useTrackPageView();
    const acmeService = React.useMemo(() => getBackendService(), []);
    const [error, setError] = React.useState<string | null>(null);
    const [email, setEmail] = React.useState("");

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const forgotPasswordMutation = useMutation({
        mutationFn: async (data: { email: string }) => acmeService.forgotPassword(data),
        onSuccess: () => {
            enqueueSnackbar("Password reset instructions sent to your email.", { variant: "success" });
            navigate("/login", { replace: true });
        },
        onError: (error: Error) => {
            console.error("Forgot password failed:", error);
            setError(error.message);
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await forgotPasswordMutation.mutateAsync({ email });
        },
        [forgotPasswordMutation, email],
    );

    const handleForgotPassword = React.useCallback(async () => {
        await forgotPasswordMutation.mutateAsync({ email });
    }, [forgotPasswordMutation, email]);

    const handleChangeEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    return (
        <UserAdministrationForm
            title="Forgot Password"
            actionButtonText="Reset Password"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleForgotPassword}
            loading={forgotPasswordMutation.isPending}
            underButtonContent={
                <Typography variant="body2">
                    Remember your password?{" "}
                    <Link href="/login" color="secondary" sx={{ textDecoration: "none", fontWeight: "medium" }}>
                        Sign in →
                    </Link>
                </Typography>
            }
        >
            <Box
                component="form"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, display: "flex", flexDirection: "column" }}
            >
                <FormTextField
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleChangeEmail}
                    icon={<Sms size={20} />}
                />
                {error && (
                    <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </UserAdministrationForm>
    );
};
