import * as React from "react";
import { ReactComponent as GlobalSearchIcon } from "../assets/global-search.svg";
import { PERSONA_OPTIONS, SearchPersona } from "./personas";
import { Box, Typography, Tooltip } from "@mui/material";
import { Building } from "iconsax-react";

export interface PersonaPickerProps {
    selectedPersona: SearchPersona;
    onSelectPersona: (persona: SearchPersona) => void;
}

export const PersonaPicker: React.FC<PersonaPickerProps> = ({ selectedPersona, onSelectPersona }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: 7,
                mb: 3,
            }}
        >
            {PERSONA_OPTIONS.map(persona => (
                <SelectablePersona
                    key={persona}
                    isSelected={persona === selectedPersona}
                    selectedPersona={persona}
                    onSelectPersona={onSelectPersona}
                />
            ))}
        </Box>
    );
};

interface SelectablePersonaProps {
    isSelected: boolean;
    selectedPersona: SearchPersona;
    onSelectPersona: (persona: SearchPersona) => void;
}

const SelectablePersona: React.FC<SelectablePersonaProps> = ({ isSelected, selectedPersona, onSelectPersona }) => {
    const disabled = selectedPersona === "general";
    const color = getPersonaIconColor(isSelected, disabled);
    const handleSelectPersona = React.useCallback(() => {
        onSelectPersona(selectedPersona);
    }, [onSelectPersona, selectedPersona]);

    const content = (
        <Box
            aria-disabled={disabled}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: !disabled ? "pointer" : "not-allowed",
                "&:hover": {
                    color: !disabled ? "#0071E3" : undefined,
                },
                color: color,
            }}
            onClick={!disabled ? handleSelectPersona : undefined}
        >
            <SelectablePersonaIcon selectedPersona={selectedPersona} color={color} />
            <Typography noWrap variant="body2" style={{ color: color }}>
                {DISPLAY_NAME_BY_PERSONA[selectedPersona]}
            </Typography>
        </Box>
    );

    if (disabled) {
        return (
            <Tooltip
                title={
                    <>
                        Have a research use case you&apos;d like to talk about?{" "}
                        <a
                            href="https://calendly.com/bolu-answergrid/30-minute-meeting-clone"
                            target="_blank"
                            style={{ color: "inherit" }}
                            rel="noreferrer"
                        >
                            Schedule a call
                        </a>
                    </>
                }
                placement="bottom"
            >
                {content}
            </Tooltip>
        );
    }

    return content;
};

function getPersonaIconColor(isSelected: boolean, disabled: boolean): string {
    if (disabled) {
        return "#8F95A3";
    }
    return isSelected ? "#0071E3" : "#667085";
}

interface SelectablePersonaIconProps {
    selectedPersona: SearchPersona;
    color: string;
}

const SelectablePersonaIcon: React.FC<SelectablePersonaIconProps> = ({ selectedPersona, color }) => {
    switch (selectedPersona) {
        case "companies": {
            return <Building variant="Bold" color={color} />;
        }
        case "general": {
            return <GlobalSearchIcon />;
        }
    }
};

const DISPLAY_NAME_BY_PERSONA: Record<SearchPersona, string> = {
    companies: "Companies",
    general: "Anything",
};
