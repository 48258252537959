import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { Button, Box, Typography, TextField, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { useSnackbar } from "notistack";
import { Lock } from "iconsax-react";
import { useCurrentUserQuery } from "../context/userContext";
import { Helmet } from "react-helmet-async";

export const ChangePassword: React.FC = () => {
    const backendService = React.useMemo(() => getBackendService(), []);
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const user = useCurrentUserQuery();

    const [isRedirecting, setIsRedirecting] = React.useState(false);

    React.useEffect(() => {
        if (isRedirecting) {
            return;
        }
        if ((user.data != null && user.data.plan_type === "trial") || !isLoggedIn()) {
            setIsRedirecting(true);
            enqueueSnackbar("Please login to change your password", { variant: "info" });
            navigate("/login");
        }
    }, [user.data, navigate, isRedirecting, enqueueSnackbar]);

    const changePasswordMutation = useMutation({
        mutationFn: (data: { oldPassword: string; newPassword: string }) =>
            backendService.changePassword({ old_password: data.oldPassword, new_password: data.newPassword }),
        onSuccess: () => {
            console.log("Password change successful");
            enqueueSnackbar("Password changed successfully", { variant: "success" });
            navigate("/home");
        },
        onError: error => {
            console.error("Password change failed:", error);
            setError("Password change failed.");
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const oldPassword = data.get("old_password") as string;
            const newPassword = data.get("new_password") as string;
            const confirmNewPassword = data.get("confirm_new_password") as string;

            if (newPassword !== confirmNewPassword) {
                setError("New passwords do not match.");
                return;
            }

            await changePasswordMutation.mutateAsync({ oldPassword, newPassword });
        },
        [changePasswordMutation],
    );

    return (
        <>
            <Helmet>
                <title>Change Password - AnswerGrid</title>
            </Helmet>
            <Container component="main" maxWidth="xs">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <ChangePasswordForm error={error} onSubmit={handleSubmit} />
            </Container>
        </>
    );
};

const ChangePasswordForm: React.FC<{
    error: string | null;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}> = ({ error, onSubmit }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Lock color={theme.palette.secondary.main} size={30} />
            <Typography component="h1" variant="h5">
                Change Password
            </Typography>
            <Box
                component="form"
                onSubmit={onSubmit}
                noValidate
                sx={{ mt: 2, width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
            >
                <TextField
                    hiddenLabel
                    required
                    fullWidth
                    name="old_password"
                    placeholder="Current Password"
                    type="password"
                    id="old_password"
                    autoComplete="current-password"
                />
                <TextField
                    hiddenLabel
                    required
                    fullWidth
                    name="new_password"
                    placeholder="New Password"
                    type="password"
                    id="new_password"
                    autoComplete="new-password"
                />
                <TextField
                    hiddenLabel
                    required
                    fullWidth
                    name="confirm_new_password"
                    placeholder="Confirm New Password"
                    type="password"
                    id="confirm_new_password"
                    autoComplete="new-password"
                />
                {error && (
                    <Typography variant="body2" color="error" align="center">
                        {error}
                    </Typography>
                )}
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Change Password
                </Button>
            </Box>
        </Box>
    );
};
