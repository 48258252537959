import { Box, Typography } from "@mui/material";
import { BeRowGeneratingFilter, OperatorsAllSuperType } from "../services/cb-backend-types";
import { CRUNCHBASE_FIELD_DISPLAY_NAMES } from "../services/crunchbase";
import * as React from "react";
import {
    categoriesValueToString,
    createdAtValueToString,
    descriptionValueToString,
    investorIdentifiersValueToString,
    lastEquityFundingTypeValueToString,
    lastFundingAtValueToString,
    lastFundingTotalValueToString,
    locationGroupIdentifiersValueToString,
    locationIdentifiersValueToString,
    fundingTotalValueToString,
    numEmployeesEnumValueToString,
    websiteURLValueToString,
} from "../services/filterUtils";

export interface HeaderFilterBarFilterProps {
    filter: BeRowGeneratingFilter;
}

export const HeaderFilterBarFilter: React.FC<HeaderFilterBarFilterProps> = ({ filter }) => {
    return (
        <Box
            sx={{
                bgcolor: "#EAE4D7",
                borderRadius: 2,
                typography: "body2",
                px: 1,
                py: 0.5,
                display: "flex",
                columnGap: 0.5,
                alignItems: "center",
                maxWidth: 350,
            }}
        >
            <Typography
                variant="body2"
                noWrap
                sx={{
                    flexShrink: 0,
                }}
            >
                {CRUNCHBASE_FIELD_DISPLAY_NAMES[filter.predicate.field_id].displayName}
            </Typography>
            <OperatorIcon operator={filter.predicate.operator_id} />
            <FilterBarValues filter={filter} />
        </Box>
    );
};

interface OperatorIconProps {
    operator: OperatorsAllSuperType;
}

export const OperatorIcon: React.FC<OperatorIconProps> = ({ operator }) => {
    switch (operator) {
        case "between":
        case "blank":
        case "domain_blank":
        case "starts":
            // TODO: Render something once we start allowing
            return null;
        case "contains":
        case "domain_includes":
        case "includes":
        case "includes_all":
            return <Box>{"="}</Box>;
        case "not_domain_includes":
        case "not_contains":
        case "not_includes":
        case "not_includes_all":
            return <Box>{"≠"}</Box>;
        case "eq":
        case "domain_eq":
            return <Box>{"="}</Box>;
        case "gt":
            return <Box>{">="} </Box>;
        case "gte":
            return <Box>{">="}</Box>;
        case "lt":
            return <Box>{"<"} </Box>;
        case "lte":
            return <Box>{"<="}</Box>;
        case "not_domain_eq":
        case "not_eq":
            return <Box>{"≠"}</Box>;
    }
};

interface FilterBarValuesProps {
    filter: BeRowGeneratingFilter;
}

export const FilterBarValues: React.FC<FilterBarValuesProps> = ({ filter }) => {
    const typographySx = {
        // color: "#667085",
        whiteSpace: "pre",
        textOverflow: "ellipsis",
        typography: "body2",
        fontWeight: "600",
    } as const;
    switch (filter.predicate.field_id) {
        case "description":
            return (
                <Typography sx={typographySx} noWrap>
                    {descriptionValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "investor_identifiers":
            return (
                <Typography sx={typographySx} noWrap>
                    {investorIdentifiersValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "categories":
            return (
                <Typography sx={typographySx} noWrap>
                    {categoriesValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "category_groups":
            return (
                <Typography sx={typographySx} noWrap>
                    {categoriesValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "last_equity_funding_type":
            return (
                <Typography sx={typographySx} noWrap>
                    {lastEquityFundingTypeValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "location_identifiers":
            return (
                <Typography sx={typographySx} noWrap>
                    {locationIdentifiersValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "last_funding_at":
            return (
                <Typography sx={typographySx} noWrap>
                    {lastFundingAtValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "last_funding_total":
            return (
                <Typography sx={typographySx} noWrap>
                    {lastFundingTotalValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "funding_total":
            return (
                <Typography sx={typographySx} noWrap>
                    {fundingTotalValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "location_group_identifiers":
            return (
                <Typography sx={typographySx} noWrap>
                    {locationGroupIdentifiersValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "num_employees_enum":
            return (
                <Typography sx={typographySx} noWrap>
                    {numEmployeesEnumValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "website_url":
            return (
                <Typography sx={typographySx} noWrap>
                    {websiteURLValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "created_at":
            return (
                <Typography sx={typographySx} noWrap>
                    {createdAtValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );
        case "founded_on":
            return (
                <Typography sx={typographySx} noWrap>
                    {createdAtValueToString(filter.predicate.values[0])}
                    {filter.predicate.values.length > 1 && ` +${filter.predicate.values.length - 1}`}
                </Typography>
            );

        case undefined:
            return null;
    }
};
