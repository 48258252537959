import * as React from "react";
import { Input } from "@mui/material";
import { AnswerGridCell } from "./AnswerGridCell";
import { GridCellValue } from "./gridCellValue";
import { CellValueV2String, LoadingStatus } from "./grid";

export interface EditableNewRowCellProps {
    autoFocus: boolean;
    align: "left" | "right" | "center" | "justify" | "inherit" | undefined;
    bannedValues: Set<string>;
    colId: "name";
    columnLoadingStatus: LoadingStatus | undefined;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    isLargeStyle: boolean;
    value: string;
    onChangeValue: (val: string) => void;
    onBlur?: () => void;
    onFinishEditingCellValue: (colId: "name", value: string) => Promise<void>;
}

export const EditableNewRowCell: React.FC<EditableNewRowCellProps> = ({
    autoFocus,
    align,
    bannedValues,
    colId,
    columnLoadingStatus,
    isFirstColumn,
    isLastColumn,
    isLargeStyle,
    value,
    onChangeValue,
    onBlur,
    onFinishEditingCellValue,
}) => {
    const [editMode, setEditMode] = React.useState(true);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => onChangeValue(e.target.value),
        [onChangeValue],
    );
    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (bannedValues.has(value) || value.trim() === "") {
                onChangeValue("");
                return;
            }
            void onFinishEditingCellValue(colId, value);
            setEditMode(false);
        }
    };

    const handleBlur = React.useCallback(() => onBlur?.(), [onBlur]);

    const valueAsCellValueV2 = React.useMemo((): CellValueV2String => ({ type: "string", value }), [value]);

    return (
        <AnswerGridCell
            align={align}
            sx={{ cursor: editMode ? "text" : "pointer", bgcolor: editMode ? "white" : undefined }}
            isFirstColumn={isFirstColumn}
            isLastColumn={isLastColumn}
            isLargeStyle={isLargeStyle}
        >
            {editMode && columnLoadingStatus == null ? (
                <Input
                    disableUnderline
                    autoFocus={autoFocus}
                    type="text"
                    sx={{ width: 1, maxWidth: 200, typography: "body2" }}
                    placeholder="Enter value"
                    value={value}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                />
            ) : (
                <GridCellValue value={valueAsCellValueV2} loadingStatus={columnLoadingStatus} />
            )}
        </AnswerGridCell>
    );
};
