export const EMPLOYEE_DISPLAY_NAME = "Employees";
export const EMPLOYEE_SEARCH_TERMS = [
    "employees",
    "employee",
    "people",
    "person",
    "founder",
    "ceo",
    "cto",
    "cmo",
    "cro",
    "cfo",
    "coo",
    "head",
    "VP",
    "director",
    "manager",
    "chief",
];
