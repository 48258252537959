import * as React from "react";
import { Box, Divider, SxProps, Theme } from "@mui/material";
import { Add } from "iconsax-react";
import { SearchPersona } from "../personas";
import { EMPTY_FILTER } from "../../services/filterUtils";
import { EditableIncompleteCrunchbaseFilter, EditableCrunchbaseFilter, isCompleteCrunchbaseFilter } from "./types";
import ButtonWithDisabledTooltip from "../../reusable/buttonWithDisabledTooltip";
import { EditableFilterComponent } from "./editableFilterComponent";
import { useState } from "react";

interface FiltersProps {
    newFilter: EditableIncompleteCrunchbaseFilter;
    setNewFilter: React.Dispatch<React.SetStateAction<EditableIncompleteCrunchbaseFilter>>;
    filters: EditableCrunchbaseFilter[];
    setFilters: React.Dispatch<React.SetStateAction<EditableCrunchbaseFilter[]>>;
    persona: SearchPersona;
    sx?: SxProps<Theme>;
    mode?: "create" | "edit";
}

export const Filters: React.FC<FiltersProps> = ({
    newFilter,
    setNewFilter,
    filters,
    setFilters,
    persona,
    sx,
    mode = "create",
}) => {
    const [showNewFilter, setShowNewFilter] = useState(mode === "create" || filters.length === 0);

    const handleAddFilter = React.useCallback(() => {
        if (!isCompleteCrunchbaseFilter(newFilter.filter)) {
            return;
        }
        const completeFilterValue = newFilter.filter;
        setFilters(prevFilters => [...prevFilters, { ...newFilter, filter: completeFilterValue, isEditing: false }]);
        setNewFilter({
            ...EMPTY_FILTER,
            persona,
        });
    }, [newFilter, persona, setFilters, setNewFilter]);

    const onFilterChange = React.useCallback(
        (index: number, newFilter: EditableCrunchbaseFilter) => {
            setFilters(fs => {
                const newFilters = fs.slice();
                newFilters[index] = newFilter;
                // If the new filter is being set to editing mode, collapse any other filter that's currently being edited
                if (newFilter.isEditing) {
                    newFilters.forEach((filter, i) => {
                        if (i !== index && filter.isEditing) {
                            newFilters[i] = { ...filter, isEditing: false };
                        }
                    });
                }
                return newFilters;
            });
        },
        [setFilters],
    );

    const handleDeleteFilter = React.useCallback(
        (index: number) => {
            setFilters(fs => fs.filter((_, i) => i !== index));
        },
        [setFilters],
    );

    const handleEditNewFilter = React.useCallback(
        (_idx: number, newFilter: EditableIncompleteCrunchbaseFilter) => setNewFilter(newFilter),
        [setNewFilter],
    );

    const handleAddFilterClick = React.useCallback(() => {
        if (mode === "edit" && !showNewFilter) {
            setShowNewFilter(true);
        } else {
            handleAddFilter();
            if (mode === "edit") {
                setShowNewFilter(false);
            }
        }
    }, [mode, handleAddFilter, showNewFilter]);

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    alignItems: "stretch",
                    flexGrow: 1,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    alignItems: "stretch",
                    flexGrow: 1,
                }}
            >
                {filters.map((filter, index) => (
                    <EditableFilterComponent
                        key={index}
                        filter={filter}
                        index={index}
                        handleDeleteFilter={handleDeleteFilter}
                        onFilterChange={onFilterChange}
                        actionButtonWhileEditing="confirm"
                    />
                ))}
                {filters.length > 0 && (
                    <Divider
                        sx={{
                            my: 1,
                        }}
                    />
                )}
                {(mode === "create" || showNewFilter) && (
                    <EditableFilterComponent
                        key={filters.length + 1}
                        filter={newFilter}
                        index={filters.length}
                        onFilterChange={handleEditNewFilter}
                        actionButtonWhileEditing="reset"
                    />
                )}
            </Box>
            <ButtonWithDisabledTooltip
                disabledTooltip={
                    mode === "edit" && showNewFilter && !isCompleteCrunchbaseFilter(newFilter.filter)
                        ? "Finish editing the filter before adding it"
                        : undefined
                }
                onClick={handleAddFilterClick}
                startIcon={<Add size={16} />}
                variant="outlined"
                size="small"
                sx={{
                    borderRadius: 8,
                    alignSelf: "start",
                    color: "#475467",
                    textTransform: "none",
                }}
            >
                Add filter
            </ButtonWithDisabledTooltip>
        </Box>
    );
};
