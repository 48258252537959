import Box from "@mui/material/Box";
import * as React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";

export interface UserAdministrationLayoutProps {
    children: React.ReactNode;
    imageUrl: string;
}

export const UserAdministrationLayout: React.FC<UserAdministrationLayoutProps> = ({ children, imageUrl }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                minHeight: "100vh",
                backgroundColor: "#F4F2EF",
                flexShrink: 1,
            }}
        >
            <Box
                sx={{
                    py: 1,
                    px: 4,
                    display: "flex",
                    alignItems: "center",
                    minHeight: 0,
                    textDecoration: "none",
                    flexGrow: 0,
                }}
            >
                <Box
                    component="a"
                    href="/home"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                    }}
                >
                    <Logo
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                    <Typography
                        variant="h6"
                        className="AG-font"
                        sx={{
                            ml: 0.85,
                            mb: -0.5,
                            color: "secondary.main",
                            fontFamily: "Garamond-Light-Cond",
                            fontWeight: 500,
                            fontSize: "24px",
                        }}
                    >
                        AnswerGrid
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    backgroundColor: "white",
                    borderRadius: 4,
                    mx: 2,
                    minHeight: 0,
                    flexGrow: 1,
                    position: "relative",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                        justifyContent: "stretch",
                        flex: 1,
                        paddingRight: 4,
                        mb: "10%",
                    }}
                >
                    {children}
                </Box>
                <Box
                    component="img"
                    src={imageUrl}
                    alt="Side Image"
                    sx={{
                        width: 266,
                        p: 0.8,
                    }}
                />
            </Box>
        </Box>
    );
};
