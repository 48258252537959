import { once } from "lodash-es";
import {
    AnswerIssueRequest,
    AnswerIssueResponse,
    BatchQueryCollectionRequest,
    BatchQueryCollectionResponse,
    LoginResponse,
    GridCreateRequest,
    GridResponse,
    GridUpdateRequest,
    GridGetAvailableResponse,
    GridBatchGetRequest,
    GridBatchGetResponse,
    GridBuildWithQueryRequest,
    BeGrid,
    GridBuildWithQueryAndFiltersRequest,
    GridFillUpToNRowsRequest,
    GetCurrentUserResponse,
    SignUpRequest,
    CheckTaskStatusResponse,
    CancelTaskResponse,
    CrunchbaseAutocompleteRequest,
    CrunchbaseAutocompleteResponse,
    CrunchbaseAutoCompleteCollectionId,
    CreateTrialUserRequest,
    CreateTrialUserResponse,
    ChangeGridFiltersRequest,
    ChangeGridFiltersResponse,
    ApolloTitleSearchRequest,
    ApolloTitleSearchResponse,
    ShareGridResponse,
    CreateInvitationCheckoutSessionRequest,
    CreateInvitationCheckoutSessionResponse,
    GetInvitationStatusResponse,
    GridPatchRequest,
    TrackCompanyExportRequest,
    TrackCompanyExportResponse,
    CellLevelBatchQueryRequest,
    CreateGridFromCsvRequest,
    CreateGridFromCsvResponse,
    MarkColumnAsWebsiteRequest,
    MarkColumnAsWebsiteResponse,
    ChangePasswordRequest,
    ChangePasswordResponse,
    SelfServiceSignUpRequest,
    SelfServiceSignUpResponse,
    VerifyEmailResponse,
    CreateSelfServiceCheckoutSessionRequest,
    CreateSelfServiceCheckoutSessionResponse,
    FreeTrialUsageResponse,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    PivotGridRequest,
    PivotGridResponse,
} from "./cb-backend-types";

export class VersionConflictError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "VersionConflictError";
    }
}

export class RatelimitError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "RatelimitError";
    }
}

const RENDER_BASE_URL = "https://api.answergrid.ai";
const AUTH_TOKEN_KEY = "contextbase-auth-token";

export function getToken(): string | undefined {
    return localStorage.getItem(AUTH_TOKEN_KEY) ?? undefined;
}

export function isLoggedIn(): boolean {
    return getToken() != null;
}

export abstract class AbstractBackendService {
    public abstract login(username: string, password: string): Promise<LoginResponse>;
    public abstract logout(): Promise<void>;
    public abstract signup(request: SignUpRequest): Promise<void>;
    public abstract createInvitationCheckoutSession(
        request: CreateInvitationCheckoutSessionRequest,
    ): Promise<CreateInvitationCheckoutSessionResponse>;
    public abstract createTrialUser(request: CreateTrialUserRequest): Promise<CreateTrialUserResponse>;
    public abstract crunchbaseAutocomplete(
        query: string,
        collection_ids?: CrunchbaseAutoCompleteCollectionId[],
    ): Promise<CrunchbaseAutocompleteResponse>;
    public abstract getCurrentUser(): Promise<GetCurrentUserResponse | undefined>;
    public abstract getFreeTrialUsage(): Promise<FreeTrialUsageResponse>;
    public abstract batchQuery(request: BatchQueryCollectionRequest): Promise<BatchQueryCollectionResponse>;
    public abstract createGridFromCsv(request: CreateGridFromCsvRequest): Promise<CreateGridFromCsvResponse>;
    public abstract createAnswerIssue(request: AnswerIssueRequest): Promise<AnswerIssueResponse>;
    public abstract createGrid(request: GridCreateRequest): Promise<GridResponse>;
    public abstract duplicateGrid(unique_id: string): Promise<GridResponse>;
    public abstract shareGrid(unique_id: string): Promise<ShareGridResponse>;
    public abstract updateGrid(unique_id: string, request: GridUpdateRequest): Promise<GridResponse>;
    public abstract patchGrid(unique_id: string, request: GridPatchRequest): Promise<GridResponse>;
    public abstract patchGridOnUnload(unique_id: string, request: GridPatchRequest): void;
    public abstract updateGridOnUnload(unique_id: string, request: GridUpdateRequest): void;
    public abstract deleteGrid(unique_id: string): Promise<void>;
    public abstract getAvailableGrids(): Promise<GridGetAvailableResponse>;
    public abstract batchGetGrids(request: GridBatchGetRequest): Promise<GridBatchGetResponse>;
    public abstract buildCompanyGridWithQuery(request: GridBuildWithQueryRequest): Promise<GridResponse>;
    public abstract buildCompanyGridWithQueryAndFilters(
        request: GridBuildWithQueryAndFiltersRequest,
    ): Promise<GridResponse>;
    public abstract changeGridFilters(requestData: ChangeGridFiltersRequest): Promise<ChangeGridFiltersResponse>;
    public abstract fillGridUpToNRows(request: GridFillUpToNRowsRequest): Promise<BeGrid>;
    // New methods for the new endpoints
    public abstract checkTaskStatus(task_id: string): Promise<CheckTaskStatusResponse>;
    public abstract cancelTask(task_id: string): Promise<CancelTaskResponse>;

    public abstract apolloTitleAutocomplete(request: ApolloTitleSearchRequest): Promise<ApolloTitleSearchResponse>;
    public abstract trackCompanyExport(request: TrackCompanyExportRequest): Promise<TrackCompanyExportResponse>;
    public abstract cellLevelBatchQuery(request: CellLevelBatchQueryRequest): Promise<BatchQueryCollectionResponse>;
    public abstract markColumnAsWebsite(request: MarkColumnAsWebsiteRequest): Promise<MarkColumnAsWebsiteResponse>;
    public abstract changePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse>;
    public abstract forgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse>;
    public abstract selfServiceSignUp(request: SelfServiceSignUpRequest): Promise<SelfServiceSignUpResponse>;
    public abstract verifyEmail(token: string): Promise<VerifyEmailResponse>;
    public abstract pivotGrid(request: PivotGridRequest): Promise<PivotGridResponse>;
}

class RealBackendService implements AbstractBackendService {
    private authorizationToken: string | undefined;
    private baseUrl: string;

    constructor() {
        this.authorizationToken = getToken();
        this.baseUrl =
            !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://127.0.0.1:8000" : RENDER_BASE_URL;
    }

    private setAuthorizationToken(token: string | undefined) {
        this.authorizationToken = token;
        if (token != null) {
            localStorage.setItem(AUTH_TOKEN_KEY, token);
        } else {
            localStorage.removeItem(AUTH_TOKEN_KEY);
        }
    }

    public async signup(request: SignUpRequest): Promise<void> {
        const response = await fetch(`${this.baseUrl}/invitation-signup/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to sign up.", response);
        }
    }

    public async getInvitationStatus(code: string): Promise<GetInvitationStatusResponse> {
        const response = await fetch(`${this.baseUrl}/invitation-status/${code}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch invitation status.", response);
        }

        return response.json() as Promise<GetInvitationStatusResponse>;
    }

    public async createInvitationCheckoutSession(
        request: CreateInvitationCheckoutSessionRequest,
    ): Promise<CreateInvitationCheckoutSessionResponse> {
        const response = await fetch(`${this.baseUrl}/create-invitation-checkout-session/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create invitation checkout session.", response);
        }

        return response.json() as Promise<CreateInvitationCheckoutSessionResponse>;
    }

    public async createSelfServiceCheckoutSession(
        request: CreateSelfServiceCheckoutSessionRequest,
    ): Promise<CreateSelfServiceCheckoutSessionResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/create-self-service-checkout-session/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create self-service checkout session.", response);
        }

        return response.json() as Promise<CreateSelfServiceCheckoutSessionResponse>;
    }

    public async login(username: string, password: string): Promise<LoginResponse> {
        const response = await fetch(`${this.baseUrl}/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest({ username, password }),
        });

        if (response.ok) {
            const data = (await response.json()) as LoginResponse;
            this.setAuthorizationToken(data.token);
            return data;
        } else {
            await this.throwWithDetailIfAvailable("Login failed.", response);
            // Unreachable. HACKHACK
            throw new Error("Login failed.");
        }
    }

    public async getCurrentUser(): Promise<GetCurrentUserResponse | undefined> {
        if (this.authorizationToken == null) {
            return undefined;
        }

        const response = await fetch(`${this.baseUrl}/me/`, {
            method: "GET",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        // TODO: We should probably do this all over the place
        if (response.status === 401) {
            this.setAuthorizationToken(undefined);
            throw new Error("Bad token. Please log in again.");
        }

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch current user data.", response);
        }

        return response.json() as Promise<GetCurrentUserResponse>;
    }

    public async getFreeTrialUsage(): Promise<FreeTrialUsageResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/free-trial-usage/`, {
            method: "GET",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch free trial usage.", response);
        }

        return response.json() as Promise<FreeTrialUsageResponse>;
    }

    public async logout(): Promise<void> {
        if (this.authorizationToken == null) {
            return Promise.resolve();
        }
        const response = await fetch(`${this.baseUrl}/logout/`, {
            method: "POST",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });
        this.setAuthorizationToken(undefined);

        if (!response.ok) {
            throw new Error("Logout failed. You may already be logged out.");
        }
    }

    public async createTrialUser(request: CreateTrialUserRequest): Promise<CreateTrialUserResponse> {
        const response = await fetch(`${this.baseUrl}/create-trial-user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest(request),
        });

        if (response.status === 429) {
            throw new RatelimitError("Rate limit exceeded. Please try again later.");
        }

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create trial user.", response);
        }
        const responseJson = await (response.json() as Promise<CreateTrialUserResponse>);

        this.setAuthorizationToken(responseJson.token);

        return responseJson;
    }

    public async batchQuery(request: BatchQueryCollectionRequest): Promise<BatchQueryCollectionResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/async_batch_query/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch batch query data.", response);
        }

        return response.json() as Promise<BatchQueryCollectionResponse>;
    }

    public async apolloTitleAutocomplete(request: ApolloTitleSearchRequest): Promise<ApolloTitleSearchResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/apollo-title-autocomplete/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (response.status === 401) {
            this.setAuthorizationToken(undefined);
            throw new Error("Bad token. Please log in again.");
        }

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch autocomplete data.", response);
        }

        return response.json() as Promise<ApolloTitleSearchResponse>;
    }

    public async crunchbaseAutocomplete(
        query: string,
        collection_ids: CrunchbaseAutoCompleteCollectionId[],
    ): Promise<CrunchbaseAutocompleteResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const requestData: CrunchbaseAutocompleteRequest = {
            query: query,
            collection_ids: collection_ids,
        };

        const response = await fetch(`${this.baseUrl}/crunchbase-autocomplete/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(requestData),
        });

        if (response.status === 401) {
            this.setAuthorizationToken(undefined);
            throw new Error("Bad token. Please log in again.");
        }

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch autocomplete data.", response);
        }

        return response.json() as Promise<CrunchbaseAutocompleteResponse>;
    }

    public async createAnswerIssue(request: AnswerIssueRequest): Promise<AnswerIssueResponse> {
        if (!this.authorizationToken) {
            throw new Error("User is not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/answer_issue/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create answer issue", response);
        }

        return response.json() as Promise<AnswerIssueResponse>;
    }

    public async createGrid(request: GridCreateRequest): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create grid.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public async updateGrid(unique_id: string, request: GridUpdateRequest): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/update/${unique_id}/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to update grid.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public async patchGrid(unique_id: string, request: GridPatchRequest): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/grid/patch/${unique_id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (response.status === 409) {
            throw new VersionConflictError("Version conflict. Please refresh the page and try again.");
        }

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to patch grid.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public patchGridOnUnload(unique_id: string, request: GridPatchRequest): void {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        try {
            void fetch(`${this.baseUrl}/grid/patch/${unique_id}/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${this.authorizationToken}`,
                },
                body: serializeRequest(request),
                keepalive: true,
            });
        } catch (e) {
            console.error("Failed to patch grid on unload", e);
        }
    }

    public async duplicateGrid(unique_id: string): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/grid/duplicate/${unique_id}/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to duplicate grid.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public async shareGrid(unique_id: string): Promise<ShareGridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/grid/share/${unique_id}/`, {
            method: "POST",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to share grid.", response);
        }

        return response.json() as Promise<ShareGridResponse>;
    }

    public updateGridOnUnload(unique_id: string, request: GridUpdateRequest): void {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        try {
            void fetch(`${this.baseUrl}/grid/update/${unique_id}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${this.authorizationToken}`,
                },
                body: serializeRequest(request),
                keepalive: true,
            });
        } catch (e) {
            console.error("Failed to update grid on unload", e);
        }
    }

    public async deleteGrid(unique_id: string): Promise<void> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/delete/${unique_id}/`, {
            method: "DELETE",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to delete grid.", response);
        }
    }

    public async getAvailableGrids(): Promise<GridGetAvailableResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/my-grids/`, {
            method: "GET",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fetch available grids.", response);
        }

        return response.json() as Promise<GridGetAvailableResponse>;
    }

    public async batchGetGrids(request: GridBatchGetRequest): Promise<GridBatchGetResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/batch-get/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to batch get grids.", response);
        }

        return response.json() as Promise<GridBatchGetResponse>;
    }

    public async buildCompanyGridWithQuery(request: GridBuildWithQueryRequest): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/build-company-grid-with-query/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to build grid with query.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public async buildCompanyGridWithQueryAndFilters(
        request: GridBuildWithQueryAndFiltersRequest,
    ): Promise<GridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/build-company-grid-with-query-and-filters/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to build grid with query and filters.", response);
        }

        return response.json() as Promise<GridResponse>;
    }

    public async changeGridFilters(requestData: ChangeGridFiltersRequest): Promise<ChangeGridFiltersResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/grid/change-grid-filters/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(requestData),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to change grid filters.", response);
        }

        return response.json() as Promise<ChangeGridFiltersResponse>;
    }

    public async fillGridUpToNRows(request: GridFillUpToNRowsRequest): Promise<BeGrid> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/fill-up-to-n-rows/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to fill grid up to N rows.", response);
        }

        return response.json() as Promise<BeGrid>;
    }

    public async checkTaskStatus(task_id: string): Promise<CheckTaskStatusResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/export-task/${task_id}/status/`, {
            method: "GET",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to check task status.", response);
        }

        return response.json() as Promise<CheckTaskStatusResponse>;
    }

    public async cancelTask(task_id: string): Promise<CancelTaskResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/export-task/${task_id}/cancel/`, {
            method: "POST",
            headers: {
                Authorization: `Token ${this.authorizationToken}`,
            },
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to cancel task.", response);
        }

        return response.json() as Promise<CancelTaskResponse>;
    }

    public async trackCompanyExport(request: TrackCompanyExportRequest): Promise<TrackCompanyExportResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/track-company-export/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to track company export.", response);
        }

        return response.json() as Promise<TrackCompanyExportResponse>;
    }

    public async cellLevelBatchQuery(request: CellLevelBatchQueryRequest): Promise<BatchQueryCollectionResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }

        const response = await fetch(`${this.baseUrl}/async_cell_level_batch_query/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to perform cell level batch query.", response);
        }

        return response.json() as Promise<BatchQueryCollectionResponse>;
    }

    public async createGridFromCsv(request: CreateGridFromCsvRequest): Promise<CreateGridFromCsvResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/create-grid-from-csv/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to create grid from CSV.", response);
        }

        return response.json() as Promise<CreateGridFromCsvResponse>;
    }

    public async markColumnAsWebsite(request: MarkColumnAsWebsiteRequest): Promise<MarkColumnAsWebsiteResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/mark-column-as-website/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to mark column as website.", response);
        }

        return response.json() as Promise<MarkColumnAsWebsiteResponse>;
    }

    public async changePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/change-password/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to change password.", response);
        }

        const data = (await response.json()) as ChangePasswordResponse;
        this.setAuthorizationToken(data.new_token);
        return data;
    }

    public async forgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
        const response = await fetch(`${this.baseUrl}/forgot-password/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to forgot password.", response);
        }

        return response.json() as Promise<ForgotPasswordResponse>;
    }

    public async selfServiceSignUp(request: SelfServiceSignUpRequest): Promise<SelfServiceSignUpResponse> {
        const response = await fetch(`${this.baseUrl}/self-service-signup/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to sign up.", response);
        }

        return response.json() as Promise<SelfServiceSignUpResponse>;
    }

    public async verifyEmail(token: string): Promise<VerifyEmailResponse> {
        const response = await fetch(`${this.baseUrl}/verify-email/${token}/`, {
            method: "GET",
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to verify email.", response);
        }

        return response.json() as Promise<VerifyEmailResponse>;
    }

    public async pivotGrid(request: PivotGridRequest): Promise<PivotGridResponse> {
        if (this.authorizationToken == null) {
            throw new Error("Not logged in.");
        }
        const response = await fetch(`${this.baseUrl}/grid/pivot/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${this.authorizationToken}`,
            },
            body: serializeRequest(request),
        });

        if (!response.ok) {
            await this.throwWithDetailIfAvailable("Failed to pivot grid.", response);
        }

        return response.json() as Promise<PivotGridResponse>;
    }

    private async throwWithDetailIfAvailable(genericErrorMessage: string, response: Response) {
        let errorData: unknown | undefined = undefined;
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            errorData = await response.json();
        } catch (e) {
            console.error("Failed to parse error response", e);
            errorData = undefined;
        }
        if (typeof errorData === "object" && errorData !== null && "detail" in errorData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
            throw new Error(`${errorData.detail}`);
        }

        throw new Error(genericErrorMessage);
    }
}

// Replaces undefined with nulls
function serializeRequest<T>(obj: T): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.stringify(obj, (key, value) => (value === undefined ? null : value));
}

export const BackendService = RealBackendService;

export const getBackendService = once(() => new BackendService());
