import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    Box,
    Typography,
    SelectChangeEvent,
    Tooltip,
    lighten,
    useTheme,
    SxProps,
    Theme,
} from "@mui/material";
import { Add, Sms } from "iconsax-react";
import * as React from "react";
import { ApolloPersonFilters, ApolloPersonFiltersWithFallback } from "../../services/cb-backend-types";
import { SearchPersona } from "../personas";
import { ApolloEditablePersonFilters } from "./apolloPersonFilters";
import { ColLabelInput } from "../../grid/colLabelInput";
import ButtonWithDisabledTooltip from "../../reusable/buttonWithDisabledTooltip";
import { ColumnGeneratedByApollo } from "../../grid/grid";
import { LinkedIn } from "@mui/icons-material";

interface CompanyPeopleDialogProps {
    isOpen: boolean;
    onClose: () => void;
    config: Omit<ColumnGeneratedByApollo, "type">;
    setConfig: React.Dispatch<React.SetStateAction<Omit<ColumnGeneratedByApollo, "type">>>;
    persona: SearchPersona;
    onSubmit: (config: Omit<ColumnGeneratedByApollo, "type">) => void;
    colLabel: string;
    onChangeColLabel: (newLabel: string) => void;
}

export const CompanyPeopleDialog: React.FC<CompanyPeopleDialogProps> = ({
    isOpen,
    onClose,
    config,
    setConfig,
    persona,
    onSubmit,
    colLabel,
    onChangeColLabel,
}) => {
    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSubmit = React.useCallback(() => {
        onSubmit(config);
        onClose();
    }, [config, onSubmit, onClose]);

    const handleSetFilterConfig = React.useCallback(
        (newConfigFilters: ApolloPersonFiltersWithFallback) => {
            setConfig(config => ({
                ...config,
                personFilters: newConfigFilters,
            }));
            const nameIsAutoSet = colLabel === getAutoSetNameFromConfig(config.personFilters);
            const canAutoSetName = colLabel === "" || nameIsAutoSet;
            const personTitles = newConfigFilters.primary.person_titles;
            if (personTitles.length === 1 && canAutoSetName) {
                onChangeColLabel(getAutoSetNameFromConfig(newConfigFilters));
            } else if (personTitles.length > 1 && canAutoSetName) {
                onChangeColLabel(getAutoSetNameFromConfig(newConfigFilters));
            } else if (personTitles.length === 0) {
                onChangeColLabel("");
            }
        },
        [colLabel, config.personFilters, onChangeColLabel, setConfig],
    );

    const handleAddFallback = React.useCallback(() => {
        const newConfigFilters = {
            ...config.personFilters,
            fallback: [...config.personFilters.fallback, { person_titles: [] }],
        };
        setConfig(oldConfig => ({
            ...oldConfig,
            personFilters: newConfigFilters,
        }));
        const nameIsAutoSet = colLabel === getAutoSetNameFromConfig(config.personFilters);
        if (nameIsAutoSet) {
            onChangeColLabel(
                getAutoSetNameFromConfig({
                    ...newConfigFilters,
                }),
            );
        }
    }, [setConfig, colLabel, onChangeColLabel, config.personFilters]);

    const theme = useTheme();

    const hasNoPrimaryTitles = config.personFilters.primary.person_titles.length === 0;

    // When there are no titles, we pretend it is disabled, but actually
    // you can click to get any employee
    const hasNoPrimaryTitlesStyle: SxProps<Theme> = {
        boxShadow: 0,
        color: lighten(theme.palette.text.disabled, 0.3),
        bgcolor: theme.palette.action.disabledBackground,
        fontWeight: 400,
        "&:hover": {
            boxShadow: 0,
            color: theme.palette.text.disabled,
            bgcolor: theme.palette.action.disabledBackground,
        },
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md">
            <DialogTitle>{"Company -> People"}</DialogTitle>
            <DialogContent
                sx={{
                    height: 390,
                    maxHeight: 390,
                    display: "flex",
                    overflowY: "auto",
                    flexDirection: "column",
                    rowGap: 2,
                    flexShrink: 0,
                    maxWidth: 600,
                    // TODO: HACKHACKHACK. What's the best way to have a constant width for the dialog?
                    minWidth: 600,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                    }}
                >
                    <ApolloEditablePersonFilters
                        config={config.personFilters}
                        setConfig={handleSetFilterConfig}
                        persona={persona}
                    />
                    <ContactEnrichmentSetting config={config} setConfig={setConfig} />
                    {config.personFilters.primary.person_titles.length > 0 &&
                        config.personFilters.fallback.length < 2 && (
                            <Tooltip title="Add a set of titles to fall back on if no employees are found for the above titles">
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={handleAddFallback}
                                    startIcon={<Add size="16" />}
                                    sx={{
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    Add fallback
                                </Button>
                            </Tooltip>
                        )}
                </Box>
                <ColLabelInput colLabel={colLabel} labelSize="body2" setColLabel={onChangeColLabel} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    sx={{
                        textTransform: "none",
                        typography: "body2",
                        borderRadius: 8,
                    }}
                    variant="text"
                    color="secondary"
                >
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    disabledTooltip={getDisabledReason(config.personFilters)}
                    startIcon={<Add size="16" />}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        borderRadius: 8,
                        typography: "body2",
                        ...(hasNoPrimaryTitles ? hasNoPrimaryTitlesStyle : {}),
                    }}
                >
                    Add column
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};

interface ContactEnrichmentSettingProps {
    config: Omit<ColumnGeneratedByApollo, "type">;
    setConfig: React.Dispatch<React.SetStateAction<Omit<ColumnGeneratedByApollo, "type">>>;
}

const ContactEnrichmentSetting: React.FC<ContactEnrichmentSettingProps> = ({ config, setConfig }) => {
    const handleEnrichSinglePersonChange = React.useCallback(
        (evt: SelectChangeEvent<string>) => {
            const enrichSinglePerson = evt.target.value === "singlePerson";
            setConfig(config => ({
                ...config,
                enrichmentSettings: {
                    ...config.enrichmentSettings,
                    enrichSinglePerson,
                },
            }));
        },
        [setConfig],
    );
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                Contact enrichment setting
            </Typography>
            <Select
                value={config.enrichmentSettings.enrichSinglePerson ? "singlePerson" : "multiplePeople"}
                onChange={handleEnrichSinglePersonChange}
                size="small"
            >
                <MenuItem value="multiplePeople">
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <LinkedIn fontSize="small" sx={{ m: "-2px", flexShrink: 0 }} />
                        Linkedin
                    </Box>
                </MenuItem>
                <MenuItem value="singlePerson">
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Sms size={16} />
                        Email and Linkedin (max 3 results)
                    </Box>
                </MenuItem>
            </Select>
        </Box>
    );
};

function getAutoSetNameFromConfig(config: ApolloPersonFiltersWithFallback): string {
    const autoSetName = getAutoSetNameFromPrimary(config.primary);
    if (config.fallback.length > 0) {
        return autoSetName + " with fallback";
    }
    return autoSetName;
}

function getAutoSetNameFromPrimary(primary: ApolloPersonFilters): string {
    const personTitles = primary.person_titles;
    if (personTitles.length === 1) {
        return capitalizeTitle(personTitles[0]);
    } else if (personTitles.length > 1) {
        const firstTwo = personTitles.slice(0, 2).map(capitalizeTitle).join(", ");
        return personTitles.length === 2 ? firstTwo : firstTwo + " + " + (personTitles.length - 2).toString() + " more";
    }
    return "";
}

function capitalizeTitle(string: string): string {
    if (string.length === 0) {
        return string;
    }
    if (string.length === 3 && string[0] === "c" && string[2] === "o") {
        return string.toUpperCase();
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getDisabledReason(config: ApolloPersonFiltersWithFallback): string | undefined {
    if (config.fallback.length > 0) {
        const fallbacksWithEmptyTitles = config.fallback
            .map((f, idx) => ({ fallback: f, idx }))
            .filter(({ fallback }) => fallback.person_titles.length === 0);
        if (fallbacksWithEmptyTitles.length > 1 || config.primary.person_titles.length === 0) {
            return `Fallback ${fallbacksWithEmptyTitles.map(({ idx }) => idx + 1).join(", ")} has no titles. Add a title to each fallback or delete it.`;
        }
    }

    return undefined;
}
