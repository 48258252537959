import React, { useCallback, KeyboardEvent, ChangeEvent } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowDown2, ArrowRight, ArrowRight2, Filter as FilterIcon } from "iconsax-react";
import { Box, Button, Input, lighten, OutlinedInput, SxProps, Theme } from "@mui/material";
import { Filters } from "../home/filters/filters";
import {
    EditableCrunchbaseFilter,
    EditableIncompleteCrunchbaseFilter,
    isCompleteEditableFilter,
} from "../home/filters/types";
import { SearchPersona } from "../home/personas";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { EMPTY_FILTER } from "../services/filterUtils";
import { getCreateDisabledReason } from "./getCreateDisabledReason";

export interface GridFromQueryInputProps {
    query: string;
    isLoadingAfterQuery: boolean;
    onQueryChange: (q: string) => void;
    onQuerySubmit: (q: string, filters: EditableCrunchbaseFilter[]) => Promise<void>;
    placeholder?: string;
    filterInfo:
        | {
              filters: EditableCrunchbaseFilter[];
              setFilters: React.Dispatch<React.SetStateAction<EditableCrunchbaseFilter[]>>;
              persona: SearchPersona;
          }
        | undefined;
    sx?: SxProps<Theme>;
}

export const GridFromQueryInput: React.FC<GridFromQueryInputProps> = ({
    query,
    isLoadingAfterQuery,
    onQueryChange: setQuery,
    onQuerySubmit: handleQuerySubmit,
    placeholder,
    filterInfo,
    sx: parentSx,
}) => {
    const [newFilter, setNewFilter] = React.useState<EditableIncompleteCrunchbaseFilter>({
        ...EMPTY_FILTER,
        // TODO: Fallback make sense?
        persona: filterInfo?.persona ?? "general",
    });

    const handleSubmitCurrentQuery = useCallback(() => {
        const filtersToSubmit = [...(filterInfo?.filters ?? [])];
        if (isCompleteEditableFilter(newFilter)) {
            filtersToSubmit.push(newFilter);
        }
        const filtersNonEditing = filtersToSubmit.map(f => ({ ...f, isEditing: false }));
        void handleQuerySubmit(query, filtersNonEditing);
    }, [filterInfo?.filters, handleQuerySubmit, newFilter, query]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleSubmitCurrentQuery();
            }
        },
        [handleSubmitCurrentQuery],
    );

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setQuery(event.target.value);
        },
        [setQuery],
    );

    const [isCollapsed, setIsCollapsed] = React.useState(true);

    const completeFiltersCount = React.useMemo(
        () => [...(filterInfo?.filters ?? []), newFilter].filter(isCompleteEditableFilter).length,
        [filterInfo?.filters, newFilter],
    );

    const filterButton = (
        <Button
            startIcon={<FilterIcon size={16} />}
            endIcon={isCollapsed ? <ArrowRight2 size={16} /> : <ArrowDown2 size={16} />}
            onClick={() => setIsCollapsed(!isCollapsed)}
            color="secondary"
            variant="outlined"
            sx={{
                textTransform: "none",
                borderRadius: 8,
            }}
        >
            {"Filters" + (completeFiltersCount > 0 ? ` (${completeFiltersCount})` : "")}
        </Button>
    );

    const submitDisabledReason = React.useMemo(
        () => getCreateDisabledReason(query, filterInfo?.filters ?? [], newFilter, true, "creating"),
        [query, filterInfo?.filters, newFilter],
    );

    if (filterInfo == null) {
        return (
            <OutlinedInput
                autoFocus
                placeholder={placeholder ?? "Find answers…"}
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown} // Change this from onKeyUp to onKeyDown
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        {!isLoadingAfterQuery ? (
                            <IconButton
                                aria-label="Find answers"
                                onClick={handleSubmitCurrentQuery}
                                disabled={query.trim() === ""}
                                edge="end"
                                size="small"
                            >
                                <ArrowRight />
                            </IconButton>
                        ) : (
                            <CircularProgress color="secondary" size={14} />
                        )}
                    </InputAdornment>
                }
            />
        );
    }

    return (
        <Box
            sx={[
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(parentSx) ? parentSx : [parentSx]),
                {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    borderWidth: 1,
                    borderRadius: 2,
                    border: 1,
                    bgcolor: "white",
                    borderColor: lighten("#DEDEDE", 0.5),
                    "&:focus-within": {
                        borderColor: "#DEDEDE",
                    },
                },
            ]}
        >
            <Input
                autoFocus
                disabled={false}
                placeholder="Find companies…"
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown} // Change this from onKeyUp to onKeyDown
                fullWidth
                disableUnderline
                multiline
                minRows={2}
                maxRows={3}
                sx={{
                    px: 1.5,
                    pt: 1.5,
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    px: 1.5,
                    py: 1,
                    flexGrow: 1,
                }}
            >
                {filterButton}
                {isCollapsed ? (
                    isLoadingAfterQuery ? (
                        <CircularProgress color="secondary" size={14} />
                    ) : (
                        <IconButton
                            disabled={submitDisabledReason != null}
                            onClick={handleSubmitCurrentQuery}
                            size="small"
                        >
                            <ArrowRight />
                        </IconButton>
                    )
                ) : isLoadingAfterQuery ? (
                    <CircularProgress color="secondary" size={14} />
                ) : (
                    <ButtonWithDisabledTooltip
                        disabledTooltip={submitDisabledReason}
                        endIcon={<ArrowRight size={16} />}
                        onClick={handleSubmitCurrentQuery}
                        color="secondary"
                        sx={{
                            bgcolor: "#F4F2EF",
                            textTransform: "none",
                            borderRadius: 8,
                            px: 1.5,
                        }}
                    >
                        Create grid
                    </ButtonWithDisabledTooltip>
                )}
            </Box>
            {!isCollapsed && (
                <Filters
                    newFilter={newFilter}
                    setNewFilter={setNewFilter}
                    filters={filterInfo.filters}
                    setFilters={filterInfo.setFilters}
                    persona={filterInfo.persona}
                    sx={{
                        p: 1,
                    }}
                />
            )}
        </Box>
    );
};
