import {
    ApolloPersonFiltersWithFallback,
    BeExternalIdentifiers,
    BeRowGeneratingFilter,
    BeSupportedFieldId,
    CheckTaskStatusResponse,
    ColumnToolName,
    GetCurrentUserResponse,
    PlanType,
} from "../services/cb-backend-types";
import { isFieldNonNullable } from "../utils/isNonNullable";
import { ColQueryWithRow } from "./useEdits";

export const MAX_ROWS_TO_RENDER = 100;

export const INITIAL_ROWS: Row[] = [{ type: "placeholder", name: "", uniqueId: randomUniqueId() }];

export function randomUniqueId() {
    return Math.random().toString(36).substr(2, 9);
}

export interface AgentCellAnswerDetails {
    sourceType: "AGENT";
    rawQuery: string;
}

export interface CrunchbaseCellAnswerDetails {
    sourceType: "FOCUS_CB";
    fieldId: string;
}

export interface ApolloCellAnswerDetails {
    sourceType: "FOCUS_AP";
    personFilters: ApolloPersonFiltersWithFallback;
    enrichmentSettings: ApolloPeopleEnrichmentSettings;
}

export interface APTechnology {
    uid: string;
    name: string;
    category: string;
}

export interface ApolloOrganization {
    technology_names: string[];
    current_technologies: APTechnology[];
    short_description: string | undefined;
    estimated_num_employees: number | undefined;
}

export interface ApolloJobPosting {
    id: string;
    title: string;
    url: string;
    city?: string;
    state?: string;
    country?: string;
    last_seen_at: string;
    posted_at: string;
}

export interface CellValueV2JobPostings {
    jobPostings: ApolloJobPosting[];
    type: "job_postings";
}

export type ApolloOrganizationField =
    | "technology"
    | "job_postings"
    | "short_description"
    | "estimated_num_employees"
    | "departmental_head_counts";

export interface ApolloOrganizationCellAnswerDetails {
    sourceType: "FOCUS_AP_ORG";
    field: ApolloOrganizationField;
}

export type CellAnswerDetails =
    | AgentCellAnswerDetails
    | CrunchbaseCellAnswerDetails
    | ApolloCellAnswerDetails
    | ApolloOrganizationCellAnswerDetails;

export interface CellValueV2String {
    value: string;
    type: "string";
}

export interface APPersonFieldString {
    type: "string";
    value: string;
}

export interface APPersonFieldError {
    type: "error";
    error: string;
}

export interface APPersonFieldMissing {
    type: "missing";
}

export interface APPersonFieldNotRequested {
    type: "not_requested";
}

export type APPersonField = APPersonFieldString | APPersonFieldError | APPersonFieldMissing | APPersonFieldNotRequested;

export interface APPersonPhoneNumber {
    value: string;
}

export interface APPersonPhoneNumbers {
    type: "phone_numbers";
    phoneNumbers: APPersonPhoneNumber[];
}

export interface APPersonEmploymentHistory {
    type: "employment_history";
    values: APPersonEmploymentHistoryEntry[];
}

export interface APPerson {
    name: APPersonFieldString;
    email: APPersonField;
    linkedin: APPersonField;
    title: APPersonField;
    phoneNumbers: APPersonPhoneNumbers | APPersonFieldMissing | APPersonFieldNotRequested;
    // New fields
    headline: APPersonField;
    imageUrl: string | undefined;
    employmentHistory: APPersonEmploymentHistory | APPersonFieldMissing | APPersonFieldNotRequested;
}

// Add this new interface for employment history
export interface APPersonEmploymentHistoryEntry {
    description: string | undefined;
    endDate: string | undefined;
    organizationId: string | undefined;
    organizationName: string | undefined;
    startDate: string | undefined;
    title: string | undefined;
}

export interface CellValueV2ApolloPeople {
    people: APPerson[];
    totalCount: number | undefined;
    type: "apollo_people";
}

export interface CellValueV2Boolean {
    value: boolean;
    reasoning?: string;
    type: "boolean";
}

export interface CellValueV2Missing {
    type: "missing";
}

export interface CellValueV2Error {
    error: string;
    type: "error";
}

export interface CellValueV2Technologies {
    technologies: APTechnology[];
    type: "technologies";
}

export interface CellValueV2Unloaded {
    type: "unloaded";
}

export interface CellValueV2Number {
    value: number;
    type: "number";
}

export interface ValueLink {
    snippet: string;
    url: string;
    title: string;
}

export interface CellValueV2Links {
    type: "links";
    links: ValueLink[];
}

export type CellValueV2 =
    | CellValueV2String
    | CellValueV2ApolloPeople
    | CellValueV2Boolean
    | CellValueV2Missing
    | CellValueV2Error
    | CellValueV2Technologies
    | CellValueV2JobPostings
    | CellValueV2Unloaded
    | CellValueV2Number
    | CellValueV2Links;

export interface Citation {
    link: string;
    title: string | undefined;
}

export interface RowValueWithCitations<T> {
    value: T;
    citations: Citation[];
    sourceDetails: CellAnswerDetails | undefined;
}

export type RealRow = {
    type: "real";
    imageUrl: string | undefined;
    data: {
        name: RowValueWithCitations<CellValueV2String>;
        [key: string]: RowValueWithCitations<CellValueV2>;
    };
    externalIdentifiers: BeExternalIdentifiers | undefined;
};

export function getStringKeyFromRow(row: Row): string {
    switch (row.type) {
        case "real":
            return getStringKeyFromRealRow(row);
        case "placeholder":
            return getStringKeyFromPlaceholderRow(row);
    }
}

export function getStringKeyFromRealRow(row: RealRow): string {
    return getStringKeyFromValueAndExternalIdentifiers(row.data.name.value.value, row.externalIdentifiers);
}

export function getStringKeyFromValueAndExternalIdentifiers(
    value: string,
    externalIdentifiers: BeExternalIdentifiers | undefined,
): string {
    return `${value}-${getKeyFromExternalIdentifiers(externalIdentifiers)}`;
}

export function getKeyFromExternalIdentifiers(externalIdentifiers: BeExternalIdentifiers | undefined): string {
    if (externalIdentifiers == null) {
        return "";
    }
    let key = "";
    if (externalIdentifiers.type === "company") {
        key += `company-${externalIdentifiers.crunchbase?.uuid ?? ""}-${externalIdentifiers.apollo?.domain_url ?? ""}`;
    }
    return key;
}

export type PlaceholderRow = {
    type: "placeholder";
    name: string;
    uniqueId: string;
};

export function getStringKeyFromPlaceholderRow(row: PlaceholderRow): string {
    return `placeholder-row-${row.uniqueId}`;
}

export interface RowKey {
    title: string;
    value: string;
    externalIdentifiers: BeExternalIdentifiers | undefined;
}

export type Row = RealRow | PlaceholderRow;

export function isRealRow(row: Row): row is RealRow {
    return row.type === "real";
}
export function isPlaceholderRow(row: Row): row is PlaceholderRow {
    return row.type === "placeholder";
}

export interface AgentToolConfig {
    toolName: ColumnToolName;
    useVisionIfPageScrape: boolean;
}

export interface FormatOptionsBoolean {
    type: "bool";
}

export interface FormatOptionsConcise {
    type: "concise";
}

export interface FormatOptionsInferred {
    type: "inferred";
}

export interface FormatOptionsNumber {
    type: "number";
}

export type FormatOptions = FormatOptionsBoolean | FormatOptionsConcise | FormatOptionsInferred | FormatOptionsNumber;

export interface ColumnGeneratedByWebSearch {
    query: string;
    type: "web_search";
    contextColumnIds: string[];
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
}

export interface ColumnGeneratedByCrunchbase {
    fieldId: BeSupportedFieldId;
    type: "crunchbase";
}

export interface ApolloPeopleEnrichmentSettings {
    enrichSinglePerson: boolean;
}

export interface ColumnGeneratedByApollo {
    type: "apollo";
    personFilters: ApolloPersonFiltersWithFallback;
    enrichmentSettings: ApolloPeopleEnrichmentSettings;
}

export interface ColumnGeneratedByApolloOrganization {
    type: "apollo_organization";
    field: ApolloOrganizationField;
}

export interface ColumnGeneratedByUserInput {
    type: "user_input";
}

export type ColumnGeneratedBy =
    | ColumnGeneratedByWebSearch
    | ColumnGeneratedByCrunchbase
    | ColumnGeneratedByApollo
    | ColumnGeneratedByApolloOrganization
    | ColumnGeneratedByUserInput;

export type Column = {
    id: string;
    label: string;
    align: "left" | "right" | "center" | "inherit" | "justify" | undefined;
    isEditable?: boolean;
    generatedBy: ColumnGeneratedBy | undefined;
};

export type KeyColumnId = "name";
export type KeyColumn = Column & { id: KeyColumnId };

export const KEY_COLUMNS: [KeyColumn] = [{ id: "name", label: "Name", align: "left", generatedBy: undefined }];

export const NEW_COLUMN_PLACEHOLDER: Omit<Column, "id"> = {
    label: "",
    align: "center",
    isEditable: true,
    generatedBy: {
        query: "",
        type: "web_search",
        contextColumnIds: [],
        toolConfig: undefined,
        formatOptions: undefined,
    },
};

export const INITIAL_COLUMNS: [KeyColumn, Column] = [
    ...KEY_COLUMNS,
    // Add empty one
    {
        id: generateRandomColumnId(),
        ...NEW_COLUMN_PLACEHOLDER,
    },
];

export function generateRandomColumnId() {
    return `col_${Math.random().toString(36).substr(2, 9)}`;
}

export interface NameColumn extends Column {
    id: "name";
}

export function isKeyColumn(column: Column): column is KeyColumn {
    return column.id === "name";
}

export interface RowGeneratingFiltersPagingInfo {
    lastFetchedUuid: string | undefined;
    totalCount: number;
    fetchedCount: number | undefined;
}

export interface RowGeneratingFilters {
    // TODO: CHANGE TO BE A FE TYPE
    filters: BeRowGeneratingFilter[];
    pagingInfo: RowGeneratingFiltersPagingInfo | undefined;
}

export interface Grid {
    unique_id: string;
    columns: Column[];
    rows: Row[];
    version: number;
    name: string;
    created_at: Date;
    lastEdited: Date;
    rowGeneratingFilters: RowGeneratingFilters;
    visibilityFiltersByColumn: VisibilityFiltersByColumn;
    createdBy: string;
    lastExportTaskId: string | undefined;
}

export interface AvailableGrid {
    unique_id: string;
    name: string;
    created_at: Date;
    lastEdited: Date;
}

export type LoadingStatus = "loading";

export interface SelectedCell {
    rowStringKey: string;
    colId: string;
    isEditing?: boolean;
}

export interface SelectedCellInfo {
    rowKey: string;
    valueWithCitations: RowValueWithCitations<CellValueV2>;
    columnGeneratedBy: ColumnGeneratedBy | undefined;
}

export const COMPANIES_COLLECTION_DESC = "Companies";
export const ANYTHING_COLLECTION_DESC = "Anything";

export interface NullVisibilityFilter {
    type: "is-null" | "is-not-null";
}

export interface BooleanVisibilityFilter {
    type: "boolean";
    value: boolean;
}

export interface StringVisibilityFilter {
    type: "contains";
    value: string[];
}

export interface NumberVisibilityFilter {
    type: "number";
    eq: number[] | undefined;
    gte: number | undefined;
    lte: number | undefined;
}

export interface ColumnVisibilityFilter {
    nullFilter?: NullVisibilityFilter;
    booleanFilter?: BooleanVisibilityFilter;
    stringFilter?: StringVisibilityFilter;
    numberFilter?: NumberVisibilityFilter;
}

export type VisibilityFiltersByColumn = Record<string, ColumnVisibilityFilter>;

export type ExportProgress = {
    numExported: number;
    total: number;
    status: CheckTaskStatusResponse["status"];
    taskId: string;
    exportMode: ExportMode;
};

export enum ExportMode {
    QuickAdd = "quick-add",
    Generate = "generate",
    Export = "export",
}

export function getCellLoadingStatus(
    cellGenerationStatusesByColByRow: Record<string, Record<string, LoadingStatus | undefined>>,
    columnId: string,
    rowStringKey: string,
    rowLoadingStatus: LoadingStatus | undefined,
): LoadingStatus | undefined {
    if (rowLoadingStatus != null) {
        return rowLoadingStatus;
    }
    const loadingRowsInCol = cellGenerationStatusesByColByRow[columnId];
    if (loadingRowsInCol == null) {
        return undefined;
    }
    return loadingRowsInCol[rowStringKey];
}

export function getUnloadedCellsColQueriesWithRows(columns: Column[], rows: Row[]): ColQueryWithRow[] {
    const unloadedCellsByColumn: Record<string, RealRow[]> = {};

    rows.forEach(row => {
        if (isRealRow(row)) {
            columns.forEach(column => {
                if (row.data[column.id].value.type === "unloaded") {
                    if (!unloadedCellsByColumn[column.id]) {
                        unloadedCellsByColumn[column.id] = [];
                    }
                    unloadedCellsByColumn[column.id].push(row);
                }
            });
        }
    });

    const colQueriesWithRows = Object.entries(unloadedCellsByColumn).map(([colId, rows]) => {
        const col = columns.find(col => col.id === colId);
        return {
            colId,
            generatedBy: col?.generatedBy,
            rows,
            colLabel: col?.label,
        };
    });

    const isGeneratedByNotNull = isFieldNonNullable<(typeof colQueriesWithRows)[number], "generatedBy">("generatedBy");

    return colQueriesWithRows.filter(isGeneratedByNotNull);
}

export function getRowsInPage(page: number, visibleRows: Row[]) {
    const start = (page - 1) * PAGE_SIZE;
    return visibleRows.slice(start, start + PAGE_SIZE);
}

export const PAGE_SIZE = 30;

export const hasTrialFeatures = (planType: PlanType): planType is "trial" | "free-account" =>
    planType === "trial" || planType === "free-account";

export const isPaidUser = (planType: PlanType): planType is "paying-user" | "higher-tier-paying-user" =>
    planType === "paying-user" || planType === "higher-tier-paying-user";

export function getPlanFromUserPlanType(planType: PlanType): "free" | "basic" | "pro" | undefined {
    switch (planType) {
        case "trial":
        case "default-plan":
        case "free-account":
            return undefined;
        case "free-trial":
            return "free";
        case "paying-user":
            return "basic";
        case "higher-tier-paying-user":
            return "pro";
    }
}

const DATE_WHEN_SELF_SERVICE_WAS_INTRODUCED = new Date("2024-08-27");
export function allowSelfServicePlanManagement(user: GetCurrentUserResponse): boolean {
    if (hasTrialFeatures(user.plan_type)) {
        return true;
    }
    return new Date(user.date_joined) >= DATE_WHEN_SELF_SERVICE_WAS_INTRODUCED;
}

export const DEFAULT_AGENT_TOOL_CONFIG: AgentToolConfig = {
    toolName: "AnswerFromSearchEngine",
    useVisionIfPageScrape: true,
};

export const getContextColChangeHandler = (
    setContextColumnIds: React.Dispatch<React.SetStateAction<string[]>>,
    setToolConfig: React.Dispatch<React.SetStateAction<AgentToolConfig | undefined>>,
) => {
    return (contextColumnIds: string[]) => {
        setContextColumnIds(contextColumnIds);
        // If the tool is the default tool and the user sets context columns, then set the tool to auto-pick
        if (contextColumnIds.length > 0) {
            setToolConfig(tc => (tc?.toolName === DEFAULT_AGENT_TOOL_CONFIG.toolName ? undefined : tc));
        } else {
            // If the user clears context columns, then set the tool to the default tool since auto-pick is not an option
            setToolConfig(tc => (tc == null ? DEFAULT_AGENT_TOOL_CONFIG : tc));
        }
    };
};

export const FREE_TRIAL_DAYS = 7;

export const FREE_TRIAL_MAX_ROWS = 100;

export function getMaxGeneratableRowsLimit(planType: Exclude<PlanType, "free-account" | "trial">) {
    if (planType === "free-trial") {
        return FREE_TRIAL_MAX_ROWS;
    }

    return undefined;
}

export const UNAUTHED_CLICKED_PLAN_LOCAL_STORAGE_KEY = "answergrid.unauthed_clicked_plan";
