import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import * as React from "react";
import { ValueChip } from "./valueChip";

interface SearchableRestrictedSelectProps<T> {
    fullWidth?: boolean;
    values: T[];
    setValues: (vs: T[]) => void;
    autocompleteValues: readonly T[];
    getValueTitle: (v: T) => string;
}

export const SearchableRestrictedSelect = <T,>({
    values,
    setValues,
    autocompleteValues,
    getValueTitle,
    fullWidth,
}: SearchableRestrictedSelectProps<T>) => {
    const handleChange = React.useCallback(
        (e: unknown, v: T[]) => {
            setValues(v);
        },
        [setValues],
    );

    const renderInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => <TextField {...params} placeholder="Enter values" size="small" />,
        [],
    );

    return (
        <Autocomplete
            fullWidth={fullWidth}
            multiple
            id="tags-filled"
            filterSelectedOptions
            options={autocompleteValues}
            getOptionLabel={getValueTitle}
            renderTags={(value: readonly T[], getTagProps) =>
                value.map((option: T, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <ValueChip {...tagProps} label={getValueTitle(option)} key={key} />;
                })
            }
            renderInput={renderInput}
            onChange={handleChange}
            value={values}
        />
    );
};
