import * as React from "react";
import {
    Box,
    MenuItem,
    Typography,
    Select,
    SelectChangeEvent,
    SxProps,
    Theme,
    useTheme,
    ListItemIcon,
} from "@mui/material";
import { AddCircle } from "iconsax-react"; // Add this import
import { Column } from "./grid";
import { ValueChip } from "../home/filters/valueChip";

export interface WebQueryColumnContextFieldsProps {
    otherColumns: Column[];
    contextColumnIds: string[];
    onChangeColumnContextField: (contextFieldIds: string[]) => void;
    size: "small" | "medium";
    label?: string;
    sx?: SxProps<Theme>;
}

export const WebQueryColumnContextFields: React.FC<WebQueryColumnContextFieldsProps> = ({
    otherColumns,
    contextColumnIds,
    onChangeColumnContextField,
    size,
    sx,
    label,
}) => {
    const handleContextFieldChange = React.useCallback(
        (event: SelectChangeEvent<string>) => {
            const value = event.target.value;
            onChangeColumnContextField([...contextColumnIds, value]);
        },
        [contextColumnIds, onChangeColumnContextField],
    );

    const availableFields = React.useMemo(
        () => otherColumns.filter(col => !contextColumnIds.includes(col.id) && col.label.trim() !== ""),
        [otherColumns, contextColumnIds],
    );
    const labelByColId = React.useMemo(() => new Map(otherColumns.map(col => [col.id, col.label])), [otherColumns]);

    const handleDeleteContextField = React.useCallback(
        (contextColId: string) => {
            onChangeColumnContextField(contextColumnIds.filter(colId => colId !== contextColId));
        },
        [contextColumnIds, onChangeColumnContextField],
    );

    const theme = useTheme();

    if (otherColumns.length === 0) {
        return null;
    }

    const sizeInputProps = size === "small" ? ({ sx: { pt: 0.65, pb: 0.25, pl: 1 } } as const) : {};

    const labelToUse = label ?? "Add context";

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    alignItems: "start",
                    overflowX: "hidden",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    // TODO: Hackhack. Do this properly.
                    overflowX: "hidden",
                    width: "100%",
                    rowGap: 1,
                }}
            >
                <Select
                    onChange={handleContextFieldChange}
                    size="small"
                    disabled={availableFields.length === 0}
                    fullWidth
                    value=""
                    displayEmpty
                    placeholder={labelToUse}
                    renderValue={() => (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: "0 !important",
                                }}
                            >
                                <AddCircle size={16} color={theme.palette.secondary.main} />
                            </ListItemIcon>
                            <Typography variant="body2">{labelToUse}</Typography>
                        </Box>
                    )}
                    inputProps={sizeInputProps}
                    sx={{
                        minWidth: 100,
                    }}
                >
                    {availableFields.map(field => (
                        <MenuItem
                            key={field.id}
                            value={field.id}
                            sx={{
                                textOverflow: "ellipsis",
                                maxWidth: 450,
                            }}
                        >
                            <Typography variant="body2" noWrap>
                                {field.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
                {contextColumnIds.length > 0 && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            overflowX: "hidden",
                            alignItems: "start",
                            rowGap: 0.5,
                            // TODO: Hackhack. Do this properly.
                            width: "100%",
                        }}
                    >
                        {contextColumnIds.map(contextColId => (
                            <SelectedContextColumnChip
                                key={contextColId}
                                labelByColId={labelByColId}
                                contextColId={contextColId}
                                onDelete={handleDeleteContextField}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

interface SelectedContextColumnChipProps {
    contextColId: string;
    labelByColId: Map<string, string>;
    onDelete: (contextColId: string) => void;
}

const SelectedContextColumnChip: React.FC<SelectedContextColumnChipProps> = ({
    contextColId,
    labelByColId,
    onDelete,
}) => {
    const handleDelete = React.useCallback(() => onDelete(contextColId), [contextColId, onDelete]);
    return (
        <ValueChip label={labelByColId.get(contextColId) ?? contextColId} allowDelete={true} onDelete={handleDelete} />
    );
};
