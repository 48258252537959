import * as React from "react";
import { RowGeneratingFilters } from "./grid";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Edit2 } from "iconsax-react";
import { HeaderFilterBarFilter } from "./headerFilterBarFilter";
import { useResizeDetector } from "react-resize-detector";
import { EditGridFiltersDialog } from "../home/filters/editGridFiltersDialog";
import { SearchPersona } from "../home/personas";
import { EditableCrunchbaseFilter } from "../home/filters/types";

export interface HeaderFilterBarProps {
    rowGeneratingFilters: RowGeneratingFilters;
    persona: SearchPersona;
    disableEdit: boolean;
    // TODO: Wire string query
    onSubmitEditedFilters: (filters: EditableCrunchbaseFilter[]) => Promise<void>;
}

export const HeaderFilterBar: React.FC<HeaderFilterBarProps> = ({
    rowGeneratingFilters,
    persona,
    disableEdit,
    onSubmitEditedFilters,
}) => {
    return (
        <HeaderFilterBarContent
            rowGeneratingFilters={rowGeneratingFilters}
            persona={persona}
            disableEdit={disableEdit}
            onSubmitEditedFilters={onSubmitEditedFilters}
        />
    );
};

interface HeaderFilterBarContentProps {
    rowGeneratingFilters: RowGeneratingFilters;
    persona: SearchPersona;
    disableEdit: boolean;
    onSubmitEditedFilters: (filters: EditableCrunchbaseFilter[]) => Promise<void>;
}

const HeaderFilterBarContent: React.FC<HeaderFilterBarContentProps> = ({
    rowGeneratingFilters,
    persona,
    disableEdit,
    onSubmitEditedFilters,
}) => {
    const [visibleCount, setVisibleCount] = React.useState(rowGeneratingFilters.filters.length);
    const hiddenContainerRef = React.useRef<HTMLDivElement>(null);
    const itemWidths = React.useRef<number[]>([]);

    const theme = useTheme();
    const filterGap = React.useMemo(() => Number(theme.spacing(1).slice(0, -2)), [theme]);

    const handleResize = React.useCallback(
        (width: number) => {
            let totalWidth = 0;
            let count = 0;

            for (let i = 0; i < itemWidths.current.length; i++) {
                const itemWidth = itemWidths.current[i] + (i < itemWidths.current.length - 1 ? filterGap : 0);
                if (totalWidth + itemWidth > width) break;
                totalWidth += itemWidth;
                count++;
            }

            setVisibleCount(count);
        },
        [filterGap],
    );

    const { ref: resizeDetectorRef } = useResizeDetector({
        refreshRate: 1000,
        refreshMode: "debounce",
        onResize: ({ width }) => {
            if (width != null) {
                handleResize(width);
            }
        },
    });

    React.useEffect(() => {
        const measureItemWidths = () => {
            if (hiddenContainerRef.current) {
                itemWidths.current = Array.from(hiddenContainerRef.current.children).map(
                    child => (child as HTMLElement).offsetWidth,
                );
                if (resizeDetectorRef.current) {
                    handleResize((resizeDetectorRef.current as HTMLDivElement).offsetWidth);
                }
            }
        };

        measureItemWidths();
    }, [rowGeneratingFilters.filters.length, handleResize, resizeDetectorRef]);

    const filtersToShow = rowGeneratingFilters.filters.slice(0, visibleCount);
    const remainingCount = rowGeneratingFilters.filters.length - visibleCount;

    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const openEditDialog = () => setEditDialogOpen(true);
    const closeEditDialog = () => setEditDialogOpen(false);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: 3,
                    columnGap: 1,
                    overflowX: "hidden",
                }}
            >
                <Button
                    startIcon={<Edit2 size="16" color={theme.palette.common.white} />}
                    variant="contained"
                    color="secondary"
                    sx={{
                        flexShrink: 0,
                        textTransform: "none",
                        borderRadius: 8,
                    }}
                    disabled={disableEdit}
                    size="small"
                    onClick={openEditDialog}
                >
                    Edit filters
                </Button>
                <Box
                    ref={resizeDetectorRef}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        overflowX: "hidden",
                        flexGrow: 1,
                    }}
                >
                    {filtersToShow.map((filter, index) => (
                        <HeaderFilterBarFilter key={index} filter={filter} />
                    ))}
                </Box>
                {remainingCount > 0 && (
                    <Typography
                        sx={{
                            flexShrink: 0,
                        }}
                        noWrap
                        variant="body2"
                    >
                        {remainingCount === rowGeneratingFilters.filters.length
                            ? `${remainingCount} filters`
                            : `+ ${remainingCount} more`}
                    </Typography>
                )}
            </Box>
            <Box
                ref={hiddenContainerRef}
                sx={{
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: 0,
                    overflow: "hidden",
                    display: "flex",
                    columnGap: `${filterGap}px`,
                }}
            >
                {rowGeneratingFilters.filters.map((filter, index) => (
                    <div key={index} style={{ whiteSpace: "nowrap" }}>
                        <HeaderFilterBarFilter filter={filter} />
                    </div>
                ))}
            </Box>
            <EditGridFiltersDialog
                isOpen={editDialogOpen}
                onClose={closeEditDialog}
                rowGeneratingFilters={rowGeneratingFilters}
                persona={persona}
                onQuerySubmit={async (_q: string, filters: EditableCrunchbaseFilter[]) =>
                    onSubmitEditedFilters(filters)
                }
            />
        </>
    );
};
