import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SearchPersona } from "../personas";
import {
    EditableCrunchbaseFilter,
    EditableIncompleteCrunchbaseFilter,
    isCompleteEditableFilter,
    isCompleteCrunchbaseFilter,
} from "./types";

import * as React from "react";
import { EMPTY_FILTER, isCrunchbasePickableFilterPredicate } from "../../services/filterUtils";
import { RowGeneratingFilters } from "../../grid/grid";
import { isNonNullable } from "../../utils/isNonNullable";
import { Filters } from "./filters";
import { getCreateDisabledReason } from "../../query-to-grid/getCreateDisabledReason";
import ButtonWithDisabledTooltip from "../../reusable/buttonWithDisabledTooltip";
import { BeRowGeneratingFilter } from "../../services/cb-backend-types";
import { isEqual } from "lodash-es";

interface EditGridFiltersDialogProps {
    rowGeneratingFilters: RowGeneratingFilters;
    persona: SearchPersona;
    isOpen: boolean;
    onClose: () => void;
    onQuerySubmit: (q: string, filters: EditableCrunchbaseFilter[]) => Promise<void>;
}

function toEditableFilters(
    rowGeneratingFilters: RowGeneratingFilters,
    persona: SearchPersona,
): EditableCrunchbaseFilter[] {
    return rowGeneratingFilters.filters
        .map<EditableCrunchbaseFilter | undefined>(filter =>
            // TODO: we might throw away some filters here if they aren't renderable in the FE
            toEditableFilter(filter, persona),
        )
        .filter(isNonNullable);
}

function toEditableFilter(
    rowGeneratingFilter: BeRowGeneratingFilter,
    persona: SearchPersona,
): EditableCrunchbaseFilter | undefined {
    return isCrunchbasePickableFilterPredicate(rowGeneratingFilter.predicate)
        ? {
              isEditing: false,
              addAsColumn: rowGeneratingFilter.add_as_column,
              persona,
              filter: rowGeneratingFilter.predicate,
          }
        : undefined;
}

export const EditGridFiltersDialog: React.FC<EditGridFiltersDialogProps> = ({
    persona,
    isOpen,
    onClose,
    onQuerySubmit,
    rowGeneratingFilters,
}) => {
    const [filters, setFilters] = React.useState<EditableCrunchbaseFilter[]>(
        toEditableFilters(rowGeneratingFilters, persona),
    );
    const [newFilter, setNewFilter] = React.useState<EditableIncompleteCrunchbaseFilter>({
        ...EMPTY_FILTER,
        persona,
    });

    const [loading, setLoading] = React.useState(false);

    const handleSubmitQuery = React.useCallback(async () => {
        setLoading(true);
        try {
            const filtersToSubmit = [...filters];
            if (isCompleteEditableFilter(newFilter)) {
                filtersToSubmit.push({ ...newFilter, isEditing: false });
            }
            const filtersNonEditing = filtersToSubmit.map(f => ({ ...f, isEditing: false }));
            await onQuerySubmit("", filtersNonEditing);
            setFilters(filtersNonEditing);
            setNewFilter({ ...EMPTY_FILTER, persona });
        } finally {
            onClose();
            setLoading(false);
        }
    }, [filters, newFilter, onClose, onQuerySubmit, persona]);

    const handleCancel = React.useCallback(() => {
        setFilters(toEditableFilters(rowGeneratingFilters, persona));
        onClose();
    }, [onClose, rowGeneratingFilters, persona]);

    const hasChanges = React.useMemo(() => {
        const newFilters = filters.map<BeRowGeneratingFilter>(f => ({
            predicate: f.filter,
            add_as_column: f.addAsColumn,
        }));
        const newFiltersWithNewFilter = isCompleteCrunchbaseFilter(newFilter.filter)
            ? [...newFilters, { predicate: newFilter.filter, add_as_column: newFilter.addAsColumn }]
            : newFilters;

        return (
            rowGeneratingFilters.filters.length !== filters.length ||
            !isEqual(rowGeneratingFilters.filters, newFiltersWithNewFilter)
        );
    }, [filters, newFilter.addAsColumn, newFilter.filter, rowGeneratingFilters.filters]);

    const submitDisabledReason = React.useMemo(
        () => getCreateDisabledReason("", filters, newFilter, hasChanges, "editing"),
        [filters, hasChanges, newFilter],
    );

    if (persona !== "companies") {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Edit Grid Filters</DialogTitle>
            <DialogContent
                sx={{
                    height: 480,
                    scrollbarGutter: "stable",
                    // TODO: HACKHACKHACK. What's the best way to have a constant width for the dialog?
                    width: 570,
                }}
            >
                {!loading ? (
                    <Filters
                        mode="edit"
                        filters={filters}
                        setFilters={setFilters}
                        newFilter={newFilter}
                        setNewFilter={setNewFilter}
                        persona={persona}
                    />
                ) : (
                    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "primary.contrastText",
                            }}
                        />
                    </Container>
                )}
            </DialogContent>
            {!loading && (
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Cancel
                    </Button>
                    <ButtonWithDisabledTooltip
                        disabledTooltip={loading ? "Loading" : submitDisabledReason}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleSubmitQuery}
                        color="secondary"
                    >
                        Save
                    </ButtonWithDisabledTooltip>
                </DialogActions>
            )}
        </Dialog>
    );
};
