import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, CircularProgress } from "@mui/material";
import { getBackendService, RatelimitError } from "./services/cb-backend";
import { useCurrentUserQuery } from "./context/userContext";
import { useSnackbar } from "notistack";

const QUERY_PARAM_STARTING_GRID = "starting-grid";
export const StartTrial: React.FC = () => {
    const navigate = useNavigate();
    const backendService = React.useMemo(() => getBackendService(), []);
    const location = useLocation();
    const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    const queryClient = useQueryClient();

    const user = useCurrentUserQuery();

    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync, isError, isPending } = useMutation({
        mutationFn: () =>
            backendService.createTrialUser({
                starting_public_grid_unique_id: searchParams.get(QUERY_PARAM_STARTING_GRID) ?? undefined,
            }),
        onSuccess: async resp => {
            await queryClient.refetchQueries(undefined);
            if (resp.starting_grid_unique_id != null) {
                navigate(`/grid/${resp.starting_grid_unique_id}`, { replace: true });
            } else {
                navigate("/home", { replace: true });
            }
        },
        onError: error => {
            if (error instanceof RatelimitError) {
                enqueueSnackbar("We are experiencing high traffic. Please try again shortly by refreshing the page.", {
                    variant: "error",
                });
                return;
            }
            enqueueSnackbar(
                "We are experiencing some temporary issues due to high traffic. Please try again shortly by refreshing the page.",
                {
                    variant: "error",
                },
            );
        },
    });

    const hasStartingGrid = React.useMemo(() => searchParams.has(QUERY_PARAM_STARTING_GRID), [searchParams]);

    React.useEffect(() => {
        const startTrial = async () => {
            if (user.isFetching || user.isError || isPending || isError) {
                return;
            }

            if (
                user.data != null &&
                (user.data.plan_type !== "trial" ||
                    // Only create a new trial session if there is a startin grid passed in
                    (user.data.plan_type === "trial" && !hasStartingGrid))
            ) {
                navigate("/home", { replace: true });
                return;
            }
            await mutateAsync();
        };

        void startTrial();
    }, [isError, isPending, mutateAsync, navigate, searchParams, user, hasStartingGrid]);

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            {isError || user.isError ? (
                <>{"Failed to start trial"}</>
            ) : (
                <CircularProgress
                    sx={{
                        color: "primary.contrastText",
                    }}
                />
            )}
        </Container>
    );
};
