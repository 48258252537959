import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ColLabelInput } from "./colLabelInput";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";

interface UserInputColumnEditorDialogProps {
    isOpen: boolean;
    colLabel: string;
    onClose: () => void;
    onChangeColLabel: (newColLabel: string) => void;
    onUpdateColumn: (columnLabel: string) => void;
    initialColLabel: string;
}

export const UserInputColumnEditorDialog: React.FC<UserInputColumnEditorDialogProps> = ({
    isOpen,
    colLabel,
    onClose,
    onChangeColLabel,
    onUpdateColumn,
    initialColLabel,
}) => {
    const hasChanges = colLabel !== initialColLabel;

    const handleUpdateColumn = React.useCallback(() => {
        onUpdateColumn(colLabel.trim());
        onClose();
    }, [onUpdateColumn, colLabel, onClose]);

    const handleCancel = React.useCallback(() => {
        onChangeColLabel(initialColLabel);
        onClose();
    }, [initialColLabel, onClose, onChangeColLabel]);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Column Label</DialogTitle>
            <DialogContent>
                <ColLabelInput colLabel={colLabel} setColLabel={onChangeColLabel} labelSize="body1" />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    sx={{ textTransform: "none", typography: "body2", borderRadius: 8 }}
                    variant="text"
                    color="secondary"
                >
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    onClick={handleUpdateColumn}
                    variant="contained"
                    color="secondary"
                    sx={{ textTransform: "none", borderRadius: 8, typography: "body2" }}
                    disabledTooltip={getUpdateColumnDisabledReason(colLabel, hasChanges)}
                >
                    Update column
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};

function getUpdateColumnDisabledReason(colLabel: string, hasChanges: boolean) {
    if (!hasChanges) {
        return "No changes have been made";
    }
    if (colLabel.trim() === "") {
        return "Column label cannot be empty";
    }
    return undefined;
}
