import * as React from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { FAVICON_SIZE, extractDomain, getFavIconUrl } from "./utils";
import { Citation } from "../grid/grid";

export interface CitationCardProps {
    citation: Citation;
}

export const CitationCard: React.FC<CitationCardProps> = ({ citation: citationUrl }) => {
    const pageTitle = citationUrl.title;
    const favicon = getFavIconUrl(citationUrl.link);

    const handleGoToUrl = React.useCallback(() => {
        window.open(citationUrl.link, "_blank");
    }, [citationUrl]);

    return (
        <Tooltip title={citationUrl.title} enterDelay={200}>
            <Box
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: 1,
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                    maxWidth: "100%",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                }}
                onClick={handleGoToUrl}
            >
                {pageTitle != null && (
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "100%",
                        }}
                    >
                        {pageTitle}
                    </Typography>
                )}
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, overflow: "hidden", maxWidth: "100%" }}>
                    <Avatar src={favicon} sx={{ width: FAVICON_SIZE, height: FAVICON_SIZE }} alt="Site Icon" />
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            color: "primary.light",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {extractDomain(citationUrl.link)}
                    </Typography>
                </Box>
            </Box>
        </Tooltip>
    );
};
