import React from "react";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { styled } from "@mui/system";
import { MaterialDesignContent } from "notistack";

// Define your styled component
const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
    "&.notistack-MuiContent-success": {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.success.main || "#7AB143",
    },
    "&.notistack-MuiContent-error": {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.error.main || "#ED6E58",
    },
    "&.notistack-MuiContent-warning": {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.warning.main || "#FBBC45",
    },
    "&.notistack-MuiContent-info": {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        backgroundColor: theme.palette.info.main || "#0071E3",
    },
    "&.notistack-MuiContent-regular": {
        backgroundColor: "#365934",
    },
}));

type AnswerGridSnackbarProviderProps = Omit<SnackbarProviderProps, "Components">;

export const AnswerGridSnackbarProvider: React.FC<AnswerGridSnackbarProviderProps> = props => {
    const components = React.useMemo(
        () => ({
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            regular: StyledMaterialDesignContent,
        }),
        [],
    );

    return <SnackbarProvider Components={components} {...props} />;
};
