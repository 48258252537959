import * as React from "react";
import { Box, Typography, Button, Divider, Grid, lighten } from "@mui/material";
import { TickCircle } from "iconsax-react";

interface PlanCardGridProps {
    cardsPerRow: number;
    onGetStartedWithFreeClick: () => void;
    onGetStartedWithBasicClick: () => void;
    onGetStartedWithProClick: () => void;
    onContactUsClick: () => void;
    currentPlan?: "free" | "basic" | "pro";
}

export const PlanCardGrid: React.FC<PlanCardGridProps> = ({
    cardsPerRow,
    onGetStartedWithFreeClick,
    onGetStartedWithBasicClick,
    onGetStartedWithProClick,
    onContactUsClick,
    currentPlan,
}) => {
    return (
        <Grid
            container
            justifyContent="center"
            maxWidth={cardsPerRow * 300 + 20}
            minWidth={cardsPerRow * 300 + 20}
            sx={{
                flexShrink: 0,
                overflowX: "auto",
                py: 1,
            }}
        >
            <Grid item sx={{ minWidth: 300 }}>
                <PlanCard
                    name="Free trial"
                    price="$0"
                    description="Free for 7 days"
                    features={[
                        "100 rows across all grids",
                        "1,000 AI answers",
                        "10 grids",
                        "10 columns per grid",
                        "CSV export (no contact info)",
                    ]}
                    buttonText={getButtonText(currentPlan, "Free")}
                    rightBorder={cardsPerRow > 1}
                    buttonOnClick={onGetStartedWithFreeClick}
                    isCurrentPlan={currentPlan === "free"}
                />
            </Grid>
            <Grid item sx={{ minWidth: 300 }}>
                <PlanCard
                    name="Basic"
                    price="$150 per month"
                    description="Starting plan for infrequent use"
                    features={[
                        "400 row export limit",
                        "4,000 AI answers",
                        "Unlimited grids",
                        "Unlimited columns",
                        "CSV import",
                        "CSV export",
                    ]}
                    buttonText={getButtonText(currentPlan, "Basic")}
                    buttonOnClick={onGetStartedWithBasicClick}
                    isCurrentPlan={currentPlan === "basic"}
                />
            </Grid>
            <Grid item sx={{ minWidth: 300 }}>
                <PlanCard
                    name="Pro"
                    price="$300 per month"
                    description="Our most popular plan"
                    features={[
                        "1,000 row export limit",
                        "10,000 AI answers",
                        "Unlimited grids",
                        "Unlimited columns",
                        "CSV import",
                        "CSV export",
                    ]}
                    buttonText={getButtonText(currentPlan, "Pro")}
                    highlighted={true}
                    buttonOnClick={onGetStartedWithProClick}
                    isCurrentPlan={currentPlan === "pro"}
                />
            </Grid>
            <Grid item sx={{ minWidth: 300 }}>
                <PlanCard
                    name="Enterprise"
                    price="Custom pricing"
                    description="Custom pricing for large scale"
                    features={["Unlimited exports", "Unlimited AI answers", "Team plans"]}
                    buttonText="Contact us"
                    buttonOnClick={onContactUsClick}
                    isCurrentPlan={false}
                />
            </Grid>
        </Grid>
    );
};

function getButtonText(currentPlan: "free" | "basic" | "pro" | undefined, name: "Free" | "Basic" | "Pro") {
    if (currentPlan === undefined) {
        return "Get started";
    }

    switch (currentPlan) {
        case "free":
            return "Upgrade";
        case "basic":
            return name === "Pro" ? "Upgrade" : "Switch";
        case "pro":
            return "Switch";
    }
}

interface PlanCardProps {
    name: "Free trial" | "Basic" | "Pro" | "Enterprise";
    price: string;
    description: string;
    discount?: string;
    features: string[];
    buttonText: string;
    highlighted?: boolean;
    rightBorder?: boolean;
    buttonOnClick?: () => void;
    isCurrentPlan: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
    name,
    price,
    description,
    discount,
    features,
    buttonText,
    highlighted = false,
    rightBorder = false,
    buttonOnClick,
    isCurrentPlan,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                py: 2,
                borderRadius: highlighted ? 2 : undefined,
                border: highlighted ? "2px solid #E3DAC8" : undefined,
                boxShadow: highlighted ? undefined : rightBorder ? "1px 0 0 0 rgba(0, 0, 0, 0.12)" : undefined,
                position: "relative",
                height: "100%", // Fill the parent height
            }}
        >
            <Box sx={{ display: "flex", alignItems: "start", flexDirection: "column", px: 2.5 }}>
                <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} color="secondary.main">
                    {name}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#667085",
                        mb: 1,
                    }}
                >
                    {price}
                </Typography>
                <Typography variant="caption" sx={{ mb: 2, color: "#667085" }}>
                    {description}
                </Typography>
                {discount != null && (
                    <Typography
                        sx={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            bgcolor: "secondary.main",
                            color: "white",
                            px: 1,
                            borderRadius: 1,
                        }}
                    >
                        {discount}
                    </Typography>
                )}
            </Box>
            <Divider sx={{ width: "100%", mb: 2 }} />
            {/* TODO: Yearly billing */}
            {/* {name !== "Free" && name !== "Enterprise" && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        Billed yearly
                    </Typography>
                    <Switch size="small" />
                </Box>
            )} */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    px: 2.5,
                }}
            >
                <Box sx={{ mb: 3, height: 250 }}>
                    {features.map((feature, index) => (
                        <Typography
                            key={index}
                            color="#667085"
                            sx={{ display: "flex", alignItems: "center", mb: 1, columnGap: 1, fontSize: 14 }}
                        >
                            <TickCircle size={14} color="#667085" variant="Bold" />
                            {feature}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ display: "flex", columnGap: 1 }}>
                    <Button
                        variant={highlighted ? "contained" : "outlined"}
                        color={highlighted ? "secondary" : undefined}
                        size="large"
                        onClick={buttonOnClick}
                        disabled={isCurrentPlan}
                        sx={theme => ({
                            borderRadius: isCurrentPlan ? undefined : 50,
                            textTransform: "none",
                            borderColor: highlighted ? "secondary.main" : "divider",
                            color: highlighted ? "white" : "#465467",
                            bgcolor: isCurrentPlan ? lighten(theme.palette.secondary.main, 0.98) : undefined,
                        })}
                    >
                        {isCurrentPlan ? "Current plan" : buttonText}
                    </Button>
                    {isCurrentPlan && name !== "Enterprise" && name !== "Free trial" && (
                        <Button color="secondary" size="large" onClick={buttonOnClick}>
                            Manage
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
