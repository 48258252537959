import * as React from "react";
import { SuggestedQueries } from "./suggestedQueries";

export interface CompanyPersonaExamplesProps {
    onQuerySubmit: (query: string) => void;
}

const COMPANY_EXAMPLES: readonly [string, string, string, string] = [
    "AI companies in San Francisco",
    "LatAm Fintechs with <100 employees",
    "NY Web3 startups who've raised a Series A",
    "European Seed to Series B Biotech startups",
] as const;

export const CompanyPersonaExamples: React.FC<CompanyPersonaExamplesProps> = ({ onQuerySubmit }) => {
    return <SuggestedQueries queries={COMPANY_EXAMPLES} onClick={onQuerySubmit} />;
};
