import { Box, Button, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useExportToCSV } from "../csv/useExportToCsv";
import { ReactComponent as Sparkles } from "../assets/sparkles.svg";
import { Column, ExportMode, ExportProgress, hasTrialFeatures, Row, RowGeneratingFilters } from "./grid";
import { TickCircle, Import, UserAdd } from "iconsax-react";
import { getBackendService } from "../services/cb-backend";
import { convertBackendGridToFrontend } from "./gridSerialization";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getReactQueryGridKey } from "../react-query/queryKeys";
import { AnswerGridHeaderActionsMenu } from "./answerGridHeaderActionsMenu";
import { useCurrentUserQuery } from "../context/userContext";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { useBoolean } from "../utils/hooks";
import { hasMoreRows, isFinalTaskStatus } from "./gridUtils";
import { useShareGrid } from "./useShareGrid";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { GenerateRowsDialog } from "./generateRowsDialog";
import { ExportStatusIcon } from "./exportStatusIcon";
import { useFreeTrialUsage } from "./userUsageQuery";

export interface AnswerGridHeaderActionsProps {
    columns: Column[];
    rows: Row[];
    visibleRows: Row[];
    rowGeneratingFilters: RowGeneratingFilters;
    gridId: string;
    name: string;
    version: number;
    exportStatus: ExportProgress | undefined;
    onGenerateRows: (totalDesiredRowCount: number, mode: ExportMode) => Promise<void>;
    setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
    setRows: React.Dispatch<React.SetStateAction<Row[]>>;
    setRowGeneratingFilters: React.Dispatch<React.SetStateAction<RowGeneratingFilters>>;
}

export const AnswerGridHeaderActions: React.FC<AnswerGridHeaderActionsProps> = ({
    columns,
    rows,
    visibleRows,
    rowGeneratingFilters,
    version,
    gridId,
    name,
    exportStatus,
    onGenerateRows,
    setExportMode,
    setRowGeneratingFilters,
    setRows,
}) => {
    const exportToCSV = useExportToCSV();

    const backendService = React.useMemo(() => getBackendService(), []);
    const queryClient = useQueryClient();

    const removeExportTaskIdMutation = useMutation({
        mutationFn: (gridId: string) =>
            backendService.updateGrid(gridId, {
                last_export_task_id: undefined,
            }),
        onSuccess: grid => {
            queryClient.setQueryData(getReactQueryGridKey(gridId), convertBackendGridToFrontend(grid));
        },
    });

    const disableLongRunningExportButton = React.useMemo(
        () => exportStatus == null || gridId == null,
        [exportStatus, gridId],
    );
    const handleExportAfterLongTask = React.useCallback(async () => {
        if (disableLongRunningExportButton || gridId == null) {
            return;
        }
        const desiredNumRows = exportStatus?.numExported;
        await exportToCSV(
            visibleRows,
            columns,
            `${name}.csv`,
            visibleRows.length < rows.length ? visibleRows.length : desiredNumRows,
        );
        await removeExportTaskIdMutation.mutateAsync(gridId);
    }, [
        columns,
        disableLongRunningExportButton,
        exportStatus?.numExported,
        exportToCSV,
        gridId,
        name,
        removeExportTaskIdMutation,
        rows.length,
        visibleRows,
    ]);

    const handleGenerateNewRows = React.useCallback(
        async (totalDesiredRowCount: number) => onGenerateRows(totalDesiredRowCount, ExportMode.Generate),
        [onGenerateRows],
    );

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: 1,
            }}
        >
            <AnswerGridHeaderProminentActions
                onGenerateRows={handleGenerateNewRows}
                totalRowCount={rows.length}
                rowGeneratingFilters={rowGeneratingFilters}
                isExportOnGoing={exportStatus != null && !isFinalTaskStatus(exportStatus.status)}
                gridId={gridId}
            />
            {exportStatus != null && (
                <ActiveExportInfo
                    exportStatus={exportStatus}
                    disableExportButton={disableLongRunningExportButton}
                    handleExportAfterLongTask={handleExportAfterLongTask}
                />
            )}
            <AnswerGridHeaderActionsMenu
                columns={columns}
                rows={rows}
                visibleRows={visibleRows}
                rowGeneratingFilters={rowGeneratingFilters}
                gridId={gridId}
                name={name}
                version={version}
                exportStatus={exportStatus}
                onGenerateRows={onGenerateRows}
                setExportMode={setExportMode}
                setRowGeneratingFilters={setRowGeneratingFilters}
                setRows={setRows}
            />
        </Box>
    );
};

interface ActiveExportInfoProps {
    exportStatus: ExportProgress;
    disableExportButton: boolean;
    handleExportAfterLongTask: () => Promise<void>;
}

const ActiveExportInfo: React.FC<ActiveExportInfoProps> = ({
    disableExportButton,
    exportStatus,
    handleExportAfterLongTask,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {exportStatus?.status === "SUCCESS" && exportStatus.exportMode === ExportMode.Export && (
                <Button
                    disabled={disableExportButton}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleExportAfterLongTask}
                    color="secondary"
                    startIcon={<Import size={16} />}
                    variant="outlined"
                    size="small"
                    sx={{
                        textTransform: "none",
                        typography: "caption",
                        borderRadius: 8,
                        mr: 1,
                        "& .MuiButton-startIcon": {
                            marginRight: 0.5,
                        },
                    }}
                >
                    Download
                </Button>
            )}
            {exportStatus != null && <ExportStatusIcon status={exportStatus.status} />}
            {exportStatus != null && (exportStatus.numExported != 0 || exportStatus.total != 0) && (
                <Typography
                    variant="caption"
                    sx={{
                        color: "secondary.main",
                        ml: 0.5,
                    }}
                >
                    {exportStatus.numExported.toString() + "/" + exportStatus.total.toString()}
                </Typography>
            )}
        </Box>
    );
};

interface AnswerGridHeaderProminentActionsProps {
    /** Button will be hidden if this is undefined */
    onGenerateRows: ((rowCount: number) => Promise<void>) | undefined;
    totalRowCount: number;
    rowGeneratingFilters: RowGeneratingFilters;
    isExportOnGoing: boolean;
    gridId: string;
}

const AnswerGridHeaderProminentActions: React.FC<AnswerGridHeaderProminentActionsProps> = ({
    onGenerateRows,
    totalRowCount,
    rowGeneratingFilters,
    isExportOnGoing,
    gridId,
}) => {
    const userQuery = useCurrentUserQuery();
    const { shareGrid, isSharingGridLoading } = useShareGrid();
    const { enqueueSnackbar } = useSnackbar();
    const [isShared, setIsShared] = useState(false);
    const {
        value: isGenerateDialogOpen,
        setTrue: openGenerateDialog,
        setFalse: closeGenerateDialog,
    } = useBoolean(false);

    const theme = useTheme();

    const handleShareGrid = React.useCallback(async () => {
        if (!userQuery.data) return;

        const shareResult = await shareGrid(gridId, true); // Always true for trial users
        if (shareResult.success) {
            setIsShared(true);
            setTimeout(() => setIsShared(false), 5000);
        } else {
            enqueueSnackbar("Failed to share grid", { variant: "error" });
        }
    }, [enqueueSnackbar, gridId, shareGrid, userQuery.data]);

    const freeTrialUsage = useFreeTrialUsage();

    if (!userQuery.data) {
        return null;
    }

    const isTrial = hasTrialFeatures(userQuery.data.plan_type);

    if (isTrial) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ButtonWithDisabledTooltip
                    variant="outlined"
                    size="small"
                    color={isShared ? "success" : undefined}
                    startIcon={
                        isShared ? <TickCircle size={16} color={theme.palette.success.main} /> : <UserAdd size={16} />
                    }
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleShareGrid}
                    disabledTooltip={isSharingGridLoading ? "Sharing in progress…" : undefined}
                    sx={{
                        textTransform: "none",
                        typography: "caption",
                        borderRadius: 8,
                        color: isShared ? "success.main" : "primary.contrastText",
                        borderColor: "divider",
                        "&:hover": {
                            borderColor: "divider",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: 0.5,
                            transition: "all 0.3s ease",
                        },
                    }}
                >
                    {!isShared && (
                        <Typography
                            variant="caption"
                            noWrap
                            sx={{
                                visibility: isShared ? "hidden" : "visible",
                                opacity: isShared ? 0 : 1,
                                transition: "all 0.3s ease",
                            }}
                        >
                            Copy share link
                        </Typography>
                    )}
                    {isShared && (
                        <Typography
                            variant="caption"
                            noWrap
                            sx={{
                                visibility: isShared ? "visible" : "hidden",
                                opacity: isShared ? 1 : 0,
                                transition: "all 0.3s ease",
                            }}
                        >
                            Copied share link
                        </Typography>
                    )}
                </ButtonWithDisabledTooltip>
            </Box>
        );
    }

    if (onGenerateRows == null) {
        return null;
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!isExportOnGoing && rowGeneratingFilters.filters.length > 0 && (
                <ButtonWithDisabledTooltip
                    variant="outlined"
                    size="small"
                    startIcon={
                        <Sparkles
                            style={{
                                width: 16,
                                height: 16,
                                color: theme.palette.success.main,
                            }}
                        />
                    }
                    onClick={openGenerateDialog}
                    sx={{
                        textTransform: "none",
                        typography: "caption",
                        borderRadius: 8,
                        color: "primary.contrastText",
                        borderColor: "divider",
                        "&:hover": {
                            borderColor: "divider",
                        },
                        "& .MuiButton-startIcon": {
                            marginRight: 0.5,
                        },
                    }}
                    disabledTooltip={getDisabledTooltip(
                        rowGeneratingFilters,
                        totalRowCount,
                        freeTrialUsage.data?.has_reached_free_trial_limit ?? false,
                    )}
                >
                    <Typography variant="caption" noWrap>
                        Generate rows
                    </Typography>
                </ButtonWithDisabledTooltip>
            )}
            <GenerateRowsDialog
                isOpen={isGenerateDialogOpen}
                totalRowCount={totalRowCount}
                onClose={closeGenerateDialog}
                onGenerateRows={onGenerateRows}
                totalAvailableRows={rowGeneratingFilters.pagingInfo?.totalCount ?? 0}
            />
        </Box>
    );
};

function getDisabledTooltip(
    rowGeneratingFilters: RowGeneratingFilters,
    totalRowCount: number,
    isFreeTrialUsageLimitExceeded: boolean,
) {
    if (!hasMoreRows(rowGeneratingFilters, totalRowCount)) {
        return "No more rows available";
    }
    if (isFreeTrialUsageLimitExceeded) {
        return "You've reached your row generation limit. Upgrade to a paid plan to generate more rows.";
    }
    return undefined;
}
