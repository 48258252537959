import * as React from "react";
import { useRedirectToLoginIfLoggedOut } from "../utils/redirectToLogin";
import { useParams } from "react-router-dom";
import { useTrackPageView } from "../mixpanel";
import { GridViewContent } from "./gridViewContent";

export const GridView: React.FC = () => {
    const { gridId } = useParams<{ gridId: string }>();
    useRedirectToLoginIfLoggedOut();
    useTrackPageView();

    return <GridViewContent gridId={gridId} />;
};
