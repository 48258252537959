import React from "react";
import { TextField, Typography, Box, InputAdornment, TextFieldProps } from "@mui/material";

interface FormTextFieldProps extends Omit<TextFieldProps, "variant"> {
    label: string;
    icon: React.ReactNode;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({ label, icon, ...props }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption">{label}</Typography>
            <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                sx={{ mt: 0 }}
                inputProps={{ sx: { typography: "body2" } }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                }}
                {...props}
            />
        </Box>
    );
};
