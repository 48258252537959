import { BeRowKey, TrackCompanyExportRequest } from "../services/cb-backend-types";
import { Row, Column, isRealRow, APPerson, RealRow, getKeyFromExternalIdentifiers, APPersonField } from "../grid/grid";
import { partition } from "../utils/arrays";
import { cellValueV2ToString } from "../grid/gridUtils";
import { uniqBy } from "lodash";

const APOLLO_COLS = ["Full Name", "First Name", "Last Name", "Email", "Linkedin", "Title"] as const;

const EMPTY_VALUE = "Unknown";

export const MAX_ROWS_PER_COMPANY = 5;

// Helper function to convert data to CSV
export const convertToCSV = (
    rows: Row[],
    columns: Column[],
    maxRowsPerCompany: number = MAX_ROWS_PER_COMPANY,
    dropContactInfo = false,
): string => {
    const [apolloCols, nonApolloCols] = partition(columns, col => col.generatedBy?.type === "apollo");

    if (apolloCols.length === 0) {
        const header = nonApolloCols.map(col => escapeCSVValue(col.label)).join(",");

        const dataRows = rows
            .filter(isRealRow)
            .map(row => {
                const nonApolloData = nonApolloCols.map(col => {
                    const key = col.id as keyof typeof row.data;
                    const cell = row.data[key];
                    return escapeCSVValue(cellValueV2ToString(cell.value));
                });

                return nonApolloData.join(",");
            })
            .join("\n");

        return `${header}\n${dataRows}`;
    }

    // Extract column headers
    const header = [...nonApolloCols, ...APOLLO_COLS]
        .map(col => escapeCSVValue(typeof col === "string" ? col : col.label))
        .join(",");

    // Extract rows data
    const dataRows = rows
        .filter(isRealRow)
        .flatMap(row => {
            const nonApolloData = nonApolloCols.map(col => {
                const key = col.id as keyof typeof row.data;
                const cell = row.data[key];
                return escapeCSVValue(cellValueV2ToString(cell.value));
            });

            const allPeople = apolloCols.flatMap(col => {
                const key = col.id as keyof typeof row.data;
                const cell = row.data[key];
                if (cell.value.type === "apollo_people") {
                    return cell.value.people;
                }
                return [];
            });

            const uniquePeople = getUniquePeople(allPeople, maxRowsPerCompany);

            if (uniquePeople.length === 0) {
                return [nonApolloData.concat(APOLLO_COLS.map(() => escapeCSVValue(EMPTY_VALUE))).join(",")];
            }

            return uniquePeople.map(person => {
                const apolloData = APOLLO_COLS.map((col, idx) =>
                    escapeCSVValue(getPartFromApolloPerson(person, idx, dropContactInfo)),
                );
                return nonApolloData.concat(apolloData).join(",");
            });
        })
        .join("\n");

    return `${header}\n${dataRows}`;
};

/**
 * Gets the unique people from the list of people by filtering out duplicates based on linkedin and emai. If neither are defined,
 * then there may be duplicates. We always slice to the first {maxRowsPerCompany} people to avoid performance issues.
 */
function getUniquePeople(people: APPerson[], maxRowsPerCompany: number): APPerson[] {
    const [peopleWithLinkedin, peopleWithoutLinkedin] = partition(people, person => person.linkedin.type === "string");
    const [peopleWithEmail, peopleWithoutEmail] = partition(
        peopleWithoutLinkedin,
        person => person.email.type === "string",
    );
    const uniquePeopleWithLinkedin = uniqBy(peopleWithLinkedin, person =>
        person.linkedin.type === "string" ? person.linkedin.value : "",
    );
    const uniquePeopleWithEmail = uniqBy(peopleWithEmail, person =>
        person.email.type === "string" ? person.email.value : "",
    );
    const uniquePeople = [...uniquePeopleWithLinkedin, ...uniquePeopleWithEmail, ...peopleWithoutEmail].slice(
        0,
        maxRowsPerCompany,
    );
    return uniquePeople;
}

export function getPartFromApolloPerson(person: APPerson, part: number, dropContactInfo: boolean): string {
    switch (part) {
        case 0:
            return getValueFromApolloPersonField(person.name) ?? "Unknown name";
        case 1:
            return getValueFromApolloPersonField(person.name)?.split(" ")[0] ?? "Unknown first name";
        case 2: {
            const split = getValueFromApolloPersonField(person.name)?.split(" ");
            return split?.[split.length - 1] ?? "Unknown last name";
        }
        case 3:
            return !dropContactInfo
                ? getValueFromApolloPersonField(person.email) ?? "Unknown email"
                : "Available on paid plans";
        case 4:
            return !dropContactInfo
                ? getValueFromApolloPersonField(person.linkedin) ?? "Unknown linkedin"
                : "Available on paid plans";
        case 5:
            return getValueFromApolloPersonField(person.title) ?? "Unknown title";
        default:
            return "ERROR IN VALUE TYPE";
    }
}

// TODO: Different way for eahc field?
function getValueFromApolloPersonField(field: APPersonField): string | undefined {
    if (field.type === "string") {
        return field.value;
    }
    return undefined;
}

export function escapeCSVValue(value: string | undefined): string {
    if (value == null) {
        return "";
    }
    return `"${value.replace(/"/g, '""').replace(/\n/g, "\\n").replace(/\r/g, "\\r")}"`;
}

export const prepareTrackRequest = (rows: RealRow[]): TrackCompanyExportRequest => ({
    rows_to_export: rows.map<BeRowKey>(row => ({
        title: "Name",
        value: row.data.name.value.value,
        external_identifiers: row.externalIdentifiers,
    })),
});
export const filterAllowedRows = (rows: Row[], allowedKeys: BeRowKey[]): Row[] => {
    const allowedUrls = new Set(allowedKeys.map(key => getKeyFromExternalIdentifiers(key.external_identifiers)));
    return rows.filter(
        row =>
            !isRealRow(row) ||
            !isCompanyRealRow(row) ||
            allowedUrls.has(getKeyFromExternalIdentifiers(row.externalIdentifiers)),
    );
};

export const createAndDownloadCsv = (csvData: string, filename: string) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export function isCompanyRealRow(row: RealRow): boolean {
    return row.externalIdentifiers?.apollo != null || row.externalIdentifiers?.crunchbase != null;
}
