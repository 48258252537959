import * as React from "react";
import { ApolloPersonFilters } from "../../services/cb-backend-types";
import { SearchableApolloPersonRoleSelect } from "./searchableApolloPersonRoleSelect";

export interface ApolloApolloEditableFilterValuePickerProps {
    config: ApolloPersonFilters;
    setConfig: (config: ApolloPersonFilters) => void;
    fieldKey: "person_titles";
    style?: "small" | "medium";
}

export const ApolloEditableFilterValuePicker: React.FC<ApolloApolloEditableFilterValuePickerProps> = ({
    config,
    setConfig,
    fieldKey,
    style = "medium",
}) => {
    const handleOnPersonTitleValueChange = React.useCallback(
        (values: string[]) => {
            setConfig({ ...config, person_titles: values });
        },
        [config, setConfig],
    );
    switch (fieldKey) {
        case "person_titles":
            return (
                <EditablePersonTitlesValuePicker
                    values={config.person_titles}
                    onPersonTitleValueChange={handleOnPersonTitleValueChange}
                    style={style}
                />
            );
    }
};

// EditableDescriptionFilter.tsx
const EditablePersonTitlesValuePicker: React.FC<{
    values: string[];
    onPersonTitleValueChange: (newValues: string[]) => void;
    style?: "small" | "medium";
}> = ({ values, onPersonTitleValueChange, style = "medium" }) => {
    const handleChange = React.useCallback(
        (newValue: string[]) => {
            onPersonTitleValueChange(newValue);
        },
        [onPersonTitleValueChange],
    );

    return <SearchableApolloPersonRoleSelect fullWidth values={values} setValues={handleChange} style={style} />;
};
