import * as React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PositiveIntegerInput } from "../reusable/positiveIntegerInput";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";

interface TruncationLimitDialogProps {
    isOpen: boolean;
    onClose: () => void;
    rowCount: number;
    truncationLimit: number | null;
    setTruncationLimit: (limit: number | null) => void;
    onTruncate: (truncationLimit: number) => void;
}

export const TruncationLimitDialog: React.FC<TruncationLimitDialogProps> = ({
    isOpen,
    onClose,
    rowCount,
    truncationLimit,
    setTruncationLimit,
    onTruncate,
}) => {
    const handleClose = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const handleConfirm = React.useCallback(() => {
        if (truncationLimit == null) {
            return;
        }
        onTruncate(truncationLimit);
        onClose();
    }, [onClose, onTruncate, truncationLimit]);

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
            <DialogTitle>Truncate Grid</DialogTitle>
            <DialogContent
                sx={{
                    width: 250,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                        maxWidth: 200,
                    }}
                >
                    Number of rows
                    <PositiveIntegerInput
                        value={truncationLimit}
                        onChange={setTruncationLimit}
                        min={1}
                        max={rowCount - 1}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    disabledTooltip={
                        truncationLimit == null ? `Enter a number between 1 and ${rowCount - 1}` : undefined
                    }
                    onClick={handleConfirm}
                    color="secondary"
                >
                    Confirm
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};
