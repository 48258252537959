import * as React from "react";
import { getBackendService } from "../services/cb-backend"; // Adjust the import path as needed
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getReactQueryGridKey } from "../react-query/queryKeys";
import { convertBackendGridToFrontend } from "../grid/gridSerialization";
import { updateAvailableGridsOnDelete, updateAvailableGridsOnUpdate } from "../context/gridContextAndProvider";

export const useRenameGrid = () => {
    const service = React.useMemo(() => getBackendService(), []);

    const queryClient = useQueryClient();
    const removeExportTaskIdMutation = useMutation({
        mutationFn: (variables: { gridId: string; newName: string }) =>
            service.updateGrid(variables.gridId, {
                name: variables.newName,
            }),
        onSuccess: grid => {
            queryClient.setQueryData(getReactQueryGridKey(grid.unique_id), convertBackendGridToFrontend(grid));
        },
    });

    return React.useCallback(
        async (gridId: string, newName: string) => {
            if (newName && newName.trim() !== "") {
                try {
                    updateAvailableGridsOnUpdate(queryClient, {
                        unique_id: gridId,
                        name: newName,
                    });
                    await removeExportTaskIdMutation.mutateAsync({ gridId, newName });
                } catch (error) {
                    console.error("Failed to rename grid", error);
                }
            }
        },
        [queryClient, removeExportTaskIdMutation],
    );
};

export const useDeleteGrid = () => {
    const service = React.useMemo(() => getBackendService(), []);

    const queryClient = useQueryClient();

    return React.useCallback(
        async (gridId: string) => {
            const confirmed = confirm(`Are you sure you want to delete this grid?`);
            if (confirmed) {
                try {
                    updateAvailableGridsOnDelete(queryClient, gridId);
                    await service.deleteGrid(gridId);
                } catch (error) {
                    console.error("Failed to delete grid", error);
                }
            }
        },
        [queryClient, service],
    );
};
