import * as React from "react";
import {
    CrunchbaseAutoCompleteCollectionId,
    CrunchbaseAutocompleteEntity,
    EntityIdentifier,
} from "../../services/cb-backend-types";
import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField } from "@mui/material";
import { ValueChip } from "./valueChip";
import { getBackendService } from "../../services/cb-backend";
import { debounce } from "lodash-es";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { identity } from "../../utils/functions";
import { getReactQueryCbAutocompleteQuery } from "../../react-query/queryKeys";

function areOptionsEqual(option: CrunchbaseAutocompleteEntity, value: CrunchbaseAutocompleteEntity) {
    return (
        option.identifier.uuid === value.identifier.uuid || option.identifier.permalink === value.identifier.permalink
    );
}

export interface SearchableEntitySelectProps {
    fullWidth?: boolean;
    values: EntityIdentifier[];
    entityCategoryIds: CrunchbaseAutoCompleteCollectionId[];
    setValues: (vs: Array<EntityIdentifier>) => void;
    getValueFromEntityIdentifier: (v: EntityIdentifier) => string;
    useEntityShortDescriptionAsLabel?: boolean;
}

export const SearchableEntitySelect: React.FC<SearchableEntitySelectProps> = ({
    values,
    setValues,
    getValueFromEntityIdentifier: getValueTitle,
    fullWidth,
    entityCategoryIds,
    useEntityShortDescriptionAsLabel = false,
}) => {
    const service = React.useMemo(() => getBackendService(), []);

    const [debouncedInputValue, setDebouncedInputValue] = React.useState<string>("");

    const handleDebouncedInputChange = React.useMemo(
        () =>
            debounce(setDebouncedInputValue, 300, {
                leading: true,
                trailing: true,
            }),
        [],
    );

    const { data: autocompleteValues, isFetching } = useQuery({
        queryKey: getReactQueryCbAutocompleteQuery(entityCategoryIds, debouncedInputValue),
        queryFn: () => service.crunchbaseAutocomplete(debouncedInputValue, entityCategoryIds),
        enabled: debouncedInputValue.trim() !== "",
        initialData: { entities: [] },
        placeholderData: keepPreviousData,
        select: data => data.entities,
    });

    const handleChange = React.useCallback(
        (e: unknown, v: CrunchbaseAutocompleteEntity[]) => {
            setValues(v.map(v => v.identifier));
        },
        [setValues],
    );

    const handleSetInputValue = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            handleDebouncedInputChange(e.target.value);
        },
        [handleDebouncedInputChange],
    );

    const renderInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                placeholder="Enter values"
                size="small"
                onChange={handleSetInputValue}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        ),
        [handleSetInputValue, isFetching],
    );

    const handleClose = React.useCallback(() => {
        setDebouncedInputValue("");
    }, []);

    const getOptionLabel = React.useCallback(
        (option: CrunchbaseAutocompleteEntity) =>
            useEntityShortDescriptionAsLabel
                ? option.short_description ?? getValueTitle(option.identifier) ?? getValueTitle(option.identifier)
                : getValueTitle(option.identifier),
        [getValueTitle, useEntityShortDescriptionAsLabel],
    );

    const valueAsAutocompleteEntities = React.useMemo(
        () =>
            values.map<CrunchbaseAutocompleteEntity>(v => ({
                identifier: v,
                facet_ids: undefined,
                short_description: undefined,
            })),
        [values],
    );

    return (
        <Autocomplete
            fullWidth={fullWidth}
            multiple
            filterSelectedOptions
            onClose={handleClose}
            isOptionEqualToValue={areOptionsEqual}
            id="searchable-entity-select"
            options={autocompleteValues}
            getOptionLabel={getOptionLabel}
            renderTags={(value: readonly CrunchbaseAutocompleteEntity[], getTagProps) =>
                value.map((option: CrunchbaseAutocompleteEntity, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <ValueChip {...tagProps} label={getOptionLabel(option)} key={key} />;
                })
            }
            noOptionsText={
                debouncedInputValue.trim() === ""
                    ? "Start typing for suggestions"
                    : isFetching
                      ? "Searching..."
                      : "No results found"
            }
            renderInput={renderInput}
            onChange={handleChange}
            value={valueAsAutocompleteEntities}
            freeSolo={false}
            loading={isFetching}
            filterOptions={identity}
        />
    );
};
