import * as React from "react";
import {
    APPerson,
    CellValueV2,
    CellValueV2ApolloPeople,
    RowValueWithCitations,
    APTechnology,
    CellValueV2Technologies,
    CellValueV2JobPostings,
    ApolloJobPosting,
    APPersonField,
} from "../grid/grid";
import { formatDisplayUrl, isValidUrl } from "./utils";
import { Box, IconButton, Link, SxProps, Theme, Typography } from "@mui/material";
import {
    ArrowDown2,
    ArrowUp2,
    Box2,
    Calendar,
    Call,
    Category,
    Link1,
    Location,
    Personalcard,
    ProfileCircle,
    Sms,
} from "iconsax-react";
import { LinkedIn } from "@mui/icons-material";
import { useCurrentUserQuery } from "../context/userContext";
import { getMixpanelInstance } from "../mixpanel";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { parsePhoneNumber } from "libphonenumber-js";

export interface SelectedCellValueProps {
    valueWithCitations: RowValueWithCitations<CellValueV2>;
    containerSx?: SxProps<Theme>;
}

export const SelectedCellValue: React.FC<SelectedCellValueProps> = ({ valueWithCitations, containerSx }) => {
    if (valueWithCitations.value.type === "apollo_people") {
        const people = valueWithCitations.value.people;
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                }}
            >
                {people.map((person, index) => (
                    <ApolloPersonCollapsibleBox key={index} defaultOpen={people.length < 3} person={person} />
                ))}
            </Box>
        );
    }
    if (valueWithCitations.value.type === "technologies") {
        const technologies = valueWithCitations.value.technologies;

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    overflowY: "auto",
                }}
            >
                {technologies.map((tech, index) => (
                    <ApolloTechnologyCollapsibleBox
                        key={index}
                        defaultOpen={technologies.length < 3}
                        technology={tech}
                    />
                ))}
            </Box>
        );
    }

    if (valueWithCitations.value.type === "job_postings") {
        const jobPostings = valueWithCitations.value.jobPostings;
        if (jobPostings.length === 0) {
            return (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <Box sx={[...(Array.isArray(containerSx) ? containerSx : [containerSx])]}>
                    <Typography variant="body2">No job postings</Typography>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    overflowY: "auto",
                }}
            >
                {jobPostings.map((jobPosting, index) => (
                    <ApolloJobPostingCollapsibleBox
                        key={index}
                        defaultOpen={jobPostings.length < 3}
                        jobPosting={jobPosting}
                    />
                ))}
            </Box>
        );
    }

    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[...(Array.isArray(containerSx) ? containerSx : [containerSx])]}>
            <SelectedCellValueContent
                valueWithCitations={
                    valueWithCitations as RowValueWithCitations<
                        Exclude<CellValueV2, CellValueV2ApolloPeople | CellValueV2Technologies | CellValueV2JobPostings>
                    >
                }
            />
        </Box>
    );
};

export interface SelectedCellValueContentProps {
    valueWithCitations: RowValueWithCitations<
        Exclude<CellValueV2, CellValueV2ApolloPeople | CellValueV2Technologies | CellValueV2JobPostings>
    >;
}

export const SelectedCellValueContent: React.FC<SelectedCellValueContentProps> = ({ valueWithCitations }) => {
    switch (valueWithCitations.value.type) {
        case "string":
            if (isValidUrl(valueWithCitations.value.value)) {
                return (
                    <Link
                        href={valueWithCitations.value.value}
                        variant="body2"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="secondary"
                        sx={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-all",
                        }}
                    >
                        {formatDisplayUrl(valueWithCitations.value.value)}
                    </Link>
                );
            } else {
                return (
                    <Typography
                        variant="body2"
                        sx={{
                            whiteSpace: "pre-line",
                        }}
                    >
                        {valueWithCitations.value.value}
                    </Typography>
                );
            }
        case "boolean":
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }}
                >
                    <Typography variant="body2">{valueWithCitations.value.value ? "Yes" : "No"}</Typography>
                    {valueWithCitations.value.reasoning != null && (
                        <Typography variant="body2">Reasoning: {valueWithCitations.value.reasoning}</Typography>
                    )}
                </Box>
            );
        case "error":
            return (
                <Typography variant="body2" color="error">
                    {valueWithCitations.value.error ?? "Error"}
                </Typography>
            );
        case "missing":
            // TODO: Conditionally maybe render Unknown for apollo
            return <Typography variant="body2">Missing</Typography>;
        case "unloaded":
            return <Typography variant="body2">Not loaded</Typography>;
        case "number":
            return <Typography variant="body2">{valueWithCitations.value.value.toLocaleString()}</Typography>;
        case "links":
            return (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {valueWithCitations.value.links.map((link, index) => (
                        <Box key={index}>
                            <Link
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="body2"
                                color="secondary"
                            >
                                {formatDisplayUrl(link.url)}
                            </Link>
                            <Typography variant="body2">{link.snippet}</Typography>
                        </Box>
                    ))}
                </Box>
            );
    }
};

interface CollapsibleBoxProps {
    defaultOpen: boolean;
    header: React.ReactNode;
    children: React.ReactNode;
}

const CollapsibleBox: React.FC<CollapsibleBoxProps> = ({ defaultOpen, header, children }) => {
    const [open, setOpen] = React.useState(defaultOpen);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Box
            sx={{
                backgroundColor: "primary.main",
                borderRadius: 1,
                marginBottom: 2,
                px: 1,
                py: 0.5,
                overflowX: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    mb: 0.5,
                    flexShrink: 1,
                    flexGrow: 1,
                }}
            >
                {header}
                <IconButton
                    onClick={handleToggle}
                    size="small"
                    sx={{
                        flexShrink: 0,
                    }}
                >
                    {open ? <ArrowUp2 size={16} /> : <ArrowDown2 size={16} />}
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: open ? "flex" : "none",
                    textOverflow: "ellipsis",
                    flexDirection: "column",
                    rowGap: 0.75,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

interface ApolloPersonCollapsibleBoxProps {
    defaultOpen: boolean;
    person: APPerson;
}

const MAX_UNLOCKED_LINKEDINS = 10;
const FREE_TRIAL_UNLOCKED_LINKEDINS_LOCAL_STORAGE_KEY = "answerGrid.free-trial-unlocked-linkedins";

function getUnlockedLinkedinsCount(): number {
    return localStorage.getItem(FREE_TRIAL_UNLOCKED_LINKEDINS_LOCAL_STORAGE_KEY)?.split(",").length ?? 0;
}

function formatPhoneNumber(phoneNumber: string): string {
    try {
        const parsedNumber = parsePhoneNumber(phoneNumber);
        if (parsedNumber) {
            return parsedNumber.formatInternational();
        }
    } catch (error) {
        console.error("Error parsing phone number:", error);
    }
    return phoneNumber;
}

const ApolloPersonCollapsibleBox: React.FC<ApolloPersonCollapsibleBoxProps> = ({ defaultOpen, person }) => {
    const user = useCurrentUserQuery();
    const [unlockedLinkedins, setUnlockedLinkedins] = React.useState<string[]>(
        user.data == null || user.data?.plan_type === "free-trial"
            ? localStorage.getItem(FREE_TRIAL_UNLOCKED_LINKEDINS_LOCAL_STORAGE_KEY)?.split(",") || []
            : [],
    );

    const isLinkedinLocked = React.useMemo(
        () =>
            user.data?.plan_type === "free-trial" &&
            person.linkedin.type === "string" &&
            !unlockedLinkedins.includes(person.linkedin.value),
        [user.data?.plan_type, person.linkedin, unlockedLinkedins],
    );

    const handleUnlockLinkedin = React.useCallback(() => {
        if (
            user.data?.plan_type === "free-trial" &&
            person.linkedin.type === "string" &&
            !unlockedLinkedins.includes(person.linkedin.value)
        ) {
            const newUnlockedLinkedins = Array.from(new Set([...unlockedLinkedins, person.linkedin.value]));
            setUnlockedLinkedins(newUnlockedLinkedins);
            localStorage.setItem(FREE_TRIAL_UNLOCKED_LINKEDINS_LOCAL_STORAGE_KEY, newUnlockedLinkedins.join(","));
            setUnlockedLinkedins(newUnlockedLinkedins);

            const mixpanelInstance = getMixpanelInstance();
            if (mixpanelInstance) {
                mixpanelInstance.track("Unlock LinkedIn value on free trial", {
                    plan_type: user.data?.plan_type,
                    unlocked_count: newUnlockedLinkedins.length,
                    remaining_unlocks: MAX_UNLOCKED_LINKEDINS - newUnlockedLinkedins.length,
                    value: person.linkedin.value,
                });
            }
        }
    }, [user.data?.plan_type, person.linkedin, unlockedLinkedins]);

    return (
        <CollapsibleBox
            defaultOpen={defaultOpen}
            header={
                <ApolloTrait
                    content={
                        <Typography variant="body2" color="text.primary" noWrap>
                            {person.name.value}
                        </Typography>
                    }
                    icon={<ProfileCircle style={{ flexShrink: 0 }} size={16} />}
                />
            }
        >
            {person.title != null && (
                <ApolloTrait
                    content={
                        <Typography variant="body2" color="text.secondary" noWrap>
                            {fieldValueToString(person.title)}
                        </Typography>
                    }
                    icon={<Personalcard size={16} style={{ flexShrink: 0 }} />}
                />
            )}
            <ApolloTrait
                content={
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {isLinkedinLocked ? (
                            <ButtonWithDisabledTooltip
                                disabledTooltip={
                                    unlockedLinkedins.length >= MAX_UNLOCKED_LINKEDINS
                                        ? `You've unlocked the maximum number (${MAX_UNLOCKED_LINKEDINS}) of LinkedIn values on your free trial`
                                        : undefined
                                }
                                onClick={handleUnlockLinkedin}
                                size="small"
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    height: 20,
                                }}
                            >
                                Unlock ({MAX_UNLOCKED_LINKEDINS - getUnlockedLinkedinsCount()} left)
                            </ButtonWithDisabledTooltip>
                        ) : person.linkedin.type === "string" ? (
                            <Link
                                href={person.linkedin.value}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="text.secondary"
                            >
                                {shortenLinkedinUrl(person.linkedin.value)}
                            </Link>
                        ) : (
                            "Unknown"
                        )}
                    </Typography>
                }
                icon={<LinkedIn fontSize="small" sx={{ m: "-2px", flexShrink: 0 }} />}
            />
            <ApolloTrait
                content={
                    <Typography
                        variant="body2"
                        color={person.email.type !== "error" ? "text.secondary" : "error"}
                        noWrap
                    >
                        {fieldValueToString(person.email)}
                    </Typography>
                }
                icon={<Sms size={16} style={{ flexShrink: 0 }} />}
            />
            <ApolloTrait
                content={
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {person.phoneNumbers.type === "phone_numbers"
                            ? person.phoneNumbers.phoneNumbers
                                  .map(phoneNumber => formatPhoneNumber(phoneNumber.value))
                                  .join(", ")
                            : fieldValueToString(person.phoneNumbers)}
                    </Typography>
                }
                icon={<Call size={16} style={{ flexShrink: 0 }} />}
            />
        </CollapsibleBox>
    );
};

function fieldValueToString(field: APPersonField): string {
    if (field.type === "string") {
        return field.value;
    }
    if (field.type === "not_requested") {
        return "Not requested";
    }
    if (field.type === "error") {
        return field.error;
    }
    return "Unknown";
}

interface ApolloTechnologyCollapsibleBoxProps {
    defaultOpen: boolean;
    technology: APTechnology;
}

const ApolloTechnologyCollapsibleBox: React.FC<ApolloTechnologyCollapsibleBoxProps> = ({ defaultOpen, technology }) => {
    return (
        <CollapsibleBox
            defaultOpen={defaultOpen}
            header={
                <ApolloTrait
                    content={
                        <Typography variant="body2" color="text.primary" noWrap>
                            {technology.name}
                        </Typography>
                    }
                    icon={<Box2 style={{ flexShrink: 0 }} size={16} />}
                />
            }
        >
            <ApolloTrait
                content={
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {technology.category}
                    </Typography>
                }
                icon={<Category size={16} style={{ flexShrink: 0 }} />}
            />
        </CollapsibleBox>
    );
};

interface ApolloJobPostingCollapsibleBoxProps {
    defaultOpen: boolean;
    jobPosting: ApolloJobPosting;
}

const ApolloJobPostingCollapsibleBox: React.FC<ApolloJobPostingCollapsibleBoxProps> = ({ defaultOpen, jobPosting }) => {
    return (
        <CollapsibleBox
            defaultOpen={defaultOpen}
            header={
                <ApolloTrait
                    content={
                        <Typography variant="body2" color="text.primary" noWrap>
                            {jobPosting.title}
                        </Typography>
                    }
                    icon={<Box2 style={{ flexShrink: 0 }} size={16} />}
                />
            }
        >
            {/* url */}
            <ApolloTrait
                content={
                    <Link
                        href={jobPosting.url}
                        variant="body2"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="text.secondary"
                    >
                        {jobPosting.url.replace("https://", "").replace("http://", "").replace("www.", "")}
                    </Link>
                }
                icon={<Link1 size={16} style={{ flexShrink: 0 }} />}
            />
            <ApolloTrait
                content={
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {new Date(jobPosting.posted_at).toLocaleDateString()}
                    </Typography>
                }
                icon={<Calendar size={16} style={{ flexShrink: 0 }} />}
            />
            {jobPosting.city != null && jobPosting.country != null && (
                <ApolloTrait
                    content={
                        <Typography variant="body2" color="text.secondary" noWrap>
                            {`${jobPosting.city}, ${jobPosting.country}`}
                        </Typography>
                    }
                    icon={<Location size={16} style={{ flexShrink: 0 }} />}
                />
            )}
        </CollapsibleBox>
    );
};

interface ApolloTraitProps {
    icon: React.ReactNode;
    content: React.ReactNode;
}

const ApolloTrait: React.FC<ApolloTraitProps> = ({ icon, content }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                textOverflow: "ellipsis",
                overflowX: "hidden",
                flexShrink: 1,
                flexGrow: 1,
            }}
        >
            {icon}
            {content}
        </Box>
    );
};

function shortenLinkedinUrl(linkedin: string): string {
    const url = new URL(linkedin);
    return url.pathname;
}
