import * as React from "react";
import { Button, IconButton } from "@mui/material";
import { Trash, Edit2 } from "iconsax-react";
import { EditableIncompleteCrunchbaseFilter, isCompleteCrunchbaseFilter } from "./types";
import { NonEditingFilter } from "./nonEditingFilter";
import { EditableFilterComponentLayout } from "./editableFilterComponentLayout";
import { EditableFilterValuePicker } from "./editableFilterValuePicker";

export interface EditableFilterComponentProps<T extends EditableIncompleteCrunchbaseFilter> {
    filter: T;
    index: number;
    handleDeleteFilter?: (idx: number) => void;
    onFilterChange: (idx: number, newFilter: T) => void;
    actionButtonWhileEditing: "reset" | "confirm";
}

// EditableFilterComponent.tsx
export const EditableFilterComponent = <T extends EditableIncompleteCrunchbaseFilter>({
    filter,
    index,
    handleDeleteFilter,
    onFilterChange,
    actionButtonWhileEditing = "confirm",
}: EditableFilterComponentProps<T>) => {
    const { isEditing } = filter;
    const dlt =
        handleDeleteFilter != null ? (
            <IconButton onClick={() => handleDeleteFilter(index)} size="small">
                <Trash size={16} />
            </IconButton>
        ) : null;

    const edit = (
        <IconButton onClick={() => onFilterChange(index, { ...filter, isEditing: true })} size={"small"}>
            <Edit2 size={16} />
        </IconButton>
    );

    const confirm =
        actionButtonWhileEditing === "confirm" ? (
            <Button
                onClick={() => onFilterChange(index, { ...filter, isEditing: false })}
                size="small"
                disabled={!isCompleteCrunchbaseFilter(filter.filter)}
                variant="text"
                color="secondary"
                sx={{
                    typography: "caption",
                    textTransform: "uppercase",
                }}
            >
                Done
            </Button>
        ) : null;

    const canReset =
        filter.filter.field_id != null || filter.filter.operator_id != null || filter.filter.values != null;
    const reset = canReset ? (
        <IconButton
            onClick={() =>
                onFilterChange(index, {
                    ...filter,
                    filter: {
                        operator_id: undefined,
                        field_id: undefined,
                        values: undefined,
                    },
                })
            }
            size={"small"}
        >
            <Trash size={16} />
        </IconButton>
    ) : undefined;

    return (
        <EditableFilterComponentLayout
            actionButtonWhileEditing={actionButtonWhileEditing === "confirm" ? confirm : reset}
            isEditing={isEditing}
            dlt={dlt}
            edit={edit}
            valuePicker={
                filter.filter.field_id != null ? (
                    <EditableFilterValuePicker filter={filter} index={index} onFilterChange={onFilterChange} />
                ) : undefined
            }
            nonEditingContent={<NonEditingFilter filter={filter} />}
            filter={filter}
            index={index}
            onFilterChange={onFilterChange}
        />
    );
};
