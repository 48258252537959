export type Async<T> = Unloaded | Loading | Loaded<T> | ErrorState;

export type Unloaded = { state: "unloaded" };
export type Loading = { state: "loading" };
export type Loaded<T> = {
    state: "loaded";
    data: T;
};
export type ErrorState = {
    state: "error";
    error: unknown;
};

export function isUnloaded<T>(value: Async<T>): value is Unloaded {
    return value.state === "unloaded";
}

export function isLoading<T>(value: Async<T>): value is Loading {
    return value.state === "loading";
}

export function isLoaded<T>(value: Async<T>): value is Loaded<T> {
    return value.state === "loaded";
}

export function isError<T>(value: Async<T>): value is ErrorState {
    return value.state === "error";
}

export function getAsyncValue<T>(value: Async<T>): T | undefined {
    if (isLoaded(value)) {
        return value.data;
    }
    return undefined;
}
