import React from "react";
import { isLoggedIn } from "../services/cb-backend";
import { useNavigate } from "react-router-dom";

// TODO: Is there a better way to do this?
export function useRedirectToLoginIfLoggedOut() {
    const navigate = useNavigate();

    React.useLayoutEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login");
        }
    }, [navigate]);

    React.useLayoutEffect(() => {
        const interval = setInterval(() => {
            if (!isLoggedIn()) {
                navigate("/login");
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [navigate]);
}
