import { Close } from "@mui/icons-material";
import { Chip, ChipProps, SxProps, Theme } from "@mui/material";
import * as React from "react";

interface ValueChipProps extends Omit<ChipProps, "deleteIcon" | "size" | "sx"> {
    allowDelete?: boolean;
    sx?: SxProps<Theme>;
    colors?: { bgcolor: string; color: string };
}

export const ValueChip: React.FC<ValueChipProps> = ({
    allowDelete = true,
    label,
    sx: parentSx,
    colors,
    ...tagProps
}) => {
    return (
        <Chip
            {...tagProps}
            label={label}
            size="small"
            deleteIcon={allowDelete ? <Close /> : undefined}
            sx={[
                { fontWeight: 500 },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(parentSx) ? parentSx : [parentSx]),
                colors == null
                    ? { bgcolor: "#E6F6FF", color: "#0071E3" }
                    : {
                          bgcolor: colors.bgcolor,
                          color: colors.color,
                      },
            ]}
        />
    );
};
