import { Box, SxProps, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import * as React from "react";
import {
    Column,
    getUnloadedCellsColQueriesWithRows,
    LoadingStatus,
    Row,
    RowGeneratingFilters,
    VisibilityFiltersByColumn,
} from "./grid";
import { getBackendService } from "../services/cb-backend";
import {
    convertBackendGridDataToFrontend,
    convertBackendRowGeneratingFilters,
    convertFrontendGridDataToGridData,
} from "./gridSerialization";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridBuildWithQueryAndFiltersRequestFilter } from "../services/cb-backend-types";
import { FREE_TRIAL_QUERY_KEY_FIRST_PART, getReactQueryGridKey } from "../react-query/queryKeys";
import { hasMoreRows, isFinalTaskStatus, removeColumns } from "./gridUtils";
import { HeaderFilterBar } from "./headerFilterBar";
import { SearchPersona } from "../home/personas";
import { EditableCrunchbaseFilter } from "../home/filters/types";
import { AnswerGridHeaderActions } from "./answerGridHeaderActions";
import { ExportProgress, ExportMode } from "./grid";
import { useOnAddColumnValues } from "./useEdits";
import { Eye } from "iconsax-react";
import { ReactComponent as ThreeRowsIcon } from "../assets/triple-row.svg";
import { ReactComponent as LoadedRowsIcon } from "../assets/loaded-rows.svg";
import { hasFilters } from "../home/visibility/visibility";
import { pluralize } from "../utils/pluralize";

export interface AnswersGridHeaderProps {
    columns: Column[];
    name: string;
    rows: Row[];
    visibleRows: Row[];
    gridId: string;
    version: number;
    rowGeneratingFilters: RowGeneratingFilters;
    exportStatus: ExportProgress | undefined;
    persona: SearchPersona;
    collectionTypeName: string;
    visibilityFiltersByColumn: VisibilityFiltersByColumn;
    onGenerateRows: (totalDesiredRowCount: number, mode: ExportMode) => Promise<void>;
    setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
    setRows: React.Dispatch<React.SetStateAction<Row[]>>;
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>;
    setRowGeneratingFilters: React.Dispatch<React.SetStateAction<RowGeneratingFilters>>;
    setCellGenerationStatusesByColByRow: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
    >;
}

export const AnswersGridHeader: React.FC<AnswersGridHeaderProps> = ({
    persona,
    columns,
    name,
    rows,
    visibleRows,
    version,
    rowGeneratingFilters,
    gridId,
    exportStatus,
    collectionTypeName,
    visibilityFiltersByColumn,
    onGenerateRows,
    setExportMode,
    setRows,
    setColumns,
    setRowGeneratingFilters,
    setCellGenerationStatusesByColByRow,
}) => {
    const backendService = React.useMemo(() => getBackendService(), []);

    const { onAddColumnValues } = useOnAddColumnValues(
        setColumns,
        setRows,
        setCellGenerationStatusesByColByRow,
        collectionTypeName,
    );

    const queryClient = useQueryClient();
    const submitQueryMutation = useMutation({
        mutationFn: async (newFilters: GridBuildWithQueryAndFiltersRequestFilter[]) => {
            const [nonEmptyColQueryColumns, rowsWithEmptyColumnsStripped] = removeColumns(
                columns,
                rows,
                column => column.generatedBy?.type === "web_search" && column.generatedBy?.query === "",
            );
            const resp = await backendService.changeGridFilters({
                filters: newFilters,
                // TODO: Does the version matter?
                old_grid_data: convertFrontendGridDataToGridData(
                    nonEmptyColQueryColumns,
                    rowsWithEmptyColumnsStripped,
                    0,
                ),
                query: "",
                mode: "better-homemade",
            });
            return resp;
        },
        onSuccess: async resp => {
            const feGridData = convertBackendGridDataToFrontend(resp.grid_data);
            setRows(feGridData.rows);
            setColumns(feGridData.columns);
            setRowGeneratingFilters(convertBackendRowGeneratingFilters(resp.row_generating_filters));
            const colQueriesWithRows = getUnloadedCellsColQueriesWithRows(feGridData.columns, feGridData.rows);
            void onAddColumnValues(colQueriesWithRows, feGridData.columns);
            void queryClient.invalidateQueries({ queryKey: FREE_TRIAL_QUERY_KEY_FIRST_PART });
            await queryClient.fetchQuery({ queryKey: getReactQueryGridKey(gridId) });
        },
    });

    const handleSubmitFilters = React.useCallback(
        async (editableFilters: EditableCrunchbaseFilter[]) => {
            const newFilters = editableFilters.map(filter => ({
                predicate: filter.filter,
                add_as_column: filter.addAsColumn,
            }));
            await submitQueryMutation.mutateAsync(newFilters);
        },
        [submitQueryMutation],
    );

    const areSomeRowsHidden = visibleRows.length < rows.length;

    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                pt: 3,
                pb: 2,
                px: 3,
                boxShadow: "0px 1px 0px #EAECF0",
                zIndex: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "left",
                        color: "secondary.main",
                        fontWeight: "medium",
                        textOverflow: "ellipsis",
                        flexGrow: 1,
                        mr: 3,
                    }}
                    noWrap
                >
                    {name}
                </Typography>
                <AnswerGridHeaderActions
                    columns={columns}
                    rows={rows}
                    visibleRows={visibleRows}
                    rowGeneratingFilters={rowGeneratingFilters}
                    version={version}
                    gridId={gridId}
                    name={name}
                    exportStatus={exportStatus}
                    onGenerateRows={onGenerateRows}
                    setExportMode={setExportMode}
                    setRowGeneratingFilters={setRowGeneratingFilters}
                    setRows={setRows}
                />
            </Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                    gap: 2,
                    mt: 1,
                }}
            >
                {rowGeneratingFilters.filters.length > 0 && (
                    <>
                        <HeaderFilterBar
                            rowGeneratingFilters={rowGeneratingFilters}
                            persona={persona}
                            disableEdit={exportStatus != null && !isFinalTaskStatus(exportStatus.status)}
                            onSubmitEditedFilters={handleSubmitFilters}
                        />
                        {areSomeRowsHidden ? (
                            <Tooltip
                                title={getRowCountTooltipWithVisibleRows(
                                    visibleRows,
                                    rows,
                                    rowGeneratingFilters,
                                    visibilityFiltersByColumn,
                                    columns,
                                )}
                                sx={{
                                    display: "flex",
                                    flex: "1 1 auto",
                                    minWidth: 0,
                                    maxWidth: "fit-content",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        overflowX: "hidden",
                                        columnGap: 1.5,
                                        flex: "1 1 auto",
                                        minWidth: 0,
                                        // TODO: Is this hacky?
                                        maxWidth: "fit-content",
                                    }}
                                >
                                    <RowNumberWithIcon
                                        number={visibleRows.length}
                                        icon={<Eye size={16} color={theme.palette.success.main} />}
                                        sx={{ mr: 0.25 }}
                                    />
                                    <RowNumberWithIcon
                                        number={rows.length}
                                        icon={<LoadedRowsIcon style={{ width: 16, height: 16 }} />}
                                    />
                                    <RowNumberWithIcon
                                        number={getTotalRowsCount(rowGeneratingFilters, rows)}
                                        icon={<ThreeRowsIcon style={{ width: 16, height: 16 }} />}
                                    />
                                </Box>
                            </Tooltip>
                        ) : (
                            <Typography
                                variant="body2"
                                color="text.primary"
                                noWrap
                                sx={{ flexShrink: 0, overflowX: "hidden" }}
                            >
                                {getRowCountString(rowGeneratingFilters, rows)}
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

function getRowCountTooltipWithVisibleRows(
    visibleRows: Row[],
    rows: Row[],
    rowGeneratingFilters: RowGeneratingFilters,
    visibilityFiltersByColumn: VisibilityFiltersByColumn,
    columns: Column[],
) {
    const columnsWithFilters = columns.filter(
        c => visibilityFiltersByColumn[c.id] != null && hasFilters(visibilityFiltersByColumn[c.id]),
    );
    const columnsWithFiltersLabels = columnsWithFilters.map(c => c.label).join(", ");
    if (!hasMoreRows(rowGeneratingFilters, rows.length) || rowGeneratingFilters.pagingInfo == null) {
        return `You've loaded all rows that match this grid's ${pluralize(rowGeneratingFilters.filters.length, "filter", "filters")}. Due to answer filtering on the columns ${columnsWithFiltersLabels}, only ${pluralize(visibleRows.length, "row", "rows")} are visible.`;
    }

    const fetchedRowCount = rowGeneratingFilters.pagingInfo?.fetchedCount ?? rows.length;
    const deletedFetchedRows = Math.max(0, fetchedRowCount - rows.length);
    const totalRowsCount = rowGeneratingFilters.pagingInfo.totalCount - deletedFetchedRows;
    return `There ${totalRowsCount === 1 ? "is" : "are"} ${pluralize(totalRowsCount, "row", "rows")} that match this grid's ${pluralize(rowGeneratingFilters.filters.length, "filter", "filters")}. You've loaded ${pluralize(rows.length, "row", "rows")}. Of those loaded rows, only ${pluralize(visibleRows.length, "row", "rows")} are visible due to answer filtering on the ${pluralize(columnsWithFilters.length, "column", "columns", false)} ${columnsWithFiltersLabels}.`;
}

function getTotalRowsCount(rowGeneratingFilters: RowGeneratingFilters, rows: Row[]) {
    if (rowGeneratingFilters.pagingInfo == null) {
        return rows.length;
    }
    const fetchedRowCount = rowGeneratingFilters.pagingInfo?.fetchedCount ?? rows.length;
    const deletedFetchedRows = Math.max(0, fetchedRowCount - rows.length);
    return rowGeneratingFilters.pagingInfo.totalCount - deletedFetchedRows;
}

const RowNumberWithIcon: React.FC<{ number: number; icon: React.ReactNode; sx?: SxProps<Theme> }> = ({
    number,
    icon,
    sx,
}) => {
    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[...(Array.isArray(sx) ? sx : [sx]), { display: "flex", alignItems: "center", columnGap: 0.5 }]}>
            {icon}
            <Typography variant="body2" color="textSecondary">
                {number}
            </Typography>
        </Box>
    );
};

function getRowCountString(rowGeneratingFilters: RowGeneratingFilters, rows: Row[]) {
    if (rowGeneratingFilters.filters.length === 0) {
        return `${rows.length} ${rows.length === 1 ? "row" : "rows"}`;
    }
    const fetchedRowCount = rowGeneratingFilters.pagingInfo?.fetchedCount ?? rows.length;
    if (fetchedRowCount === rowGeneratingFilters.pagingInfo?.totalCount || rowGeneratingFilters.pagingInfo == null) {
        return `All ${rows.length} rows loaded`;
    }
    const deletedFetchedRows = Math.max(0, fetchedRowCount - rows.length);
    return `${rows.length} of ${rowGeneratingFilters.pagingInfo.totalCount - deletedFetchedRows} rows loaded`;
}
