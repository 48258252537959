import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./App.css";

declare module "@mui/material/styles" {
    interface Palette {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
    }

    interface PaletteOptions {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F8F8F7",
        },
        secondary: {
            light: "#EFF2ED",
            main: "#365934",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: red.A400,
        },
        // Add custom colors
        // blue: {
        //     400: "#0071E3",
        // },
        // green: {
        //     400: "#5EA15E",
        //     500: "#365934",
        // },
        surface: {
            100: "#E3DAC8",
            75: "#F0EDE6",
            50: "#F4F2EF",
            25: "#F8F8F7",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 8,
                },
            },
        },
    },
});
