import { Box, TextField, Typography, TypographyProps } from "@mui/material";
import * as React from "react";

interface ColLabelInputProps {
    colLabel: string;
    labelSize?: TypographyProps["variant"];
    setColLabel: (newColLabel: string) => void;
}

export const ColLabelInput: React.FC<ColLabelInputProps> = ({ colLabel, labelSize = "body1", setColLabel }) => {
    const handleChangeColLabel = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setColLabel(e.target.value);
        },
        [setColLabel],
    );
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "inline-flex",
                    // show whitespace
                }}
            >
                <Typography
                    variant={labelSize}
                    sx={{
                        fontWeight: "medium",
                    }}
                >
                    Column name
                </Typography>
                <Typography
                    variant={labelSize}
                    sx={{
                        whiteSpace: "pre",
                    }}
                >
                    {" (optional)"}
                </Typography>
            </Box>
            <TextField size="small" variant="outlined" value={colLabel} onChange={handleChangeColLabel} />
        </Box>
    );
};
