import * as React from "react";
import { ReactComponent as Logo } from "./assets/logomark-primary.svg";
import { Box, Button, Container, Typography } from "@mui/material";

export const IGNORE_MOBILE_WARNING_LOCAL_STORAGE_KEY = "answergrid.ignoreMobileWarning";
export const MobileDisabledMessage: React.FC = () => {
    const handleIgnore = () => {
        localStorage.setItem(IGNORE_MOBILE_WARNING_LOCAL_STORAGE_KEY, "true");
        window.location.reload();
    };
    return (
        <Container
            component="main"
            sx={{
                height: "98vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: 4,
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
                <Logo
                    style={{
                        width: 40,
                        height: 40,
                    }}
                />
            </Box>
            <Typography variant="body1" align="center" sx={{ mb: 2, mx: 4 }}>
                Thank you for your interest in AnswerGrid.
                <br></br>
                <br></br>
                Our app is best experienced on larger screens. We recommend accessing AnswerGrid from a desktop or
                tablet device.
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleIgnore}
                sx={{
                    maxWidth: 300,
                    alignSelf: "center",
                }}
            >
                Continue anyway
            </Button>
        </Container>
    );
};
