import * as React from "react";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    lighten,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { Add, Information, Message2, MouseCircle, Refresh2, RefreshCircle, Shuffle } from "iconsax-react";
import { WebQueryColumnContextFields } from "./WebQueryColumnContextFields";
import {
    ANYTHING_COLLECTION_DESC,
    AgentToolConfig,
    COMPANIES_COLLECTION_DESC,
    Column,
    ColumnGeneratedBy,
    FormatOptions,
    RealRow,
    Row,
    isRealRow,
} from "./grid";
import { CompleteColumnForQuery, getBatchQueryRequest } from "./useUpdateQueries";
import { getBackendService } from "../services/cb-backend";
import { Async, isError, isLoaded, isLoading } from "../utils/async";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { ColLabelInput } from "./colLabelInput";
import { convertBeCellValueV2ToFrontendCellValueV2 } from "./gridSerialization";
import { cellValueV2ToString } from "./gridUtils";
import { AgentToolPicker } from "./agentToolPicker";
import { isEqual } from "lodash-es";
import { PROMPT_EXAMPLES, PromptExample } from "./promptExamples";
import { useBoolean } from "../utils/hooks";
import { FormatOptionsPicker } from "./formatOptionsPicker";

export interface AdvancedColumnEditorDialogProps {
    colQuery: string;
    colId: string;
    colLabel: string;
    contextColumnIds: string[];
    isOpen: boolean;
    otherColumns: Column[];
    rows: Row[];
    columns: Column[];
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
    onClose: () => void;
    onChangeColQuery: (newColQuery: string) => void;
    onChangeColLabel: (newColLabel: string) => void;
    onChangeContextColumnIds: (newContextColumnIds: string[]) => void;
    onAddColumn: (columnQuery: ColumnGeneratedBy, columnLabel?: string) => void;
    onChangeToolConfig: (newToolConfig: AgentToolConfig | undefined) => void;
    onChangeFormatOptions: (newFormatOptions: FormatOptions | undefined) => void;
    initialColumnEditorState: ColumnEditorState | undefined;
}

export interface ColumnEditorState {
    colLabel: string;
    colQuery: string;
    contextColumnIds: string[];
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
}

export const AdvancedColumnEditorDialog: React.FC<AdvancedColumnEditorDialogProps> = ({
    colLabel,
    colId,
    colQuery,
    contextColumnIds,
    isOpen,
    otherColumns,
    rows,
    columns,
    toolConfig,
    formatOptions,
    onClose,
    onChangeContextColumnIds,
    onChangeColQuery,
    onChangeColLabel,
    onAddColumn,
    onChangeToolConfig,
    onChangeFormatOptions,
    initialColumnEditorState,
}) => {
    const hasChanges = React.useMemo(() => {
        return (
            initialColumnEditorState == null ||
            colLabel !== initialColumnEditorState.colLabel ||
            colQuery !== initialColumnEditorState.colQuery ||
            !isEqual(contextColumnIds, initialColumnEditorState.contextColumnIds) ||
            !isEqual(toolConfig, initialColumnEditorState.toolConfig) ||
            !isEqual(formatOptions, initialColumnEditorState.formatOptions)
        );
    }, [colLabel, colQuery, contextColumnIds, toolConfig, formatOptions, initialColumnEditorState]);

    const handleAddColumn = React.useCallback(() => {
        void onAddColumn(
            {
                query: colQuery,
                type: "web_search",
                contextColumnIds,
                toolConfig,
                formatOptions,
            },
            colLabel.trim().length > 0 ? colLabel : undefined,
        );
    }, [onAddColumn, colQuery, contextColumnIds, toolConfig, formatOptions, colLabel]);

    const handleCancel = React.useCallback(() => {
        if (initialColumnEditorState != null) {
            onChangeColLabel(initialColumnEditorState.colLabel);
            onChangeColQuery(initialColumnEditorState.colQuery);
            onChangeContextColumnIds(initialColumnEditorState.contextColumnIds);
            onChangeToolConfig(initialColumnEditorState.toolConfig);
            onChangeFormatOptions(initialColumnEditorState.formatOptions);
        }
        onClose();
    }, [
        initialColumnEditorState,
        onClose,
        onChangeColLabel,
        onChangeColQuery,
        onChangeContextColumnIds,
        onChangeToolConfig,
        onChangeFormatOptions,
    ]);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md">
            <DialogTitle>Column editor</DialogTitle>
            <DialogContent
                sx={{
                    height: 530,
                    maxHeight: 530,
                    display: "flex",
                    overflowY: "hidden",
                    flexShrink: 0,
                    maxWidth: "md",
                    // TODO: HACKHACKHACK. What's the best way to have a constant width for the dialog?
                    minWidth: 900,
                }}
            >
                <AdvancedColumnEditorDialogContent
                    colId={colId}
                    colLabel={colLabel}
                    colQuery={colQuery}
                    contextColumnIds={contextColumnIds}
                    otherColumns={otherColumns}
                    rows={rows}
                    columns={columns}
                    toolConfig={toolConfig}
                    formatOptions={formatOptions}
                    onChangeColQuery={onChangeColQuery}
                    onChangeColLabel={onChangeColLabel}
                    onChangeContextColumnIds={onChangeContextColumnIds}
                    onChangeToolConfig={onChangeToolConfig}
                    onChangeFormatOptions={onChangeFormatOptions}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    sx={{
                        textTransform: "none",
                        typography: "body2",
                        borderRadius: 8,
                    }}
                    variant="text"
                    color="secondary"
                >
                    {initialColumnEditorState != null && hasChanges ? "Cancel" : "Close"}
                </Button>
                <ButtonWithDisabledTooltip
                    startIcon={<Add size="16" />}
                    onClick={handleAddColumn}
                    variant="contained"
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        borderRadius: 8,
                        typography: "body2",
                    }}
                    disabledTooltip={getAddColumnDisabledReason(colQuery, contextColumnIds, toolConfig, hasChanges)}
                >
                    {initialColumnEditorState != null ? "Update column" : "Add column"}
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};

function getAddColumnDisabledReason(
    colQuery: string,
    contextColumnIds: string[],
    toolConfig: AgentToolConfig | undefined,
    hasChanges: boolean,
) {
    if (!hasChanges) {
        return "No changes have been made";
    }
    if (colQuery.trim() === "") {
        return "Prompt cannot be empty";
    }
    if (contextColumnIds.length === 0 && toolConfig?.toolName === "AnswerFromContextWebPage") {
        return "You need to specify a URL column as context to use this tool";
    }
    if (contextColumnIds.length === 0 && toolConfig?.toolName === "GeneralInstructionAssistant") {
        return "You need to specify one or more columns as context to use this tool";
    }
    return undefined;
}

interface AdvancedColumnEditorDialogContentProps {
    colLabel: string;
    colQuery: string;
    colId: string;
    contextColumnIds: string[];
    otherColumns: Column[];
    rows: Row[];
    columns: Column[];
    toolConfig: AgentToolConfig | undefined;
    formatOptions: FormatOptions | undefined;
    onChangeColQuery: (newColQuery: string) => void;
    onChangeColLabel: (newColLabel: string) => void;
    onChangeContextColumnIds: (newContextColumnIds: string[]) => void;
    onChangeToolConfig: (newToolConfig: AgentToolConfig | undefined) => void;
    onChangeFormatOptions: (newFormatOptions: FormatOptions | undefined) => void;
}

const AdvancedColumnEditorDialogContent: React.FC<AdvancedColumnEditorDialogContentProps> = ({
    colLabel,
    colQuery,
    colId,
    contextColumnIds,
    otherColumns,
    rows,
    columns,
    toolConfig,
    formatOptions,
    onChangeContextColumnIds,
    onChangeColQuery,
    onChangeColLabel,
    onChangeToolConfig,
    onChangeFormatOptions,
}) => {
    const handleChangeColQuery = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => onChangeColQuery(e.target.value),
        [onChangeColQuery],
    );

    const { value: showTemplates, toggleValue: toggleShowTemplates } = useBoolean(false);

    return (
        <Box
            sx={{
                display: "flex",
                overflowY: "hidden",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    minWidth: 450,
                    flexBasis: 450,
                    flexGrow: 0,
                    overflowY: "auto",
                    pr: 1,
                }}
            >
                <ColLabelInput colLabel={colLabel} setColLabel={onChangeColLabel} labelSize="body2" />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: "medium",
                        }}
                    >
                        Tool
                    </Typography>
                    <AgentToolPicker
                        isAnyContextColumnSelected={contextColumnIds.length > 0}
                        toolConfig={toolConfig}
                        onChange={onChangeToolConfig}
                    />
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: "medium",
                            mt: 1,
                        }}
                    >
                        Format
                    </Typography>
                    <FormatOptionsPicker
                        toolConfig={toolConfig}
                        formatOptions={formatOptions}
                        onChange={onChangeFormatOptions}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 2,
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: "medium",
                            }}
                        >
                            Prompt
                        </Typography>
                        <Button
                            variant="text"
                            size="small"
                            sx={{ textTransform: "none", color: "#0071E3", p: 0, m: 0 }}
                            onClick={toggleShowTemplates}
                        >
                            {showTemplates ? "Hide templates" : "Show templates"}
                        </Button>
                    </Box>
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={colQuery}
                        onChange={handleChangeColQuery}
                        minRows={6}
                        maxRows={6}
                        sx={{
                            flexGrow: 1,
                        }}
                    />
                    {showTemplates && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mt: 2,
                                rowGap: 0.5,
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "medium",
                                }}
                            >
                                Prompt templates
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 1,
                                }}
                            >
                                {PROMPT_EXAMPLES.map(prompt => (
                                    <PromptExampleRow
                                        key={prompt.title}
                                        example={prompt}
                                        onChangeColQuery={onChangeColQuery}
                                        onChangeColLabel={onChangeColLabel}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}
                    <WebQueryColumnContextFields
                        contextColumnIds={contextColumnIds}
                        onChangeColumnContextField={onChangeContextColumnIds}
                        otherColumns={otherColumns}
                        size="medium"
                        sx={{
                            mt: 2,
                        }}
                    />
                </Box>
            </Box>
            {/* vertical divider */}
            <Box
                sx={{
                    width: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    ml: 5,
                }}
            />
            <ColumnPreview
                colId={colId}
                contextColumnIds={contextColumnIds}
                colLabel={colLabel}
                colQuery={colQuery}
                formatOptions={formatOptions}
                rows={rows}
                columns={columns}
                toolConfig={toolConfig}
            />
        </Box>
    );
};

interface PromptExampleRowProps {
    example: PromptExample;
    onChangeColQuery: (newColQuery: string) => void;
    onChangeColLabel: (newColLabel: string) => void;
}

const PromptExampleRow: React.FC<PromptExampleRowProps> = ({ example, onChangeColLabel, onChangeColQuery }) => {
    const handleClickExample = React.useCallback(() => {
        onChangeColQuery(example.prompt);
        onChangeColLabel(example.title);
    }, [example.prompt, example.title, onChangeColLabel, onChangeColQuery]);

    const primaryMain = useTheme().palette.primary.main;
    return (
        <Button
            sx={{
                display: "flex",
                bgcolor: "primary.main",
                color: "primary.contrastText",
                "&:hover": {
                    bgcolor: lighten(primaryMain, 0.7),
                },
                textTransform: "none",
                borderRadius: 2,
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
                py: 0.5,
            }}
            onClick={handleClickExample}
        >
            <Typography variant="body2">{example.title}</Typography>
            <IconButton size="small" onClick={handleClickExample}>
                <MouseCircle size="16" />
            </IconButton>
        </Button>
    );
};

interface ColumnPreviewProps {
    colLabel: string;
    colQuery: string;
    colId: string;
    contextColumnIds: string[];
    formatOptions: FormatOptions | undefined;
    rows: Row[];
    columns: Column[];
    toolConfig: AgentToolConfig | undefined;
}

const ColumnPreview: React.FC<ColumnPreviewProps> = ({
    colId,
    colLabel,
    colQuery,
    contextColumnIds,
    formatOptions,
    rows,
    columns,
    toolConfig,
}) => {
    const [selectedRowKeyForPreview, setSelectedRowKeyForPreview] = React.useState<string | undefined>(undefined);

    const maybeSelectedRow = React.useMemo(
        () => getMaybeSelectedRow(rows, selectedRowKeyForPreview),
        [rows, selectedRowKeyForPreview],
    );

    const backendService = React.useMemo(() => getBackendService(), []);

    const [previewContentAndDeps, setPreviewContentAndDeps] = React.useState<{
        previewContent: Async<string>;
        colQuery: string;
        toolConfig: AgentToolConfig | undefined;
        selectedRowKeyForPreview: string | undefined;
    }>({ previewContent: { state: "unloaded" }, colQuery: "", selectedRowKeyForPreview: undefined, toolConfig });

    const areDepsOutOfSync = React.useMemo(
        () => previewContentAndDeps.colQuery !== colQuery || !isEqual(previewContentAndDeps.toolConfig, toolConfig),
        [previewContentAndDeps.colQuery, previewContentAndDeps.toolConfig, colQuery, toolConfig],
    );

    const disabledReason = React.useMemo(() => getDisabledReason(colQuery, rows), [colQuery, rows]);

    const handlePreviewAnswerForRow = React.useCallback(
        async (maybeSelectedRow: RealRow | undefined) => {
            const previewRow = maybeSelectedRow ?? rows.find(isRealRow);
            if (previewRow == null) {
                return;
            }
            setSelectedRowKeyForPreview(previewRow.data.name.value.value);
            const previewColQuery: CompleteColumnForQuery = {
                id: colId,
                generatedBy: {
                    query: colQuery,
                    type: "web_search",
                    contextColumnIds,
                    toolConfig,
                    formatOptions,
                },
                label: colLabel,
                // TODO: Can we avoid passing these
                align: "center",
                isEditable: true,
            };
            const request = getBatchQueryRequest(
                [{ rows: [previewRow], column: previewColQuery }],
                columns,
                previewRow.externalIdentifiers?.type === "company"
                    ? COMPANIES_COLLECTION_DESC
                    : ANYTHING_COLLECTION_DESC,
            );

            setPreviewContentAndDeps({
                previewContent: { state: "loading" },
                colQuery,
                toolConfig,
                selectedRowKeyForPreview: previewRow.data.name.value.value,
            });

            try {
                const response = await backendService.cellLevelBatchQuery(request);
                // TODO: Maybe add nicer rendering than just stringifying with errors and stuff
                const cellValue = convertBeCellValueV2ToFrontendCellValueV2(
                    response.rows[0].cell_response_by_col_id[colId].cell_response.query_response,
                );
                if (cellValue.type === "error") {
                    setPreviewContentAndDeps({
                        previewContent: { state: "error", error: cellValue.error },
                        toolConfig,
                        colQuery,
                        selectedRowKeyForPreview: previewRow.data.name.value.value,
                    });
                    return;
                }

                const previewContent = cellValueV2ToString(cellValue);
                setPreviewContentAndDeps({
                    previewContent: { state: "loaded", data: previewContent },
                    toolConfig,
                    colQuery,
                    selectedRowKeyForPreview: previewRow.data.name.value.value,
                });
            } catch (e: unknown) {
                setPreviewContentAndDeps({
                    previewContent: { state: "error", error: e },
                    colQuery,
                    toolConfig,
                    selectedRowKeyForPreview: previewRow.data.name.value.value,
                });
            }
        },
        [rows, colId, colQuery, contextColumnIds, toolConfig, formatOptions, colLabel, columns, backendService],
    );
    const handlePreviewAnswer = React.useCallback(
        async () => handlePreviewAnswerForRow(maybeSelectedRow),
        [handlePreviewAnswerForRow, maybeSelectedRow],
    );

    const handleSelectRowKeyForPreview = React.useCallback(
        (rowKey: string | undefined) => {
            setSelectedRowKeyForPreview(rowKey);
            void handlePreviewAnswerForRow(getMaybeSelectedRow(rows, rowKey));
        },
        [handlePreviewAnswerForRow, rows],
    );

    if (maybeSelectedRow != null) {
        return (
            <Box
                sx={{
                    display: "flex",
                    pl: 2,
                    flexBasis: 350,
                    minWidth: 350,
                    flex: 0,
                    overflowY: "hidden",
                }}
            >
                <ColumnPreviewContent
                    previewContent={previewContentAndDeps.previewContent}
                    highlightRefreshButton={areDepsOutOfSync}
                    rows={rows}
                    selectedRow={maybeSelectedRow}
                    onPreviewAnswer={handlePreviewAnswer}
                    onSelectRowKeyForPreview={handleSelectRowKeyForPreview}
                />
            </Box>
        );
    } else {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flexBasis: 350,
                    minWidth: 350,
                    flex: 0,
                }}
            >
                <ColumnPreviewPlaceholder disabledReason={disabledReason} onPreviewAnswer={handlePreviewAnswer} />
            </Box>
        );
    }
};

function getMaybeSelectedRow(rows: Row[], selectedRowKeyForPreview: string | undefined): RealRow | undefined {
    return rows.filter(isRealRow).find(r => r.data.name.value.value === selectedRowKeyForPreview);
}

function getDisabledReason(colQuery: string, rows: Row[]): string | undefined {
    if (colQuery === "") {
        return "Prompt cannot be empty";
    }
    if (rows.filter(isRealRow).length === 0) {
        return "No rows to preview";
    }
}

interface ColumnPreviewContentProps {
    previewContent: Async<string>;
    rows: Row[];
    highlightRefreshButton: boolean;
    selectedRow: RealRow | undefined;
    onPreviewAnswer: () => Promise<void>;
    onSelectRowKeyForPreview: (rowKey: string | undefined) => void;
}

type RowOption = { row: RealRow; idx: number };

const ColumnPreviewContent: React.FC<ColumnPreviewContentProps> = ({
    previewContent,
    rows,
    selectedRow,
    highlightRefreshButton,
    onPreviewAnswer,
    onSelectRowKeyForPreview,
}) => {
    const options = React.useMemo(() => rows.filter(isRealRow).map<RowOption>((r, idx) => ({ row: r, idx })), [rows]);
    const handleSelect = React.useCallback(
        (e: unknown, rowOption: RowOption | null) => {
            onSelectRowKeyForPreview(rowOption?.row.data.name.value.value);
        },
        [onSelectRowKeyForPreview],
    );
    const selectedRowAsOption = React.useMemo(
        () => options.find(o => o.row.data.name.value === selectedRow?.data.name.value),
        [options, selectedRow],
    );

    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    columnGap: 1,
                }}
            >
                <Message2 size="16" color="#0071E3" />
                <Typography variant="body2">Preview cell for a row</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    justifyContent: "space-between",
                    flexGrow: 1,
                    overflowY: "hidden",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                        overflowY: "hidden",
                    }}
                >
                    <Autocomplete
                        fullWidth
                        loading={isLoading(previewContent)}
                        options={options}
                        getOptionLabel={rowOptionToLabel}
                        onChange={handleSelect}
                        value={selectedRowAsOption}
                        renderInput={params => (
                            <TextField
                                {...params}
                                hiddenLabel
                                placeholder="Select row"
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        overflowY: "auto",
                        flex: 1,
                    }}
                >
                    <ColumnPreviewContentText previewContent={previewContent} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <IconButton
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onPreviewAnswer}
                    sx={{
                        color: "secondary.main",
                        width: 32,
                    }}
                    size="small"
                >
                    {highlightRefreshButton ? (
                        <RefreshCircle variant="Bold" size={24} color={theme.palette.secondary.main} />
                    ) : (
                        <Refresh2 size="16" color={theme.palette.secondary.main} />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};

function rowOptionToLabel(rowOption: RowOption): string {
    return `${rowOption.idx + 1} - ${rowOption.row.data.name.value.value}`;
}

interface ColumnPreviewContentTextProps {
    previewContent: Async<string>;
}

const ColumnPreviewContentText: React.FC<ColumnPreviewContentTextProps> = ({ previewContent }) => {
    const theme = useTheme();
    if (isLoading(previewContent)) {
        return (
            <CircularProgress
                size={16}
                sx={{
                    color: "primary.contrastText",
                }}
            />
        );
    } else if (isError(previewContent)) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "start",
                    columnGap: 0.5,
                }}
            >
                <Information size="20" color={theme.palette.error.main} />
                <Typography variant="body2" color="error">
                    {previewContent.error instanceof Error
                        ? previewContent.error.message
                        : typeof previewContent.error === "string"
                          ? previewContent.error
                          : "An error occurred"}
                </Typography>
            </Box>
        );
    } else if (isLoaded(previewContent)) {
        return <Typography variant="body2">{previewContent.data}</Typography>;
    }
    return null;
};

interface ColumnPreviewPlaceholderProps {
    disabledReason: string | undefined;
    onPreviewAnswer: () => Promise<void>;
}

const ColumnPreviewPlaceholder: React.FC<ColumnPreviewPlaceholderProps> = ({ disabledReason, onPreviewAnswer }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                rowGap: 2,
            }}
        >
            <Message2 size="24" />
            <Typography variant="body2">Preview your column</Typography>
            <ButtonWithDisabledTooltip
                disabledTooltip={disabledReason}
                startIcon={<Shuffle size="16" />}
                variant="outlined"
                color="secondary"
                size="small"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onPreviewAnswer}
                sx={{
                    textTransform: "none",
                    borderRadius: 8,
                }}
            >
                Preview answer
            </ButtonWithDisabledTooltip>
        </Box>
    );
};
