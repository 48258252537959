import * as React from "react";
import { Box, Typography } from "@mui/material";
import { EditableFilterComponentLayoutNonEditingContent } from "./editableFilterComponentLayoutNonEditingContent";
import { APOLLO_FILTER_FIELD_DISPLAY_NAMES } from "./apolloFilters";

// ApolloEditableFilterComponentLayout.tsx
export interface ApolloEditableFilterComponentLayoutProps {
    actionButtonWhileEditing?: React.ReactNode;
    isEditing: boolean;
    dlt: React.ReactNode;
    edit: React.ReactNode;
    valuePicker: React.ReactNode;
    nonEditingContent: React.ReactNode | undefined;
    field: string;
    style?: "small" | "medium";
    // TODO: Add add as column when adding person focus
}

export const ApolloEditableFilterComponentLayout: React.FC<ApolloEditableFilterComponentLayoutProps> = ({
    actionButtonWhileEditing,
    isEditing,
    dlt,
    edit,
    valuePicker,
    nonEditingContent,
    field,
    style = "medium",
}) => {
    return (
        <Box sx={{ display: "flex", alignItems: "stretch", flex: 1, overflowX: "hidden" }}>
            <ApolloEditableFilterComponentLayoutContent
                actionButtonWhileEditing={actionButtonWhileEditing}
                isEditing={isEditing}
                dlt={dlt}
                edit={edit}
                valuePicker={valuePicker}
                nonEditingContent={nonEditingContent}
                field={field}
                style={style}
            />
        </Box>
    );
};

const ApolloEditableFilterComponentLayoutContent: React.FC<ApolloEditableFilterComponentLayoutProps> = ({
    actionButtonWhileEditing,
    isEditing,
    dlt,
    edit,
    valuePicker,
    nonEditingContent,
    field,
    style = "medium",
}) => {
    if (!isEditing) {
        return (
            <EditableFilterComponentLayoutNonEditingContent
                nonEditingContent={nonEditingContent}
                dlt={dlt}
                edit={edit}
            />
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                flex: 1,
                overflowX: "hidden",
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, overflowX: "hidden" }}>
                <Typography
                    variant={style === "small" ? "caption" : "body2"}
                    fontWeight="medium"
                    sx={{ overflowX: "hidden" }}
                >
                    {APOLLO_FILTER_FIELD_DISPLAY_NAMES[field as keyof typeof APOLLO_FILTER_FIELD_DISPLAY_NAMES]
                        ?.displayName ?? field}
                </Typography>
                {actionButtonWhileEditing}
            </Box>
            {valuePicker != null && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>{valuePicker}</Box>
            )}
        </Box>
    );
};
