import * as React from "react";
import { EditableIncompleteCrunchbaseFilter } from "./types";
import { Box, Typography } from "@mui/material";
import { ValueChip } from "./valueChip";
import {
    descriptionValueToString,
    numEmployeesEnumValueToString,
    investorIdentifiersValueToString,
    locationIdentifiersValueToString,
    lastEquityFundingTypeValueToString,
    locationGroupIdentifiersValueToString,
    operatorDisplayNames,
    categoriesValueToString,
    categoryGroupsValueToString,
    lastFundingTotalValueToString,
    fundingTotalValueToString,
    foundedOnValueToString,
    lastFundingAtValueToString,
} from "../../services/filterUtils";
import { CRUNCHBASE_FIELD_DISPLAY_NAMES } from "../../services/crunchbase";

export interface NonEditingFilterProps {
    filter: EditableIncompleteCrunchbaseFilter;
}

export const NonEditingFilter: React.FC<NonEditingFilterProps> = ({ filter }) => {
    const typeAndOperator = `${filter.filter.field_id != null ? CRUNCHBASE_FIELD_DISPLAY_NAMES[filter.filter.field_id].displayName : "Select type"} ${filter.filter.operator_id != null ? operatorDisplayNames[filter.filter.operator_id].displayName : "Select operation"}`;
    return (
        <Box sx={{ display: "inline-flex", alignItems: "center", flexGrow: 1, columnGap: 1, overflowX: "hidden" }}>
            <Typography
                variant="body2"
                noWrap
                sx={{
                    flexShrink: 0,
                }}
            >
                {typeAndOperator}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: 1,
                    rowGap: 1,
                    overflowX: "hidden",
                }}
            >
                <NonEditingFilterValues filter={filter} />
            </Box>
        </Box>
    );
};

const NonEditingFilterValues: React.FC<{ filter: EditableIncompleteCrunchbaseFilter }> = ({ filter }) => {
    switch (filter.filter.field_id) {
        case "description":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={descriptionValueToString(v)} />
                    ))}
                </>
            );
        case "last_equity_funding_type":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={lastEquityFundingTypeValueToString(v)} />
                    ))}
                </>
            );
        case "location_group_identifiers":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={locationGroupIdentifiersValueToString(v)} />
                    ))}
                </>
            );
        case "investor_identifiers":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={investorIdentifiersValueToString(v)} />
                    ))}
                </>
            );
        case "location_identifiers":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={locationIdentifiersValueToString(v)} />
                    ))}
                </>
            );
        case "num_employees_enum":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={numEmployeesEnumValueToString(v)} />
                    ))}
                </>
            );
        case "categories":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={categoriesValueToString(v)} />
                    ))}
                </>
            );
        case "category_groups":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={categoryGroupsValueToString(v)} />
                    ))}
                </>
            );
        case "last_funding_total":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={lastFundingTotalValueToString(v)} />
                    ))}
                </>
            );
        case "funding_total":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={fundingTotalValueToString(v)} />
                    ))}
                </>
            );
        case "founded_on":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={foundedOnValueToString(v)} />
                    ))}
                </>
            );
        case "last_funding_at":
            return (
                <>
                    {filter.filter.values?.map((v, idx) => (
                        <ValueChip allowDelete={false} key={idx} label={lastFundingAtValueToString(v)} />
                    ))}
                </>
            );
        case undefined:
            return null;
    }
};
