import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import * as React from "react";
import { ValueChip } from "./valueChip";

export interface SearchableFreeTextSelectProps<T> {
    fullWidth?: boolean;
    values: (T | string)[];
    setValues: (vs: Array<T | string>) => void;
    autocompleteValues: readonly T[] | undefined;
    getValueTitle: (v: T) => string;
}

export const SearchableFreeTextSelect = <T,>({
    values,
    setValues,
    autocompleteValues = [],
    getValueTitle,
    fullWidth,
}: SearchableFreeTextSelectProps<T>) => {
    const handleChange = React.useCallback(
        (e: unknown, v: (string | T)[]) => {
            setValues(v);
        },
        [setValues],
    );

    const renderInput = React.useCallback(
        (params: AutocompleteRenderInputParams) => <TextField {...params} placeholder="Enter values" size="small" />,
        [],
    );

    return (
        <Autocomplete
            fullWidth={fullWidth}
            multiple
            id="tags-filled"
            options={autocompleteValues}
            filterSelectedOptions
            getOptionLabel={v => (typeof v === "string" ? v : getValueTitle(v))}
            freeSolo
            renderTags={(value: readonly T[], getTagProps) =>
                value.map((option: T, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return <ValueChip {...tagProps} label={getValueTitle(option)} key={key} />;
                })
            }
            renderInput={renderInput}
            onChange={handleChange}
            value={values}
        />
    );
};
