import { BeSupportedFieldId } from "./cb-backend-types";

export type FeSupportedCrunchbaseFieldId = BeSupportedFieldId;

// Field display names
export const CRUNCHBASE_FIELD_DISPLAY_NAMES: Record<FeSupportedCrunchbaseFieldId, { displayName: string }> = {
    description: { displayName: "Description" },
    num_employees_enum: { displayName: "Number of Employees" },
    last_equity_funding_type: { displayName: "Last Funding Round" },
    last_funding_at: { displayName: "Last Funding Round Date" },
    last_funding_total: { displayName: "Last Funding Round Amount ($)" },
    funding_total: { displayName: "Total Funding ($)" },
    website_url: { displayName: "Website" },
    investor_identifiers: { displayName: "Investors" },
    categories: { displayName: "Industry" },
    category_groups: { displayName: "Industry Groups" },
    location_group_identifiers: { displayName: "HQ Region" },
    location_identifiers: { displayName: "HQ Location" },
    rank: { displayName: "Crunchbase Rank" },
    created_at: { displayName: "Added to Crunchbase on" },
    founded_on: { displayName: "Founding date" },
    linkedin: { displayName: "LinkedIn" },
};
