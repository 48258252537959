import React, { ReactNode } from "react";
import { getBackendService } from "../services/cb-backend";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { availableGridsQueryOptions } from "../react-query/queryKeys";
import { BeAvailableGrid } from "../services/cb-backend-types";

export const GridProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const service = React.useMemo(() => getBackendService(), []);

    const queryOptions = React.useMemo(() => availableGridsQueryOptions(service), [service]);
    useQuery(queryOptions);

    return <>{children}</>;
};

export const updateAvailableGridsOnCreate = (queryClient: QueryClient, grid: BeAvailableGrid) => {
    // TODO: hack for type
    const queryOptions = availableGridsQueryOptions(getBackendService());
    const currentData = queryClient.getQueryData(queryOptions.queryKey);
    queryClient.setQueryData(queryOptions.queryKey, [...(currentData ?? []), grid]);
};

export const updateAvailableGridsOnDelete = (queryClient: QueryClient, gridId: string) => {
    // TODO: hack for type
    const queryOptions = availableGridsQueryOptions(getBackendService());
    const currentData = queryClient.getQueryData(queryOptions.queryKey);
    queryClient.setQueryData(
        queryOptions.queryKey,
        currentData?.filter(grid => grid.unique_id !== gridId),
    );
};

export type AvailableGridUpdate = {
    unique_id: string;
} & Partial<Omit<BeAvailableGrid, "unique_id">>;

export const updateAvailableGridsOnUpdate = (queryClient: QueryClient, updatedGrid: AvailableGridUpdate) => {
    // TODO: hack for type
    const queryOptions = availableGridsQueryOptions(getBackendService());
    const currentData = queryClient.getQueryData(queryOptions.queryKey);
    queryClient.setQueryData(
        queryOptions.queryKey,
        currentData?.map(g =>
            g.unique_id === updatedGrid.unique_id ? { ...g, ...updatedGrid, last_edited: new Date().toISOString() } : g,
        ),
    );
};
