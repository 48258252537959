import { Box } from "@mui/material";
import * as React from "react";

interface EditableFilterComponentLayoutNonEditingContentProps {
    nonEditingContent: React.ReactNode | undefined;
    dlt: React.ReactNode;
    edit: React.ReactNode;
}

export const EditableFilterComponentLayoutNonEditingContent: React.FC<
    EditableFilterComponentLayoutNonEditingContentProps
> = ({ nonEditingContent, dlt, edit }) => {
    return (
        <Box
            sx={{
                py: 1,
                pl: 1.5,
                borderRadius: 1,
                border: 1,
                borderColor: "divider",
                justifyContent: "space-between",
                display: "flex",
                columnGap: 3,
                alignItems: "center",
                flex: 1,
                overflowX: "hidden",
            }}
        >
            {nonEditingContent}
            <Box
                sx={{
                    flexShrink: 0,
                }}
            >
                {dlt}
                {edit}
            </Box>
        </Box>
    );
};
