import * as React from "react";
import { Box, Menu, Typography, IconButton, useTheme, MenuItem } from "@mui/material";
import { Eye, More, Refresh, Setting5, Trash, PlayCircle, Global, KeySquare, Key } from "iconsax-react";
import {
    AgentToolConfig,
    CellValueV2,
    Column,
    ColumnGeneratedBy,
    ColumnGeneratedByApollo,
    DEFAULT_AGENT_TOOL_CONFIG,
    FormatOptions,
    getContextColChangeHandler,
    hasTrialFeatures,
    isRealRow,
    Row,
} from "./grid";
import { AnswerGridCell } from "./AnswerGridCell";
import { useBoolean } from "../utils/hooks";
import { HeaderGridCellPopover } from "./headerGridCellPopover";
import { AdvancedColumnEditorDialog, ColumnEditorState } from "./advancedColumnEditorDialog";
import { hasFilters } from "../home/visibility/visibility";
import { ColumnVisibilityFilter, VisibilityFiltersByColumn } from "./grid";
import { VisibilityFilterDialog } from "../home/visibility/visibilityFiltersDialog";
import { CompanyPeopleDialog } from "../home/filters/companyPeopleDialog";
import { isNonNullable } from "../utils/isNonNullable";
import { UserInputColumnEditorDialog } from "./userInputColumnEditor";
import { isErrorValueTypeValue } from "./gridUtils";
import { useCurrentUserQuery } from "../context/userContext";

export interface HeaderGridCellProps {
    allColumns: Column[];
    allRows: Row[];
    isExportOngoing: boolean;
    isSomeRowLoading: boolean;
    isSomeColumnCellLoading: boolean;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    column: Column;
    enableApolloColumns: boolean;
    enableCrunchbaseColumns: boolean;
    visibilityFilters: ColumnVisibilityFilter;
    hasUnloadedCells: boolean;
    isMarkingSomeColumnAsWebsite: boolean;
    onLoadAllUnloadedCells: () => Promise<void>;
    handleRetryColumnValues: (column: Column, errorValueType?: CellValueV2["type"]) => Promise<void>;
    setVisibilityFiltersByColumn: React.Dispatch<React.SetStateAction<VisibilityFiltersByColumn>>;
    onAddColumnValuesToThisPage: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    onDeleteColumn: (colId: string) => void;
    onMarkAsWebsite: (colId: string) => Promise<void>;
    onPivotGrid: (colId: string) => Promise<void>;
    onMarkAsNewKey: ((colId: string) => void) | undefined;
}

export const HeaderGridCell: React.FC<HeaderGridCellProps> = React.memo(function _HeaderGridCell({
    allColumns,
    allRows,
    column,
    isFirstColumn,
    isLastColumn,
    isExportOngoing,
    isSomeRowLoading,
    isSomeColumnCellLoading,
    enableApolloColumns,
    enableCrunchbaseColumns,
    visibilityFilters,
    hasUnloadedCells,
    isMarkingSomeColumnAsWebsite,
    setVisibilityFiltersByColumn,
    onAddColumnValuesToThisPage,
    onDeleteColumn,
    handleRetryColumnValues,
    onLoadAllUnloadedCells,
    onMarkAsWebsite,
    onPivotGrid,
    onMarkAsNewKey,
}: HeaderGridCellProps) {
    const gridCellRef = React.useRef<HTMLTableElement>(null);
    const [rightAnchorPosition, setRightAnchorPosition] = React.useState<null | { top: number; left: number }>(null);
    const [rightClickAnchorEl, setRightClickAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editingAnchorEl, setEditingAnchorEl] = React.useState<null | HTMLTableElement>(null);

    React.useLayoutEffect(() => {
        if (gridCellRef.current != null) {
            setEditingAnchorEl(gridCellRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridCellRef.current]);

    const handleRightClick = React.useCallback((event: React.MouseEvent<HTMLTableCellElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setRightClickAnchorEl(event.currentTarget);
        setRightAnchorPosition({ top: event.clientY, left: event.clientX });
    }, []);

    const handleCloseRightClick = React.useCallback(() => {
        setRightClickAnchorEl(null);
        setRightAnchorPosition(null);
    }, []);

    const handleDeleteColumn = React.useCallback(() => {
        onDeleteColumn(column.id);
        handleCloseRightClick();
    }, [onDeleteColumn, column.id, handleCloseRightClick]);

    const handlePivotGrid = React.useCallback(() => {
        void onPivotGrid(column.id);
        handleCloseRightClick();
    }, [column.id, handleCloseRightClick, onPivotGrid]);

    return (
        <>
            <AnswerGridCell
                align={column.align}
                isFirstColumn={isFirstColumn}
                isLastColumn={isLastColumn}
                isLargeStyle={column.isEditable ?? false}
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    "&:hover .visibility-icon": {
                        right: "20px",
                    },
                    "&:hover .edit-icon": {
                        visibility: "visible",
                    },
                    position: "relative",
                    py: 1.25,
                }}
                ref={gridCellRef}
                onContextMenu={handleRightClick}
            >
                {column.isEditable ? (
                    <EditingHeaderGridCellContent
                        allRows={allRows}
                        allColumns={allColumns}
                        isExportOngoing={isExportOngoing}
                        isSomeRowLoading={isSomeRowLoading}
                        enableApolloColumns={enableApolloColumns}
                        enableCrunchbaseColumns={enableCrunchbaseColumns}
                        column={column}
                        onFinishEditingHeaderColumn={onAddColumnValuesToThisPage}
                        anchorEl={editingAnchorEl}
                    />
                ) : (
                    <NonEditingHeaderGridCellContent
                        allColumns={allColumns}
                        allRows={allRows}
                        column={column}
                        isExportOngoing={isExportOngoing}
                        isSomeColumnCellLoading={isSomeColumnCellLoading}
                        isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                        visibilityFilters={visibilityFilters}
                        enableApolloColumns={enableApolloColumns}
                        onLoadAllUnloadedCells={hasUnloadedCells ? onLoadAllUnloadedCells : undefined}
                        onDeleteColumn={onDeleteColumn}
                        onAddColumnValuesToThisPage={onAddColumnValuesToThisPage}
                        setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
                        handleRetryColumnValues={handleRetryColumnValues}
                        onMarkAsWebsite={onMarkAsWebsite}
                        onPivotGrid={handlePivotGrid}
                        onMarkAsNewKey={onMarkAsNewKey}
                    />
                )}
            </AnswerGridCell>
            <Menu
                anchorEl={rightClickAnchorEl}
                open={Boolean(rightClickAnchorEl)}
                onClose={handleCloseRightClick}
                anchorReference="anchorPosition"
                anchorPosition={rightAnchorPosition ?? undefined}
                disableAutoFocusItem
                sx={{ p: 1 }}
            >
                <MenuItem
                    onClick={handleDeleteColumn}
                    sx={{ px: 1.5 }}
                    disabled={isExportOngoing || isSomeColumnCellLoading}
                >
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Trash size="16" />
                        <Typography variant="caption">Delete column</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
});

interface EditingHeaderGridCellContentProps {
    allRows: Row[];
    allColumns: Column[];
    isExportOngoing: boolean;
    isSomeRowLoading: boolean;
    enableApolloColumns: boolean;
    enableCrunchbaseColumns: boolean;
    column: Column;
    anchorEl: HTMLTableElement | null;
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
}

const EditingHeaderGridCellContent: React.FC<EditingHeaderGridCellContentProps> = ({
    allRows,
    allColumns,
    isExportOngoing,
    isSomeRowLoading,
    enableApolloColumns,
    enableCrunchbaseColumns,
    column,
    anchorEl,
    onFinishEditingHeaderColumn,
}) => {
    const [open, setOpen] = React.useState(true);

    const [newColLabel, setNewColLabel] = React.useState<string>(column.label);
    const [newColQuery, setNewColQuery] = React.useState<string>(column.label);
    const [newContextColumnIds, setNewContextColumnIds] = React.useState<string[]>(
        column.generatedBy?.type == "web_search" ? column.generatedBy.contextColumnIds : [],
    );
    const [toolConfig, setToolConfig] = React.useState<AgentToolConfig | undefined>(DEFAULT_AGENT_TOOL_CONFIG);
    const [formatOptions, setFormatOptions] = React.useState<FormatOptions | undefined>({ type: "inferred" });

    const [apolloSourceConfig, setApolloSourceConfig] = React.useState<Omit<ColumnGeneratedByApollo, "type">>({
        personFilters: {
            primary: {
                person_titles: [],
            },
            fallback: [],
        },
        enrichmentSettings: { enrichSinglePerson: false },
    });

    const handleClickEnterQuery = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
        setOpen(true);
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const handlePopoverClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    const handleChangeContextColumnIds = React.useMemo(
        () => getContextColChangeHandler(setNewContextColumnIds, setToolConfig),
        [setNewContextColumnIds, setToolConfig],
    );

    const handleChangeToolConfigInPopover = React.useCallback((toolConfig: AgentToolConfig | undefined) => {
        setToolConfig(toolConfig);
    }, []);

    const handleFinishEditingThisHeaderColumn = React.useCallback(
        (columnQuery: ColumnGeneratedBy, colLabel?: string) =>
            onFinishEditingHeaderColumn(column.id, columnQuery, colLabel),
        [onFinishEditingHeaderColumn, column.id],
    );

    const handleAddColumn = React.useCallback(
        (columnQuery: ColumnGeneratedBy, colLabel?: string) => {
            void onFinishEditingHeaderColumn(column.id, columnQuery, colLabel);
            setOpen(false);
        },
        [onFinishEditingHeaderColumn, column.id],
    );

    const {
        value: isAdvancedDialogOpen,
        setTrue: openAdvancedDialog,
        setFalse: closeAdvancedDialog,
    } = useBoolean(false);

    const handleCloseAdvancedDialog = React.useCallback(() => {
        setOpen(false);
        closeAdvancedDialog();
    }, [closeAdvancedDialog]);

    const otherColumns = React.useMemo(
        () => allColumns.filter(col => col.id !== column.id && col.id !== "name"),
        [allColumns, column.id],
    );

    return (
        <>
            <Box
                onClick={handleClickEnterQuery}
                sx={{
                    width: 1,
                    maxWidth: 200,
                    cursor: "text",
                    py: "6px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "start",
                }}
            >
                <Typography
                    noWrap
                    variant="body2"
                    sx={{
                        color: "#acacac",
                        maxHeight: 100,
                        WebkitLineClamp: 3,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                    }}
                >
                    {newColQuery.length > 0 ? "Finish query..." : "Enter query..."}
                </Typography>
            </Box>
            <HeaderGridCellPopover
                open={open}
                anchorEl={anchorEl}
                apolloSourceConfig={apolloSourceConfig}
                colLabel={newColLabel}
                otherColumns={otherColumns}
                isExportOngoing={isExportOngoing}
                isSomeRowLoading={isSomeRowLoading}
                enableApolloColumns={enableApolloColumns}
                enableCrunchbaseColumns={enableCrunchbaseColumns}
                newColQuery={newColQuery}
                newContextColumnIds={newContextColumnIds}
                toolConfig={toolConfig}
                formatOptions={formatOptions}
                onChangeFormatOptions={setFormatOptions}
                onChangeColQuery={setNewColQuery}
                onChangeColLabel={setNewColLabel}
                onOpenAdvancedDialog={openAdvancedDialog}
                onClose={handlePopoverClose}
                onChangeColumnContextField={handleChangeContextColumnIds}
                onFinishEditingHeaderColumn={handleFinishEditingThisHeaderColumn}
                onChangeApolloSourceConfig={setApolloSourceConfig}
                onChangeToolConfig={handleChangeToolConfigInPopover}
            />
            <AdvancedColumnEditorDialog
                isOpen={isAdvancedDialogOpen}
                onClose={handleCloseAdvancedDialog}
                colId={column.id}
                colQuery={newColQuery}
                colLabel={newColLabel}
                formatOptions={formatOptions}
                rows={allRows}
                columns={allColumns}
                contextColumnIds={newContextColumnIds}
                otherColumns={otherColumns}
                toolConfig={toolConfig}
                initialColumnEditorState={undefined}
                onChangeColQuery={setNewColQuery}
                onChangeColLabel={setNewColLabel}
                onChangeContextColumnIds={handleChangeContextColumnIds}
                onAddColumn={handleAddColumn}
                onChangeToolConfig={setToolConfig}
                onChangeFormatOptions={setFormatOptions}
            />
        </>
    );
};

interface NonEditingHeaderGridCellContentProps {
    allColumns: Column[];
    allRows: Row[];
    column: Column;
    isExportOngoing: boolean;
    isSomeColumnCellLoading: boolean;
    visibilityFilters: ColumnVisibilityFilter;
    isMarkingSomeColumnAsWebsite: boolean;
    enableApolloColumns: boolean;
    onLoadAllUnloadedCells: (() => Promise<void>) | undefined;
    handleRetryColumnValues: (column: Column, errorValueType?: CellValueV2["type"]) => Promise<void>;
    onDeleteColumn: (colId: string) => void;
    onAddColumnValuesToThisPage: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    setVisibilityFiltersByColumn: React.Dispatch<React.SetStateAction<VisibilityFiltersByColumn>>;
    onMarkAsWebsite: (colId: string) => Promise<void>;
    onPivotGrid: () => void;
    onMarkAsNewKey: ((colId: string) => void) | undefined;
}

const NonEditingHeaderGridCellContent: React.FC<NonEditingHeaderGridCellContentProps> = ({
    allColumns,
    allRows,
    column,
    isExportOngoing,
    isSomeColumnCellLoading,
    visibilityFilters,
    isMarkingSomeColumnAsWebsite,
    enableApolloColumns,
    handleRetryColumnValues,
    onLoadAllUnloadedCells,
    onDeleteColumn,
    onAddColumnValuesToThisPage,
    setVisibilityFiltersByColumn,
    onMarkAsWebsite,
    onPivotGrid,
    onMarkAsNewKey,
}) => {
    const theme = useTheme();
    const {
        value: isVisibilityDialogOpen,
        setTrue: openVisibilityDialog,
        setFalse: closeVisibilityDialog,
    } = useBoolean(false);

    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpenMoreMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMoreMenuAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMoreMenu = React.useCallback(() => {
        setMoreMenuAnchorEl(null);
    }, []);

    const handleCloseVisibilityDialog = React.useCallback(() => {
        closeVisibilityDialog();
        setMoreMenuAnchorEl(null);
    }, [closeVisibilityDialog]);

    const handleDeleteColumn = React.useCallback(() => {
        onDeleteColumn(column.id);
        handleCloseMoreMenu();
    }, [onDeleteColumn, column.id, handleCloseMoreMenu]);

    const handleOpenVisibilityDialog = React.useCallback(() => {
        setMoreMenuAnchorEl(null);
        openVisibilityDialog();
    }, [openVisibilityDialog]);

    const hasVisibilityFilters = visibilityFilters != null && hasFilters(visibilityFilters);

    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    fontWeight: "600",
                    color: "secondary.main",
                    maxHeight: 100,
                    WebkitLineClamp: 3,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 500,
                }}
            >
                {column.label}
            </Typography>
            <IconButton
                onClick={handleOpenMoreMenu}
                className="edit-icon"
                sx={{
                    visibility: moreMenuAnchorEl != null ? "visible" : "hidden",
                    position: "absolute",
                    right: 0,
                    top: 0,
                }}
                size="small"
            >
                <More size={16} color={theme.palette.secondary.main} />
            </IconButton>
            <IconButton
                onClick={handleOpenVisibilityDialog}
                className="visibility-icon"
                sx={{
                    visibility: hasVisibilityFilters ? "visible" : "hidden",
                    position: "absolute",
                    right: moreMenuAnchorEl != null ? 20 : 0,
                    top: "0px",
                }}
                size="small"
            >
                <Eye size={16} color={theme.palette.secondary.main} />
            </IconButton>
            <VisibilityFilterDialog
                allRows={allRows}
                visibilityFilters={visibilityFilters}
                setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
                column={column}
                isExportOngoing={isExportOngoing}
                isOpen={isVisibilityDialogOpen}
                onClose={handleCloseVisibilityDialog}
                onLoadAllUnloadedCells={onLoadAllUnloadedCells}
            />
            <NonEditingHeaderGridCellContentMoreMenu
                column={column}
                allColumns={allColumns}
                allRows={allRows}
                enableApolloColumns={enableApolloColumns}
                isExportOngoing={isExportOngoing}
                isSomeColumnCellLoading={isSomeColumnCellLoading}
                isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                onLoadAllUnloadedCells={onLoadAllUnloadedCells}
                handleRetryColumnValues={handleRetryColumnValues}
                onDeleteColumn={handleDeleteColumn}
                onFinishEditingHeaderColumn={onAddColumnValuesToThisPage}
                onOpenVisibilityDialog={handleOpenVisibilityDialog}
                anchorEl={moreMenuAnchorEl}
                onClose={handleCloseMoreMenu}
                onPivotGrid={onPivotGrid}
                onMarkAsWebsite={onMarkAsWebsite}
                onMarkAsNewKey={onMarkAsNewKey}
            />
        </>
    );
};

const NonEditingHeaderGridCellContentMoreMenu: React.FC<{
    column: Column;
    allColumns: Column[];
    allRows: Row[];
    isExportOngoing: boolean;
    isSomeColumnCellLoading: boolean;
    anchorEl: HTMLElement | null;
    isMarkingSomeColumnAsWebsite: boolean;
    enableApolloColumns: boolean;
    onLoadAllUnloadedCells: (() => Promise<void>) | undefined;
    handleRetryColumnValues: (column: Column, errorValueType?: CellValueV2["type"]) => Promise<void>;
    onDeleteColumn: (colId: string) => void;
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    onOpenVisibilityDialog: () => void;
    onClose: () => void;
    onMarkAsWebsite: (colId: string) => Promise<void>;
    onPivotGrid: () => void;
    onMarkAsNewKey: ((colId: string) => void) | undefined;
}> = ({
    anchorEl,
    allColumns,
    allRows,
    column,
    enableApolloColumns,
    isExportOngoing,
    isSomeColumnCellLoading,
    isMarkingSomeColumnAsWebsite,
    onLoadAllUnloadedCells,
    onDeleteColumn,
    onFinishEditingHeaderColumn,
    onOpenVisibilityDialog,
    handleRetryColumnValues,
    onClose,
    onMarkAsWebsite,
    onPivotGrid,
    onMarkAsNewKey,
}) => {
    const theme = useTheme();

    const handleDeleteColumn = React.useCallback(() => {
        onDeleteColumn(column.id);
        onClose();
    }, [onDeleteColumn, column.id, onClose]);

    const handleRetryFailedColumnValues = React.useCallback(() => {
        void handleRetryColumnValues(column);
        onClose();
    }, [handleRetryColumnValues, column, onClose]);

    const handleLoadUnloadedColumnValues = React.useMemo(
        () =>
            onLoadAllUnloadedCells != null
                ? () => {
                      void onLoadAllUnloadedCells();
                      onClose();
                  }
                : undefined,
        [onLoadAllUnloadedCells, onClose],
    );

    const isAnyValueErroredForColumn = React.useMemo(() => {
        return allRows.some(row => isRealRow(row) && isErrorValueTypeValue(row.data[column.id]?.value, "error"));
    }, [allRows, column.id]);

    const sharedMenuItems = [
        onLoadAllUnloadedCells != null && !isExportOngoing ? (
            <MenuItem
                key="load-unloaded-column-values"
                data-value="load-unloaded-column-values"
                onClick={handleLoadUnloadedColumnValues}
                sx={{ px: 1.5 }}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    <PlayCircle size="16" />
                    <Typography variant="caption">Load all column cells</Typography>
                </Box>
            </MenuItem>
        ) : undefined,
        onMarkAsNewKey != null ? (
            <MenuItem
                key="mark-as-new-key"
                data-value="mark-as-new-key"
                onClick={() => onMarkAsNewKey(column.id)}
                sx={{ px: 1.5 }}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    <Key size="16" />
                    <Typography variant="caption">Make key</Typography>
                </Box>
            </MenuItem>
        ) : undefined,
        <MenuItem
            key="visibility-filters"
            data-value="visibility-filters"
            onClick={onOpenVisibilityDialog}
            sx={{ px: 1.5 }}
        >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <Eye size="16" />
                <Typography variant="caption">Filter answers</Typography>
            </Box>
        </MenuItem>,
        isAnyValueErroredForColumn ? (
            <MenuItem
                key="retry-failed-column-values"
                data-value="retry-failed-column-values"
                onClick={handleRetryFailedColumnValues}
                sx={{ px: 1.5 }}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    <Refresh size="16" />
                    <Typography variant="caption">Retry failed values</Typography>
                </Box>
            </MenuItem>
        ) : undefined,

        <MenuItem
            key="delete-column"
            data-value="delete-column"
            onClick={handleDeleteColumn}
            sx={{ px: 1.5 }}
            disabled={isExportOngoing || isSomeColumnCellLoading}
        >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                <Trash size="16" color={theme.palette.error.main} />
                <Typography variant="caption" color="error">
                    Delete column
                </Typography>
            </Box>
        </MenuItem>,
    ].filter(isNonNullable);

    switch (column.generatedBy?.type) {
        case "web_search":
            return (
                <WebSearchMoreMenu
                    column={column}
                    allColumns={allColumns}
                    allRows={allRows}
                    isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                    isWebsiteAlreadyMarked={enableApolloColumns}
                    anchorEl={anchorEl}
                    sharedMenuItems={sharedMenuItems}
                    onMarkAsWebsite={onMarkAsWebsite}
                    onFinishEditingHeaderColumn={onFinishEditingHeaderColumn}
                    onClose={onClose}
                    onPivotGrid={onPivotGrid}
                />
            );
        case "apollo":
            return (
                <ApolloMoreMenu
                    column={column}
                    isSomeColumnCellLoading={isSomeColumnCellLoading}
                    onFinishEditingHeaderColumn={onFinishEditingHeaderColumn}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    sharedMenuItems={sharedMenuItems}
                    onPivotGrid={onPivotGrid}
                />
            );
        case "user_input":
            return (
                <UserInputMoreMenu
                    column={column}
                    isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                    isWebsiteAlreadyMarked={enableApolloColumns}
                    anchorEl={anchorEl}
                    sharedMenuItems={sharedMenuItems}
                    onClose={onClose}
                    onFinishEditingHeaderColumn={onFinishEditingHeaderColumn}
                    onMarkAsWebsite={onMarkAsWebsite}
                />
            );
        default:
            return (
                <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} sx={{ p: 1 }}>
                    {sharedMenuItems}
                </Menu>
            );
    }
};

interface WebSearchMoreMenuProps {
    column: Column;
    allColumns: Column[];
    allRows: Row[];
    sharedMenuItems: React.ReactNode[];
    isMarkingSomeColumnAsWebsite: boolean;
    isWebsiteAlreadyMarked: boolean;
    anchorEl: HTMLElement | null;
    onMarkAsWebsite: (colId: string) => Promise<void>;
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    onClose: () => void;
    onPivotGrid: () => void;
}

const WebSearchMoreMenu: React.FC<WebSearchMoreMenuProps> = ({
    column,
    allColumns,
    allRows,
    anchorEl,
    sharedMenuItems,
    isMarkingSomeColumnAsWebsite,
    isWebsiteAlreadyMarked,
    onMarkAsWebsite,
    onFinishEditingHeaderColumn,
    onClose,
    onPivotGrid,
}) => {
    const {
        value: isAdvancedDialogOpen,
        setTrue: openAdvancedDialog,
        setFalse: closeAdvancedDialog,
    } = useBoolean(false);

    const initialColumnEditorState = React.useMemo((): ColumnEditorState | undefined => {
        if (column.generatedBy?.type === "web_search") {
            return {
                colLabel: column.label,
                colQuery: column.generatedBy.query,
                contextColumnIds: column.generatedBy.contextColumnIds,
                toolConfig: column.generatedBy.toolConfig,
                formatOptions: column.generatedBy.formatOptions,
            };
        }
        return undefined;
    }, [column]);

    const [newColLabel, setNewColLabel] = React.useState<string>(initialColumnEditorState?.colLabel ?? column.label);
    const [newColQuery, setNewColQuery] = React.useState<string>(initialColumnEditorState?.colQuery ?? "");
    const [newContextColumnIds, setNewContextColumnIds] = React.useState<string[]>(
        initialColumnEditorState?.contextColumnIds ?? [],
    );
    // TODO: If you edit the tool
    const [newToolConfig, setNewToolConfig] = React.useState<AgentToolConfig | undefined>(
        initialColumnEditorState?.toolConfig ??
            ((initialColumnEditorState?.contextColumnIds.length ?? 0) > 0 ? undefined : DEFAULT_AGENT_TOOL_CONFIG),
    );
    const [newFormatOptions, setNewFormatOptions] = React.useState<FormatOptions | undefined>(
        initialColumnEditorState?.formatOptions,
    );

    const handleChangeContextColumnIds = React.useMemo(
        () => getContextColChangeHandler(setNewContextColumnIds, setNewToolConfig),
        [setNewContextColumnIds, setNewToolConfig],
    );

    const otherColumns = useOtherColumns(allColumns, column.id);
    const handleFinishEditingThisHeaderColumn = useHandleFinishEditingThisHeaderColumn(
        onFinishEditingHeaderColumn,
        column.id,
    );

    const handleAddColumn = React.useCallback(
        (columnQuery: ColumnGeneratedBy, columnLabel?: string) => {
            void handleFinishEditingThisHeaderColumn(columnQuery, columnLabel);
            closeAdvancedDialog();
            onClose();
        },
        [closeAdvancedDialog, handleFinishEditingThisHeaderColumn, onClose],
    );

    const handleOpenAdvancedDialog = React.useCallback(() => {
        openAdvancedDialog();
    }, [openAdvancedDialog]);

    const handleCloseAdvancedDialog = React.useCallback(() => {
        closeAdvancedDialog();
        onClose();
    }, [closeAdvancedDialog, onClose]);

    const handleMarkAsWebsite = React.useCallback(() => {
        void onMarkAsWebsite(column.id);
        onClose();
    }, [onMarkAsWebsite, column.id, onClose]);

    const isFindWebLinksTool =
        column.generatedBy?.type === "web_search" && column.generatedBy?.toolConfig?.toolName === "FindWebPages";
    const user = useCurrentUserQuery();

    return (
        <>
            <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} sx={{ p: 1 }}>
                <MenuItem data-value="edit-column" onClick={handleOpenAdvancedDialog} sx={{ px: 1.5 }}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Setting5 size="16" />
                        <Typography variant="caption">Configure</Typography>
                    </Box>
                </MenuItem>
                {isFindWebLinksTool && user.data?.is_admin && (
                    <MenuItem data-value="pivot-grid" onClick={onPivotGrid} sx={{ px: 1.5 }}>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <KeySquare size="16" />
                            <Typography variant="caption">As key in new grid</Typography>
                        </Box>
                    </MenuItem>
                )}
                {!isMarkingSomeColumnAsWebsite && !isWebsiteAlreadyMarked && (
                    <MenuItem
                        key="mark-as-website"
                        data-value="mark-as-website"
                        onClick={handleMarkAsWebsite}
                        sx={{ px: 1.5 }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <Global size="16" />
                            <Typography variant="caption">Mark as website</Typography>
                        </Box>
                    </MenuItem>
                )}
                {sharedMenuItems}
            </Menu>
            <AdvancedColumnEditorDialog
                isOpen={isAdvancedDialogOpen}
                onClose={handleCloseAdvancedDialog}
                colId={column.id}
                colQuery={newColQuery}
                colLabel={newColLabel}
                rows={allRows}
                columns={allColumns}
                contextColumnIds={newContextColumnIds}
                otherColumns={otherColumns}
                initialColumnEditorState={initialColumnEditorState}
                toolConfig={newToolConfig}
                formatOptions={newFormatOptions}
                onChangeColQuery={setNewColQuery}
                onChangeColLabel={setNewColLabel}
                onChangeContextColumnIds={handleChangeContextColumnIds}
                onAddColumn={handleAddColumn}
                onChangeToolConfig={setNewToolConfig}
                onChangeFormatOptions={setNewFormatOptions}
            />
        </>
    );
};

interface UserInputMoreMenuProps {
    column: Column;
    anchorEl: HTMLElement | null;
    sharedMenuItems: React.ReactNode[];
    isMarkingSomeColumnAsWebsite: boolean;
    isWebsiteAlreadyMarked: boolean;
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    onClose: () => void;
    onMarkAsWebsite: (colId: string) => Promise<void>;
}

const UserInputMoreMenu: React.FC<UserInputMoreMenuProps> = ({
    column,
    anchorEl,
    sharedMenuItems,
    isMarkingSomeColumnAsWebsite,
    isWebsiteAlreadyMarked,
    onFinishEditingHeaderColumn,
    onClose,
    onMarkAsWebsite,
}) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [newColLabel, setNewColLabel] = React.useState<string>(column.label);
    const handleOpenDialog = React.useCallback(() => {
        setIsDialogOpen(true);
        onClose();
    }, [onClose]);

    const handleCloseDialog = React.useCallback(() => {
        setIsDialogOpen(false);
        onClose();
    }, [onClose]);

    const handleUpdateColumn = React.useCallback(() => {
        void onFinishEditingHeaderColumn(column.id, { ...column.generatedBy, type: "user_input" }, newColLabel);
        onClose();
    }, [onFinishEditingHeaderColumn, column.id, column.generatedBy, newColLabel, onClose]);

    const handleMarkAsWebsite = React.useCallback(() => {
        void onMarkAsWebsite(column.id);
        onClose();
    }, [onMarkAsWebsite, column.id, onClose]);

    return (
        <>
            <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} sx={{ p: 1 }}>
                <MenuItem data-value="edit-column" onClick={handleOpenDialog} sx={{ px: 1.5 }}>
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Setting5 size="16" />
                        <Typography variant="caption">Configure</Typography>
                    </Box>
                </MenuItem>
                {!isMarkingSomeColumnAsWebsite && !isWebsiteAlreadyMarked && (
                    <MenuItem
                        key="mark-as-website"
                        data-value="mark-as-website"
                        onClick={handleMarkAsWebsite}
                        sx={{ px: 1.5 }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <Global size="16" />
                            <Typography variant="caption">Mark as website</Typography>
                        </Box>
                    </MenuItem>
                )}
                {sharedMenuItems}
            </Menu>
            <UserInputColumnEditorDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                colLabel={newColLabel}
                onChangeColLabel={setNewColLabel}
                onUpdateColumn={handleUpdateColumn}
                initialColLabel={column.label}
            />
        </>
    );
};

interface ApolloMoreMenuProps {
    column: Column;
    isSomeColumnCellLoading: boolean;
    anchorEl: HTMLElement | null;
    sharedMenuItems: React.ReactNode[];
    onPivotGrid: () => void;
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>;
    onClose: () => void;
}

const ApolloMoreMenu: React.FC<ApolloMoreMenuProps> = ({
    column,
    isSomeColumnCellLoading,
    onFinishEditingHeaderColumn,
    anchorEl,
    onClose,
    sharedMenuItems,
    onPivotGrid,
}) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [colLabel, setColLabel] = React.useState(column.label);
    const [config, setConfig] = React.useState<Omit<ColumnGeneratedByApollo, "type">>(
        getApolloConfigFromGeneratedBy(column.generatedBy),
    );

    const handleOpenDialog = React.useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const handleCloseDialog = React.useCallback(() => {
        setIsDialogOpen(false);
        onClose();
    }, [onClose]);

    const handleFinishEditingThisHeaderColumn = React.useCallback(
        async (newConfig: Omit<ColumnGeneratedByApollo, "type">) => {
            await onFinishEditingHeaderColumn(column.id, { ...newConfig, type: "apollo" }, colLabel);
            onClose();
        },
        [onFinishEditingHeaderColumn, column.id, colLabel, onClose],
    );
    const user = useCurrentUserQuery();

    return (
        <>
            <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} sx={{ p: 1 }}>
                <MenuItem
                    data-value="edit-column"
                    onClick={handleOpenDialog}
                    sx={{ px: 1.5 }}
                    disabled={isSomeColumnCellLoading}
                >
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                        <Setting5 size="16" />
                        <Typography variant="caption">Configure</Typography>
                    </Box>
                </MenuItem>
                {user.data != null && !hasTrialFeatures(user.data.plan_type) && (
                    <MenuItem data-value="pivot-grid" onClick={onPivotGrid} sx={{ px: 1.5 }}>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                            <KeySquare size="16" />
                            <Typography variant="caption">As key in new grid</Typography>
                        </Box>
                    </MenuItem>
                )}
                {sharedMenuItems}
            </Menu>
            <CompanyPeopleDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                config={config}
                setConfig={setConfig}
                colLabel={colLabel}
                onChangeColLabel={setColLabel}
                persona="companies"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleFinishEditingThisHeaderColumn}
            />
        </>
    );
};

function getApolloConfigFromGeneratedBy(generatedBy: ColumnGeneratedBy | undefined) {
    return generatedBy?.type === "apollo"
        ? {
              personFilters: generatedBy.personFilters,
              enrichmentSettings: generatedBy.enrichmentSettings,
          }
        : {
              personFilters: {
                  primary: { person_titles: [] },
                  fallback: [],
              },
              enrichmentSettings: { enrichSinglePerson: false },
          };
}

function useOtherColumns(allColumns: Column[], columnId: string) {
    return React.useMemo(
        () => allColumns.filter(col => col.id !== columnId && col.id !== "name"),
        [allColumns, columnId],
    );
}

function useHandleFinishEditingThisHeaderColumn(
    onFinishEditingHeaderColumn: (colId: string, columnQuery: ColumnGeneratedBy, columnLabel?: string) => Promise<void>,
    columnId: string,
) {
    return React.useCallback(
        async (columnQuery: ColumnGeneratedBy, colLabel?: string) =>
            onFinishEditingHeaderColumn(columnId, columnQuery, colLabel),
        [onFinishEditingHeaderColumn, columnId],
    );
}
