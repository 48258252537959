import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import "./App.css";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SignIn } from "./user-administration/SignIn";
import { SignUpViaInvitation } from "./user-administration/SignUpViaInvitation";
import { Navigate, RouterProvider, RouterProviderProps, createBrowserRouter } from "react-router-dom";
import { HomeView } from "./home/HomeView";
import { GridView } from "./grid/GridView";
import { GridProvider } from "./context/gridContextAndProvider";
import { SnackbarProviderProps, enqueueSnackbar } from "notistack";
import { StartTrial } from "./StartTrial";
import { AnswerGridSnackbarProvider } from "./answerGridSnackbarProvider";
import { useMediaQuery } from "@mui/material";
import { ChangePassword } from "./user-administration/ChangePassword";
import { VerifyEmail } from "./VerifyEmail";
import { SignUpViaSelfService } from "./user-administration/SignUpViaSelfService";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import { ForgotPassword } from "./user-administration/ForgotPassword";
import { IGNORE_MOBILE_WARNING_LOCAL_STORAGE_KEY, MobileDisabledMessage } from "./mobileDisabledMessage";
import { HelmetProvider } from "react-helmet-async";

const ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
    vertical: "bottom",
    horizontal: "right",
};

const rootElement = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(rootElement!);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Overrides the default failure retry count to 2 from 3
            // TODO: Doesn't seem to work
            retry: 2,
        },
    },
    queryCache: new QueryCache({
        onError: (error, query) => {
            console.error(error);
            if (query.meta?.toastOnFailure != null && !query.meta.toastOnFailure) {
                return;
            }
            enqueueSnackbar(`An unexpected error occurred: ${error.message}`, { variant: "error" });
        },
    }),
});

const router: RouterProviderProps["router"] = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/home" replace={true} />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/invitation",
        element: <SignUpViaInvitation />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/signup",
        element: <SignUpViaSelfService />,
    },
    {
        path: "/register",
        element: <Navigate to="/signup" replace={true} />,
    },
    {
        path: "/login",
        element: <SignIn />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/change-password",
        element: <ChangePassword />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/verify-email",
        element: <VerifyEmail />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/home",
        element: <HomeView />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/grid/:gridId",
        element: <GridView />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        path: "/try-it",
        element: <StartTrial />,
        ErrorBoundary: ErrorBoundary,
    },
]);

H.init("ve6lm8re", {
    serviceName: "frontend-app",
    tracingOrigins: true,
    environment: process.env.NODE_ENV === "production" ? "production" : "development",
    disableSessionRecording: process.env.NODE_ENV !== "production",
    // TODO: Audit for any PII to remove
    privacySetting: "none",
    networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
            // insert full or partial urls that you don't want to record here
            // Out of the box, Highlight will not record these URLs (they can be safely removed):
            "https://www.googleapis.com/identitytoolkit",
            "https://securetoken.googleapis.com",
        ],
    },
});

const App: React.FC = () => {
    const isMobile = useMediaQuery("(max-width:450px) and (max-height:1000px)");
    const ignore = localStorage.getItem(IGNORE_MOBILE_WARNING_LOCAL_STORAGE_KEY) === "true";

    if (isMobile && !ignore) {
        return (
            <ThemeProvider theme={theme}>
                <MobileDisabledMessage />
            </ThemeProvider>
        );
    }

    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AnswerGridSnackbarProvider anchorOrigin={ANCHOR_ORIGIN}>
                    <QueryClientProvider client={queryClient}>
                        <GridProvider>
                            <RouterProvider router={router} />
                        </GridProvider>
                    </QueryClientProvider>
                </AnswerGridSnackbarProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
};

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
