import * as React from "react";
import {
    EntityIdentifier,
    LocationIdentifier,
    NumEmployeesEnumValue,
    FoundedOnValue,
    LastFundingTotalValue,
    CrunchbaseAutoCompleteCollectionId,
    FundingTotalValue,
    LAST_EQUITY_FUNDING_TYPE_VALUES,
    LastEquityFundingTypeValue,
    NUM_EMPLOYEES_ENUM_VALUES,
    entityIdentifierToLocationIdentifier,
    LastFundingAtValue,
} from "../../services/cb-backend-types";
import { EditableIncompleteCrunchbaseFilter } from "./types";
import { SearchableFreeTextSelect } from "./searchableFreeTextSelect";
import {
    descriptionValueToString,
    locationGroupIdentifiersValueToString,
    investorIdentifiersValueToString,
    categoriesValueToString,
    categoryGroupsValueToString,
    lastEquityFundingTypeValueToString,
    numEmployeesEnumValueToString,
} from "../../services/filterUtils";
import { SearchableEntitySelect } from "./searchableEntitySelect";
import { SearchableRestrictedSelect } from "./searchableRestrictedSelect";
import { PositiveIntegerInput } from "../../reusable/positiveIntegerInput";

// TODO: Type so filter_id is not nullable
// EditableFilterValuePicker.tsx
export interface EditableFilterValuePickerProps<T extends EditableIncompleteCrunchbaseFilter> {
    filter: T;
    index: number;
    onFilterChange: (idx: number, newFilter: T) => void;
}

export const EditableFilterValuePicker = <T extends EditableIncompleteCrunchbaseFilter>({
    filter,
    index,
    onFilterChange,
}: EditableFilterValuePickerProps<T>) => {
    const handleDescriptionValueChange = React.useCallback(
        (newValues: string[]) => {
            if (filter.filter.field_id === "description") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleLocationGroupValueChange = React.useCallback(
        (newValues: EntityIdentifier[]) => {
            if (filter.filter.field_id === "location_group_identifiers") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleInvestorValueChange = React.useCallback(
        (newValues: EntityIdentifier[]) => {
            if (filter.filter.field_id === "investor_identifiers") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleLastEquityFundingTypeValueChange = React.useCallback(
        (newValues: string[]) => {
            if (filter.filter.field_id === "last_equity_funding_type") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleLocationValueChange = React.useCallback(
        (newValues: LocationIdentifier[]) => {
            if (filter.filter.field_id === "location_identifiers") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleNumEmployeesEnumValueChange = React.useCallback(
        (newValues: NumEmployeesEnumValue[]) => {
            if (filter.filter.field_id === "num_employees_enum") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleCategoriesValueChange = React.useCallback(
        (newValues: EntityIdentifier[]) => {
            if (filter.filter.field_id === "categories") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleCategoryGroupsValueChange = React.useCallback(
        (newValues: EntityIdentifier[]) => {
            if (filter.filter.field_id === "category_groups") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleLastFundingTotalChange = React.useCallback(
        (newValues: LastFundingTotalValue[]) => {
            if (filter.filter.field_id === "last_funding_total") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleTotalFundingTotalChange = React.useCallback(
        (newValues: LastFundingTotalValue[]) => {
            if (filter.filter.field_id === "funding_total") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleFoundedOnValueChange = React.useCallback(
        (newValues: FoundedOnValue[]) => {
            if (filter.filter.field_id === "founded_on") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    const handleLastFundingAtValueChange = React.useCallback(
        (newValues: LastFundingAtValue[]) => {
            if (filter.filter.field_id === "last_funding_at") {
                onFilterChange(index, { ...filter, filter: { ...filter.filter, values: newValues } });
            }
        },
        [filter, onFilterChange, index],
    );

    switch (filter.filter.field_id) {
        case "description":
            return (
                <EditableDescriptionFilter
                    values={filter.filter.values ?? []}
                    onDescriptionValueChange={handleDescriptionValueChange}
                />
            );
        case "location_group_identifiers":
            return (
                <EditableLocationGroupFilter
                    values={filter.filter.values ?? []}
                    onLocationGroupValueChange={handleLocationGroupValueChange}
                />
            );
        case "investor_identifiers":
            return (
                <EditableInvestorIdentifiersFilter
                    values={filter.filter.values ?? []}
                    onInvestorValueChange={handleInvestorValueChange}
                />
            );
        case "last_equity_funding_type":
            return (
                <EditableLastEquityFundingTypeFilter
                    values={filter.filter.values ?? []}
                    onLastEquityFundingTypeValueChange={handleLastEquityFundingTypeValueChange}
                />
            );
        case "location_identifiers":
            return (
                <EditableHeadquartersLocationFilter
                    values={filter.filter.values ?? []}
                    onLocationValueChange={handleLocationValueChange}
                />
            );
        case "num_employees_enum":
            return (
                <EditableNumEmployeesEnumFilter
                    values={filter.filter.values ?? []}
                    onNumEmployeesEnumValueChange={handleNumEmployeesEnumValueChange}
                />
            );
        case "categories":
            return (
                <EditableCategoriesFilter
                    values={filter.filter.values ?? []}
                    onCategoriesValueChange={handleCategoriesValueChange}
                />
            );
        case "category_groups":
            return (
                <EditableCategoryGroupsFilter
                    values={filter.filter.values ?? []}
                    onCategoryGroupsValueChange={handleCategoryGroupsValueChange}
                />
            );
        case "last_funding_total":
            return (
                <EditableLastFundingTotalFilter
                    values={filter.filter.values ?? []}
                    onLastFundingTotalValueChange={handleLastFundingTotalChange}
                />
            );
        case "funding_total":
            return (
                <EditableFundingTotalFilter
                    values={filter.filter.values ?? []}
                    onFundingTotalValueChange={handleTotalFundingTotalChange}
                />
            );
        case "founded_on":
            return (
                <EditableFoundedOnFilter
                    values={filter.filter.values ?? []}
                    onFoundedOnValueChange={handleFoundedOnValueChange}
                />
            );
        case "last_funding_at":
            return (
                <EditableLastFundingAtFilter
                    values={filter.filter.values ?? []}
                    onLastFundingAtValueChange={handleLastFundingAtValueChange}
                />
            );
        case undefined:
            return null;
    }
};

// EditableDescriptionFilter.tsx
const EditableDescriptionFilter: React.FC<{
    values: string[];
    onDescriptionValueChange: (newValues: string[]) => void;
}> = ({ values, onDescriptionValueChange }) => {
    const handleChange = (newValue: string[]) => {
        onDescriptionValueChange(newValue);
    };

    return (
        <SearchableFreeTextSelect
            fullWidth
            values={values}
            autocompleteValues={undefined}
            setValues={handleChange}
            getValueTitle={descriptionValueToString}
        />
    );
};

const LOCATION_GROUP_COLLECTION_IDS: CrunchbaseAutoCompleteCollectionId[] = ["location.groups"];

// EditableLocationGroupFilter.tsx
const EditableLocationGroupFilter: React.FC<{
    values: EntityIdentifier[];
    onLocationGroupValueChange: (newValues: EntityIdentifier[]) => void;
}> = ({ values, onLocationGroupValueChange }) => {
    const handleChange = (newValue: EntityIdentifier[]) => {
        onLocationGroupValueChange(newValue);
    };

    return (
        <SearchableEntitySelect
            fullWidth
            values={values}
            entityCategoryIds={LOCATION_GROUP_COLLECTION_IDS}
            setValues={handleChange}
            getValueFromEntityIdentifier={locationGroupIdentifiersValueToString}
            // There are many entries e.g. for "New York" so the description is useful
            useEntityShortDescriptionAsLabel={true}
        />
    );
};

// TODO: Passing two here is buggy and leads to e.g. regions being returned. Ideally we ask CB to fix.
const CITY_AND_COUNTRY_COLLECTION_IDS: CrunchbaseAutoCompleteCollectionId[] = ["location.countries", "location.cities"];

// EditableLocationFilter.tsx
const EditableHeadquartersLocationFilter: React.FC<{
    values: LocationIdentifier[];
    onLocationValueChange: (newValues: LocationIdentifier[]) => void;
}> = ({ values, onLocationValueChange }) => {
    const handleChange = React.useCallback(
        (newValue: EntityIdentifier[]) => {
            // TODO: We could try to pass the facet_ids from the autocomplete response
            // to fill the location type here as well.
            onLocationValueChange(newValue.map(entityIdentifierToLocationIdentifier));
        },
        [onLocationValueChange],
    );

    return (
        <SearchableEntitySelect
            fullWidth
            values={values}
            setValues={handleChange}
            entityCategoryIds={CITY_AND_COUNTRY_COLLECTION_IDS}
            getValueFromEntityIdentifier={locationGroupIdentifiersValueToString}
            // There are many entries e.g. for "New York" so the description is useful
            useEntityShortDescriptionAsLabel={true}
        />
    );
};

const INVESTOR_COLLECTION_IDS: CrunchbaseAutoCompleteCollectionId[] = ["organization.investors"];

// EditableInvestorIdentifiersFilter.tsx
const EditableInvestorIdentifiersFilter: React.FC<{
    values: EntityIdentifier[];
    onInvestorValueChange: (newValues: EntityIdentifier[]) => void;
}> = ({ values, onInvestorValueChange }) => {
    const handleChange = (newValue: EntityIdentifier[]) => {
        onInvestorValueChange(newValue);
    };

    return (
        <SearchableEntitySelect
            fullWidth
            values={values}
            entityCategoryIds={INVESTOR_COLLECTION_IDS}
            setValues={handleChange}
            getValueFromEntityIdentifier={investorIdentifiersValueToString}
        />
    );
};

const CATEGORIES_COLLECTION_IDS: CrunchbaseAutoCompleteCollectionId[] = ["categories"];

const EditableCategoriesFilter: React.FC<{
    values: EntityIdentifier[];
    onCategoriesValueChange: (newValues: EntityIdentifier[]) => void;
}> = ({ values, onCategoriesValueChange }) => {
    const handleChange = (newValue: EntityIdentifier[]) => {
        onCategoriesValueChange(newValue);
    };

    return (
        <SearchableEntitySelect
            fullWidth
            values={values}
            entityCategoryIds={CATEGORIES_COLLECTION_IDS}
            setValues={handleChange}
            getValueFromEntityIdentifier={categoriesValueToString}
        />
    );
};

const CATEGORY_GROUPS_COLLECTION_IDS: CrunchbaseAutoCompleteCollectionId[] = ["category_groups"];

const EditableCategoryGroupsFilter: React.FC<{
    values: EntityIdentifier[];
    onCategoryGroupsValueChange: (newValues: EntityIdentifier[]) => void;
}> = ({ values, onCategoryGroupsValueChange }) => {
    const handleChange = (newValue: EntityIdentifier[]) => {
        onCategoryGroupsValueChange(newValue);
    };

    return (
        <SearchableEntitySelect
            fullWidth
            values={values}
            entityCategoryIds={CATEGORY_GROUPS_COLLECTION_IDS}
            setValues={handleChange}
            getValueFromEntityIdentifier={categoryGroupsValueToString}
            useEntityShortDescriptionAsLabel={false}
        />
    );
};

// EditableLastEquityFundingTypeFilter.tsx
const EditableLastEquityFundingTypeFilter: React.FC<{
    values: LastEquityFundingTypeValue[];
    onLastEquityFundingTypeValueChange: (newValues: string[]) => void;
}> = ({ values, onLastEquityFundingTypeValueChange }) => {
    const handleChange = (newValue: LastEquityFundingTypeValue[]) => {
        onLastEquityFundingTypeValueChange(newValue);
    };

    return (
        <SearchableRestrictedSelect
            fullWidth
            values={values}
            setValues={handleChange}
            autocompleteValues={LAST_EQUITY_FUNDING_TYPE_VALUES} // Adjust if needed
            getValueTitle={lastEquityFundingTypeValueToString}
        />
    );
};

// NumEmployeesEnumValue.tsx
const EditableNumEmployeesEnumFilter: React.FC<{
    values: NumEmployeesEnumValue[];
    onNumEmployeesEnumValueChange: (newValues: NumEmployeesEnumValue[]) => void;
}> = ({ values, onNumEmployeesEnumValueChange }) => {
    const handleChange = (newValue: NumEmployeesEnumValue[]) => {
        onNumEmployeesEnumValueChange(newValue);
    };

    return (
        <SearchableRestrictedSelect
            fullWidth
            values={values}
            setValues={handleChange}
            autocompleteValues={NUM_EMPLOYEES_ENUM_VALUES}
            getValueTitle={numEmployeesEnumValueToString}
        />
    );
};

// FoundedOnValue.tsx
// TODO: Support between with multiple values?
const EditableLastFundingTotalFilter: React.FC<{
    values: LastFundingTotalValue[];
    onLastFundingTotalValueChange: (newValues: LastFundingTotalValue[]) => void;
}> = ({ values, onLastFundingTotalValueChange }) => {
    // TODO: HACKHACKHACK This is quite hacky, this logic depends on the operator
    const value = values[0]?.value ?? 0;

    const onChange = React.useCallback(
        (value: number | null) => {
            if (value == null) {
                return;
            }
            onLastFundingTotalValueChange([
                {
                    value,
                    value_usd: value,
                    currency: "USD",
                },
            ]);
        },
        [onLastFundingTotalValueChange],
    );

    return (
        <PositiveIntegerInput
            fullWidth
            value={value}
            hiddenLabel
            onChange={onChange}
            min={1}
            max={1000000000}
            size="small"
        />
    );
};

// TODO: Share code with the above
const EditableFundingTotalFilter: React.FC<{
    values: FundingTotalValue[];
    onFundingTotalValueChange: (newValues: FundingTotalValue[]) => void;
}> = ({ values, onFundingTotalValueChange }) => {
    // TODO: HACKHACKHACK This is quite hacky, this logic depends on the operator
    const value = values[0]?.value ?? 0;

    const onChange = React.useCallback(
        (value: number | null) => {
            if (value == null) {
                return;
            }
            onFundingTotalValueChange([
                {
                    value,
                    value_usd: value,
                    currency: "USD",
                },
            ]);
        },
        [onFundingTotalValueChange],
    );

    return <PositiveIntegerInput fullWidth value={value} onChange={onChange} min={1} max={100000000000} size="small" />;
};

const EditableFoundedOnFilter: React.FC<{
    values: string[];
    onFoundedOnValueChange: (newValues: string[]) => void;
}> = ({ values, onFoundedOnValueChange }) => {
    const handleChange = (newValue: string[]) => {
        onFoundedOnValueChange(newValue);
    };

    // TODO: Use proper date picker
    return (
        <SearchableFreeTextSelect
            fullWidth
            values={values}
            autocompleteValues={undefined}
            setValues={handleChange}
            getValueTitle={descriptionValueToString}
        />
    );
};

const EditableLastFundingAtFilter: React.FC<{
    values: LastFundingAtValue[];
    onLastFundingAtValueChange: (newValues: LastFundingAtValue[]) => void;
}> = ({ values, onLastFundingAtValueChange }) => {
    const handleChange = (newValue: LastFundingAtValue[]) => {
        onLastFundingAtValueChange(newValue);
    };

    // TODO: Use date picker
    return (
        <SearchableFreeTextSelect
            fullWidth
            values={values}
            autocompleteValues={undefined}
            setValues={handleChange}
            getValueTitle={descriptionValueToString}
        />
    );
};
