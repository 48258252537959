import * as React from "react";
import { Box, CircularProgress, IconButton, Typography, useTheme } from "@mui/material";
import { SelectedCellInfo } from "../grid/grid";
import { CitationCard } from "../selected-cell-components/citationCard";
import { CloseCircle, MouseSquare } from "iconsax-react";
import { KEEP_SELECTED_CELL_CLASS } from "../grid/AnswerGridCell";
import { SelectedCellValue } from "./selectedCellValue";
import { useCurrentUserQuery } from "../context/userContext";

export interface SelectedCellSidebarProps {
    cellInfo: SelectedCellInfo | undefined;
    onClose: () => void;
}

export const SelectedCellSidebar: React.FC<SelectedCellSidebarProps> = ({ cellInfo, onClose }) => {
    return (
        <Box
            className={KEEP_SELECTED_CELL_CLASS}
            sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "primary.main",
                borderRadius: 3,
                p: 2,
                flexBasis: 320,
                rowGap: 2,
                minWidth: 420,
                overflowY: "auto",
                border: 1,
                borderColor: "#DEDEDE",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: "bold", color: "secondary.main" }}>
                    {cellInfo?.rowKey ?? "No cell selected"}
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseCircle size={16} />
                </IconButton>
            </Box>
            <SelectedCellSidebarContent cellInfo={cellInfo} onClose={onClose} />
        </Box>
    );
};

interface SelectedCellSidebarContentProps {
    cellInfo: SelectedCellInfo | undefined;
    onClose: () => void;
}

export const SelectedCellSidebarContent: React.FC<SelectedCellSidebarContentProps> = ({ cellInfo }) => {
    const theme = useTheme();
    if (cellInfo == null) {
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MouseSquare color={theme.palette.secondary.main} size={50} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                rowGap: 2,
            }}
        >
            <SelectedCellValue
                valueWithCitations={cellInfo.valueWithCitations}
                containerSx={{
                    overflowY: "auto",
                    maxHeight: "360px",
                    backgroundColor: "grey.100",
                    p: 1,
                    borderRadius: 1,
                    minWidth: 0,
                    maxWidth: "100%",
                    overflowX: "hidden",
                }}
            />
            {cellInfo.valueWithCitations.citations.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        minWidth: 0,
                        maxWidth: "100%",
                        rowGap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{ color: "#667085" }}>
                        Sources
                    </Typography>
                    {cellInfo.valueWithCitations.citations.map((citation, index) => (
                        <CitationCard citation={citation} key={index} />
                    ))}
                    <PageScrapeIframe cellInfo={cellInfo} />
                </Box>
            )}
        </Box>
    );
};

interface PageScrapeIframeProps {
    cellInfo: SelectedCellInfo;
}

const IFRAME_LOAD_TIMEOUT = 10000;

const PageScrapeIframe: React.FC<PageScrapeIframeProps> = ({ cellInfo }) => {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const loadingRef = React.useRef(loading);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleLoad = React.useCallback(() => {
        loadingRef.current = false;
        setLoading(false);
        setError(false);
    }, []);

    const firstUrl = cellInfo.valueWithCitations.citations[0].link;

    React.useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    React.useEffect(() => {
        // Reset loading and error state when URL changes
        loadingRef.current = true;
        setLoading(true);
        setError(false);

        // set a timeout for error
        const timeout = setTimeout(() => {
            if (loadingRef.current) {
                setError(true);
                setLoading(false);
            }
        }, IFRAME_LOAD_TIMEOUT);

        return () => clearTimeout(timeout);
    }, [firstUrl]);

    const userQueryResult = useCurrentUserQuery();

    if (userQueryResult.isPending || userQueryResult.data == null) {
        return null;
    }

    return (
        <>
            {cellInfo.columnGeneratedBy?.type === "web_search" &&
                cellInfo.columnGeneratedBy.toolConfig?.toolName === "AnswerFromContextWebPage" && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {loading && (
                                <CircularProgress
                                    sx={{
                                        color: "primary.contrastText",
                                    }}
                                />
                            )}
                        </Box>
                        <iframe
                            key={firstUrl}
                            src={firstUrl}
                            style={{
                                height: "500px",
                                display: loading || error ? "none" : "block", // Hide iframe while loading or on error
                            }}
                            onLoad={handleLoad}
                        />
                    </>
                )}
        </>
    );
};
