import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as Sparkles } from "../assets/sparkles.svg";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { PositiveIntegerInput } from "../reusable/positiveIntegerInput";
import { getMaxGeneratableRowsLimit, hasTrialFeatures } from "./grid";
import { useCurrentUserQuery } from "../context/userContext";
import { useFreeTrialUsage } from "./userUsageQuery";

interface GenerateRowsDialogProps {
    isOpen: boolean;
    totalRowCount: number;
    onClose: () => void;
    onGenerateRows: (rowCount: number) => Promise<void>;
    totalAvailableRows: number;
}

export const GenerateRowsDialog: React.FC<GenerateRowsDialogProps> = ({
    isOpen,
    totalRowCount,
    onClose,
    onGenerateRows,
    totalAvailableRows,
}) => {
    const user = useCurrentUserQuery();
    const maybeMaxGeneratableRows = React.useMemo(
        () =>
            // Trial users can't open this dialog
            user.data != null && !hasTrialFeatures(user.data.plan_type)
                ? getMaxGeneratableRowsLimit(user.data.plan_type)
                : totalAvailableRows,
        [user.data, totalAvailableRows],
    );
    const [rowCount, setRowCount] = React.useState<number | null>(
        maybeMaxGeneratableRows != null
            ? Math.min(totalRowCount + 10, Math.min(maybeMaxGeneratableRows, totalAvailableRows))
            : Math.min(totalRowCount + 10, totalAvailableRows),
    );

    const handleGenerateRows = React.useCallback(() => {
        if (rowCount == null) return;
        void onGenerateRows(rowCount);
        onClose();
    }, [onGenerateRows, onClose, rowCount]);

    React.useEffect(() => {
        if (rowCount != null && rowCount < totalRowCount + 1) {
            setRowCount(Math.min(totalRowCount + 10, totalAvailableRows));
        }
    }, [rowCount, totalAvailableRows, totalRowCount]);

    const handleChange = React.useCallback(
        (value: number | null) => {
            setRowCount(value != null ? Math.min(value, totalAvailableRows) : null);
        },
        [totalAvailableRows],
    );

    const maxGeneratableRows = maybeMaxGeneratableRows ?? totalAvailableRows;

    const usage = useFreeTrialUsage();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Generate Rows</DialogTitle>
            <DialogContent sx={{ maxWidth: 400 }}>
                <Box sx={{ mt: 2 }}>
                    <PositiveIntegerInput
                        placeholder="Number of rows"
                        value={rowCount}
                        onChange={handleChange}
                        min={totalRowCount + 1}
                        max={maxGeneratableRows}
                        fullWidth
                    />
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1, whiteSpace: "pre-line" }}>
                        {`Total available: ${totalAvailableRows}.`}
                        {maybeMaxGeneratableRows != null &&
                            usage.data != null &&
                            `\nYou've generated ${usage.data.num_free_trial_rows_generated} of your ${maybeMaxGeneratableRows} free trial rows.`}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                    size="small"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    onClick={handleGenerateRows}
                    color="secondary"
                    variant="contained"
                    size="small"
                    disabledTooltip={
                        rowCount == null || rowCount < totalRowCount + 1
                            ? `Enter a number between ${totalRowCount + 1} and ${maxGeneratableRows}`
                            : undefined
                    }
                    startIcon={
                        <Sparkles
                            style={{
                                width: 16,
                                height: 16,
                            }}
                        />
                    }
                    sx={{
                        borderRadius: 8,
                    }}
                >
                    Generate
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};
