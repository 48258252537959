import { CrunchbasePickableFilterPredicates } from "../../services/filterUtils";
import { SearchPersona } from "../personas";

export type EditableCrunchbaseFilter = GeneralEditableFilter<CrunchbasePickableFilterPredicates>;

export type GeneralEditableIncompleteCrunchbaseFilterValue<T extends CrunchbasePickableFilterPredicates> =
    Partial<T> & {
        type: T["type"];
    };
export type EditableIncompleteCrunchbaseFilterValue =
    GeneralEditableIncompleteCrunchbaseFilterValue<CrunchbasePickableFilterPredicates>;

export const isCompleteCrunchbaseFilter = (
    filter: EditableIncompleteCrunchbaseFilterValue,
): filter is CrunchbasePickableFilterPredicates => getIncompleteCrunchbaseFilterParts(filter).length === 0;

export const getIncompleteCrunchbaseFilterParts = (
    filter: EditableIncompleteCrunchbaseFilterValue,
): Array<"operator" | "values" | "field"> => {
    const missingParts: Array<"operator" | "values" | "field"> = [];
    if (filter.operator_id == null) {
        missingParts.push("operator");
    }
    if (filter.values == null || filter.values.length === 0) {
        missingParts.push("values");
    }
    if (filter.field_id == null) {
        missingParts.push("field");
    }
    return missingParts;
};

export interface EditableIncompleteCrunchbaseFilter extends Omit<EditableCrunchbaseFilter, "filter"> {
    filter: EditableIncompleteCrunchbaseFilterValue;
}

export function isCompleteEditableFilter(
    filter: EditableIncompleteCrunchbaseFilter,
): filter is EditableCrunchbaseFilter {
    return isCompleteCrunchbaseFilter(filter.filter);
}

export interface GeneralEditableFilter<T> {
    isEditing: boolean;
    persona: SearchPersona;
    addAsColumn: boolean;
    filter: T;
}
