import * as React from "react";
import { Column, isKeyColumn } from "./grid";
import { AnswerGridCell } from "./AnswerGridCell";
import { DeletableGridRow } from "./deletableGridRow";
import { EditableNewRowCell } from "./editableNewRowCell";

export interface AddRowPlaceholderProps {
    autoFocus: boolean;
    bannedValues: Set<string>;
    columns: Column[];
    rowStringKey: string;
    value: string;
    onBlur?: (rowStringKey: string) => void;
    onChangeValue: (rowStringKey: string, val: string) => void;
    onDeleteRow: (rowStringKey: string) => void;
    onFinishEditingRowKeyValue: (colId: "name", value: string, rowStringKey: string) => Promise<void>;
}

export const AddRowPlaceholder: React.FC<AddRowPlaceholderProps> = ({
    autoFocus,
    bannedValues,
    columns,
    rowStringKey,
    value,
    onBlur,
    onChangeValue,
    onDeleteRow,
    onFinishEditingRowKeyValue,
}) => {
    const handleFinishEditingCellValue = React.useCallback(
        async (colId: "name", value: string) => onFinishEditingRowKeyValue(colId, value, rowStringKey),
        [onFinishEditingRowKeyValue, rowStringKey],
    );
    const handleChangeValue = React.useCallback(
        (val: string) => onChangeValue(rowStringKey, val),
        [onChangeValue, rowStringKey],
    );
    const handleBlur = React.useCallback(() => onBlur?.(rowStringKey), [onBlur, rowStringKey]);

    return (
        // TODO: Should you be allowed to delete placeholder when editing?
        <DeletableGridRow rowStringKey={rowStringKey} onDeleteRow={onDeleteRow} disableDelete={false}>
            {columns.map((column, idx) =>
                isKeyColumn(column) ? (
                    <EditableNewRowCell
                        autoFocus={autoFocus}
                        key={column.id}
                        align={column.align}
                        bannedValues={bannedValues}
                        colId={column.id}
                        columnLoadingStatus={undefined}
                        isFirstColumn={idx === 0}
                        isLastColumn={idx === columns.length - 1}
                        isLargeStyle={column.isEditable ?? false}
                        value={value}
                        onChangeValue={handleChangeValue}
                        onBlur={handleBlur}
                        onFinishEditingCellValue={handleFinishEditingCellValue}
                    />
                ) : (
                    <AnswerGridCell
                        isFirstColumn={idx === 0}
                        isLastColumn={idx === columns.length - 1}
                        isLargeStyle={column.isEditable ?? false}
                        align={column.align}
                        key={column.id}
                    >
                        {"-"}
                    </AnswerGridCell>
                ),
            )}
        </DeletableGridRow>
    );
};
