import * as React from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface NumberInputProps extends Omit<TextFieldProps, "onChange" | "value"> {
    value: number | null;
    onChange: (value: number | null) => void;
    min?: number;
    max?: number;
    precision?: number;
    step?: number;
}

export const NumberInput: React.FC<NumberInputProps> = ({
    value,
    onChange,
    min,
    max,
    step = 1,
    precision = 2,
    ...textFieldProps
}) => {
    const formatValue = React.useCallback(
        (val: number | null) => {
            if (val === null) return "";
            return Number.isInteger(val) ? val.toString() : val.toFixed(precision);
        },
        [precision],
    );

    const [inputValue, setInputValue] = React.useState(formatValue(value));

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setInputValue(newValue);

            if (newValue === "" || newValue === "-") {
                onChange(null);
            } else {
                const parsedValue = parseFloat(newValue);
                if (!isNaN(parsedValue)) {
                    if (min !== undefined && parsedValue < min) {
                        onChange(null);
                    } else if (max !== undefined && parsedValue > max) {
                        onChange(null);
                    } else {
                        onChange(parsedValue);
                    }
                }
            }
        },
        [onChange, min, max],
    );

    const handleBlur = React.useCallback(() => {
        if (inputValue === "" || inputValue === "-") {
            setInputValue(formatValue(value));
        } else {
            let parsedValue = parseFloat(inputValue);
            if (!isNaN(parsedValue)) {
                if (min !== undefined && parsedValue < min) {
                    parsedValue = min;
                } else if (max !== undefined && parsedValue > max) {
                    parsedValue = max;
                }
                setInputValue(formatValue(parsedValue));
                onChange(parsedValue);
            }
        }
    }, [inputValue, value, min, max, onChange, formatValue]);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
                const currentValue = parseFloat(inputValue) || 0;
                const newValue = event.key === "ArrowUp" ? currentValue + step : currentValue - step;
                if (min !== undefined && newValue < min) {
                    onChange(null);
                    setInputValue("");
                } else if (max !== undefined && newValue > max) {
                    onChange(null);
                    setInputValue("");
                } else {
                    setInputValue(formatValue(newValue));
                    onChange(newValue);
                }
            }
        },
        [inputValue, onChange, min, max, step, formatValue],
    );

    return (
        <TextField
            {...textFieldProps}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputProps={{
                ...textFieldProps.inputProps,
                inputMode: "decimal",
                pattern: "^-?\\d*\\.?\\d*$",
            }}
        />
    );
};
