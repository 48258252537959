import * as React from "react";
import { AnswersGrid } from "./AnswersGrid";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useQuery } from "@tanstack/react-query";
import { gridQueryOptions } from "../react-query/queryKeys";
import { getBackendService } from "../services/cb-backend";
import { Typography, Box, useTheme, CircularProgress, Button } from "@mui/material";
import { ArrowRight, Information } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MAX_TITLE_LENGTH = 30; // Adjust this value as needed

const truncateTitle = (title: string, maxLength: number) => {
    if (title.length <= maxLength) return title;
    return title.slice(0, maxLength - 3) + "…";
};

export interface GridViewContentProps {
    gridId: string | undefined;
}

export const GridViewContent: React.FC<GridViewContentProps> = ({ gridId }) => {
    const service = React.useMemo(() => getBackendService(), []);
    const options = React.useMemo(() => gridQueryOptions(service, gridId), [service, gridId]);
    const queryResult = useQuery(options);

    const theme = useTheme();
    const navigate = useNavigate();

    const onGoToHome = React.useCallback(() => {
        navigate("/home");
    }, [navigate]);

    // TODO: Should we show this when the query is fetched but is erroring?
    if (queryResult.isError || gridId == null) {
        return (
            <SidebarLayout selectedGridId={gridId}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "primary.main",
                        p: 2,
                        borderRadius: 2,
                    }}
                >
                    <Information size={40} color={theme.palette.secondary.main} />
                    <Typography variant="h6" color="secondary.main">
                        This grid does not exist or you do not have access to view it
                    </Typography>
                    <Button
                        onClick={onGoToHome}
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 1, borderRadius: 8 }}
                        endIcon={<ArrowRight size={16} />}
                    >
                        Go to home
                    </Button>
                </Box>
            </SidebarLayout>
        );
    } else if (queryResult.isLoading || queryResult.data == null) {
        return (
            <SidebarLayout selectedGridId={gridId}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress
                        size={16}
                        sx={{
                            color: "primary.contrastText",
                        }}
                    />
                </Box>
            </SidebarLayout>
        );
    }

    const gridName = queryResult.data.name || "Untitled Grid";
    const truncatedGridName = truncateTitle(gridName, MAX_TITLE_LENGTH);

    return (
        <>
            <Helmet>
                <title>{`${truncatedGridName} - AnswerGrid`}</title>
            </Helmet>
            <AnswersGrid key={"grid-" + gridId} gridId={gridId} grid={queryResult.data} />
        </>
    );
};
