import * as React from "react";
import { Link, Typography, Box } from "@mui/material";
import { getBackendService, isLoggedIn } from "../services/cb-backend";
import { useLocation, useNavigate } from "react-router-dom";
import { useTrackPageView } from "../mixpanel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCurrentUserQuery } from "../context/userContext";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { SecuritySafe, Sms } from "iconsax-react";
import { FormTextField } from "./formTextField";
import { Helmet } from "react-helmet-async";
import { CURRENT_USER_KEY } from "../react-query/queryKeys";

export const SignIn: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Sign In - AnswerGrid</title>
            </Helmet>
            <UserAdministrationLayout imageUrl="https://AnswerGrid.b-cdn.net/landscape-at.png">
                <SignInContent />
            </UserAdministrationLayout>
        </>
    );
};

const SignInContent: React.FC = () => {
    useTrackPageView();
    const acmeService = React.useMemo(() => getBackendService(), []);
    const [error, setError] = React.useState<string | null>(null); // State to hold login error message
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const user = useCurrentUserQuery();
    const [isRedirecting, setIsRedirecting] = React.useState(false);

    React.useEffect(() => {
        if (!isRedirecting && user.data != null && user.data.plan_type !== "trial" && user.data.is_email_verified) {
            navigate("/home", { replace: true });
            setIsRedirecting(true);
            enqueueSnackbar("You are already logged in. Redirecting to home.", { variant: "info" });
        }
    }, [navigate, enqueueSnackbar, user.data, isRedirecting]);

    const queryClient = useQueryClient();
    const loginMutation = useMutation({
        mutationFn: async (data: { email: string; password: string }) => acmeService.login(data.email, data.password),
        onMutate: async () => {
            if (isLoggedIn()) {
                await acmeService.logout();
            }
        },
        onSuccess: async data => {
            setIsRedirecting(true);
            // This should be covered by the refetchQueries, but it's not working, so let's be safe
            await queryClient.invalidateQueries({ queryKey: CURRENT_USER_KEY });
            await queryClient.refetchQueries();

            // Check for the forgot-password query parameter
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has("forgot-password")) {
                navigate("/change-password", { replace: true });
            } else {
                navigate(`/home${location.search}`, { replace: true });
            }
            console.log("Login successful", data);
        },
        onError: (error: Error) => {
            console.error("Login failed:", error);
            setError(error.message);
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await loginMutation.mutateAsync({ email, password });
        },
        [loginMutation, email, password],
    );

    const handleLogin = React.useCallback(async () => {
        await loginMutation.mutateAsync({ email, password });
    }, [loginMutation, email, password]);

    const handleChangeEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleChangePassword = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, []);

    return (
        <UserAdministrationForm
            title="Sign In"
            actionButtonText="Log in"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleLogin}
            loading={loginMutation.isPending}
            underButtonContent={
                <Typography variant="body2">
                    New to AnswerGrid?{" "}
                    <Link href="/signup" color="secondary" sx={{ textDecoration: "none", fontWeight: "medium" }}>
                        Create an account →
                    </Link>
                </Typography>
            }
        >
            <Box
                component="form"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, display: "flex", flexDirection: "column" }}
            >
                <FormTextField
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleChangeEmail}
                    icon={<Sms size={20} />}
                />
                <FormTextField
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChangePassword}
                    icon={<SecuritySafe size={20} />}
                />
                <Link
                    // TODO: Add forgot password functionality
                    href="/forgot-password"
                    variant="body2"
                    color="secondary"
                    sx={{
                        alignSelf: "flex-end",
                        mt: 1,
                        textDecoration: "none",
                        fontWeight: "medium",
                        display: "inline-block",
                    }}
                >
                    Forgot password?
                </Link>
                {error && (
                    <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </UserAdministrationForm>
    );
};
