import {
    Box,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    CircularProgress,
    Tooltip,
    useTheme,
    Button,
} from "@mui/material";
import { ReactComponent as LockedAddRowsIcon } from "../assets/locked-add-row.svg";
import { ReactComponent as AddRowsIcon } from "../assets/add-rows-generate-icon.svg";
import { ReactComponent as AddIcon } from "../assets/add-icon.svg";
import { EditableKeyColumnCell } from "./editableKeyColumnCell";
import {
    Column,
    Row,
    isRealRow,
    isKeyColumn,
    SelectedCell,
    ColumnGeneratedBy,
    LoadingStatus,
    isPlaceholderRow,
    KEY_COLUMNS,
    PlaceholderRow,
    RealRow,
    getStringKeyFromRealRow,
    getStringKeyFromPlaceholderRow,
    getStringKeyFromRow,
    ExportMode,
    ExportProgress,
    CellValueV2String,
    getCellLoadingStatus,
    CellValueV2,
    isPaidUser,
    hasTrialFeatures,
    FREE_TRIAL_MAX_ROWS,
} from "./grid";
import { HeaderGridCell } from "./headerGridCell";
import { GridCellWithContextMenu } from "./gridCellWithContextMenu";
import { AddRowPlaceholder } from "./addRowPlaceholder";
import { DeletableGridRow } from "./deletableGridRow";
import * as React from "react";
import { VisibilityFiltersByColumn } from "./grid";
import { useCurrentUserQuery } from "../context/userContext";
import { FreeTrialUsageResponse, GetCurrentUserResponse } from "../services/cb-backend-types";
import { useBoolean } from "../utils/hooks";
import { UpgradePlanDialog } from "../settings/upgradeDialog";
import { useFreeTrialUsage } from "./userUsageQuery";

const QUICK_ADD_ROWS_INCREMENT = 10;

export interface GeneratedByFiltersInfo {
    hasMoreRows: boolean;
}

interface AnswersGridTableProps {
    columns: Column[];
    rowsInPage: Row[];
    allRows: Row[];
    isExportOngoing: boolean;
    selectedCell: SelectedCell | undefined;
    gridId: string;
    generatedByFiltersInfo: GeneratedByFiltersInfo | undefined;
    isLastPlaceholderRowFocused: boolean;
    isSomeColumnLoading: boolean;
    isSomeRowLoading: boolean;
    isRightSidebarOpen: boolean;
    rowGenerationStatuses: Record<string, LoadingStatus | undefined>;
    cellGenerationStatusesByColByRow: Record<string, Record<string, LoadingStatus | undefined>>;
    visibilityFiltersByColumn: VisibilityFiltersByColumn;
    showBottomBorder: boolean;
    exportStatus: ExportProgress | undefined;
    columnsWithUnloadedCells: Set<string>;
    isMarkingSomeColumnAsWebsite: boolean;
    handleRetryColumnValues: (column: Column, errorValueType?: CellValueV2["type"]) => Promise<void>;
    onAddColumnValuesToThisPage: (
        colId: string,
        columnQuery: ColumnGeneratedBy,
        // By default, the column label is the query
        colLabel?: string,
    ) => Promise<void>;
    onDeleteColumn: (columnId: string) => void;
    onAddColumn: () => void;
    onEditKeyColumnCell: (colId: "name", prevValue: string, value: string) => Promise<void>;
    onDeleteRow: (rowStringKey: string) => void;
    onOnAddRowWithRowKey: (colId: "name", value: string, rowStringKey: string) => Promise<void>;
    onStartAddingNewRows: (e: React.MouseEvent<HTMLDivElement>) => void;
    onLoadAllUnloadedCells: () => Promise<void>;
    onChangePlaceholderRowValue: (rowStringKey: string, value: string) => void;
    onGenerateNewRows: (incrementalRowCount: number, exportMode: ExportMode) => Promise<void>;
    handleBlurLastRow: (rowStringKey: string) => void;
    setVisibilityFiltersByColumn: React.Dispatch<React.SetStateAction<VisibilityFiltersByColumn>>;
    setSelectedCell: (cell: SelectedCell | undefined) => void;
    handleOpenRightSidebar: () => void;
    onChangeCellValue: (rowStringKey: string, colId: string, value: CellValueV2String) => void;
    onMarkAsWebsite: (columnId: string) => Promise<void>;
    onPivotGrid: (columnId: string) => Promise<void>;
    onMarkAsNewKey: (columnId: string) => void;
}

// TODO: Push down state and handlers and things into here if possible
export const AnswersGridTable: React.FC<AnswersGridTableProps> = ({
    columns,
    rowsInPage,
    allRows,
    isExportOngoing,
    gridId,
    generatedByFiltersInfo,
    selectedCell,
    rowGenerationStatuses,
    cellGenerationStatusesByColByRow,
    visibilityFiltersByColumn,
    isRightSidebarOpen,
    isLastPlaceholderRowFocused,
    isSomeColumnLoading,
    isSomeRowLoading,
    showBottomBorder,
    exportStatus,
    columnsWithUnloadedCells,
    isMarkingSomeColumnAsWebsite,
    handleRetryColumnValues,
    onAddColumnValuesToThisPage,
    onDeleteColumn,
    onAddColumn,
    onEditKeyColumnCell,
    onDeleteRow,
    onLoadAllUnloadedCells,
    onPivotGrid,
    onOnAddRowWithRowKey: handleOnAddRowWithRowKey,
    onStartAddingNewRows: handleStartAddingNewRows,
    onChangePlaceholderRowValue: handleChangePlaceholderRowValue,
    handleBlurLastRow,
    onGenerateNewRows: handleGenerateNewRows,
    setSelectedCell,
    handleOpenRightSidebar,
    setVisibilityFiltersByColumn,
    onChangeCellValue,
    onMarkAsWebsite,
    onMarkAsNewKey,
}) => {
    const handleClick = React.useCallback(
        (event: React.MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('[role="tooltip"]') && !target.closest('[role="menu"]')) {
                setSelectedCell(undefined);
            }
        },
        [setSelectedCell],
    );

    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const handleAddColumnWithScroll = React.useCallback(() => {
        onAddColumn();
        requestAnimationFrame(() => {
            if (tableContainerRef.current) {
                tableContainerRef.current.scrollTo({
                    left: tableContainerRef.current.scrollWidth,
                    top: 0,
                });
            }
        });
    }, [onAddColumn]);

    const user = useCurrentUserQuery();
    const isTrialUserWithTwentyColumns = user.data?.plan_type === "trial" && columns.length >= 20;
    const disableNewColumn = isSomeRowLoading || isExportOngoing || isTrialUserWithTwentyColumns;

    const isLastColumnEditable = React.useMemo(() => {
        return columns.length > 0 && columns[columns.length - 1].isEditable;
    }, [columns]);

    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "stretch",
                // TODO: Depends on popover width
                pr: isLastColumnEditable ? 6 : 0,
                overflowX: "auto",
                overflowY: "hidden",
            }}
            onClick={handleClick}
        >
            <AnswersGridTableContainer
                tableRef={tableContainerRef}
                columns={columns}
                rowsInPage={rowsInPage}
                allRows={allRows}
                gridId={gridId}
                isSomeRowLoading={isSomeRowLoading}
                isExportOngoing={isExportOngoing}
                exportStatus={exportStatus}
                isLastPlaceholderRowFocused={isLastPlaceholderRowFocused}
                isSomeColumnLoading={isSomeColumnLoading}
                isRightSidebarOpen={isRightSidebarOpen}
                generatedByFiltersInfo={generatedByFiltersInfo}
                selectedCell={selectedCell}
                handleRetryColumnValues={handleRetryColumnValues}
                rowGenerationStatuses={rowGenerationStatuses}
                cellGenerationStatusesByColByRow={cellGenerationStatusesByColByRow}
                visibilityFiltersByColumn={visibilityFiltersByColumn}
                showBottomBorder={showBottomBorder}
                columnsWithUnloadedCells={columnsWithUnloadedCells}
                isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                onMarkAsWebsite={onMarkAsWebsite}
                onAddColumnValuesToThisPage={onAddColumnValuesToThisPage}
                onDeleteColumn={onDeleteColumn}
                onLoadAllUnloadedCells={onLoadAllUnloadedCells}
                onEditKeyColumnCell={onEditKeyColumnCell}
                onDeleteRow={onDeleteRow}
                onOnAddRowWithRowKey={handleOnAddRowWithRowKey}
                onStartAddingNewRows={handleStartAddingNewRows}
                onChangePlaceholderRowValue={handleChangePlaceholderRowValue}
                handleBlurLastRow={handleBlurLastRow}
                onGenerateNewRows={handleGenerateNewRows}
                setSelectedCell={setSelectedCell}
                handleOpenRightSidebar={handleOpenRightSidebar}
                setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
                onChangeCellValue={onChangeCellValue}
                onPivotGrid={onPivotGrid}
                onMarkAsNewKey={onMarkAsNewKey}
            />
            <Box
                sx={{
                    cursor: disableNewColumn ? "not-allowed" : "e-resize",
                    width: "30px",
                    minWidth: "30px",
                    borderTop: 1,
                    borderRight: 1,
                    borderBottom: 1,
                    borderLeft: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    typography: "body1",
                }}
                title="Add column"
                onClick={disableNewColumn ? undefined : handleAddColumnWithScroll}
            >
                <AddIcon
                    style={{
                        color: theme.palette.secondary.main,
                        margin: "-5px",
                        position: "relative",
                    }}
                />
            </Box>
        </Box>
    );
};

type AnswersGridTableContainerProps = Omit<AnswersGridTableProps, "onAddColumn"> & {
    tableRef: React.RefObject<HTMLDivElement>;
};

const AnswersGridTableContainer: React.FC<AnswersGridTableContainerProps> = ({
    columns,
    rowsInPage,
    allRows,
    isExportOngoing,
    gridId,
    generatedByFiltersInfo,
    selectedCell,
    rowGenerationStatuses,
    cellGenerationStatusesByColByRow,
    isRightSidebarOpen,
    isLastPlaceholderRowFocused,
    isSomeRowLoading,
    isSomeColumnLoading,
    showBottomBorder,
    visibilityFiltersByColumn,
    exportStatus,
    tableRef,
    columnsWithUnloadedCells,
    isMarkingSomeColumnAsWebsite,
    onLoadAllUnloadedCells,
    onAddColumnValuesToThisPage,
    onDeleteColumn: handleDeleteColumn,
    onEditKeyColumnCell: handleEditKeyColumnCell,
    onDeleteRow: handleDeleteRow,
    onOnAddRowWithRowKey: handleOnAddRowWithRowKey,
    onStartAddingNewRows: handleStartAddingNewRows,
    onChangePlaceholderRowValue: handleChangePlaceholderRowValue,
    handleBlurLastRow,
    onGenerateNewRows: handleGenerateNewRows,
    handleRetryColumnValues,
    setSelectedCell,
    handleOpenRightSidebar,
    setVisibilityFiltersByColumn,
    onChangeCellValue,
    onMarkAsWebsite,
    onPivotGrid,
    onMarkAsNewKey,
}) => {
    const keyColumnValues = React.useMemo(
        () =>
            new Set(
                KEY_COLUMNS.flatMap(keyColumn =>
                    rowsInPage
                        .filter(isRealRow)
                        .map(row => row.data[keyColumn.id].value.value)
                        .filter((value): value is string => value != null),
                ),
            ),
        [rowsInPage],
    );

    const lastRow = rowsInPage[rowsInPage.length - 1];
    const isLastRowEmptyPlaceholder = lastRow != null && isPlaceholderRow(lastRow) && lastRow.name.trim() === "";

    const someRowHaveCrunchbaseIdentifiers = React.useMemo(
        () => rowsInPage.some(row => isRealRow(row) && row.externalIdentifiers?.crunchbase != null),
        [rowsInPage],
    );
    const someRowHasAnApolloIdentifier = React.useMemo(
        () => rowsInPage.some(row => isRealRow(row) && row.externalIdentifiers?.apollo != null),
        [rowsInPage],
    );

    const headRef = React.useRef<HTMLTableSectionElement>(null);

    useEscapeToDeselectBindings(selectedCell, setSelectedCell);
    useKeyboardSelectionNavigationBindings(selectedCell, setSelectedCell, columns, rowsInPage, tableRef, headRef);

    const bottomBorderSx = showBottomBorder
        ? ({
              borderBottom: "1px solid",
              borderColor: "divider",
          } as const)
        : {};

    const user = useCurrentUserQuery();

    const freeTrialUsage = useFreeTrialUsage();

    const addRowsButtonState = React.useMemo(
        () =>
            getAddRowsButtonState(
                generatedByFiltersInfo,
                rowsInPage.length,
                lastRow,
                isLastPlaceholderRowFocused,
                user.data,
                freeTrialUsage.data,
            ),
        [
            generatedByFiltersInfo,
            rowsInPage.length,
            lastRow,
            isLastPlaceholderRowFocused,
            user.data,
            freeTrialUsage.data,
        ],
    );

    const { cursor: newRowsCursor, onClick: newRowsOnClick } = React.useMemo(
        () =>
            getNewRowsButtonMode(
                generatedByFiltersInfo != null,
                isLastRowEmptyPlaceholder,
                isSomeColumnLoading,
                exportStatus,
                isExportOngoing,
                handleStartAddingNewRows,
                handleGenerateNewRows,
            ),
        [
            generatedByFiltersInfo,
            isLastRowEmptyPlaceholder,
            isSomeColumnLoading,
            exportStatus,
            isExportOngoing,
            handleStartAddingNewRows,
            handleGenerateNewRows,
        ],
    );

    const isSomeColumnCellLoadingByCol = React.useMemo(() => {
        const result: Record<string, boolean> = {};
        for (const [colId, rowStatuses] of Object.entries(cellGenerationStatusesByColByRow)) {
            result[colId] = Object.values(rowStatuses).some(status => status === "loading");
        }
        return result;
    }, [cellGenerationStatusesByColByRow]);

    return (
        <TableContainer
            sx={{
                minWidth: 0,
                width: "auto",
                ...bottomBorderSx,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    width: "14px",
                },
                "&::-webkit-scrollbar-thumb": {
                    bgcolor: "#98A2B3",
                    borderRadius: "8px",
                    border: "3px solid #F4F2EF",
                },
                "&::-webkit-scrollbar-track": {
                    bgcolor: "primary.main",
                },
                "&::-webkit-scrollbar-track-piece:horizontal": {
                    borderRight: 1,
                    borderColor: "divider",
                },
                "&::-webkit-scrollbar-corner": {
                    background: "transparent",
                },
            }}
            component={Box}
            ref={tableRef}
        >
            <Table
                sx={{
                    minWidth: 650,
                    width: "auto",
                    borderColor: "divider",
                    overflowX: "visible",
                    scrollbarGutter: "stable",
                }}
                aria-label="simple grid"
                stickyHeader
            >
                <TableHead ref={headRef}>
                    <TableRow>
                        {columns.map((column, idx) => (
                            <HeaderGridCell
                                key={column.id}
                                allColumns={columns}
                                allRows={allRows}
                                column={column}
                                isSomeColumnCellLoading={isSomeColumnCellLoadingByCol[column.id] ?? false}
                                isSomeRowLoading={isSomeRowLoading}
                                isExportOngoing={isExportOngoing}
                                enableApolloColumns={someRowHasAnApolloIdentifier}
                                enableCrunchbaseColumns={someRowHaveCrunchbaseIdentifiers}
                                isFirstColumn={idx === 0}
                                isLastColumn={idx === columns.length - 1}
                                isMarkingSomeColumnAsWebsite={isMarkingSomeColumnAsWebsite}
                                visibilityFilters={visibilityFiltersByColumn[column.id]}
                                hasUnloadedCells={columnsWithUnloadedCells.has(column.id)}
                                onLoadAllUnloadedCells={onLoadAllUnloadedCells}
                                handleRetryColumnValues={handleRetryColumnValues}
                                setVisibilityFiltersByColumn={setVisibilityFiltersByColumn}
                                onAddColumnValuesToThisPage={onAddColumnValuesToThisPage}
                                onDeleteColumn={handleDeleteColumn}
                                onMarkAsWebsite={onMarkAsWebsite}
                                onPivotGrid={onPivotGrid}
                                // Only allow marking as new key when not generated by filters
                                onMarkAsNewKey={
                                    generatedByFiltersInfo == null && idx !== 0 ? onMarkAsNewKey : undefined
                                }
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsInPage.map((row, rowIndex) =>
                        isRealRow(row) ? (
                            <AnswerGridTableRealRow
                                key={`${gridId ?? ""}-${getStringKeyFromRealRow(row)}`}
                                row={row}
                                isExportOngoing={isExportOngoing}
                                onDeleteRow={handleDeleteRow}
                                columns={columns}
                                isRightSidebarOpen={isRightSidebarOpen}
                                isGeneratedByFilters={generatedByFiltersInfo != null}
                                keyColumnValues={keyColumnValues}
                                cellGenerationStatusesByColByRow={cellGenerationStatusesByColByRow}
                                rowLoadingStatus={rowGenerationStatuses[row.data.name.value.value]}
                                selectedColumnId={selectedCell?.colId}
                                selectedState={
                                    selectedCell?.rowStringKey === getStringKeyFromRealRow(row)
                                        ? selectedCell.isEditing
                                            ? "editing"
                                            : "selected"
                                        : undefined
                                }
                                setSelectedCell={setSelectedCell}
                                handleOpenRightSidebar={handleOpenRightSidebar}
                                handleEditKeyColumnCell={handleEditKeyColumnCell}
                                handleDeleteColumn={handleDeleteColumn}
                                onChangeCellValue={onChangeCellValue}
                            />
                        ) : (
                            <AnswerGridTablePlaceholderRow
                                key={`${gridId ?? ""}-${getStringKeyFromPlaceholderRow(row)}`}
                                rowStringKey={getStringKeyFromPlaceholderRow(row)}
                                row={row}
                                onDeleteRow={handleDeleteRow}
                                columns={columns}
                                keyColumnValues={keyColumnValues}
                                onBlur={rowIndex === rowsInPage.length - 1 ? handleBlurLastRow : undefined}
                                handleChangePlaceholderRowValue={handleChangePlaceholderRowValue}
                                handleOnAddRowWithRowKey={handleOnAddRowWithRowKey}
                            />
                        ),
                    )}
                </TableBody>
            </Table>
            {addRowsButtonState !== "hide" && (
                <AddRowsButton
                    state={addRowsButtonState}
                    generatedByFiltersInfo={generatedByFiltersInfo}
                    cursor={newRowsCursor}
                    onClick={newRowsOnClick}
                    isLoading={generatedByFiltersInfo != null && isLoadingStatus(exportStatus)}
                />
            )}
        </TableContainer>
    );
};

function getNewRowsButtonMode(
    isGeneratedByFilters: boolean,
    isLastRowEmptyPlaceholder: boolean,
    isSomeColumnLoading: boolean,
    exportStatus: ExportProgress | undefined,
    isExportOngoing: boolean,
    handleStartAddingNewRows: (e: React.MouseEvent<HTMLDivElement>) => void,
    handleGenerateNewRows: (rowCount: number, exportMode: ExportMode) => Promise<void>,
) {
    if (isExportOngoing || isSomeColumnLoading) {
        return { cursor: "not-allowed", onClick: undefined };
    }
    if (isGeneratedByFilters) {
        if (isLoadingStatus(exportStatus)) {
            return { cursor: "not-allowed", onClick: undefined };
        }
        return {
            cursor: "s-resize",
            onClick: () => handleGenerateNewRows(QUICK_ADD_ROWS_INCREMENT, ExportMode.QuickAdd),
        };
    }
    if (isLastRowEmptyPlaceholder) {
        return { cursor: "not-allowed", onClick: undefined };
    }
    return { cursor: "s-resize", onClick: handleStartAddingNewRows };
}

function getAddRowsButtonState(
    generatedByFiltersInfo: GeneratedByFiltersInfo | undefined,
    rowCount: number,
    lastRow: Row | undefined,
    isLastPlaceholderRowFocused: boolean,
    user: GetCurrentUserResponse | undefined,
    freeTrialUsage: FreeTrialUsageResponse | undefined,
): "hide" | "show" | "lock" {
    if (user != null && hasTrialFeatures(user?.plan_type)) {
        return "lock";
    }
    if (generatedByFiltersInfo != null) {
        if (!generatedByFiltersInfo.hasMoreRows) {
            return "hide";
        }
        if (user == null) {
            return "hide";
        }
        if (user.is_admin) {
            return "show";
        }
        if (user.plan_type === "free-trial") {
            return freeTrialUsage?.has_reached_free_trial_limit ?? false ? "lock" : "show";
        }
        if (isPaidUser(user.plan_type)) {
            return rowCount <= 500 - QUICK_ADD_ROWS_INCREMENT ? "show" : "hide";
        }
        if (user.plan_type === "default-plan") {
            return rowCount <= 50 - QUICK_ADD_ROWS_INCREMENT ? "show" : "hide";
        }
    } else {
        return lastRow?.type !== "placeholder" || !isLastPlaceholderRowFocused ? "show" : "hide";
    }

    return "hide";
}

function isLoadingStatus(status: ExportProgress | undefined): boolean {
    switch (status?.status) {
        case "PENDING":
        case "RETRY":
        case "STARTED":
        case "PROGRESS":
            return true;
        case "FAILURE":
        case "SUCCESS":
        case "REVOKED":
        case undefined:
            return false;
    }
}

function useEscapeToDeselectBindings(
    selectedCell: SelectedCell | undefined,
    setSelectedCell: (cell: SelectedCell | undefined) => void,
) {
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (selectedCell != null && event.key === "Escape") {
                setSelectedCell(undefined);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedCell, setSelectedCell]);
}

function useKeyboardSelectionNavigationBindings(
    selectedCell: SelectedCell | undefined,
    setSelectedCell: (cell: SelectedCell | undefined) => void,
    columns: Column[],
    rows: Row[],
    tableRef: React.RefObject<HTMLDivElement>,
    headRef: React.RefObject<HTMLTableSectionElement>,
) {
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const { key } = event;
            if (selectedCell == null) {
                return;
            }
            const { rowStringKey, colId } = selectedCell;
            const rowIndex = rows.findIndex(row => getStringKeyFromRow(row) === rowStringKey);
            const colIds = columns.map(col => col.id);

            const container = tableRef.current;
            // data class is-selected
            const selectedCellElement = document.querySelector(`[data-is-selected="${"true"}"]`);
            if (selectedCellElement == null) {
                return;
            }

            const cellRect = selectedCellElement.getBoundingClientRect();

            if (key === "ArrowUp" && rowIndex > 0) {
                const prevRowStringKey = getStringKeyFromRow(rows[rowIndex - 1]);
                setSelectedCell({ rowStringKey: prevRowStringKey, colId });
                if (
                    container != null &&
                    cellRect.top - cellRect.height / 2 <=
                        container?.getBoundingClientRect().top + (headRef.current?.getBoundingClientRect().height ?? 50)
                ) {
                    container.scrollTop -= cellRect.height;
                }
                event.preventDefault();
            } else if (key === "ArrowDown" && rowIndex < rows.length - 1) {
                const nextRowStringKey = getStringKeyFromRow(rows[rowIndex + 1]);
                setSelectedCell({ rowStringKey: nextRowStringKey, colId });
                if (
                    container != null &&
                    cellRect.bottom + cellRect.height / 2 >= container.getBoundingClientRect().bottom
                ) {
                    container.scrollTop += cellRect.height;
                }
                event.preventDefault();
            } else if (key === "ArrowLeft") {
                const currColIdx = colIds.indexOf(colId);
                if (currColIdx === -1 || currColIdx === 0) {
                    return;
                }
                const prevColId = colIds[currColIdx - 1];
                setSelectedCell({ rowStringKey, colId: prevColId });
                if (container != null && cellRect.left <= container.getBoundingClientRect().left) {
                    container.scrollLeft -= 2 * cellRect.width;
                }
                event.preventDefault();
            } else if (key === "ArrowRight") {
                const currColIdx = colIds.indexOf(colId);
                if (currColIdx === -1 || currColIdx === colIds.length - 1) {
                    return;
                }
                const nextColId = colIds[currColIdx + 1];
                setSelectedCell({ rowStringKey, colId: nextColId });
                if (container != null && cellRect.right >= container.getBoundingClientRect().right) {
                    container.scrollLeft += 2 * cellRect.width;
                }
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [columns, headRef, rows, selectedCell, setSelectedCell, tableRef]);
}

interface AnswerGridTableRealRowProps {
    row: RealRow;
    isExportOngoing: boolean;
    columns: Column[];
    isRightSidebarOpen: boolean;
    isGeneratedByFilters: boolean;
    keyColumnValues: Set<string>;
    cellGenerationStatusesByColByRow: Record<string, Record<string, LoadingStatus | undefined>>;
    rowLoadingStatus: LoadingStatus | undefined;
    selectedState: "selected" | "editing" | undefined;
    selectedColumnId: string | undefined;
    onChangeCellValue: (rowStringKey: string, colId: string, value: CellValueV2String) => void;
    onDeleteRow: (rowStringKey: string) => void;
    setSelectedCell: (cell: SelectedCell | undefined) => void;
    handleOpenRightSidebar: () => void;
    handleEditKeyColumnCell: (colId: "name", prevValue: string, value: string) => Promise<void>;
    handleDeleteColumn: (colId: string) => void;
}

const AnswerGridTableRealRow: React.FC<AnswerGridTableRealRowProps> = ({
    row,
    isExportOngoing,
    columns,
    isRightSidebarOpen,
    isGeneratedByFilters,
    keyColumnValues,
    cellGenerationStatusesByColByRow,
    rowLoadingStatus,
    selectedState,
    selectedColumnId,
    onDeleteRow,
    setSelectedCell,
    handleOpenRightSidebar,
    handleEditKeyColumnCell,
    handleDeleteColumn,
    onChangeCellValue,
}) => {
    const rowStringKey = getStringKeyFromRealRow(row);

    const handleChangeCellValue = React.useCallback(
        (colId: string, value: CellValueV2String) => {
            const col = columns.find(col => col.id === colId);
            if (col == null || col.generatedBy?.type !== "user_input") {
                return;
            }
            onChangeCellValue(rowStringKey, colId, value);
        },
        [columns, rowStringKey, onChangeCellValue],
    );

    const isSomeColumnCellLoadingByCol = React.useMemo(() => {
        const result: Record<string, boolean> = {};
        for (const [colId, rowStatuses] of Object.entries(cellGenerationStatusesByColByRow)) {
            result[colId] = Object.values(rowStatuses).some(status => status === "loading");
        }
        return result;
    }, [cellGenerationStatusesByColByRow]);

    return (
        <DeletableGridRow rowStringKey={rowStringKey} disableDelete={isExportOngoing} onDeleteRow={onDeleteRow}>
            {columns.map((column, colIdx) =>
                isKeyColumn(column) && !columns.some(col => col.label.trim() === "") && !isGeneratedByFilters ? (
                    <EditableKeyColumnCell
                        key={column.id}
                        align={column.align}
                        bannedValues={keyColumnValues}
                        colId={column.id}
                        loadingStatus={getCellLoadingStatus(
                            cellGenerationStatusesByColByRow,
                            column.id,
                            rowStringKey,
                            rowLoadingStatus,
                        )}
                        imageUrl={row.imageUrl}
                        isFirstColumn={colIdx === 0}
                        isLastColumn={colIdx === columns.length - 1}
                        value={row.data[column.id].value.value}
                        onFinishEditingCellValue={handleEditKeyColumnCell}
                    />
                ) : (
                    <GridCellWithContextMenu
                        key={column.id}
                        rowStringKey={rowStringKey}
                        colId={column.id}
                        isThisColumnLoading={isSomeColumnCellLoadingByCol[column.id] ?? false}
                        isExportOngoing={isExportOngoing}
                        isRightSidebarOpen={isRightSidebarOpen}
                        imageUrl={isKeyColumn(column) ? row.imageUrl : undefined}
                        isFirstColumn={colIdx === 0}
                        isLastColumn={colIdx === columns.length - 1}
                        generatedBy={column.generatedBy}
                        selectedState={selectedColumnId === column.id ? selectedState : undefined}
                        align={column.align}
                        valueWithCitations={row.data[column.id]}
                        isLargeStyle={column.isEditable ?? false}
                        loadingStatus={getCellLoadingStatus(
                            cellGenerationStatusesByColByRow,
                            column.id,
                            rowStringKey,
                            rowLoadingStatus,
                        )}
                        onChangeCellValue={handleChangeCellValue}
                        onDeleteRow={onDeleteRow}
                        onDeleteColumn={handleDeleteColumn}
                        onSelectCell={setSelectedCell}
                        onOpenRightSidebar={handleOpenRightSidebar}
                    />
                ),
            )}
        </DeletableGridRow>
    );
};

interface AnswerGridTablePlaceholderRowProps {
    row: PlaceholderRow;
    rowStringKey: string;
    onDeleteRow: (rowStringKey: string) => void;
    columns: Column[];
    keyColumnValues: Set<string>;
    onBlur: ((rowStringKey: string) => void) | undefined;
    handleChangePlaceholderRowValue: (rowStringKey: string, value: string) => void;
    handleOnAddRowWithRowKey: (colId: "name", value: string, rowStringKey: string) => Promise<void>;
}

const AnswerGridTablePlaceholderRow: React.FC<AnswerGridTablePlaceholderRowProps> = ({
    row,
    rowStringKey,
    onDeleteRow: handleDeleteRow,
    columns,
    keyColumnValues,
    onBlur,
    handleChangePlaceholderRowValue,
    handleOnAddRowWithRowKey,
}) => {
    return (
        <AddRowPlaceholder
            key={rowStringKey}
            autoFocus={true}
            bannedValues={keyColumnValues}
            columns={columns}
            rowStringKey={rowStringKey}
            value={row.name}
            onBlur={onBlur}
            onChangeValue={handleChangePlaceholderRowValue}
            onFinishEditingRowKeyValue={handleOnAddRowWithRowKey}
            onDeleteRow={handleDeleteRow}
        />
    );
};

interface AddRowsButtonProps {
    state: "show" | "lock";
    generatedByFiltersInfo: GeneratedByFiltersInfo | undefined;
    cursor: string;
    onClick: ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
    isLoading: boolean;
}

const AddRowsButton: React.FC<AddRowsButtonProps> = ({ state, generatedByFiltersInfo, cursor, onClick, isLoading }) => {
    const [disableButton, setDisableButton] = React.useState(false);
    const theme = useTheme();

    React.useEffect(() => {
        if (isLoading && disableButton) {
            setDisableButton(false);
        }
    }, [isLoading, disableButton]);

    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // temporarily disable the button for 1 second
            setDisableButton(true);
            setTimeout(() => {
                setDisableButton(false);
            }, 2000);
            onClick?.(e);
        },
        [onClick],
    );

    const isLocked = state === "lock";

    const button = (
        <Box
            title={isLocked ? undefined : "Add rows"}
            sx={{
                borderRight: 1,
                borderTop: 1,
                // borderBottom: 1,
                borderColor: "divider",
                cursor: isLocked || disableButton ? "not-allowed" : cursor,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "32px",
                flexDirection: "column",
                position: "sticky",
                bgcolor: "primary.main",
                left: 0,
                bottom: 0,
                zIndex: 3,
            }}
            onClick={isLocked || disableButton ? undefined : handleClick}
        >
            {isLoading ? (
                <CircularProgress
                    size={14}
                    sx={{
                        color: "primary.contrastText",
                    }}
                />
            ) : isLocked ? (
                <LockedAddRowsIcon />
            ) : generatedByFiltersInfo != null ? (
                <AddRowsIcon
                    style={{
                        color: theme.palette.secondary.main,
                        margin: "-5px",
                        position: "relative",
                    }}
                />
            ) : (
                <AddIcon
                    style={{
                        color: theme.palette.secondary.main,
                        margin: "-5px",
                        position: "relative",
                    }}
                />
            )}
        </Box>
    );

    const { value: isUpgradeDialogOpen, setTrue: openUpgradeDialog, setFalse: closeUpgradeDialog } = useBoolean();

    return isLocked ? (
        <>
            <Tooltip title={<LockedTooltipTitle onClick={openUpgradeDialog} />} placement="bottom">
                {button}
            </Tooltip>
            <UpgradePlanDialog open={isUpgradeDialogOpen} onClose={closeUpgradeDialog} />
        </>
    ) : (
        button
    );
};

interface LockedTooltipTitleProps {
    onClick: () => void;
}

const LockedTooltipTitle: React.FC<LockedTooltipTitleProps> = ({ onClick }) => {
    const user = useCurrentUserQuery();
    if (user.data?.plan_type === "free-trial") {
        return (
            <>
                {`You've reached the ${FREE_TRIAL_MAX_ROWS} total rows limit for your free trial. To add more rows, `}
                <UpgradeButton onClick={onClick} text={"upgrade to a paid plan"} />
            </>
        );
    }
    return (
        <>
            To add more rows, <UpgradeButton onClick={onClick} text={"start a free trial or a paid plan"} />
        </>
    );
};

interface UpgradeButtonProps {
    onClick: () => void;
    text: string;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ onClick, text }) => {
    return (
        <Button
            onClick={onClick}
            sx={{ color: "inherit", fontSize: 10, textDecoration: "underline" }}
            size="small"
            variant="text"
        >
            {text}
        </Button>
    );
};
