import React, { useState, useCallback, useMemo, useRef } from "react";
import { Select, MenuItem, TextField, SelectProps, Box, Typography, useTheme, SelectChangeEvent } from "@mui/material";

interface SelectWithAutocompleteProps<T extends string | number | readonly string[] | undefined>
    extends Omit<SelectProps<T>, "renderValue"> {
    options: T[];
    getOptionLabel: (option: T) => string;
    renderValue?: (value: T | "") => React.ReactNode;
    filterOption?: (option: T, inputValue: string) => boolean;
}

export function SelectWithAutocomplete<T extends string | number | readonly string[] | undefined>({
    options,
    getOptionLabel,
    renderValue,
    filterOption,
    ...selectProps
}: SelectWithAutocompleteProps<T>) {
    const [searchTerm, setSearchTerm] = useState("");
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
    const paperRef = useRef<HTMLDivElement>(null);
    const highlightedRef = useRef<HTMLLIElement>(null);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleSelectOpen = useCallback(() => {
        requestAnimationFrame(() => {
            inputRef.current?.focus();
        });
    }, []);

    const handleSelectClose = useCallback(() => {
        setSearchTerm("");
    }, []);

    const filteredOptions = useMemo(() => {
        return options.filter(option =>
            filterOption
                ? filterOption(option, searchTerm)
                : getOptionLabel(option).toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }, [options, filterOption, getOptionLabel, searchTerm]);

    const scrollHighlightedItemIntoView = useCallback(() => {
        if (highlightedRef.current && paperRef.current) {
            const paperRect = paperRef.current.getBoundingClientRect();
            const itemRect = highlightedRef.current.getBoundingClientRect();

            if (itemRect.bottom > paperRect.bottom) {
                paperRef.current.scrollTop += itemRect.bottom - paperRect.bottom;
            } else if (itemRect.top < paperRect.top) {
                paperRef.current.scrollTop -= paperRect.top - itemRect.top;
            }
        }
    }, []);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                event.preventDefault();
                const direction = event.key === "ArrowDown" ? 1 : -1;
                setHighlightedIndex(prevIndex => {
                    const newIndex = Math.max(0, Math.min(prevIndex + direction, filteredOptions.length - 1));
                    requestAnimationFrame(() => {
                        if (direction === -1 && newIndex === 0 && paperRef.current) {
                            paperRef.current.scrollTop = 0;
                        } else {
                            scrollHighlightedItemIntoView();
                        }
                    });
                    return newIndex;
                });
            } else if (event.key === "Enter" && highlightedIndex !== -1) {
                event.preventDefault();
                const selectedOption = filteredOptions[highlightedIndex];
                if (selectedOption) {
                    selectProps.onChange?.({ target: { value: selectedOption } } as SelectChangeEvent<T>, null);
                    highlightedRef.current?.click();
                }
            }
        },
        [filteredOptions, highlightedIndex, scrollHighlightedItemIntoView, selectProps],
    );

    return (
        <Select
            {...selectProps}
            renderValue={renderValue}
            onClose={handleSelectClose}
            onOpen={handleSelectOpen}
            MenuProps={{
                ...selectProps.MenuProps,
                autoFocus: false,
                PaperProps: {
                    ...selectProps.MenuProps?.PaperProps,
                    ref: paperRef,
                    style: {
                        ...selectProps.MenuProps?.PaperProps?.style,
                        maxHeight: 300,
                        overflowY: "auto",
                    },
                },
            }}
            onKeyDown={handleKeyDown}
        >
            <Box sx={{ px: 0.5, pb: 0.5, bgcolor: "background.paper", zIndex: 1 }}>
                <TextField
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={e => {
                        if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
                            e.preventDefault();
                            handleKeyDown(e);
                        } else if (e.key !== "Escape") {
                            e.stopPropagation();
                        }
                    }}
                    inputProps={{
                        sx: {
                            typography: "body2",
                            py: `${theme.spacing(0.5)} !important`,
                        },
                    }}
                />
            </Box>
            {filteredOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    ref={index === highlightedIndex ? highlightedRef : null}
                    value={option}
                    selected={index === highlightedIndex}
                    sx={{
                        backgroundColor: index === highlightedIndex ? "action.hover" : "inherit",
                        "&:active": {
                            backgroundColor: "action.selected",
                        },
                    }}
                >
                    <Typography variant="body2">{getOptionLabel(option)}</Typography>
                </MenuItem>
            ))}
            {filteredOptions.length === 0 && (
                <MenuItem disabled>
                    <Typography color="text.primary.light" variant="body2">
                        No results
                    </Typography>
                </MenuItem>
            )}
            {selectProps.value != null && !filteredOptions.includes(selectProps.value as T) && (
                <MenuItem value={selectProps.value as T} sx={{ display: "none" }}>
                    <Typography variant="body2">{getOptionLabel(selectProps.value as T)}</Typography>
                </MenuItem>
            )}
        </Select>
    );
}
